<template>
  <div>
    <div v-if="!isAr">
      <div class="hidden-sm-and-down">
        <div
          v-for="(item, i) in items"
          :key="i"
          :style="'line-height: 12px; margin: 0 0' + (lh - 24) + 'px 0'"
        >
          <v-card outlined class="d-flex transparent">
            <v-card style="margin: 0.15% 0 0 0" outlined class="transparent">
              <v-icon :color="color" class="mr-3" style="font-size: 0.9375vw">{{
                bullet ? "mdi-circle" : "mdi-square-rounded"
              }}</v-icon>
            </v-card>
            <p :style="'color:' + color">
              <span v-html="item.item"></span>
            </p>
          </v-card>
        </div>
      </div>
      <div class="hidden-md-and-up">
        <div
          v-for="(item, i) in items"
          :key="i"
          :style="'line-height: 12px; margin: 0 0' + (lh - 24) + 'px 0'"
        >
          <v-card outlined class="d-flex transparent">
            <v-card outlined class="transparent" style="margin: 1.5% 0 0 0">
              <v-icon
                :color="color"
                class="mr-3"
                style="font-size: 4.266666666666667vw"
                >{{ bullet ? "mdi-circle" : "mdi-square-rounded" }}</v-icon
              >
            </v-card>
            <v-card outlined class="transparent">
              <p :style="'font-size:4.266666666666667vw; color:' + color">
                <span v-html="item.item"></span>
              </p>
            </v-card>
          </v-card>
        </div>
      </div>
    </div>
    <!-- ARABIC -->
    <div v-if="isAr">
      <div class="hidden-sm-and-down">
        <div
          v-for="(item, i) in items"
          :key="i"
          :style="'line-height: 12px; margin: 0 0' + (lh - 24) + 'px 0'"
        >
          <v-card outlined class="d-flex justify-end transparent">
            <v-card outlined class="transparent">
              <p class="ar" :style="'font-size: 1.5625vw; color:' + color">
                <span
                  :style="'font-size:' + fontsize"
                  v-html="item.item"
                ></span>
              </p>
            </v-card>
            <v-card outlined class="transparent" style="margin: 0.5% 0 0 0">
              <v-icon
                :color="color"
                class="ml-3"
                :style="'font-size: 0.9375vw; color:' + color"
                >{{ bullet ? "mdi-circle" : "mdi-square-rounded" }}</v-icon
              >
            </v-card>
          </v-card>
        </div>
      </div>
      <div class="hidden-md-and-up">
        <div
          v-for="(item, i) in items"
          :key="i"
          :style="'line-height: 12px; margin: 0 0' + (lh - 24) + 'px 0'"
        >
          <v-card outlined class="d-flex justify-end transparent">
            <v-card outlined class="transparent">
              <p
                class="ar"
                :style="'font-size:4.266666666666667vw; color:' + color"
              >
                <span v-html="item.item"></span>
              </p>
            </v-card>
            <v-card outlined class="transparent" style="margin: 1.5% 0 0 0">
              <v-icon
                :color="color"
                class="ml-3"
                style="font-size: 4.266666666666667vw"
                >{{ bullet ? "mdi-circle" : "mdi-square-rounded" }}</v-icon
              >
            </v-card>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "vue-list",
  props: ["items", "bullet", "color", "lh", "fontsize"],
  data() {
    return {
      isAr: sessionStorage.getItem("lang"),
    };
  },
};
</script>

<style>
</style>