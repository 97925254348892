<template>
  <div align="right">
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 15.859375vw">
        <v-card outlined class="transparent" align="center">
          <h1 class="ar" style="color: #2a3977">
            الشبكية والسائل الزجاجي
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <p class="ar" style="color: #2a3977">
          تعالج هذه الوحدة مجموعة كبيرة ومتنوعة من الحالات المرضية، والتي يمكن
          أن تؤثر على الجسم الزجاجي والشبكية التي تقع على الجزء الخلفي من العين
          وهي ليست مرئية بسهولة.
        </p>
        <v-card outlined tile class="transparent" height="1.953125vw"></v-card>
        <h2 style="color: #2a9ae5; font-size: 2.5vw" class="boldm titlea ar">
          شبكية العين
        </h2>
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <h3
          style="color: #2a9ae5; font-size: 1.7187500000000002vw"
          class="boldm ar"
        >
          ما هي شبكية العين؟
        </h3>
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <p class="ar" style="color: #2a3977">
          الشبكية هي طبقة شفافة من الأنسجة العصبية في الجزء الخلفي من العين وهي
          تعمل مثل الفيلم في الكاميرا، فتقوم بالتقاط الصور التي تركز عليها
          الهياكل في الجزء الأمامي من العين. بعد التقاط الصور، تقوم شبكية العين
          بنقلها إلى الدماغ.
        </p>
        <v-card outlined tile class="transparent" height="1.09375vw"></v-card>
      </div>
      <v-card class="transparent" outlined align="center">
        <v-img width="75.9375vw" height="24.375vw" :src="photo1"></v-img>

        <v-row no-gutters>
          <v-col cols="5">
            <v-card outlined class="transparent" align="center">
              <p
                style="
                  font-size: 1.171875vw;
                  color: #204170;
                  margin: 0% -50% 0 0;
                "
              >
                <span class="sboldm">
                  الشبكية العادية العصب البصري الأوعية <br />الدموية في شبكية
                  العين البقعة
                </span>
              </p>
            </v-card>
          </v-col>
          <v-col>
            <v-card outlined class="transparent" align="center" cols="7">
              <p
                class="sboldm"
                style="
                  font-size: 1.171875vw;
                  color: #204170;
                  margin: 0% 0 0 -5%;
                "
              >
                <span class="sboldm">
                  اعتلال الشبكية من السكري اتساع الأوعية الدموية <br />وذمة
                  ميكروانيوريسم والافرازات بقع القطن
                </span>
              </p>
            </v-card></v-col
          >
        </v-row>
      </v-card>
      <div style="margin: 0 15.859375vw">
        <v-card
          outlined
          tile
          class="transparent"
          height="6.406249999999999vw"
        ></v-card>
        <h2 style="color: #2a9ae5; font-size: 2.5vw" class="boldm titlea ar">
          البقعة
        </h2>
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>

        <h3
          style="color: #2a9ae5; font-size: 1.7187500000000002vw"
          class="boldm ar"
        >
          ما هي البقعة؟
        </h3>

        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>

        <p class="ar" style="color: #2a3977">
          البقعة (ماكولا) هي الجزء المركزي للشبكية المتخصص في الرؤية عالية
          الدقة، وهي مهمة جدا في مهام مثل القراءة، والقيادة، وغيرها من الأنشطة
          التي تتطلب تمييزًا بصريًا دقيقًا. السطح الزجاجي هو مادة تشبه هلام يملأ
          العين وغالبًا ما تتعلق بأمراض مثل دموع الشبكية، انفصال الشبكية،
          وغيرها.
        </p>

        <v-card outlined tile class="transparent" height="2.265625vw"></v-card>

        <h2 style="color: #2a9ae5; font-size: 2.5vw" class="boldm titlea ar">
          مرض السكري والعيون
        </h2>

        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <p class="ar" style="color: #2a3977">
          يرتبط مرض السكري مع العديد من المضاعفات التي تصيب العيون، ولكن اعتلال
          الشبكية نتيجة للسكري هو إلى حد بعيد من المضاعفات الأكثر خطورة للجميع.
        </p>
        <v-card outlined tile class="transparent" height="2.265625vw"></v-card>

        <h3
          style="color: #2a9ae5; font-size: 1.7187500000000002vw"
          class="boldm ar"
        >
          ما الذي يسبب اعتلال الشبكية من السكري؟
        </h3>
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>

        <p class="ar" style="color: #2a3977">
          تحدث نتيجة للأضرار التي لحقت الأوعية الدموية في شبكية العين. من خلال
          البؤبؤ المتوسع، يمكن للطبيب رؤية الأوعية الدموية غير الطبيعية بشكل
          مباشر، ورؤية التورم في شبكية العين والنزيف في شبكية العين. في الحالات
          المتقدمة، تتطور الأوعية الدموية الجديدة، وإذا تركت دون مراقبة، فإنها
          سوف تنزف وتسبب فقدان شديد في الرؤية.
          <br /><br />
          الأطباء المتخصصين لدينا في مركز الشامي للعيون مجهزين لعلاج جميع
          الأمراض الطبية والجراحية التي تؤثر على الجسم الزجاجي، والبقعة، وشبكية
          العين للمرضى من جميع الأعمار.
          <br /><br />
          ونحن نستخدم الأدوات الأكثر تقدمًا في تشخيص وعلاج التنكس البقعي،
          واعتلال الشبكية من السكري والأمراض الأخرى في شبكية العين. بالإضافة إلى
          العلاجات الأكثر فعالية، نحن نستخدم أحدث وسائل الحقن والإجراءات
          الجراحية.
        </p>

        <v-card outlined tile class="transparent" height="2.96875vw"></v-card>
        <v-card
          style="margin: 0 0 4.609375vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-btn
            width="35.78125vw"
            height="4.84375vw"
            class="ar"
            depressed
            @click="overlay = !overlay"
            style="
              text-transform: none;
              font-size: 2.03125vw;
              letter-spacing: -1.5px !important;
              border-radius: 2.421875vw;
              color: white;
            "
            color="#2A9AE5"
          >
            احجز موعد استشارة
          </v-btn>
        </v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform light>
          <v-btn
            @click="overlay = !overlay"
            height="3.28125vw"
            width="12.968750000000002vw"
            depressed
            color="#2A9AE5"
            style="
              text-transform: none;
              border-radius: 3.359375vw;
              margin: 0 14px 0 0;
            "
            class="white--text btntxt"
          >
            <span class="ar" style="margin: 0 0 0 0; font-size: 1.25vw"
              >الغاء</span
            >
          </v-btn>
        </Vform>
      </v-overlay>
    </div>
    <!-- MOBILE -->
    <div class="hidden-md-and-up">
      <mimg-page :img="photo"></mimg-page>
      <div style="margin: 0 10.666666666666668vw">
        <v-card outlined class="transparent" align="center">
          <h1
            class="art"
            style="color: #2a3977; font-size: 5.333333333333334vw"
          >
            الشبكية والسائل الزجاجي
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <p
          class="sboldm arp"
          style="color: #2a3977; margin: 0; font-size: 4.266666666666667vw"
        >
          تعالج هذه الوحدة مجموعة كبيرة ومتنوعة من الحالات المرضية، والتي يمكن
          أن تؤثر على الجسم الزجاجي والشبكية التي تقع على الجزء الخلفي من العين
          وهي ليست مرئية بسهولة.
        </p>
        <h2
          style="color: #2a9ae5; margin: 8vw 0 0 0; font-size: 4.8vw"
          class="boldm titlear"
        >
          شبكية العين
        </h2>
        <h3
          style="
            color: #2a9ae5;
            font-size: 4.533333333333333vw;
            margin: 3.4666666666666663vw 0;
          "
          class="boldm subtitlemmar"
        >
          ما هي شبكية العين؟
        </h3>
        <p
          class="arp"
          style="color: #2a3977; margin: 0; font-size: 4.266666666666667vw"
        >
          الشبكية هي طبقة شفافة من الأنسجة العصبية في الجزء الخلفي من العين وهي
          تعمل مثل الفيلم في الكاميرا، فتقوم بالتقاط الصور التي تركز عليها
          الهياكل في الجزء الأمامي من العين. بعد التقاط الصور، تقوم شبكية العين
          بنقلها إلى الدماغ.
        </p>
        <v-card outlined tile class="transparent" height="4vw"></v-card>
      </div>
      <v-card outlined class="transparent" align="center">
        <v-img width="90.66666666666666vw" height="52vw" :src="g1m"></v-img>
        <p
          class="sboldm arp"
          style="margin: 1% 0 0 0; font-size: 4vw; color: #204170"
        >
          الشبكية العادية<br />
          العصب البصري<br />
          الأوعية الدموية في شبكية العين<br />
          البقعة
        </p>
      </v-card>
      <v-card outlined tile class="transparent" height="3.515625vw"></v-card>
      <v-card outlined class="transparent" align="center">
        <v-img width="63.46666666666667vw" height="52vw" :src="g2m"></v-img>
        <p
          class="sboldm arp"
          style="margin: 1% 0 0 0; font-size: 4vw; color: #204170"
        >
          اعتلال الشبكية من السكري <br />اتساع الأوعية الدموية<br />
          وذمة ميكروانيوريسم والافرازات<br />
          بقع القطن
        </p>
      </v-card>
      <div style="margin: 0 10.666666666vw">
        <v-card
          outlined
          tile
          class="transparent"
          height="6.406249999999999vw"
        ></v-card>
        <h2
          style="color: #2a9ae5; margin: 0; font-size: 4.8vw"
          class="boldm titlear"
        >
          البقعة
        </h2>

        <h3
          style="
            color: #2a9ae5;
            font-size: 4.8vw;
            margin: 3.4666666666666663vw 0;
          "
          class="boldm subtitlemmar"
        >
          ما هي البقعة؟
        </h3>

        <p
          class="arp"
          style="color: #2a3977; font-size: 4.266666666666667vw; margin: 0"
        >
          البقعة (ماكولا) هي الجزء المركزي للشبكية المتخصص في الرؤية عالية
          الدقة، وهي مهمة جدا في مهام مثل القراءة، والقيادة، وغيرها من الأنشطة
          التي تتطلب تمييزًا بصريًا دقيقًا. السطح الزجاجي هو مادة تشبه هلام يملأ
          العين وغالبًا ما تتعلق بأمراض مثل دموع الشبكية، انفصال الشبكية،
          وغيرها.
        </p>

        <h2
          style="
            color: #2a9ae5;
            font-size: 4.8vw;
            margin: 13.066666666666665vw 0 3.4666666666666663vw 0;
          "
          class="boldm titlear"
        >
          مرض السكري والعيون
        </h2>

        <p
          class="arp"
          style="color: #2a3977; font-size: 4.266666666666667vw; margin: 0"
        >
          يرتبط مرض السكري مع العديد من المضاعفات التي تصيب العيون، ولكن اعتلال
          الشبكية نتيجة للسكري هو إلى حد بعيد من المضاعفات الأكثر خطورة للجميع.
        </p>

        <h3
          style="color: #2a9ae5; font-size: 4.8vw; margin: 4.8vw 0"
          class="boldm subtitlemmar"
        >
          ما الذي يسبب اعتلال الشبكية من السكري؟
        </h3>

        <p
          class="arp"
          style="color: #2a3977; font-size: 4.266666666666667vw; margin: 0"
        >
          تحدث نتيجة للأضرار التي لحقت الأوعية الدموية في شبكية العين. من خلال
          البؤبؤ المتوسع، يمكن للطبيب رؤية الأوعية الدموية غير الطبيعية بشكل
          مباشر، ورؤية التورم في شبكية العين والنزيف في شبكية العين. في الحالات
          المتقدمة، تتطور الأوعية الدموية الجديدة، وإذا تركت دون مراقبة، فإنها
          سوف تنزف وتسبب فقدان شديد في الرؤية.
          <br /><br />
          الأطباء المتخصصين لدينا في مركز الشامي للعيون مجهزين لعلاج جميع
          الأمراض الطبية والجراحية التي تؤثر على الجسم الزجاجي، والبقعة، وشبكية
          العين للمرضى من جميع الأعمار.
          <br /><br />
          ونحن نستخدم الأدوات الأكثر تقدمًا في تشخيص وعلاج التنكس البقعي،
          واعتلال الشبكية من السكري والأمراض الأخرى في شبكية العين. بالإضافة إلى
          العلاجات الأكثر فعالية، نحن نستخدم أحدث وسائل الحقن والإجراءات
          الجراحية.
        </p>

        <v-card
          outlined
          tile
          class="transparent"
          height="10.666666666666668vw"
        ></v-card>
        <v-card
          style="margin: 0 0 14.133333333333335vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-btn
            width="70.13333333333334vw"
            height="10.133333333333333vw"
            depressed
            @click="overlay = !overlay"
            class="arp"
            style="
              text-transform: none;
              font-size: 4.266666666666667vw;
              border-radius: 5.066666666666666vw;
              color: white;
            "
            color="#2A9AE5"
          >
            احجز موعد استشارة
          </v-btn>
        </v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform :clickbtn="btnclick" light> </Vform>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import photo from "../../assets/Services/Retina/Main-photo12.png";
import photo1 from "../../assets/Services/Retina/retinapic1.png";
import g1m from "../../assets/Services/Retina/Mask Group 54.png";
import g2m from "../../assets/Services/Retina/Mask Group 55.png";

import Vform from "../../componentsar/Form";

export default {  
metaInfo: {
    title: "الشامي العيون",
  },
  components: {
    Vform,
  },

  data() {
    return {
      photo,
      g1m,
      g2m,
      photo1,
      overlay: false,
    };
  },
  methods: {
    btnclick() {
      this.overlay = false;
    },
  },
};
</script>

<style>
</style>