<template>
  <v-app>
    <main-bar></main-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <Footer class="hidden-sm-and-down"></Footer>
  </v-app>
</template>

<script>
import Footer from "./views/Footer";

export default {
  name: "App",
  metaInfo: {
    title: "Shami Eye",
  },
  components: {
    Footer,
  },

  data: () => ({
    //
  }),
};
</script>
