<template>
  <div>
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 15.859375vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977">Ophthalmic Plastic</h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>

        <p style="color: #2a3977">
          Ophthalmic plastic surgery is a unique specialty that relies on a
          surgeon’s technical ability and artistic skill. Our surgeons are
          qualified to perform aesthetic and reconstructive procedures in and
          around the delicate eye structure.
        </p>

        <v-card outlined tile class="transparent" height="1.875vw"></v-card>

        <div>
          <p class="boldm" style="color: #2a9ae5; font-size: 2.34375vw">
            <v-icon color="#2a9ae5" class="mr-3" style="font-size: 1.328125vw">
              mdi-circle </v-icon
            ><span>Eye Lids</span>
          </p>
        </div>

        <v-card outlined class="transparent"
          ><h3 style="color: #2a3977; font-size: 1.5625vw" class="boldm">
            The Ophthalmic Plastic Surgery unit focuses
          </h3></v-card
        >
        <v-card outlined tile class="transparent" height="0.78125vw"></v-card>

        <vue-list :items="items1" color="#2A9AE5" :lh="18"></vue-list>

        <v-card outlined tile class="transparent" height="2.34375vw"></v-card>

        <div>
          <p class="boldm" style="color: #2a9ae5; font-size: 2.34375vw">
            <v-icon color="#2a9ae5" class="mr-3" style="font-size: 1.328125vw">
              mdi-circle </v-icon
            ><span>Lacrimal Drainage System</span>
          </p>
          <p style="color: #2a9ae5">
            The Ophthalmic Plastic Surgery unit focuses on all problems of the
            lacrimal drainage system
          </p>
        </div>
        <v-card outlined tile class="transparent" height="2.34375vw"></v-card>

        <div>
          <p class="boldm" style="color: #2a9ae5; font-size: 2.34375vw">
            <v-icon color="#2a9ae5" class="mr-3" style="font-size: 1.328125vw">
              mdi-circle </v-icon
            ><span>Orbit</span>
          </p>
          <v-card outlined class="transparent"
            ><h3 style="color: #2a3977; font-size: 1.5625vw" class="boldm">
              The Ophthalmic Plastic Surgery unit focuses on the eyelids, orbits
              and also it includes
            </h3></v-card
          >
          <v-card outlined tile class="transparent" height="0.78125vw"></v-card>

          <vue-list :items="items2" color="#2A9AE5" :lh="18"></vue-list>
        </div>
        <v-card outlined tile class="transparent" height="2.96875vw"></v-card>
        <v-card
          style="margin: 0 0 4.609375vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-btn
            width="35.78125vw"
            height="4.84375vw"
            depressed
            @click="overlay = !overlay"
            style="
              text-transform: none;
              font-size: 2.03125vw;
              letter-spacing: -1.5px !important;
              border-radius: 2.421875vw;
              color: white;
            "
            color="#2A9AE5"
          >
            Schedule An Appointment
          </v-btn>
        </v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform light>
          <v-btn
            @click="overlay = !overlay"
            height="3.28125vw"
            width="12.968750000000002vw"
            depressed
            color="#2A9AE5"
            style="
              text-transform: none;
              border-radius: 3.359375vw;
              margin: 0 0 0 14px;
            "
            class="white--text btntxt"
          >
            <span style="margin: 0 0 0 0; font-size: 1.25vw">Cancel</span>
          </v-btn>
        </Vform>
      </v-overlay>
    </div>
    <!-- MOBILE -->
    <div class="hidden-md-and-up">
      <mimg-page :img="mphoto"></mimg-page>
      <div style="margin: 0 10.66666666vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977; font-size: 5.333333333333334vw">
            Ophthalmic Plastic
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>

        <p
          class="sboldm"
          style="color: #2a3977; font-size: 4.266666666666667vw"
        >
          Ophthalmic plastic surgery is a unique specialty that relies on a
          surgeon’s technical ability and artistic skill. Our surgeons are
          qualified to perform aesthetic and reconstructive procedures in and
          around the delicate eye structure.
        </p>

        <div>
          <p
            class="boldm"
            style="
              color: #2a9ae5;
              font-size: 2.34375vw;
              margin: 8vw 0 3.733333333333334vw 0;
            "
          >
            <v-icon
              color="#2a9ae5"
              class="mr-3 mb-1"
              style="font-size: 1.328125vw"
            >
              mdi-circle </v-icon
            ><span style="font-size: 4.8vw">Eye Lids</span>
          </p>
        </div>

        <v-card outlined class="transparent"
          ><h3
            style="
              color: #2a3977;
              font-size: 4.533333333333333vw;
              margin: 1.3333333333333335vw 0 1.6vw 0;
            "
            class="boldm"
          >
            The Ophthalmic Plastic Surgery unit focuses
          </h3></v-card
        >

        <vue-list :items="items1" color="#2A9AE5" :lh="11"></vue-list>

        <div style="margin: 10.4vw 0 0 0">
          <p
            class="boldm"
            style="
              color: #2a9ae5;
              font-size: 4vw;
              margin: 0 0 3.733333333333334vw 0;
            "
          >
            <v-icon color="#2a9ae5" class="mr-3 mb-1" style="font-size: 4vw">
              mdi-circle </v-icon
            ><span style="font-size: 4.8vw">Lacrimal Drainage System</span>
          </p>
          <p
            class="sboldm"
            style="font-size: 4.266666666666667vw; color: #2a3977"
          >
            The Ophthalmic Plastic Surgery unit focuses on all problems of the
            lacrimal drainage system
          </p>
        </div>
        <v-card
          outlined
          class="transparent"
          height="3.733333333333334vw"
        ></v-card>
        <div>
          <p
            class="boldm"
            style="color: #2a9ae5; font-size: 2.34375vw; margin: 0"
          >
            <v-icon
              color="#2a9ae5"
              class="mr-3 mb-2"
              style="font-size: 1.328125vw"
            >
              mdi-circle </v-icon
            ><span style="font-size: 4.8vw">Orbit</span>
          </p>
          <v-card outlined class="transparent"
            ><h3
              style="
                color: #2a3977;
                font-size: 4.533333333333333vw;
                margin: 3.733333333333334vw 0 4.533333333333333vw 0;
              "
              class="boldm"
            >
              The Ophthalmic Plastic Surgery unit focuses on the eyelids, orbits
              and also it includes
            </h3></v-card
          >

          <vue-list :items="items2" color="#2A9AE5" :lh="11"></vue-list>
        </div>
        <v-card
          outlined
          tile
          class="transparent"
          height="10.666666666666668vw"
        ></v-card>
        <v-card
          style="margin: 0 0 14.133333333333335vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-btn
            width="70.13333333333334vw"
            height="10.133333333333333vw"
            depressed
            @click="overlay = !overlay"
            style="
              text-transform: none;
              font-size: 4.266666666666667vw;
              letter-spacing: -1.5px !important;
              border-radius: 5.066666666666666vw;
              color: white;
            "
            color="#2A9AE5"
          >
            Schedule An Appointment
          </v-btn>
        </v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform :clickbtn="btnclick" light> </Vform>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import photo from "../../assets/Services/Oph/Main-photo15.png";
import mphoto from "../../assets/Services/Oph/Group 463.png";

import Vform from "../../components/Form";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Shami Eye | Ophthalmic Plastic",
    meta: [
      {
        name: "description",
        content:
          "  Ophthalmic plastic surgery is a unique specialty that relies on a surgeon’s technical ability and artistic skill. Our surgeons are qualified to perform aesthetic and reconstructive procedures in and around the delicate eye structure.",
      },
    ],
    // all titles will be injected into this template
  },
  components: {
    Vform,
  },
  data() {
    return {
      photo,
      mphoto,
      overlay: false,
      items1: [
        { item: "Functional disorders of the eyelids" },
        { item: "Reconstructive and cosmetic eyelid surgery" },
      ],
      items2: [
        {
          item:
            "Treatment of orbital tumors and inflammations (such as thyroid eye disease)",
        },
        { item: "Removal of eyes (Enucleation)" },
        {
          item: "Treatment for traumatic lacerations and fractures.",
        },
      ],
    };
  },
  methods: {
    btnclick() {
      this.overlay = false;
    },
  },
};
</script>

<style>
</style>