<template>
  <div>
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 15.859375vw">
        <v-card outlined class="transparent" align="center">
          <v-card
            width="22.0328125vw"
            height="7.4875vw"
            style="margin: 0 0 3vw 0"
            class="transparent"
            outlined
            ><v-img :src="popen"></v-img
          ></v-card>
          <h1 style="color: #2a3977">Shami Academy</h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>

        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <p style="color: #2a3977">
          Jordan, being a safe country in the Middle East, is considered a hub
          for academia and education among Arab physicians living in Jordan and
          neighboring countries.
          <br /><br />
          Shami Academy is an educational foundation that aims to educate
          upcoming ophthalmologists with the latest advances in the field of
          ophthalmology.
          <br /><br />
          Conferences, surgical courses and live surgery sessions held at Shami
          Academy aim to deliver a good grasp of knowledge and surgical skills
          so that participants serve patients better in their home countries.
        </p>
        <v-card outlined tile class="transparent" height="1.5625vw"></v-card>
        <v-card outlined class="transparent">
          <h2 class="boldm" style="color: #2a9ae5; line-height: 0.5">
            Hall of Fame
          </h2>
          <span style="color: #204170; font-size: 1.484375vw">
            <span class="boldm">First Ophthalmology Hall of Fame</span> & Live
            Surgery Session in Amman, Jordan.
          </span>
        </v-card>
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <p style="color: #2a3977">
          In 2015, Shami Eye Center launched its first “Hall of Fame” in which
          famous and senior scholars in ophthalmology were honored for their
          great achievements in eye health care in Jordan. In the same event,
          Shami Eye Center hosted Professor Takayuki Akahoshi, a world renowned
          cataract surgeon, who is Guinness recorded as the fastest cataract
          surgeon in the world (1 minute and 29 seconds). Professor Takayuki
          Akahoshi shared his rich and valuable experience and was a great
          addition to Shami Academy.
          <br /><br />
          <span style="color: #2a9ae5">
            "Advancing ophthalmic education to provide the best possible eye
            care”
          </span>
        </p>

        <v-card outlined tile class="transparent" height="2.96875vw"></v-card>
      </div>
    </div>
    <!-- MOBILE -->
    <div class="hidden-md-and-up">
      <mimg-page :img="mphoto"></mimg-page>
      <div style="margin: 0 10.66666666vw">
        <v-card outlined class="transparent" align="center">
          <v-card
            width="57.86666666666667vw"
            height="19.663999999999998vw"
            style="margin: 0 0 8.853333333333333vw 0"
            class="transparent"
            outlined
            ><v-img :src="popen"></v-img
          ></v-card>
          <h1 style="color: #2a3977; font-size: 5.333333333333334vw">
            Shami Academy
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>

        <p
          style="
            color: #2a3977;
            font-size: 4.266666666666667vw;
            margin: 3.4666666666666663vw 0 9.066666666666666vw 0;
          "
        >
          Jordan, being a safe country in the Middle East, is considered a hub
          for academia and education among Arab physicians living in Jordan and
          neighboring countries.
          <br /><br />
          Shami Academy is an educational foundation that aims to educate
          upcoming ophthalmologists with the latest advances in the field of
          ophthalmology.
          <br /><br />
          Conferences, surgical courses and live surgery sessions held at Shami
          Academy aim to deliver a good grasp of knowledge and surgical skills
          so that participants serve patients better in their home countries.
        </p>
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <h2
          style="color: #2a9ae5; margin: 0; font-size: 4.8vw"
          class="boldm titlea"
        >
          Hall of Fame
        </h2>
        <br />
        <p class="boldm" style="font-size: 4.533333333333333vw; color: #2a3977">
          First Ophthalmology Hall of Fame & Live Surgery Session in Amman,
          Jordan.
        </p>
        <p
          style="
            color: #2a3977;
            font-size: 4.266666666666667vw;
            margin: 3.4666666666666663vw 0 9.066666666666666vw 0;
          "
        >
          In 2015, Shami Eye Center launched its first “Hall of Fame” in which
          famous and senior scholars in ophthalmology were honored for their
          great achievements in eye health care in Jordan. In the same event,
          Shami Eye Center hosted Professor Takayuki Akahoshi, a world renowned
          cataract surgeon, who is Guinness recorded as the fastest cataract
          surgeon in the world (1 minute and 29 seconds). Professor Takayuki
          Akahoshi shared his rich and valuable experience and was a great
          addition to Shami Academy.
          <br /><br />
          <span style="color: #2a9ae5">
            "Advancing ophthalmic education to provide the best possible eye
            care”
          </span>
        </p>
        <v-card
          outlined
          tile
          class="transparent"
          height="10.666666666666668vw"
        ></v-card>
      </div>
    </div>
  </div>
</template>

<script>
import photo from "../../assets/news/academy/mask_group_50.png";
import popen from "../../assets/news/academy/Shamiacademy.png";
import mphoto from "../../assets/news/academy/Group 466.png";

export default {
  data() {
    return {
      photo,
      mphoto,
      popen,
      overlay: false,
    };
  },
};
</script>

<style>
</style>