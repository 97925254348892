<template>
  <div>
    <div v-if="!isAr">
      <div class="hidden-sm-and-down">
        <v-card
          class="d-flex align-end justify-center transparent"
          outlined
          height="11.953125vw"
          flat
          tile
          style="
            padding: 0% 4.84375vw 0 4.84375vw;
            position: absolute;
            z-index: 200;
            width: 100%;
          "
        >
          <v-card
            flat
            class="d-flex align-center"
            height="7.421875vw"
            style="
              padding: 0 0 0 4.84375vw;
              border-radius: 3.359375vw;
              width: 83.59375vw;
            "
          >
            <v-card
              style="margin: 0 9.53125vw 0 0; border-radius: 0"
              outlined
              class="transparent"
            >
              <router-link to="/">
                <v-img
                  class="shrink"
                  width="13.4375vw"
                  height="3.8281250000000004vw"
                  :src="logo"
                >
                </v-img>
              </router-link>
            </v-card>

            <v-card
              align="center"
              v-if="aboutus"
              @mouseleave="aboutus = false"
              outlined
              class="transparent"
              width="16.40625vw"
              height="17.109375vw"
              style="
                position: absolute;
                top: 5.078125vw;
                left: 21.875vw;
                border-radius: 3.359375vw;
                overflow: hidden;
              "
            >
              <v-card
                elevation="0"
                color="#2A9AE5"
                height="0.546875vw"
              ></v-card>
              <v-list>
                <v-card
                  outlined
                  class="transparent"
                  height="0.703125vw"
                ></v-card>
                <v-list-item v-for="(item, index) in items" :key="index">
                  <v-list-item-title
                    ><router-link :to="item.route"
                      ><v-card outlined class="transparent" align="center">
                        <h7 class="navbart">{{ item.title }} </h7></v-card
                      ></router-link
                    >
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
            <v-card
              class="transparent"
              outlined
              style="margin: 0 2.34375vw 0 0"
            >
              <h7 @mouseenter="allfalse('aboutus')" class="navbart">ABOUT+</h7>
            </v-card>

            <!-- Our Serivces Pop Down -->
            <v-card
              align="center"
              v-if="ourservices"
              outlined
              class="transparent"
              @mouseleave="ourservices = false"
              width="18.671875vw"
              height="25.937500000000004vw"
              style="
                position: absolute;
                top: 5.078125vw;
                left: 30.15625vw;
                border-radius: 3.359375vw;
                overflow: hidden;
              "
            >
              <v-card
                elevation="0"
                color="#2A9AE5"
                height="0.546875vw"
              ></v-card>
              <v-list>
                <v-card
                  outlined
                  class="transparent"
                  height="0.703125vw"
                ></v-card>
                <v-list-item v-for="(item, index) in services" :key="index">
                  <v-list-item-title>
                    <router-link :to="item.route">
                      <v-card outlined class="transparent" align="center">
                        <h7 class="navbart">{{ item.title }} </h7></v-card
                      >
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
            <v-card
              class="transparent"
              outlined
              style="margin: 0 2.03125vw 0 0"
            >
              <h7 @mouseenter="allfalse('ourservices')" class="navbart"
                >OUR SERVICES+</h7
              >
            </v-card>
            <!-- News Pop Down -->
            <v-card
              align="center"
              v-if="news"
              @mouseleave="news = false"
              outlined
              class="transparent"
              width="16.40625vw"
              height="14.0625vw"
              style="
                position: absolute;
                top: 5.078125vw;
                left: 42.265625vw;
                border-radius: 3.359375vw;
                overflow: hidden;
              "
            >
              <v-card
                elevation="0"
                color="#2A9AE5"
                height="0.546875vw"
              ></v-card>
              <v-list>
                <v-card
                  outlined
                  class="transparent"
                  height="0.703125vw"
                ></v-card>
                <v-list-item v-for="(item, index) in newsitems" :key="index">
                  <v-list-item-title>
                    <router-link :to="item.route">
                      <v-card outlined class="transparent" align="center">
                        <h7 class="navbart">{{ item.title }} </h7></v-card
                      >
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
            <v-card
              class="transparent"
              outlined
              style="margin: 0 2.421875vw 0 0"
            >
              <h7 @mouseenter="allfalse('news')" class="navbart">NEWS ROOM+</h7>
            </v-card>
            <v-card
              class="transparent"
              style="margin: 0 2.03125vw 0 0"
              outlined
            >
              <router-link
                style="text-decoration: none !important"
                to="/medical"
              >
                <h7 class="navbart">MEDICAL TIPS</h7>
              </router-link>
            </v-card>
            <v-card
              class="transparent"
              style="margin: 0 2.65625vw 0 0"
              outlined
            >
              <router-link
                style="text-decoration: none !important"
                to="/contact"
              >
                <h7 class="navbart">CONTACT</h7>
              </router-link>
            </v-card>
            <div>
              <button @click="convertAr()" class="btn-tb">Ar</button>
            </div>
          </v-card>
        </v-card>
      </div>

      <!-- MOBILE -->
      <div class="hidden-md-and-up">
        <v-card
          class="d-flex align-end justify-center transparent"
          outlined
          flat
          tile
          style="
            padding: 0% 4.84375vw 0 4.84375vw;
            position: absolute;
            z-index: 200;
            width: 100%;
            margin: 4.266666666666667vw 0 0 0;
          "
        >
          <v-card
            flat
            class="d-flex align-center"
            height="16.266666666666666vw"
            style="
              padding: 0 0 0 5.066666666666666vw;
              border-radius: 5.866666666666666vw;
              width: 88.26666666666667vw;
            "
          >
            <v-card
              style="margin: 0 30.4vw 0 0; border-radius: 0"
              outlined
              class="transparent"
            >
              <router-link to="/">
                <v-img
                  class="shrink"
                  width="29.599999999999998vw"
                  height="8.533333333333333vw"
                  :src="logo"
                >
                </v-img>
              </router-link>
            </v-card>
            <v-card @click="convertAr()" outlined class="transparent">
              <button
                style="
                  height: 7.199999999999999vw;
                  width: 7.199999999999999vw;
                  background-color: #2a9ae5;
                  border-radius: 50%;
                "
              >
                <h2
                  style="
                    margin: 0;
                    font-size: 2.933333333333333vw;
                    color: white;
                  "
                >
                  Ar
                </h2>
              </button>
            </v-card>
            <v-card
              style="border-radius: 0%; margin: 0 0 0 4.266666666666667vw"
              outlined
              class="transparent"
            >
              <v-img
                width="5.104vw"
                height="4.424vw"
                :src="burgermenu"
                @click.stop="drawer = !drawer"
              >
              </v-img>
            </v-card>
          </v-card>
        </v-card>
        <!-- height: 71.2vw !important; with test -->
        <v-navigation-drawer
          width="57.333333333333336vw"
          style="
            z-index: 300;
            height: 62vw !important;
            margin: 20vw 6.133333333333333vw 0 0;
            border-radius: 11.466666666666667vw;
          "
          v-model="drawer"
          absolute
          right
        >
          <v-card flat color="#2a9ae5" height="2.933333333333333vw"> </v-card>

          <v-list nav dense>
            <v-list-item-group
              v-model="group"
              active-class="deep-purple--text text--accent-4"
              style="margin: 0 5.866666666666666vw"
            >
              <v-list-item
                @click="aboutusm = !aboutusm"
                style="margin: 3vw 0 4.533333333333333vw 0"
              >
                <v-list-item-title>
                  <v-row no gutter>
                    <v-col cols="6">
                      <p
                        style="
                          color: #204170;
                          font-size: 4.266666666666667vw;
                          margin: 0 0 0 0;
                        "
                      >
                        ABOUT
                      </p></v-col
                    >
                    <v-col
                      style="color: #204170; font-size: 4.266666666666667vw"
                      align-self="center"
                      align="right"
                      cols="6"
                    >
                      <span v-if="!aboutusm">+</span>
                      <span v-if="aboutusm">-</span></v-col
                    >
                  </v-row></v-list-item-title
                >
              </v-list-item>
              <v-card
                v-if="aboutusm"
                outlined
                class="transparent"
                style="margin: -5% 0 0 0"
              >
                <v-list-item to="/about" style="margin: 0 0 1.6vw 0">
                  <v-list-item-title>
                    <p
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      About Us
                    </p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item to="/meettheteam" style="margin: 0 0 1.6vw 0">
                  <v-list-item-title>
                    <p
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      Meet The Team
                    </p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item to="/faq" style="margin: 0 0 1.6vw 0">
                  <v-list-item-title>
                    <p
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      FAQ
                    </p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item to="/insurance" style="margin: 0 0 1.6vw 0">
                  <v-list-item-title>
                    <p
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      Insurance Companies
                    </p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  to="/careers"
                  style="margin: 0 0 2.1333333333333333vww 0"
                >
                  <v-list-item-title>
                    <p
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      Careers
                    </p>
                  </v-list-item-title>
                </v-list-item>
              </v-card>
              <v-list-item
                @click="ourservicesm = !ourservicesm"
                style="margin: 0 0 4.533333333333333vw 0"
              >
                <v-list-item-title>
                  <v-row no gutter>
                    <v-col cols="6">
                      <p
                        style="
                          color: #204170;
                          font-size: 4.266666666666667vw;
                          margin: 0 0 0 0;
                        "
                      >
                        OUR SERVICES
                      </p></v-col
                    >
                    <v-col
                      style="color: #204170; font-size: 4.266666666666667vw"
                      align-self="center"
                      align="right"
                      cols="6"
                    >
                      <span v-if="!ourservicesm">+</span>
                      <span v-if="ourservicesm">-</span></v-col
                    >
                  </v-row></v-list-item-title
                >
              </v-list-item>
              <v-card
                v-if="ourservicesm"
                outlined
                class="transparent"
                style="margin: -5% 0 0 0"
              >
                <v-list-item to="/visioncorrection" style="margin: 0 0 1.6vw 0">
                  <v-list-item-title>
                    <p
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      Vision Correction
                    </p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item to="/cataract" style="margin: 0 0 1.6vw 0">
                  <v-list-item-title>
                    <p
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      Cataract
                    </p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item to="/retina" style="margin: 0 0 1.6vw 0">
                  <v-list-item-title>
                    <p
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      Retina
                    </p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item to="/cornea" style="margin: 0 0 1.6vw 0">
                  <v-list-item-title>
                    <p
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      Cornea
                    </p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item to="/glaucoma" style="margin: 0 0 1.6vw 0">
                  <v-list-item-title>
                    <p
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      Glaucoma
                    </p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item to="/post" style="margin: 0 0 1.6vw 0">
                  <v-list-item-title>
                    <p
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      Post Operation
                    </p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  to="/pediatric"
                  style="margin: 0 0 2.1333333333333333vww 0"
                >
                  <v-list-item-title>
                    <p
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      Pediatrics Ophthalmology
                    </p>
                  </v-list-item-title>
                </v-list-item>
              </v-card>
              <v-list-item
                @click="newsm = !newsm"
                style="margin: 0 0 4.533333333333333vw 0"
              >
                <v-list-item-title>
                  <v-row no gutter>
                    <v-col cols="6">
                      <p
                        style="
                          color: #204170;
                          font-size: 4.266666666666667vw;
                          margin: 0 0 0 0;
                        "
                      >
                        NEWS ROOM
                      </p></v-col
                    >
                    <v-col
                      style="color: #204170; font-size: 4.266666666666667vw"
                      align-self="center"
                      align="right"
                      cols="6"
                    >
                      <span v-if="!newsm">+</span>
                      <span v-if="newsm">-</span></v-col
                    >
                  </v-row></v-list-item-title
                >
              </v-list-item>
              <v-card
                v-if="newsm"
                outlined
                class="transparent"
                style="margin: -5% 0 0 0"
              >
                <v-list-item to="/csr" style="margin: 0 0 1.6vw 0">
                  <v-list-item-title>
                    <p
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      CSR
                    </p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item to="/academy" style="margin: 0 0 1.6vw 0">
                  <v-list-item-title>
                    <p
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      Shami Academy
                    </p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item to="/athletics" style="margin: 0 0 1.6vw 0">
                  <v-list-item-title>
                    <p
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      Shami Athletics
                    </p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  to="/gallery"
                  style="margin: 0 0 2.1333333333333333vww 0"
                >
                  <v-list-item-title>
                    <p
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      Gallery
                    </p>
                  </v-list-item-title>
                </v-list-item>
              </v-card>

              <v-list-item
                to="/medical"
                style="margin: 0 0 4.533333333333333vw 0"
              >
                <v-list-item-title>
                  <v-row no gutter>
                    <v-col cols="6">
                      <p
                        style="
                          color: #204170;
                          font-size: 4.266666666666667vw;
                          margin: 0 0 0 0;
                        "
                      >
                        MEDICAL TIPS
                      </p></v-col
                    >
                    <v-col
                      style="font-size: 4.266666666666667vw"
                      align-self="center"
                      align="right"
                      cols="6"
                    >
                    </v-col> </v-row
                ></v-list-item-title>
              </v-list-item>
              <!-- <v-list-item to="test" style="margin: 0 0 4.533333333333333vw 0">
                <v-list-item-title>
                  <v-row no gutter>
                    <v-col cols="6">
                      <p
                        style="
                          color: #204170;
                          font-size: 4.266666666666667vw;
                          margin: 0 0 0 0;
                        "
                      >
                        TESTIMONIALS
                      </p></v-col
                    >
                    <v-col
                      style="font-size: 4.266666666666667vw"
                      align-self="center"
                      align="right"
                      cols="6"
                    >
                    </v-col> </v-row
                ></v-list-item-title>
              </v-list-item> -->
              <v-list-item
                to="/contact"
                style="margin: 0 0 4.533333333333333vw 0"
              >
                <v-list-item-title>
                  <v-row no gutter>
                    <v-col cols="6">
                      <p
                        style="
                          color: #204170;
                          font-size: 4.266666666666667vw;
                          margin: 0 0 0 0;
                        "
                      >
                        CONTACT
                      </p></v-col
                    >
                    <v-col
                      style="font-size: 4.266666666666667vw"
                      align-self="center"
                      align="right"
                      cols="6"
                    >
                    </v-col> </v-row
                ></v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </div>
    </div>
    <!-- ARABIC -->
    <div v-if="isAr">
      <div class="hidden-sm-and-down">
        <v-card
          class="d-flex align-end justify-center transparent"
          outlined
          height="11.953125vw"
          flat
          tile
          style="
            padding: 0% 4.84375vw 0 4.84375vw;
            position: absolute;
            z-index: 200;
            width: 100%;
          "
        >
          <v-card
            flat
            class="d-flex align-center"
            height="7.421875vw"
            style="
              padding: 0 0 0 5.390625vw;
              border-radius: 3.359375vw;
              width: 83.59375vw;
            "
          >
            <div style="margin: 0 4.375vw 0 0">
              <button @click="convertEn()" class="btn-tb">En</button>
            </div>
            <v-card
              align="center"
              v-if="aboutus"
              @mouseleave="aboutus = false"
              outlined
              class="transparent"
              width="16.40625vw"
              height="17.109375vw"
              style="
                position: absolute;
                top: 5.078125vw;
                left: 45.15625vw;
                border-radius: 3.359375vw;
                overflow: hidden;
              "
            >
              <v-card
                elevation="0"
                color="#2A9AE5"
                height="0.546875vw"
              ></v-card>
              <v-list>
                <v-card
                  outlined
                  class="transparent"
                  height="0.703125vw"
                ></v-card>
                <v-list-item v-for="(item, index) in itemsar" :key="index">
                  <v-list-item-title
                    ><router-link :to="item.route"
                      ><v-card outlined class="transparent" align="center">
                        <h7 class="h7ar navbart ar"
                          >{{ item.title }}
                        </h7></v-card
                      ></router-link
                    >
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
            <v-card class="transparent" outlined style="margin: 0 0 0 0">
              <router-link
                style="text-decoration: none !important"
                to="/ar/contact"
              >
                <h7 class="h7ar navbart ar">تواصل معنا</h7>
              </router-link>
            </v-card>

            <!-- Our Serivces Pop Down -->
            <v-card
              align="center"
              v-if="ourservices"
              outlined
              class="transparent"
              @mouseleave="ourservices = false"
              width="18.671875vw"
              height="25.937500000000004vw"
              style="
                position: absolute;
                top: 5.078125vw;
                left: 35.09375vw;
                border-radius: 3.359375vw;
                overflow: hidden;
              "
            >
              <v-card
                elevation="0"
                color="#2A9AE5"
                height="0.546875vw"
              ></v-card>
              <v-list>
                <v-card
                  outlined
                  class="transparent"
                  height="0.703125vw"
                ></v-card>
                <v-list-item v-for="(item, index) in servicesar" :key="index">
                  <v-list-item-title>
                    <router-link :to="item.route">
                      <v-card outlined class="transparent" align="center">
                        <h7 class="h7ar navbart ar"
                          >{{ item.title }}
                        </h7></v-card
                      >
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
            <v-card
              class="transparent"
              outlined
              style="margin: 0 2.3vw 0 3.296875vw"
            >
              <router-link
                style="text-decoration: none !important"
                to="/ar/medical"
              >
                <h7 class="navbart h7ar ar">نصائح طبية</h7>
              </router-link>
            </v-card>
            <!-- News Pop Down -->
            <v-card
              align="center"
              v-if="news"
              @mouseleave="news = false"
              outlined
              class="transparent ar"
              width="16.40625vw"
              height="14.0625vw"
              style="
                position: absolute;
                top: 5.078125vw;
                left: 26.34375vw;
                border-radius: 3.359375vw;
                overflow: hidden;
              "
            >
              <v-card
                elevation="0"
                color="#2A9AE5"
                height="0.546875vw"
              ></v-card>
              <v-list>
                <v-card
                  outlined
                  class="transparent"
                  height="0.703125vw"
                ></v-card>
                <v-list-item v-for="(item, index) in newsitemsar" :key="index">
                  <v-list-item-title>
                    <router-link :to="item.route">
                      <v-card outlined class="transparent" align="center">
                        <h7 class="h7ar navbart ar"
                          >{{ item.title }}
                        </h7></v-card
                      >
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
            <v-card class="transparent" outlined style="margin: 0 0 0 0">
              <h7 @mouseenter="allfalse('news')" class="ar navbart h7ar"
                >+ غرفة الاخبار</h7
              >
            </v-card>
            <v-card class="transparent" style="margin: 0 4vw 0 3.9vw" outlined>
              <h7 @mouseenter="allfalse('ourservices')" class="navbart ar h7ar"
                >+ خدماتنا</h7
              >
            </v-card>
            <v-card class="transparent" style="margin: 0 8vw 0 0" outlined>
              <h7 @mouseenter="allfalse('aboutus')" class="h7ar navbart ar"
                >+ من نحن</h7
              >
            </v-card>

            <v-card
              style="margin: 0 0 0 0; border-radius: 0"
              outlined
              class="transparent"
            >
              <router-link to="/ar">
                <v-img
                  class="shrink"
                  width="13.4375vw"
                  height="3.8281250000000004vw"
                  :src="logoar"
                >
                </v-img>
              </router-link>
            </v-card>
          </v-card>
        </v-card>
      </div>

      <!-- MOBILE -->
      <div class="hidden-md-and-up">
        <v-card
          class="d-flex align-end justify-center transparent"
          outlined
          flat
          tile
          style="
            padding: 0% 4.84375vw 0 4.84375vw;
            position: absolute;
            z-index: 200;
            width: 100%;
            margin: 4.266666666666667vw 0 0 0;
          "
        >
          <v-card
            flat
            class="d-flex align-center"
            height="16.266666666666666vw"
            style="
              padding: 0 5.066666666666666vw 0 0;
              border-radius: 5.866666666666666vw;
              width: 88.26666666666667vw;
            "
          >
            <v-card
              style="border-radius: 0%; margin: 0 0 0 4.266666666666667vw"
              outlined
              class="transparent"
            >
              <v-img
                width="5.104vw"
                height="4.424vw"
                :src="burgermenu"
                @click.stop="drawer = !drawer"
              >
              </v-img>
            </v-card>
            <v-card
              style="margin: 0 31.2vw 0 4.266666666666667vw"
              outlined
              class="transparent"
              @click="convertEn()"
            >
              <button
                style="
                  height: 7.199999999999999vw;
                  width: 7.199999999999999vw;
                  background-color: #2a9ae5;
                  border-radius: 50%;
                "
              >
                <h2
                  style="
                    margin: 0;
                    font-size: 2.933333333333333vw;
                    color: white;
                  "
                >
                  En
                </h2>
              </button>
            </v-card>

            <v-card
              style="margin: 0 0 0 0; border-radius: 0"
              outlined
              class="transparent"
            >
              <router-link to="/ar">
                <v-img
                  class="shrink"
                  width="29.599999999999998vw"
                  height="8.533333333333333vw"
                  :src="logoar"
                >
                </v-img>
              </router-link>
            </v-card>
          </v-card>
        </v-card>
        <v-navigation-drawer
          width="57.333333333333336vw"
          style="
            z-index: 300;
            height: 71.2vw !important;
            margin: 20vw 0 0 6.133333333333333vw;
            border-radius: 11.466666666666667vw;
          "
          v-model="drawer"
          absolute
          left
        >
          <v-card flat color="#2a9ae5" height="2.933333333333333vw"> </v-card>

          <v-list nav dense>
            <v-list-item-group
              v-model="group"
              active-class="deep-purple--text text--accent-4"
              style="margin: 0 5.866666666666666vw"
            >
              <v-list-item
                @click="aboutusm = !aboutusm"
                style="margin: 3vw 0 4.533333333333333vw 0"
              >
                <v-list-item-title>
                  <v-row no gutter>
                    <v-col
                      style="color: #204170; font-size: 4.26667vw"
                      align-self="center"
                      align="left"
                      cols="6"
                    >
                      <span v-if="!aboutusm">+</span>
                      <span v-if="aboutusm">-</span></v-col
                    >
                    <v-col cols="6">
                      <p
                        class="ar"
                        style="
                          color: #204170;
                          font-size: 4.26667vw;
                          margin: 0 0 0 0;
                          text-align: right;
                        "
                      >
                        من نحن
                      </p></v-col
                    >
                  </v-row></v-list-item-title
                >
              </v-list-item>
              <v-card
                v-if="aboutusm"
                outlined
                class="transparent"
                style="margin: -5% 0 0 0"
                align="right"
              >
                <v-list-item to="/ar/about" style="margin: 0 0 1.6vw 0">
                  <v-list-item-title>
                    <p
                      class="ar"
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      نبذة عنا
                    </p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item to="/ar/meettheteam" style="margin: 0 0 1.6vw 0">
                  <v-list-item-title>
                    <p
                      class="ar"
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      أطباء الشامي للعيون
                    </p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item to="/ar/faq" style="margin: 0 0 1.6vw 0">
                  <v-list-item-title>
                    <p
                      class="ar"
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      الأسئلة المتداولة
                    </p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item to="/ar/insurance" style="margin: 0 0 1.6vw 0">
                  <v-list-item-title>
                    <p
                      class="ar"
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      شركات التأمين
                    </p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  to="/ar/careers"
                  style="margin: 0 0 2.1333333333333333vww 0"
                >
                  <v-list-item-title>
                    <p
                      class="ar"
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      وظائف
                    </p>
                  </v-list-item-title>
                </v-list-item>
              </v-card>
              <v-list-item
                @click="ourservicesm = !ourservicesm"
                style="margin: 0 0 4.533333333333333vw 0"
              >
                <v-list-item-title>
                  <v-row no gutter>
                    <v-col
                      style="color: #204170; font-size: 4.26667vw"
                      align-self="center"
                      align="ledt"
                      cols="6"
                    >
                      <span v-if="!ourservicesm">+</span>
                      <span v-if="ourservicesm">-</span></v-col
                    >
                    <v-col cols="6">
                      <p
                        class="ar"
                        style="
                          color: #204170;
                          font-size: 4.26667vw;
                          margin: 0 0 0 0;
                          text-align: right;
                        "
                      >
                        خدماتنا
                      </p></v-col
                    >
                  </v-row></v-list-item-title
                >
              </v-list-item>
              <v-card
                v-if="ourservicesm"
                outlined
                class="transparent"
                style="margin: -5% 0 0 0"
                align="right"
              >
                <v-list-item
                  to="/ar/visioncorrection"
                  style="margin: 0 0 1.6vw 0"
                >
                  <v-list-item-title>
                    <p
                      class="ar"
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      تصحيح النظر
                    </p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item to="/ar/cataract" style="margin: 0 0 1.6vw 0">
                  <v-list-item-title>
                    <p
                      class="ar"
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      الماء الابيض
                    </p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item to="/ar/retina" style="margin: 0 0 1.6vw 0">
                  <v-list-item-title>
                    <p
                      class="ar"
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      الشبكية
                    </p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item to="/ar/cornea" style="margin: 0 0 1.6vw 0">
                  <v-list-item-title>
                    <p
                      class="ar"
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      القرنية
                    </p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item to="/ar/glaucoma" style="margin: 0 0 1.6vw 0">
                  <v-list-item-title>
                    <p
                      class="ar"
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      الزرق (الجلوكوما)
                    </p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item to="/ar/post" style="margin: 0 0 1.6vw 0">
                  <v-list-item-title>
                    <p
                      class="ar"
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      العناية ما بعد العملية
                    </p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  to="/ar/pediatric"
                  style="margin: 0 0 2.1333333333333333vww 0"
                >
                  <v-list-item-title>
                    <p
                      class="ar"
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      عيون الأطفال
                    </p>
                  </v-list-item-title>
                </v-list-item>
              </v-card>
              <v-list-item
                @click="newsm = !newsm"
                style="margin: 0 0 4.533333333333333vw 0"
              >
                <v-list-item-title>
                  <v-row no gutter>
                    <v-col
                      style="color: #204170; font-size: 4.266666666666667vw"
                      align-self="center"
                      align="left"
                      cols="6"
                    >
                      <span v-if="!newsm">+</span>
                      <span v-if="newsm">-</span></v-col
                    >
                    <v-col cols="6">
                      <p
                        class="ar"
                        style="
                          color: #204170;
                          font-size: 4.266666666666667vw;
                          margin: 0 0 0 -12%;
                        "
                      >
                        غرفة الاخبار
                      </p></v-col
                    >
                  </v-row></v-list-item-title
                >
              </v-list-item>
              <v-card
                v-if="newsm"
                outlined
                class="transparent"
                style="margin: -5% 0 0 0"
                align="right"
              >
                <v-list-item to="/ar/csr" style="margin: 0 0 1.6vw 0">
                  <v-list-item-title>
                    <p
                      class="ar"
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      المسؤولية الاجتماعية
                    </p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item to="/ar/academy" style="margin: 0 0 1.6vw 0">
                  <v-list-item-title>
                    <p
                      class="ar"
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      أكاديمية الشامي
                    </p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item to="/ar/athletics" style="margin: 0 0 1.6vw 0">
                  <v-list-item-title>
                    <p
                      class="ar"
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      الشامي للرياضة
                    </p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  to="/ar/gallery"
                  style="margin: 0 0 2.1333333333333333vww 0"
                >
                  <v-list-item-title>
                    <p
                      class="ar"
                      style="
                        color: #2a9ae5;
                        font-size: 3.733333333333334vw;
                        margin: 0 0 0 0;
                      "
                    >
                      معرض الصور
                    </p>
                  </v-list-item-title>
                </v-list-item>
              </v-card>
              <v-list-item
                to="/ar/medical"
                style="margin: 0 0 4.533333333333333vw 0"
              >
                <v-list-item-title>
                  <v-row no gutter>
                    <v-col
                      style="font-size: 4.266666666666667vw"
                      align-self="center"
                      align="right"
                      cols="6"
                    >
                    </v-col>
                    <v-col cols="6">
                      <p
                        class="ar"
                        style="
                          color: #204170;
                          font-size: 4.266666666666667vw;
                          margin: 0 0 0 -15%;
                        "
                      >
                        نصائح طبية
                      </p></v-col
                    >
                  </v-row></v-list-item-title
                >
              </v-list-item>
              <v-list-item
                to="/ar/contact"
                style="margin: 0 0 4.533333333333333vw 0"
              >
                <v-list-item-title>
                  <v-row no gutter>
                    <v-col
                      style="font-size: 4.266666666666667vw"
                      align-self="center"
                      align="right"
                      cols="6"
                    >
                    </v-col>
                    <v-col cols="6">
                      <p
                        class="ar"
                        style="
                          color: #204170;
                          font-size: 4.266666666666667vw;
                          margin: 0 0 0 0;
                          text-align: right;
                        "
                      >
                        التوصيات
                      </p></v-col
                    ></v-row
                  ></v-list-item-title
                >
              </v-list-item>
              <v-list-item
                to="/ar/contact"
                style="margin: 0 0 4.533333333333333vw 0"
              >
                <v-list-item-title>
                  <v-row no gutter>
                    <v-col
                      style="font-size: 4.266666666666667vw"
                      align-self="center"
                      align="right"
                      cols="5"
                    >
                    </v-col>
                    <v-col class="pl-5" cols="7">
                      <p
                        class="ar"
                        style="
                          color: #204170;
                          font-size: 4.266666666666667vw;
                          margin: 0 0 0 0;
                        "
                      >
                        تواصل معنا
                      </p></v-col
                    ></v-row
                  ></v-list-item-title
                >
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </div>
    </div>
  </div>
</template>

<script>
import logo from "../assets/ELogo.png";
import logoar from "../assets/ALogo.png";
import burgermenu from "../assets/Group -1.png";

export default {
  name: "navbar",
  data: () => ({
    logo,
    logoar,
    burgermenu,
    drawer: false,
    aboutusm: false,
    newsm: false,
    ourservicesm: false,
    items: [
      { title: "ABOUT US", route: "/about" },
      { title: "MEET THE TEAM", route: "/meettheteam" },
      { title: "FAQ", route: "/faq" },
      { title: "INSURANCE COMPANIES", route: "/insurance" },
      { title: "CAREERS", route: "/careers" },
    ],
    itemsar: [
      { title: "نبذة عنا", route: "/ar/about" },
      { title: "أطباء الشامي للعيون", route: "/ar/meettheteam" },
      { title: "الأسئلة المتداولة", route: "/ar/faq" },
      { title: "شركات التأمين", route: "/ar/insurance" },
      { title: "وظائف", route: "/ar/careers" },
    ],
    services: [
      { title: "VISION CORRECTION", route: "/visioncorrection" },
      { title: "CATARACT", route: "/cataract" },
      { title: "RETINA", route: "/retina" },
      { title: "CORNEA", route: "/cornea" },
      { title: "GLAUCOMA", route: "/glaucoma" },
      { title: "POST OPERATION", route: "/post" },
      { title: "PEDIATRICS OPHTHALMOLOGY", route: "/pediatric" },
      { title: "OPHTHALMIC PLASTIC", route: "/ophthalmic" },
    ],
    servicesar: [
      { title: "تصحيح النظر", route: "/ar/visioncorrection" },
      { title: "الماء الابيض", route: "/ar/cataract" },
      { title: "الشبكية", route: "/ar/retina" },
      { title: "القرنية", route: "/ar/cornea" },
      { title: "الزرق (الجلوكوما)", route: "/ar/glaucoma" },
      { title: "العناية ما بعد العملية", route: "/ar/post" },
      { title: "عيون الأطفال", route: "/ar/pediatric" },
      { title: "تجميل العيون", route: "/ar/ophthalmic" },
    ],
    newsitems: [
      { title: "CSR", route: "/csr" },
      { title: "SHAMI ACADEMY", route: "/academy" },
      { title: "SHAMI ATHLETICS", route: "/athletics" },
      { title: "GALLERY", route: "/gallery" },
      { title: "ZOOM", route: "/zoom" },
    ],
    newsitemsar: [
      { title: "المسؤولية الاجتماعية", route: "/ar/csr" },
      { title: "أكاديمية الشامي", route: "/ar/academy" },
      { title: "الشامي للرياضة", route: "/ar/athletics" },
      { title: "معرض الصور", route: "/ar/gallery" },
    ],
    closeOnClick: true,
    ourservices: false,
    aboutus: false,
    news: false,
    isAr: sessionStorage.getItem("lang"),
  }),

  methods: {
    consoleThis() {
      console.log("Clicked");
    },
    convertAr() {
      sessionStorage.setItem("lang", true);
      this.$router.push("/ar");
      location.reload();
    },
    convertEn() {
      sessionStorage.setItem("lang", "");
      this.$router.push("/");
      location.reload();
    },
    // eslint-disable-next-line no-unused-vars
    allfalse(item) {
      if (item == "ourservices") {
        this.aboutus = false;
        this.news = false;
        this.ourservices = true;
      } else if (item == "news") {
        this.ourservices = false;
        this.aboutus = false;
        this.news = true;
      } else if (item == "aboutus") {
        this.ourservices = false;
        this.news = false;
        this.aboutus = true;
      }
    },
  },
};
</script>

<style>
.btn-tb {
  width: 2.8125vw;
  height: 2.8125vw;
  background-color: #2a9ae5;
  border-radius: 1.953125vw;
  color: white;
}

h7 {
  color: #204170;
  font-size: 0.875rem;
}

.v-list--nav .v-list-item {
  padding: 0px 0px !important;
}

.v-menu__content {
  border-radius: 3.359375vw !important;
  max-height: max(17.109375vw, 300px) !important;
  text-align: center;
}

.v-list-item {
  min-height: 1.40625vw !important;
  margin-bottom: 12px;
}

.v-application .deep-purple--text.text--accent-4 {
  color: white !important;
  caret-color: white !important;
}

a {
  text-decoration: none !important;
}
</style>
