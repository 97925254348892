<template>
  <div>
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 15.859375vw">
        <v-card outlined class="transparent" align="center">
          <h1 class="ar" style="color: #2a3977">
            العناية ما بعد العملية
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>

        <p class="ar" style="color: #2a3977">
          في مركز الشامي للعيون لدينا وحدة منفصلة بعد الجراحة لمتابعة مرضانا
          وتقديم أفضل النتائج وخدمة الرعاية
        </p>

        <v-card outlined tile class="transparent" height="2.96875vw"></v-card>
        <v-card
          style="margin: 0 0 4.609375vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-btn
            width="35.78125vw"
            height="4.84375vw"
            depressed
            class="ar"
            @click="overlay = !overlay"
            style="
              text-transform: none;
              font-size: 2.03125vw;
              letter-spacing: -1.5px !important;
              border-radius: 2.421875vw;
              color: white;
            "
            color="#2A9AE5"
          >
            احجز موعد استشارة
          </v-btn>
        </v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform light>
          <v-btn
            @click="overlay = !overlay"
            height="3.28125vw"
            width="12.968750000000002vw"
            depressed
            color="#2A9AE5"
            style="
              text-transform: none;
              border-radius: 3.359375vw;
              margin: 0 14px 0 0;
            "
            class="white--text btntxt"
          >
            <span class="ar" style="margin: 0 0 0 0; font-size: 1.25vw"
              >الغاء</span
            >
          </v-btn>
        </Vform>
      </v-overlay>
    </div>
    <!-- MOBILE -->
    <div align="right" class="hidden-md-and-up">
      <mimg-page :img="mphoto"></mimg-page>
      <div style="margin: 0 10.66666666vw">
        <v-card outlined class="transparent" align="center">
          <h1
            class="art"
            style="color: #2a3977; font-size: 5.333333333333334vw"
          >
            العناية ما بعد العملية
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>

        <p
          class="arp"
          style="color: #2a3977; font-size: 4.266666666666667vw; margin: 0"
        >
          في مركز الشامي للعيون لدينا وحدة منفصلة بعد الجراحة لمتابعة مرضانا
          وتقديم أفضل النتائج وخدمة الرعاية.
        </p>

        <v-card
          outlined
          tile
          class="transparent"
          height="10.666666666666668vw"
        ></v-card>
        <v-card
          style="margin: 0 0 4.609375vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-btn
            class="arp"
            width="70.13333333333334vw"
            height="10.133333333333333vw"
            depressed
            @click="overlay = !overlay"
            style="
              text-transform: none;
              font-size: 4.266666666666667vw;
              border-radius: 5.066666666666666vw;
              color: white;
            "
            color="#2A9AE5"
          >
            احجز موعد استشارة
          </v-btn>
        </v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform :clickbtn="btnclick" light> </Vform>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import photo from "../../assets/Services/Post/Main-photo16.png";
import mphoto from "../../assets/Services/Post/Group 464.png";
import Vform from "../../componentsar/Form";

export default {  
metaInfo: {
    title: "الشامي العيون",
  },
  components: { Vform },
  data() {
    return {
      photo,
      mphoto,
      overlay: false,
    };
  },
  methods: {
    btnclick() {
      this.overlay = false;
    },
  },
};
</script>

<style>
</style>