<template>
  <div>
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 16.484375vw 10.390625vw 16.484375vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977">Meet The Team</h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.890625vw"></v-card>
        <v-row>
          <v-col
            style="margin: 0 0 -0.546875vw 0 !important"
            cols="4"
            v-for="(profile, i) in profiles"
            :key="i"
            ><v-card
              align="center"
              style="
                border-radius: 3.359375vw;
                padding: 0 3.359375vw;
                box-shadow: 0 0 1.171875vw 0.15625vw #dcdcdc !important;
              "
              height="26.25vw"
              width="21.09375vw"
            >
              <v-card outlined class="transparent" height="2.96875vw"></v-card>
              <v-img
                contain
                style="height: 9.921875vw !important"
                :src="profile.img"
              ></v-img>
              <v-card outlined class="transparent" height="1.640625vw"></v-card>
              <h5 style="color: #204170">{{ profile.title }}</h5>
              <div class="mt-2 mb-4" style="margin: 0 -10%">
                <h4 style="color: #2a9ae5" class="boldm">
                  <span>{{ profile.name }}</span> <br />
                  {{ profile.name2 }}
                </h4>
              </div>
              <h6 style="color: #204170">{{ profile.desc }}</h6>
            </v-card></v-col
          >
        </v-row>
      </div>
    </div>
    <!-- MOBILE -->
    <div class="hidden-md-and-up">
      <mimg-page :img="mphoto"></mimg-page>
      <div style="margin: 0 9.6vw 10.390625vw 9.6vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977; font-size: 5.333333333333334vw">
            Meet The Team
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.890625vw"></v-card>
        <v-row no-gutters>
          <v-col
            style="margin: 0 0 2.4vw 0 !important"
            cols="12"
            v-for="(profile, i) in profiles"
            :key="i"
            ><v-card
              class="d-flex align-center"
              style="
                border-radius: 7.199999999999999vw;
                padding: 0 0 0 5.066666666666666vw;
                box-shadow: 0 0 20px 2px #dcdcdc !important;
              "
              height="44.266666666666666vw"
              width="80vw"
            >
              <v-card outlined class="transparent" height="2.96875vw"></v-card>
              <v-card outlined class="transparent">
                <v-img
                  style="
                    height: 21.866666666666667vw !important;
                    width: 21.866666666666667vw;
                  "
                  :src="profile.img"
                ></v-img>
              </v-card>
              <v-card outlined class="transparent" height="1.640625vw"></v-card>
              <v-card
                outlined
                class="transparent"
                style="margin: 0 0 0 1.866666666666667vw"
              >
                <h5 style="color: #204170; font-size: 3.733333333333334vw">
                  {{ profile.title }}
                </h5>

                <h4
                  style="color: #2a9ae5; font-size: 3.733333333333334vw"
                  class="boldm"
                >
                  <span>{{ profile.name }}</span> <br />
                  {{ profile.name2 }}
                </h4>

                <h6 style="color: #204170; font-size: 3.733333333333334vw">
                  {{ profile.desc }}
                </h6>
              </v-card>
            </v-card></v-col
          >
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import photo from "../assets/mtt/Group 386.png";
import mphoto from "../assets/mtt/Group 452.png";

import pp1 from "../assets/mtt/Photo1.png";
import pp2 from "../assets/mtt/Photo2.png";
import pp4 from "../assets/mtt/Photo4.png";
import pp6 from "../assets/mtt/Photo6.png";

import pp8 from "../assets/mtt/Photo8.png";
import pp9 from "../assets/mtt/Photo9.png";

import pp12 from "../assets/mtt/Photo12.png";
import pp13 from "../assets/mtt/Photo13.png";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Shami Eye | Meet The Team",
    meta: [
      {
        name: "description",
        content:
          "Meet Our Team of Certified and Professional top doctors anywhere in the world!",
      },
    ],
    // all titles will be injected into this template
  },
  data() {
    return {
      photo,
      mphoto,
      profiles: [
        {
          img: pp1,
          title: "Medical Director",
          name: "Dr. Mohammad",
          name2: "Abu Dab'at",
          desc: "Medical Ophthalmology Surgeon Consultant",
        },
        {
          img: pp2,
          title: "Ophthalmologist",
          name: "Dr. Omar Al Zubi",
          desc: "Medical Ophthalmology Surgeon Consultant",
        },

        {
          img: pp4,
          title: "Ophthalmologist",
          name: "Dr. Yazan Tamimi",
          desc: "Medical Ophthalmology Surgeon Consultant",
        },
        {
          img: pp6,
          title: "Ophthalmologist",
          name: "Dr. Mohammad Farah",
          desc: "Medical Ophthalmology Surgeon Consultant",
        },

        {
          img: pp8,
          title: "Ophthalmologist",
          name: "Dr. Zeid Ismail",
          desc: "Medical Ophthalmology Surgeon Consultant",
        },
        {
          img: pp9,
          title: "Ophthalmologist",
          name: "Dr. Ali Hoja",
          desc: "Medical Ophthalmology Surgeon Consultant",
        },

        {
          img: pp12,
          title: "Ophthalmologist",
          name: "Dr. Mona Freihat",
          desc: "Medical Ophthalmology Surgeon Consultant",
        },
        {
          img: pp13,
          title: "Ophthalmologist",
          name: "Dr. Rashed Nazzal",
          desc: "Medical Ophthalmology Surgeon Consultant",
        },
      ],
    };
  },
};
</script>

<style>
</style>