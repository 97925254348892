<template>
  <div>
    <div>
      <div>
        <div class="hidden-sm-and-down" align="center">
          <iframe
            name="dummyframe"
            id="dummyframe"
            style="display: none"
          ></iframe>
          <v-card
            align="center"
            style="
              padding: 5.234375vw 5.15625vw;
              border-radius: 4.296875vw;
              background-color: white !important;
            "
            width="48.59375vw"
            height="43.125vw"
          >
            <h2
              style="
                margin: 0 0 2.890625vw 0;
                color: #2a9ae5;
                font-size: 2.578125vw;
              "
              class="boldm titlea ar"
            >
              احجز موعد استشارة
            </h2>
            <form
              target="dummyframe"
              id="theForm"
              name="theForm"
              action="https://docs.google.com/forms/u/2/d/e/1FAIpQLSc1Aio08HlG4r9eVU908C-Ny3GtcR-yQ4lBWQLWv6yAjbLyvQ/formResponse"
            >
              <v-card
                class="d-flex justify-end align-center"
                width="38.359375vw"
                height="3.28125vw"
                color="#F2F3F5"
                style="
                  padding: 0 1.40625vw;
                  margin: 0 0 1.09375vw 0;
                  box-shadow: 0 0 0 0 #f2f3f5 !important;
                  border-radius: 3.359375vw;
                "
              >
                <input
                  style="font-size: 1.015625vw; text-align: right"
                  type="text"
                  class="ar"
                  v-model="fname"
                  placeholder="الاسم الأول"
                  name="entry.2005620554"
                  required
                />
              </v-card>
              <v-card
                class="d-flex justify-end align-center"
                width="38.359375vw"
                height="3.28125vw"
                color="#F2F3F5"
                style="
                  padding: 0 1.40625vw;
                  margin: 0 0 1.09375vw 0;
                  box-shadow: 0 0 0 0 #f2f3f5 !important;
                  border-radius: 3.359375vw;
                "
              >
                <input
                  style="font-size: 1.015625vw; text-align: right"
                  class="ar"
                  type="text"
                  v-model="lname"
                  placeholder="اسم العائلة"
                  name="entry.1045781291"
                  required
                />
              </v-card>
              <v-card
                class="d-flex justify-end align-center"
                width="38.359375vw"
                height="3.28125vw"
                color="#F2F3F5"
                style="
                  padding: 0 1.40625vw;
                  margin: 0 0 1.09375vw 0;
                  box-shadow: 0 0 0 0 #f2f3f5 !important;
                  border-radius: 3.359375vw;
                "
              >
                <input
                  style="font-size: 1.015625vw; text-align: right"
                  type="text"
                  class="ar"
                  v-model="email"
                  placeholder="البريد الالكتروني"
                  name="entry.1065046570"
                  required
                />
              </v-card>
              <v-card
                class="d-flex justify-end align-center"
                width="38.359375vw"
                height="3.28125vw"
                color="#F2F3F5"
                style="
                  padding: 0 1.40625vw;
                  margin: 0 0 1.09375vw 0;
                  box-shadow: 0 0 0 0 #f2f3f5 !important;
                  border-radius: 3.359375vw;
                "
              >
                <input
                  style="font-size: 1.015625vw; text-align: right"
                  type="text"
                  v-model="phonenumber"
                  placeholder="رقم الهاتف"
                  class="ar"
                  name="entry.1166974658"
                  required
                />
              </v-card>

              <v-select
                name="entry.839337160"
                multiple
                solo
                flat
                v-model="service"
                :items="items"
                label="+"
                style="color: #2a9ae5"
                append-icon="الخدمة"
              ></v-select>

              <v-card
                height="3.2031249999999996vw"
                outlined
                class="transparent"
              ></v-card>
              <v-card v-if="sessub" outlined class="transparent">
                <slot></slot>
              </v-card>
              <v-card v-if="!sessub" outlined class="transparent">
                <slot></slot>

                <v-btn
                  v-if="!submitted"
                  @click="sub"
                  height="3.28125vw"
                  width="12.968750000000002vw"
                  depressed
                  color="#2A9AE5"
                  style="
                    text-transform: none;
                    border-radius: 3.359375vw;
                    margin: 0 0 0 14px;
                  "
                  class="white--text btntxt"
                >
                  <span class="ar" style="margin: 0 0 0 0; font-size: 1.25vw"
                    >ارسال</span
                  >
                </v-btn>
              </v-card>
              <span
                class="ar"
                v-if="submitted"
                style="
                  margin: 0 0 0 0;

                  color: #2a9ae5;
                "
                v-html="textsub"
              ></span>

              <v-card v-if="sessub" outlined class="transparent">
                <span
                  class="ar"
                  style="
                    margin: 0 0 0 0;

                    color: #2a9ae5;
                  "
                  v-html="textsub"
                ></span>
              </v-card>
            </form>
          </v-card>
        </div>
        <v-card outlined class="transparent" height="100px"></v-card>
        <!-- MOBILE -->
        <div class="hidden-md-and-up" align="center">
          <v-card
            align="center"
            style="
              padding: 0 6.933333333333333vw;
              border-radius: 14.666666666666666vw;
              background-color: white !important;
            "
            width="82.66666666666667vw"
            height="101.33333333333334vw"
          >
            <iframe
              name="dummyframe"
              id="dummyframe"
              style="display: none"
            ></iframe>

            <v-card outlined class="transparent" height="4.266666666666667vw">
            </v-card>
            <v-card
              @click="clickbtn"
              outlined
              class="transparent"
              align="right"
            >
              <p
                style="
                  font-size: 5.066666666666666vw;
                  margin: 0 0 -4% 0;
                  color: #2a9ae5;
                "
              >
                X
              </p>
            </v-card>
            <h2
              style="margin: 0 0 4vw 0; color: #2a9ae5; font-size: 4.8vw"
              class="sboldm titlear"
            >
              احجز موعد استشارة
            </h2>
            <form
              method="POST"
              target="dummyframe"
              id="theForm1"
              name="theForm1"
              action="https://docs.google.com/forms/u/2/d/e/1FAIpQLSc1Aio08HlG4r9eVU908C-Ny3GtcR-yQ4lBWQLWv6yAjbLyvQ/formResponse"
            >
              <v-card
                class="d-flex justify-end align-center"
                width="69.06666666666666vw"
                height="7.733333333333333vw"
                color="#F2F3F5"
                style="
                  padding: 0 4.266666666666667vw;
                  margin: 0 0 2.666666666666667vw 0;
                  box-shadow: 0 0 0 0 #f2f3f5 !important;
                  border-radius: 3.359375vw;
                "
              >
                <input
                  style="font-size: 2.933333333333333vw; text-align: right"
                  class="ar"
                  type="text"
                  v-model="fname"
                  placeholder="الاسم الأول"
                  name="entry.2005620554"
                  required
                />
              </v-card>
              <v-card
                class="d-flex justify-end align-center"
                width="69.06666666666666vw"
                height="7.733333333333333vw"
                color="#F2F3F5"
                style="
                  padding: 0 4.266666666666667vw;
                  margin: 0 0 2.666666666666667vw 0;
                  box-shadow: 0 0 0 0 #f2f3f5 !important;
                  border-radius: 3.359375vw;
                "
              >
                <input
                  style="font-size: 2.933333333333333vw; text-align: right"
                  class="ar"
                  type="text"
                  v-model="lname"
                  placeholder="اسم العائلة"
                  name="entry.1045781291"
                  required
                />
              </v-card>
              <v-card
                class="d-flex justify-end align-center"
                width="69.06666666666666vw"
                height="7.733333333333333vw"
                color="#F2F3F5"
                style="
                  padding: 0 4.266666666666667vw;
                  margin: 0 0 2.666666666666667vw 0;
                  box-shadow: 0 0 0 0 #f2f3f5 !important;
                  border-radius: 3.359375vw;
                "
              >
                <input
                  style="font-size: 2.933333333333333vw; text-align: right"
                  class="ar"
                  type="email"
                  v-model="email"
                  placeholder="البريد الالكتروني"
                  name="entry.1065046570"
                  required
                />
              </v-card>
              <v-card
                class="d-flex justify-end align-center"
                width="69.06666666666666vw"
                height="7.733333333333333vw"
                color="#F2F3F5"
                style="
                  padding: 0 4.266666666666667vw;
                  margin: 0 0 2.666666666666667vw 0;
                  box-shadow: 0 0 0 0 #f2f3f5 !important;
                  border-radius: 3.359375vw;
                "
              >
                <input
                  style="font-size: 2.933333333333333vw; text-align: right"
                  class="ar"
                  type="tel"
                  v-model="phonenumber"
                  placeholder="رقم الهاتف"
                  name="entry.1166974658"
                  required
                />
              </v-card>

              <v-select
                name="entry.839337160"
                solo
                flat
                :items="items"
                multiple
                label="+"
                class="ar"
                style="
                  color: #2a9ae5;
                  height: 7.733333333333333vw !important;
                  font-family: ar !important;
                "
                append-icon="الخدمة"
              ></v-select>

              <v-card
                height="8.533333333333333vw"
                outlined
                class="transparent"
              ></v-card>
              <v-card v-if="!sessub" outlined class="transparent">
                <v-btn
                  v-if="!submitted"
                  @click="subm"
                  height="8.533333333333333vw"
                  width="33.6vw"
                  depressed
                  color="#2A9AE5"
                  style="
                    text-transform: none;
                    border-radius: 11.466666666666667vw;
                    margin: 0 0px 0 0;
                  "
                  class="white--text btntxt"
                >
                  <span
                    class="ar"
                    style="margin: 0 0 0 0; font-size: 4.266666666666667vw"
                    >إرسال</span
                  >
                </v-btn>
              </v-card>
              <span
                v-if="submitted"
                style="
                  margin: 0 0 0 0;
                  font-size: 4.266666666666667vw;
                  color: #2a9ae5;
                "
                v-html="textsub"
              ></span>

              <v-card v-if="sessub" outlined class="transparent">
                <span
                  style="
                    margin: 0 0 0 0;
                    font-size: 4.266666666666667vw;
                    color: #2a9ae5;
                  "
                  v-html="textsub"
                ></span>
              </v-card>
            </form>
          </v-card>
        </div>
      </div>
      <!-- <div v-if="submitted">
        <br /><br />
        <v-card align="center" width="25vw" shaped light>
          <span class="ar">شكرا لإرسال الاستبيان</span>
          <br /><br />
          <v-card align="center">
            <slot></slot>
          </v-card>
          <br /><br />
        </v-card>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["clickbtn"],
  data() {
    return {
      active: false,
      textsub: "شكرا لك على تقديمك",
      phonenumber: "",
      fname: "",
      lname: "",
      email: "",
      service: "",
      submitted: false,
      sessub: sessionStorage["submitted"],
      items: [
        "تصحيح النظر",
        "الماء الابيض",
        "الشبكية",
        "القرنية",
        "الزرق (الجلوكوما)",
        "العناية ما بعد العملية",
        "عيون الأطفال",
        "خدمات أخرى",
      ],
    };
  },
  methods: {
    sub() {
      if (
        this.fname == "" ||
        this.lname == "" ||
        this.email == "" ||
        this.phonenumber == ""
      ) {
        alert("الرجاء تعبئة كل الفراغات في النموذج");
      } else {
        document.getElementById("theForm").submit();
        this.submitted = true;
        sessionStorage["submitted"] = true;
      }
    },
    subm() {
      if (
        this.fname == "" ||
        this.lname == "" ||
        this.email == "" ||
        this.phonenumber == ""
      ) {
        alert("الرجاء تعبئة كل الفراغات في النموذج");
      } else {
        document.getElementById("theForm1").submit();
        this.submitted = true;
        sessionStorage["submitted"] = true;
      }
    },
  },
};
</script>

<style>
.v-input__slot {
  background-color: #f2f3f5 !important;
  border-radius: 3.359375vw !important;
  padding: 0 1.40625vw !important;
}

.v-input__control {
  width: 38.359375vw !important;
  max-height: 3.28125vw !important;
  min-height: 3.28125vw !important;
}

.v-label {
  font-size: 0.9375vw;
  color: #2a9ae5 !important;
}

.v-icon {
  font-size: max(1.015643vw, 12px) !important;
  color: #2a9ae5 !important;
  font-style: normal;
  font-family: ar !important;
}

.theme--dark.v-select .v-select__selection--comma {
  color: #2a9ae5 !important;
  font-family: ar;
  font-size: max(12px, 1.01563vw) !important;
}

.v-list-item__title,
.v-list-item__subtitle {
  flex: 1 1 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: ar !important;
}

.v-list-item {
  min-height: 1.40625vw;
  margin-bottom: 12px;
  padding: 0 0 0 2 !important;
}
</style>