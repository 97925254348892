
    <style>
input {
  font-size: 20px !important;
}
</style>
<template>
  <div style="margin-top: 300px">
    <img
      style="z-index: 0"
      src="../Requirements/IMG/Body.png"
      class="Body-Background"
    />

    <div class="LoginGH">
      <div class="LoginBanner">
        <form class="animate__animated animate__backInUp animate__delay-1s">
          <div class="FormRow">
            <label> بتلبس نظارة ؟ : </label>
            <select required>
              <option disabled selected></option>
              <option>Yes / نعم</option>
              <option>NO / لا</option>
            </select>
            <label> Do you wear glasses : </label>
          </div>

          <div class="FormRow">
            <label> هل أنت مهتم بتصحيح النظر بالليزك: </label>
            <select required>
              <option disabled selected></option>
              <option>Yes / نعم</option>
              <option>NO / لا</option>
            </select>
            <label> Are you interested in Lasik: </label>
          </div>

          <div class="FormRow">
            <label> الإسم الكامل : </label>
            <input required type="text" />
            <label> Full name : </label>
          </div>
          <div class="FormRow">
            <label> رقم الهاتف : </label>
            <input required type="text" />
            <label> Phone number : </label>
          </div>
          <div class="FormRow">
            <label> العمر : </label>
            <input required type="text" />
            <label> Age : </label>
          </div>
          <div class="FormRow">
            <label> (اختياري) البريد الإلكتروني : </label>
            <input type="text" />
            <label> Email address : (Optional)</label>
          </div>

          <div class="FormRow">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>

    <h2 class="Credit">By Shami Eye</h2>
  </div>
</template>

<script>
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Shami Eye | Zoom Meeting",
    meta: [
      {
        name: "description",
        content: "Zoom Meeting",
      },
    ],
    // all titles will be injected into this template
  },
};
</script>


<style>
@import "../Requirements/CSS/bootstrap.min.css";
@import "https://use.fontawesome.com/releases/v5.7.2/css/all.css";
@import "../Requirements/CSS/Animation.css";
@import "../Requirements/CSS/slick.css";
@import "../Requirements/CSS/style.css";
@import "../Requirements/CSS/mobile.css";
</style>
