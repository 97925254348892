<template>
  <div>
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 15.859375vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977">
            Retina & Vitreoretinal Disorders
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <p style="color: #2a3977">
          This unit treats a large variety of conditions, which can affect the
          vitreous and retina that lie on the back part of the eye that is not
          readily visible.
        </p>
        <v-card outlined tile class="transparent" height="1.953125vw"></v-card>
        <h2 style="color: #2a9ae5" class="boldm titlea">Retina</h2>
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <h3 style="color: #2a9ae5; font-size: 1.5625vw" class="boldm">
          What is the retina?
        </h3>
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <p style="color: #2a3977">
          The retina is a transparent layer of nerve tissue in the back of the
          eye that functions like the film in a camera, capturing images focused
          by the structures in the front of the eye. After capturing the images,
          the retina transmits them to the brain.
        </p>
        <v-card outlined tile class="transparent" height="1.09375vw"></v-card>
      </div>
      <v-card class="transparent" outlined align="center">
        <v-img width="75.9375vw" height="24.375vw" :src="photo1"></v-img>

        <v-row no-gutters>
          <v-col cols="5">
            <v-card outlined class="transparent" align="center">
              <p
                style="
                  font-size: 1.171875vw;
                  color: #204170;
                  margin: 0% -50% 0 0;
                "
              >
                <span class="sboldm"> Normal Retina </span>
              </p>
            </v-card>
          </v-col>
          <v-col>
            <v-card outlined class="transparent" align="center" cols="7">
              <p
                class="sboldm"
                style="
                  font-size: 1.171875vw;
                  color: #204170;
                  margin: 0% 0 0 -5%;
                "
              >
                <span class="sboldm"> Diabetic Retinopathy </span>
              </p>
            </v-card></v-col
          >
        </v-row>
      </v-card>
      <div style="margin: 0 15.859375vw">
        <v-card
          outlined
          tile
          class="transparent"
          height="6.406249999999999vw"
        ></v-card>
        <h2 style="color: #2a9ae5" class="boldm titlea">Macula</h2>
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>

        <h3 style="color: #2a9ae5; font-size: 1.5625vw" class="boldm">
          What is the macula?
        </h3>

        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>

        <p style="color: #2a3977">
          The macula is the central part of the retina that is specialized for
          high-resolution vision and is critical in tasks such as reading,
          driving, and other activities requiring fine visual discrimination.
          The vitreous is a jelly-like substance that fills the eye and is
          frequently implicated in diseases such as retinal tears, retinal
          detachment, and others.
        </p>

        <v-card outlined tile class="transparent" height="2.265625vw"></v-card>

        <h2 style="color: #2a9ae5" class="boldm titlea">Diabetes & the Eye</h2>

        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <p style="color: #2a3977">
          Diabetes is associated with many ocular complications, but diabetic
          retinopathy is by far the most serious complication of all.
        </p>
        <v-card outlined tile class="transparent" height="2.265625vw"></v-card>

        <h3 style="color: #2a9ae5; font-size: 1.5625vw" class="boldm">
          What causes diabetic retinopathy?
        </h3>
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>

        <p style="color: #2a3977">
          It is caused by damage to the blood vessels in the retina. Through
          dilated pupils, a physician can directly visualize abnormal blood
          vessels, retinal swelling and retinal hemorrhages. In advanced cases,
          new blood vessels develop and, if left unattended, they will bleed and
          cause severe loss of vision.
          <br />
          <br />
          Our specialists at Shami Eye Center are equipped to treat all of the
          medical and surgical diseases affecting the vitreous, macula, and
          retina for patients of all ages.
          <br />
          <br />
          We use the most advanced tools in the diagnosis and treatment of
          macular degeneration, diabetic retinopathy and other diseases of the
          retina. In addition to the most effective therapies, we use the most
          up-to-date injections & surgical procedures.
        </p>

        <v-card outlined tile class="transparent" height="2.96875vw"></v-card>
        <v-card
          style="margin: 0 0 4.609375vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-btn
            width="35.78125vw"
            height="4.84375vw"
            depressed
            @click="overlay = !overlay"
            style="
              text-transform: none;
              font-size: 2.03125vw;
              letter-spacing: -1.5px !important;
              border-radius: 2.421875vw;
              color: white;
            "
            color="#2A9AE5"
          >
            Schedule An Appointment
          </v-btn>
        </v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform light>
          <v-btn
            @click="overlay = !overlay"
            height="3.28125vw"
            width="12.968750000000002vw"
            depressed
            color="#2A9AE5"
            style="
              text-transform: none;
              border-radius: 3.359375vw;
              margin: 0 0 0 14px;
            "
            class="white--text btntxt"
          >
            <span style="margin: 0 0 0 0; font-size: 1.25vw">Cancel</span>
          </v-btn>
        </Vform>
      </v-overlay>
    </div>
    <!-- MOBILE -->
    <div class="hidden-md-and-up">
      <mimg-page :img="photo"></mimg-page>
      <div style="margin: 0 10.666666666666668vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977; font-size: 5.333333333333334vw">
            Retina & Vitreoretinal Disorders
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <p
          class="sboldm"
          style="color: #2a3977; margin: 0; font-size: 4.266666666666667vw"
        >
          This unit treats a large variety of conditions, which can affect the
          vitreous and retina that lie on the back part of the eye that is not
          readily visible.
        </p>
        <h2
          style="color: #2a9ae5; margin: 8vw 0 0 0; font-size: 4.8vw"
          class="boldm titlea"
        >
          Retina
        </h2>
        <h3
          style="
            color: #2a9ae5;
            font-size: 4.533333333333333vw;
            margin: 3.4666666666666663vw 0;
          "
          class="boldm"
        >
          What is the retina?
        </h3>
        <p style="color: #2a3977; margin: 0; font-size: 4.266666666666667vw">
          The retina is a transparent layer of nerve tissue in the back of the
          eye that functions like the film in a camera, capturing images focused
          by the structures in the front of the eye. After capturing the images,
          the retina transmits them to the brain.
        </p>
        <v-card outlined tile class="transparent" height="4vw"></v-card>
      </div>
      <v-card outlined class="transparent" align="center">
        <v-img width="90.66666666666666vw" height="52vw" :src="g1m"></v-img>
        <p
          class="sboldm"
          style="margin: 1% 0 0 0; font-size: 4vw; color: #204170"
        >
          Normal Retina
        </p>
      </v-card>
      <v-card outlined tile class="transparent" height="3.515625vw"></v-card>
      <v-card outlined class="transparent" align="center">
        <v-img width="63.46666666666667vw" height="52vw" :src="g2m"></v-img>
        <p
          class="sboldm"
          style="margin: 1% 0 0 0; font-size: 4vw; color: #204170"
        >
          Diabetic Retinopathy
        </p>
      </v-card>
      <div style="margin: 0 10.666666666vw">
        <v-card
          outlined
          tile
          class="transparent"
          height="6.406249999999999vw"
        ></v-card>
        <h2
          style="color: #2a9ae5; margin: 0; font-size: 4.8vw"
          class="boldm titlea"
        >
          Macula
        </h2>

        <h3
          style="
            color: #2a9ae5;
            font-size: 4.8vw;
            margin: 3.4666666666666663vw 0;
          "
          class="boldm"
        >
          What is the macula?
        </h3>

        <p style="color: #2a3977; font-size: 4.266666666666667vw; margin: 0">
          The macula is the central part of the retina that is specialized for
          high-resolution vision and is critical in tasks such as reading,
          driving, and other activities requiring fine visual discrimination.
          The vitreous is a jelly-like substance that fills the eye and is
          frequently implicated in diseases such as retinal tears, retinal
          detachment, and others.
        </p>

        <h2
          style="
            color: #2a9ae5;
            font-size: 4.8vw;
            margin: 13.066666666666665vw 0 3.4666666666666663vw 0;
          "
          class="boldm titlea"
        >
          Diabetes & the Eye
        </h2>

        <p style="color: #2a3977; font-size: 4.266666666666667vw; margin: 0">
          Diabetes is associated with many ocular complications, but diabetic
          retinopathy is by far the most serious complication of all.
        </p>

        <h3
          style="color: #2a9ae5; font-size: 4.8vw; margin: 4.8vw 0"
          class="boldm"
        >
          What causes diabetic retinopathy?
        </h3>

        <p style="color: #2a3977; font-size: 4.266666666666667vw; margin: 0">
          It is caused by damage to the blood vessels in the retina. Through
          dilated pupils, a physician can directly visualize abnormal blood
          vessels, retinal swelling and retinal hemorrhages. In advanced cases,
          new blood vessels develop and, if left unattended, they will bleed and
          cause severe loss of vision.
          <br />
          <br />
          Our specialists at Shami Eye Center are equipped to treat all of the
          medical and surgical diseases affecting the vitreous, macula, and
          retina for patients of all ages.
          <br />
          <br />
          We use the most advanced tools in the diagnosis and treatment of
          macular degeneration, diabetic retinopathy and other diseases of the
          retina. In addition to the most effective therapies, we use the most
          up-to-date injections & surgical procedures.
        </p>

        <v-card
          outlined
          tile
          class="transparent"
          height="10.666666666666668vw"
        ></v-card>
        <v-card
          style="margin: 0 0 14.133333333333335vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-btn
            width="70.13333333333334vw"
            height="10.133333333333333vw"
            depressed
            @click="overlay = !overlay"
            style="
              text-transform: none;
              font-size: 4.266666666666667vw;
              letter-spacing: -1.5px !important;
              border-radius: 5.066666666666666vw;
              color: white;
            "
            color="#2A9AE5"
          >
            Schedule An Appointment
          </v-btn>
        </v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform :clickbtn="btnclick" light> </Vform>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import photo from "../../assets/Services/Retina/Main-photo12.png";
import photo1 from "../../assets/Services/Retina/retinapic1.png";
import g1m from "../../assets/Services/Retina/Mask Group 54.png";
import g2m from "../../assets/Services/Retina/Mask Group 55.png";

import Vform from "../../components/Form";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Shami Eye | Retina",
    meta: [
      {
        name: "description",
        content:
          "Learn more about Retina & Vitreoretinal Disorders such as diabetic retinopathy and mecula and book your appointment in a center in Jordan near you today!",
      },
    ],
    // all titles will be injected into this template
  },

  components: {
    Vform,
  },

  data() {
    return {
      photo,
      g1m,
      g2m,
      photo1,
      overlay: false,
    };
  },
  methods: {
    btnclick() {
      this.overlay = false;
    },
  },
};
</script>

<style>
</style>