<template>
  <div>
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <v-card outlined class="transparent">
        <h1 style="color: #2a9ae5" align="center">Comming Soon</h1>
      </v-card>
      <br />
      <VForm></VForm>
      <v-card outlined class="transparent" height="100px"></v-card>
    </div>
    <!-- MOBILE -->
    <div class="hidden-md-and-up">
      <mimg-page :img="photo"></mimg-page>
      <v-card outlined class="transparent">
        <h1
          style="color: #2a9ae5; font-size: 5.333333333333334vw"
          align="center"
        >
          Comming Soon
        </h1>
      </v-card>
      <br />
      <VForm></VForm>
      <v-card outlined class="transparent" height="100px"></v-card>
    </div>
  </div>
</template>

<script>
import photo from "../assets/mtt/Group 386.png";
import p1 from "../assets/footer/Email-icon.png";
import p2 from "../assets/footer/Facebook-icon.png";
import p3 from "../assets/footer/Instagram-icon.png";
import p4 from "../assets/footer/Linkedin-icon.png";
import p5 from "../assets/footer/Location-icon.png";
import p6 from "../assets/footer/Phone-icon.png";
import p7 from "../assets/footer/Twitter-icon.png";
import p8 from "../assets/footer/Youtube-icon.png";
import VForm from "../componentsar/Form";

export default {
  components: {
    VForm,
  },
  metaInfo: {
    title: "شامي العيون",
  },
  data() {
    return {
      active: false,
      photo,
      p1,
      p2,
      p3,
      p4,
      p5,
      p6,
      p7,
      p8,
      phonenumber: "",
      fname: "",
      lname: "",
      email: "",
      service: "",
    };
  },
};
</script>

<style >
.v-input__slot {
  background-color: #f2f3f5 !important;
  border-radius: 3.359375vw !important;
  padding: 0 max(1.40625vw, 16px) !important;
}

.v-input__control {
  width: 38.359375vw !important;
  max-height: 3.28125vw !important;
  min-height: max(3.28125vw, 29px) !important;
}

.v-label {
  font-size: max(0.9375vw, 11px);
  color: #2a9ae5 !important;
}

.theme--light.v-select .v-select__selection--comma {
  color: rgba(0, 0, 0, 0.87);
  font-size: max(0.9375vw, 11px);
}
</style>