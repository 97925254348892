<template>
  <div>
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 16.484375vw 10.390625vw 16.484375vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977">Gallery</h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.890625vw"></v-card>

        <div>
          <v-row>
            <v-col
              style="margin: 0 0 -0.546875vw 0 !important"
              cols="4"
              v-for="(profile, i) in profiles"
              :key="i"
            >
              <v-lazy
                v-model="isActive"
                :options="{
                  threshold: 0.5,
                }"
                min-height="200"
                transition="fade-transition"
              >
                <v-card
                  @click="loadimg(i)"
                  style="
                    border-radius: 3.359375vw;
                    box-shadow: 0 0 1.171875vw 0.15625vw #dcdcdc !important;
                  "
                  height="20.78125vw"
                  width="20.78125vw"
                >
                  <v-img
                    style="
                      height: 20.78125vw !important;
                      border-radius: 3.359375vw;
                    "
                    lazy-src="https://picsum.photos/id/11/100/60"
                    :src="profile.img"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row> </template
                  ></v-img>
                </v-card>
              </v-lazy>
              <v-overlay :value="profile.dialog">
                <v-row>
                  <v-col
                    align-self="center"
                    align="left"
                    class="justify-center"
                    cols="3"
                  >
                    <v-card
                      @click="previous()"
                      class="transparent"
                      outlined
                      height="3.8281250000000004vw"
                      width="3.8281250000000004vw"
                    >
                      <v-img
                        contain
                        style="border-radius: 3.359375vw"
                        :src="Prev"
                      >
                      </v-img>
                    </v-card>
                  </v-col>
                  <v-col align-self="center" align="center" cols="6">
                    <v-card
                      align-self="center"
                      @click="profile.dialog = false"
                      class="transparent"
                      outlined
                    >
                      <v-img
                        height="46.5625vw"
                        width="47.03125vw"
                        contain
                        style="border-radius: 3.359375vw"
                        :src="profiles[prev].img"
                      >
                      </v-img>
                    </v-card>
                  </v-col>
                  <v-col align-self="center" align="right" cols="3">
                    <v-card
                      @click="next()"
                      class="transparent"
                      outlined
                      height="3.8281250000000004vw"
                      width="3.8281250000000004vw"
                    >
                      <v-img
                        contain
                        style="border-radius: 3.359375vw"
                        :src="Next"
                      >
                      </v-img>
                    </v-card>
                  </v-col>
                </v-row>
              </v-overlay>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <!-- MOBILE -->
    <div class="hidden-md-and-up">
      <mimg-page :img="mphoto"></mimg-page>
      <div style="margin: 0 9.6vw 10.390625vw 9.6vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977; font-size: 5.333333333333334vw">
            Gallery
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.890625vw"></v-card>
        <v-row no-gutters>
          <v-col
            cols="6"
            style="margin: 0 0 2.4vw 0 !important"
            v-for="(profile, i) in profiles"
            :key="i"
            ><v-card
              @click="loadimg(i)"
              outlined
              class="d-flex align-center transparent"
              style="border-radius: 7.199999999999999vw; padding: 0 1vw 0 1vw"
            >
              <v-card outlined class="transparent" height="2.96875vw"></v-card>
              <v-card outlined class="transparent">
                <v-img
                  style="
                    height: 39.726027397260275vw !important;
                    width: 39.726027397260275vw;
                    border-radius: 7.199999999999999vw;
                  "
                  :src="profile.img"
                  lazy-src="https://picsum.photos/id/11/100/60"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-card>
            </v-card>
            <v-overlay :value="profile.dialog">
              <v-card
                align-self="center"
                @click="profile.dialog = false"
                class="transparent"
                outlined
              >
                <v-img
                  height="90.41095890410958vw"
                  width="90.41095890410958vw"
                  contain
                  style="border-radius: 3.359375vw"
                  :src="profiles[prev].img"
                >
                </v-img>
              </v-card>
              <v-card outlined class="transparent" height="1.640625vw"></v-card>
              <v-row>
                <v-col align="right" cols="6">
                  <v-card
                    @click="previous()"
                    class="transparent"
                    outlined
                    height="7.9452054794520555vw"
                    width="7.9452054794520555vw"
                  >
                    <v-img
                      contain
                      style="border-radius: 3.359375vw"
                      :src="Prev"
                    >
                    </v-img>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card
                    @click="next()"
                    class="transparent"
                    outlined
                    height="7.9452054794520555vw"
                    width="7.9452054794520555vw"
                  >
                    <v-img
                      contain
                      style="border-radius: 3.359375vw"
                      :src="Next"
                    >
                    </v-img>
                  </v-card>
                </v-col>
              </v-row> </v-overlay
          ></v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import photo from "../../assets/Gallery/Main-photo20.png";
import mphoto from "../../assets/Gallery/Group 469.png";
import Next from "../../assets/Gallery/Next.png";
import Prev from "../../assets/Gallery/Prev.png";
import ppn from "../../assets/Gallery/Amman/newpic.jpg";
import pp1 from "../../assets/Gallery/Amman/tameem.png";
import pp2 from "../../assets/Gallery/Amman/Pic (1).png";
import pp3 from "../../assets/Gallery/Amman/Pic (2).png";
import pp4 from "../../assets/Gallery/Amman/Pic (3).png";
import pp5 from "../../assets/Gallery/Amman/Pic (4).png";
import pp6 from "../../assets/Gallery/Amman/Pic (5).png";
import pp8 from "../../assets/Gallery/Amman/Pic (6).png";
import pp9 from "../../assets/Gallery/Amman/Pic (7).png";
import pp11 from "../../assets/Gallery/Amman/Pic (8).png";
import pp12 from "../../assets/Gallery/Amman/Pic (9).png";
import pp13 from "../../assets/Gallery/Amman/Pic (10).png";
import pp14 from "../../assets/Gallery/Amman/Pic (11).png";
import pp15 from "../../assets/Gallery/Amman/Pic (12).png";
import pp16 from "../../assets/Gallery/Amman/Pic (13).png";
import pp17 from "../../assets/Gallery/Amman/Pic (14).png";
import pp18 from "../../assets/Gallery/Amman/Pic (15).png";
import pp19 from "../../assets/Gallery/Amman/Pic (16).png";
import pp20 from "../../assets/Gallery/Amman/Pic (17).png";
import pp21 from "../../assets/Gallery/Amman/Pic (18).png";
import pp22 from "../../assets/Gallery/Amman/Pic (19).png";
import pp23 from "../../assets/Gallery/Amman/Pic (20).png";
import pp24 from "../../assets/Gallery/Amman/Pic (21).png";
import pp25 from "../../assets/Gallery/Amman/Pic (22).png";
import pp26 from "../../assets/Gallery/Amman/Pic (23).png";
import pp27 from "../../assets/Gallery/Amman/Pic (24).png";
import pp28 from "../../assets/Gallery/Amman/Pic (25).png";
import pp29 from "../../assets/Gallery/Amman/Pic (26).png";
import pp30 from "../../assets/Gallery/Amman/Pic (27).png";
import pp31 from "../../assets/Gallery/Amman/Pic (28).png";
import pp32 from "../../assets/Gallery/Amman/Pic (29).png";
import pp33 from "../../assets/Gallery/Amman/Pic (30).png";
import pp34 from "../../assets/Gallery/Amman/Pic (31).png";
import pp35 from "../../assets/Gallery/Amman/Pic (32).png";
import pp36 from "../../assets/Gallery/Amman/Pic (33).png";
import pp37 from "../../assets/Gallery/Amman/Pic (34).png";
import pp38 from "../../assets/Gallery/Amman/Pic (35).png";
import pp39 from "../../assets/Gallery/Amman/Pic (36).png";
import pp40 from "../../assets/Gallery/Amman/Pic (37).png";
import pp41 from "../../assets/Gallery/Amman/Pic (38).png";
import pp42 from "../../assets/Gallery/Amman/Pic (39).png";
import pp50 from "../../assets/Gallery/Amman/Pic (1).png";
import pp43 from "../../assets/Gallery/Zarqa/Pic (2).png";
import pp44 from "../../assets/Gallery/Zarqa/Pic (3).png";
import pp45 from "../../assets/Gallery/Zarqa/Pic (4).png";
import pp46 from "../../assets/Gallery/Zarqa/Pic (5).png";
import pp48 from "../../assets/Gallery/Zarqa/Pic (6).png";
import pp49 from "../../assets/Gallery/Zarqa/Pic (7).png";
import pp51 from "../../assets/Gallery/Zarqa/Pic (8).png";
import pp52 from "../../assets/Gallery/Zarqa/Pic (9).png";
import pp53 from "../../assets/Gallery/Zarqa/Pic (10).png";
import pp54 from "../../assets/Gallery/Zarqa/Pic (11).png";
import pp55 from "../../assets/Gallery/Zarqa/Pic (12).png";
import pp56 from "../../assets/Gallery/Amman/Pic (1).png";
import pp57 from "../../assets/Gallery/Amman/Pic (2).png";
import pp58 from "../../assets/Gallery/Amman/Pic (3).png";
import pp59 from "../../assets/Gallery/Amman/Pic (4).png";
import pp60 from "../../assets/Gallery/Amman/Pic (5).png";
import pp61 from "../../assets/Gallery/Amman/Pic (6).png";
import pp62 from "../../assets/Gallery/Amman/Pic (7).png";
import pp63 from "../../assets/Gallery/Amman/Pic (8).png";
import pp64 from "../../assets/Gallery/Amman/Pic (9).png";
import pp65 from "../../assets/Gallery/Amman/Pic (10).png";
import pp66 from "../../assets/Gallery/Amman/Pic (11).png";
import pp67 from "../../assets/Gallery/Amman/Pic (12).png";
import pp68 from "../../assets/Gallery/Amman/Pic (13).png";
import pp69 from "../../assets/Gallery/Amman/Pic (14).png";
import pp70 from "../../assets/Gallery/Amman/Pic (15).png";
import pp71 from "../../assets/Gallery/Amman/Pic (16).png";
import pp72 from "../../assets/Gallery/Amman/Pic (17).png";
import pp73 from "../../assets/Gallery/Amman/Pic (18).png";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Shami Eye | Gallery",
    meta: [
      {
        name: "description",
        content: "Our Galary",
      },
    ],
    // all titles will be injected into this template
  },
  data() {
    return {
      overlay: false,
      prev: 1,
      photo,
      Next,
      Prev,
      mphoto,
      isActive: false,
      profiles: [
        {
          dialog: false,
          img: ppn,
        },
        {
          dialog: false,
          img: pp1,
        },
        {
          dialog: false,
          img: pp2,
        },
        {
          dialog: false,
          img: pp3,
        },
        {
          dialog: false,
          img: pp4,
        },
        {
          dialog: false,
          img: pp5,
        },
        {
          dialog: false,
          img: pp6,
        },

        {
          dialog: false,
          img: pp8,
        },
        {
          dialog: false,
          img: pp9,
        },

        {
          dialog: false,
          img: pp11,
        },
        {
          dialog: false,
          img: pp12,
        },
        {
          dialog: false,
          img: pp13,
        },
        {
          dialog: false,
          img: pp14,
        },
        {
          dialog: false,
          img: pp15,
        },
        {
          dialog: false,
          img: pp16,
        },
        {
          dialog: false,
          img: pp17,
        },
        {
          dialog: false,
          img: pp18,
        },
        {
          dialog: false,
          img: pp19,
        },
        {
          dialog: false,
          img: pp20,
        },
        {
          dialog: false,
          img: pp21,
        },
        {
          dialog: false,
          img: pp22,
        },
        {
          dialog: false,
          img: pp23,
        },
        {
          dialog: false,
          img: pp24,
        },
        {
          dialog: false,
          img: pp25,
        },
        {
          dialog: false,
          img: pp26,
        },
        {
          dialog: false,
          img: pp27,
        },
        {
          dialog: false,
          img: pp28,
        },
        {
          dialog: false,
          img: pp29,
        },
        {
          dialog: false,
          img: pp30,
        },
        {
          dialog: false,
          img: pp31,
        },
        {
          dialog: false,
          img: pp32,
        },
        {
          dialog: false,
          img: pp33,
        },
        {
          dialog: false,
          img: pp34,
        },
        {
          dialog: false,
          img: pp35,
        },
        {
          dialog: false,
          img: pp36,
        },
        {
          dialog: false,
          img: pp37,
        },
        {
          dialog: false,
          img: pp38,
        },
        {
          dialog: false,
          img: pp39,
        },
        {
          dialog: false,
          img: pp40,
        },
        {
          dialog: false,
          img: pp41,
        },
        {
          dialog: false,
          img: pp42,
        },
        {
          dialog: false,
          img: pp43,
        },
        {
          dialog: false,
          img: pp44,
        },
        {
          dialog: false,
          img: pp45,
        },
        {
          dialog: false,
          img: pp46,
        },

        {
          dialog: false,
          img: pp48,
        },
        {
          dialog: false,
          img: pp49,
        },
        {
          dialog: false,
          img: pp50,
        },
        {
          dialog: false,
          img: pp51,
        },
        {
          dialog: false,
          img: pp52,
        },
        {
          dialog: false,
          img: pp53,
        },
        {
          dialog: false,
          img: pp54,
        },
        {
          dialog: false,
          img: pp55,
        },
        {
          dialog: false,
          img: pp56,
        },
        {
          dialog: false,
          img: pp57,
        },
        {
          dialog: false,
          img: pp58,
        },
        {
          dialog: false,
          img: pp59,
        },
        {
          dialog: false,
          img: pp60,
        },
        {
          dialog: false,
          img: pp61,
        },
        {
          dialog: false,
          img: pp62,
        },
        {
          dialog: false,
          img: pp63,
        },
        {
          dialog: false,
          img: pp64,
        },
        {
          dialog: false,
          img: pp65,
        },
        {
          dialog: false,
          img: pp66,
        },
        {
          dialog: false,
          img: pp67,
        },
        {
          dialog: false,
          img: pp68,
        },
        {
          dialog: false,
          img: pp69,
        },
        {
          dialog: false,
          img: pp70,
        },
        {
          dialog: false,
          img: pp71,
        },
        {
          dialog: false,
          img: pp72,
        },
        {
          dialog: false,
          img: pp73,
        },
      ],
    };
  },
  methods: {
    loadimg(i) {
      this.profiles[i].dialog = !this.profiles[i].dialog;
      this.prev = i;
    },
    previous() {
      if (this.prev > 0) {
        this.prev = this.prev - 1;
      } else {
        this.prev = 69;
      }
    },
    next() {
      if (this.prev < 69) {
        this.prev = this.prev + 1;
      } else {
        this.prev = 0;
      }
    },
  },
};
</script>

<style>
</style>