<template>
  <div>
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 15.859375vw">
        <v-card outlined class="transparent" align="center">
          <v-card
            width="24.56875vw"
            height="5.33359375vw"
            style="margin: 0 0 3vw 0"
            class="transparent"
            outlined
            ><v-img :src="popen"></v-img
          ></v-card>
          <h1 style="color: #2a3977">Shami Athletics</h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>

        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <p style="color: #2a3977">
          At Shami Eye, we believe sport helps young people make good choices,
          lead healthy lives and build valuable skills, we have always supported
          young and upcoming Jordanian athletes who strive to be role models for
          younger generations. We believe that the encouragement and support
          “Shami Athletics” provide to athletes is a key factor in building
          confidence, and that can lead to success in the high-pressure sports
          events. This comes as part of our mission, which is to highlight human
          empowerment, knowledge sharing, and uncompromised quality.
        </p>

        <v-card outlined tile class="transparent" height="2.96875vw"></v-card>
      </div>
    </div>
    <!-- MOBILE -->
    <div class="hidden-md-and-up">
      <mimg-page :img="mphoto"></mimg-page>
      <div style="margin: 0 10.66666666vw">
        <v-card outlined class="transparent" align="center">
          <v-card
            width="57.86666666666667vw"
            height="11.805333333333333vw"
            style="margin: 0 0 8.853333333333333vw 0"
            class="transparent"
            outlined
            ><v-img :src="popen"></v-img
          ></v-card>
          <h1 style="color: #2a3977; font-size: 5.333333333333334vw">
            Shami Athletics
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <v-card outlined class="transparent" align="center">
          <p style="color: #2a3977; font-size: 4.266666666666667vw; margin: 0">
            At Shami Eye, we believe sport helps young people make good choices,
            lead healthy lives and build valuable skills, we have always
            supported young and upcoming Jordanian athletes who strive to be
            role models for younger generations. We believe that the
            encouragement and support “Shami Athletics” provide to athletes is a
            key factor in building confidence, and that can lead to success in
            the high-pressure sports events. This comes as part of our mission,
            which is to highlight human empowerment, knowledge sharing, and
            uncompromised quality.
          </p>
        </v-card>
        <v-card
          outlined
          tile
          class="transparent"
          height="10.666666666666668vw"
        ></v-card>
      </div>
    </div>
  </div>
</template>

<script>
import photo from "../../assets/news/athletics/mask_group_50.png";
import popen from "../../assets/news/academy/Shamiathletics.png";
import mphoto from "../../assets/news/athletics/mask_group_50.png";

export default {
  data() {
    return {
      photo,
      mphoto,
      popen,
      overlay: false,
    };
  },
};
</script>

<style>
</style>