<template>
  <div>
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 18.90625vw 10.390625vw 18.90625vw">
        <v-card outlined class="transparent" align="center">
          <h1 class="ar" style="color: #2a3977">شركات التأمين</h1></v-card
        >
        <v-card outlined tile class="transparent" height="4.21875vw"></v-card>
        <v-row no-gutters justify="center">
          <v-col
            align-self="center"
            class="cols5"
            v-for="(company, i) in companies"
            :key="i"
          >
            <v-card
              class="d-flex justify-center align-center flip-card mb-3"
              height="11.796875vw"
              width="11.5625vw"
              style="
                border-radius: 2.65625vw;
                box-shadow: 0 0 1.171875vw 0.15625vw #dcdcdc !important;
              "
              @mouseenter="company.active = true"
              @mouseleave="company.active = false"
              align="center"
            >
              <div class="flip-card-inner">
                <div v-if="!company.active" class="flip-card-front">
                  <v-img contain :src="company.logo"></v-img>
                </div>

                <div
                  style="padding: 0 0.8203125vw"
                  v-if="company.active"
                  class="flip-card-back"
                >
                  <h6
                    style="color: #2a9ae5"
                    class="boldm ar"
                    v-html="company.name"
                  ></h6>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- MOBILE -->
    <div class="hidden-md-and-up">
      <mimg-page :img="mphoto"></mimg-page>
      <div style="margin: 0 10.2vw 10.390625vw 10.2vw">
        <v-card outlined class="transparent" align="center">
          <h1
            class="art"
            style="color: #2a3977; font-size: 5.333333333333334vw"
          >
            شركات التأمين
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="4.21875vw"></v-card>
        <v-row>
          <v-col
            align-self="center"
            cols="6"
            v-for="(company, i) in companies"
            :key="i"
          >
            <v-card
              class="d-flex justify-center align-center flip-card"
              min-height="40.266666666666666vw"
              min-width="39.46666666666667vw"
              style="
                border-radius: 9.066666666666666vw;
                box-shadow: 0 0 20px 2px #dcdcdc !important;
                margin: 0 0 -2.666666666666667vw 0 !important;
              "
              @click.native="company.active = !company.active"
              align="center"
            >
              <div class="flip-card-inner">
                <div v-if="!company.active" class="flip-card-front">
                  <v-img contain :src="company.logo"></v-img>
                </div>

                <div
                  style="padding: 0 0.8203125vw"
                  v-if="company.active"
                  class="flip-card-back"
                >
                  <h6
                    style="color: #2a9ae5; font-size: 4.266666666666667vw"
                    class="boldm ar"
                    v-html="company.name"
                  ></h6>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import photo from "../assets/Insurance/Main-photo7.png";
import mphoto from "../assets/Insurance/Group 455.png";
import Logo1 from "../assets/Insurance/Logo1.png";
import Logo2 from "../assets/Insurance/Logo2.png";
import Logo3 from "../assets/Insurance/Logo3.png";
import Logo4 from "../assets/Insurance/Logo4.png";
import Logo5 from "../assets/Insurance/Logo5.png";
import Logo6 from "../assets/Insurance/Logo6.png";
import Logo7 from "../assets/Insurance/Logo7.png";
import Logo8 from "../assets/Insurance/Logo8.png";
import Logo9 from "../assets/Insurance/Logo9.png";
import Logo10 from "../assets/Insurance/Logo10.png";
import Logo11 from "../assets/Insurance/Logo11.png";
import Logo12 from "../assets/Insurance/Logo12.png";
import Logo13 from "../assets/Insurance/Logo13.png";
import Logo14 from "../assets/Insurance/Logo14.png";
import Logo15 from "../assets/Insurance/Logo15.png";
import Logo16 from "../assets/Insurance/Logo16.png";
import Logo17 from "../assets/Insurance/Logo17.png";
import Logo18 from "../assets/Insurance/Logo18.png";
import Logo19 from "../assets/Insurance/Logo19.png";
import Logo20 from "../assets/Insurance/Logo20.png";
import Logo21 from "../assets/Insurance/Logo21.png";
import Logo22 from "../assets/Insurance/Logo22.png";
import Logo23 from "../assets/Insurance/Logo23.png";
import Logo24 from "../assets/Insurance/Logo24.png";
import Logo25 from "../assets/Insurance/Logo25.png";
import Logo26 from "../assets/Insurance/Logo26.png";
import Logo27 from "../assets/Insurance/Logo27.png";
import Logo28 from "../assets/Insurance/Logo28.png";
import Logo29 from "../assets/Insurance/Logo29.png";
import Logo30 from "../assets/Insurance/Logo30.png";
import Logo31 from "../assets/Insurance/Logo31.png";
import Logo32 from "../assets/Insurance/Logo32.png";
import Logo33 from "../assets/Insurance/Logo33.png";

export default {
  metaInfo: {
    title: "الشامي العيون",
  },
  data() {
    return {
      photo,
      mphoto,
      companies: [
        { active: false, name: "مجموعة <br> الخليج للتأمين", logo: Logo1 },
        { active: false, name: "نات هيلث", logo: Logo2 },
        { active: false, name: "سوليدرتي <br> الأولى للتأمين", logo: Logo3 },
        { active: false, name: "جامعة اليرموك", logo: Logo4 },
        {
          active: false,
          name: "مصفاة البترول <br>الاردنية المساهمة  <br>المحدودة",
          logo: Logo5,
        },
        { active: false, name: "البنك المركزي  <br>الأردني", logo: Logo6 },
        {
          active: false,
          name: " بنك الإستثمار  <br>العربي الأردني <br/> (AJIB)",
          logo: Logo7,
        },
        {
          active: false,
          name: "الشركة  <br> المتخصصة لإدارة التأمينات الطبية",
          logo: Logo8,
        },
        { active: false, name: "ميد نت", logo: Logo9 },
        {
          active: false,
          name: "نقابة الأطباء<br> الأردنية",
          logo: Logo10,
        },
        {
          active: false,
          name: "شركة الضامنون <br>العرب للتامين",
          logo: Logo11,
        },
        { active: false, name: "كيبلكو الأردن", logo: Logo12 },
        {
          active: false,
          name: "شركة توليد <br>الكهرباء المركزية <br>(CEGCO)",
          logo: Logo13,
        },
        {
          active: false,
          name: "شركة الكهرباء<br> الأردنية",
          logo: Logo14,
        },
        {
          active: false,
          name: "شركة الكهرباء  <br>الوطنية <br/> (NEPCO)",
          logo: Logo15,
        },
        {
          active: false,
          name: "البنك العربي  <br> الإسلامي الدولي",
          logo: Logo16,
        },
        { active: false, name: "شركة التأمين <br>الإسلامية", logo: Logo17 },
        {
          active: false,
          name: "شركة مناجم <br>الفوسفات الاردنية <br/> (JPMC)",
          logo: Logo18,
        },
        { active: false, name: "غلوب ميد", logo: Logo19 },
        { active: false, name: "ميدي فيزا", logo: Logo20 },
        {
          active: false,
          name: "التأمين الصحة <br>الملكية",
          logo: Logo21,
        },
        {
          active: false,
          name: "النسر العربي <br> للتأمين",
          logo: Logo22,
        },
        {
          active: false,
          name: "جامعة البلقاء<br> التطبيقية",
          logo: Logo23,
        },
        { active: false, name: "البوتاس العربية", logo: Logo24 },
        { active: false, name: "الجمارك الأردنية", logo: Logo25 },
        {
          active: false,
          name: "‫نقابة المهندسين <br> الأردنيين",
          logo: Logo26,
        },
        { active: false, name: "نقابة المحامين  <br>الأردنيين", logo: Logo27 },
        { active: false, name: "نيوتن للتأمين", logo: Logo28 },
        {
          active: false,
          name: "شركة توزيع<br> الكهرباء <br>(EDCO)",
          logo: Logo29,
        },
        {
          active: false,
          name: "أومني كير",
          logo: Logo30,
        },
        {
          active: false,
          name: "الشركة الهندية <br>الأردنية للكيماويات<br> المحدودة",
          logo: Logo31,
        },
        {
          active: false,
          name: "شركة السمرا<br> لتوليد الكهرباء <br>(SEPCO)",
          logo: Logo32,
        },
        {
          active: false,
          name: "المجموعة العربية<br>الأوروبية للتأمين",
          logo: Logo33,
        },
      ],
    };
  },
};
</script>

<style>
.cols5 {
  width: 20%;
  max-width: 20%;
  flex-basis: 20%;
}
</style>