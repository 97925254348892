<template>
  <div>
    <div align="right" class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 15.859375vw">
        <v-card outlined class="transparent" align="center">
          <v-card
            width="24.56875vw"
            height="5.33359375vw"
            style="margin: 0 0 3vw 0"
            class="transparent"
            outlined
            ><v-img :src="popen"></v-img
          ></v-card>
          <h1 class="ar" style="color: #2a3977">الشامي للرياضة</h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>

        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <p class="ar" style="color: #2a3977">
          في الشامي للعيون، نرى أن الرياضة تساعد الشباب على تعزيز الثقة بالنفس
          وبناء شخصية سليمة وعيش حياة صحية، كما أنها تؤدي لبناء مهارات خاصة يمكن
          توظيفها في خدمة المجتمع. لذلك، فقد دعمنا منذ فترة طويلة الرياضيين
          الأردنيين الذين يسعون جاهدين ليكونوا قدوة للأجيال الشابة. وليس هناك شك
          في أن دعمنا المستمر للرياضة هو عامل هام يؤثر على الأداء الرياضي
          للمشاركين في الأنشطة الرياضية. ونحن نؤمن بأن التشجيع والدعم الذي يوفره
          الشامي للرياضة هو عامل رئيسي في بناء الثقة التي يمكن أن تؤدي إلى
          النجاح في الأنشطة الرياضية الهامة.
        </p>

        <v-card outlined tile class="transparent" height="2.96875vw"></v-card>
      </div>
    </div>
    <!-- MOBILE -->
    <div align="right" class="hidden-md-and-up">
      <mimg-page :img="mphoto"></mimg-page>
      <div style="margin: 0 10.66666666vw">
        <v-card outlined class="transparent" align="center">
          <v-card
            width="57.86666666666667vw"
            height="11.805333333333333vw"
            style="margin: 0 0 8.853333333333333vw 0"
            class="transparent"
            outlined
            ><v-img :src="popen"></v-img
          ></v-card>
          <h1 class="ar" style="color: #2a3977; font-size: 5.333333333333334vw">
            الشامي للرياضة
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <v-card outlined class="transparent" align="center">
          <p
            class="ar"
            style="color: #2a3977; font-size: 4.266666666666667vw; margin: 0"
          >
            في الشامي للعيون، نرى أن الرياضة تساعد الشباب على تعزيز الثقة بالنفس
            وبناء شخصية سليمة وعيش حياة صحية، كما أنها تؤدي لبناء مهارات خاصة
            يمكن توظيفها في خدمة المجتمع. لذلك، فقد دعمنا منذ فترة طويلة
            الرياضيين الأردنيين الذين يسعون جاهدين ليكونوا قدوة للأجيال الشابة.
            وليس هناك شك في أن دعمنا المستمر للرياضة هو عامل هام يؤثر على الأداء
            الرياضي للمشاركين في الأنشطة الرياضية. ونحن نؤمن بأن التشجيع والدعم
            الذي يوفره الشامي للرياضة هو عامل رئيسي في بناء الثقة التي يمكن أن
            تؤدي إلى النجاح في الأنشطة الرياضية الهامة.
          </p>
        </v-card>
        <v-card
          outlined
          tile
          class="transparent"
          height="10.666666666666668vw"
        ></v-card>
      </div>
    </div>
  </div>
</template>

<script>
import photo from "../../assets/news/athletics/mask_group_50.png";
import popen from "../../assets/news/academy/Shamiathletics.png";
import mphoto from "../../assets/news/athletics/mask_group_50.png";

export default {  
metaInfo: {
    title: "الشامي العيون",
  },
  data() {
    return {
      photo,
      mphoto,
      popen,
      overlay: false,
    };
  },
};
</script>

<style>
</style>