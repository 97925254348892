<template>
  <div>
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 18.90625vw 10.390625vw 18.90625vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977">Careers</h1></v-card
        >
        <v-card
          outlined
          tile
          class="transparent"
          height="3.5937499999999996vw"
        ></v-card>
        <v-card outlined class="transparent" align="center">
          <p style="color: #204170">
            Send your CV to
            <span style="color: #2a9ae5" class="boldm">hr@shamieye.com</span>
          </p>
        </v-card>
      </div>
    </div>
    <div class="hidden-md-and-up">
      <mimg-page :img="photo"></mimg-page>
      <div style="margin: 0 0 10.390625vw 0">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977; font-size: 5.333333333333334vw">
            Careers
          </h1></v-card
        >
        <v-card
          outlined
          tile
          class="transparent"
          height="3.5937499999999996vw"
        ></v-card>
        <v-card outlined class="transparent" align="center">
          <p style="color: #204170; font-size: 4.266666666666667vw">
            Send your CV to
            <span style="color: #2a9ae5" class="boldm">hr@shamieye.com</span>
          </p>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import photo from "../assets/careers/Main-photo8.png";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Shami Eye | Careers",
    meta: [
      {
        name: "description",
        content:
          "Interested in joining Shami Eye Team? Send your resume today!",
      },
    ],
    // all titles will be injected into this template
  },
  data() {
    return {
      photo,
    };
  },
};
</script>

<style>
</style>