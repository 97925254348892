<template>
  <div>
    <div>
      <div>
        <div class="hidden-sm-and-down" align="center">
          <iframe
            name="dummyframe"
            id="dummyframe"
            style="display: none"
          ></iframe>
          <v-card
            align="center"
            style="
              padding: 5.234375vw 5.15625vw;
              border-radius: 4.296875vw;
              background-color: white !important;
            "
            width="48.59375vw"
            height="43.125vw"
          >
            <h2
              style="margin: 0 0 2.890625vw 0; color: #2a9ae5"
              class="boldm titlea"
            >
              Schedule An Appointment
            </h2>
            <form
              target="dummyframe"
              id="theForm"
              name="theForm"
              action="https://docs.google.com/forms/u/2/d/e/1FAIpQLSc1Aio08HlG4r9eVU908C-Ny3GtcR-yQ4lBWQLWv6yAjbLyvQ/formResponse"
            >
              <v-card
                class="d-flex justify-left align-center"
                width="38.359375vw"
                height="3.28125vw"
                color="#F2F3F5"
                style="
                  padding: 0 1.40625vw;
                  margin: 0 0 1.09375vw 0;
                  box-shadow: 0 0 0 0 #f2f3f5 !important;
                  border-radius: 3.359375vw;
                "
              >
                <input
                  style="font-size: 0.9375vw"
                  type="text"
                  v-model="fname"
                  placeholder="First Name"
                  name="entry.2005620554"
                />
              </v-card>
              <v-card
                class="d-flex justify-left align-center"
                width="38.359375vw"
                height="3.28125vw"
                color="#F2F3F5"
                style="
                  padding: 0 1.40625vw;
                  margin: 0 0 1.09375vw 0;
                  box-shadow: 0 0 0 0 #f2f3f5 !important;
                  border-radius: 3.359375vw;
                "
              >
                <input
                  style="font-size: 0.9375vw"
                  type="text"
                  v-model="lname"
                  placeholder="Last Name"
                  name="entry.1045781291"
                />
              </v-card>
              <v-card
                class="d-flex justify-left align-center"
                width="38.359375vw"
                height="3.28125vw"
                color="#F2F3F5"
                style="
                  padding: 0 1.40625vw;
                  margin: 0 0 1.09375vw 0;
                  box-shadow: 0 0 0 0 #f2f3f5 !important;
                  border-radius: 3.359375vw;
                "
              >
                <input
                  style="font-size: 0.9375vw"
                  type="text"
                  v-model="email"
                  name="entry.1065046570"
                  placeholder="E-Mail"
                />
              </v-card>
              <v-card
                class="d-flex justify-left align-center"
                width="38.359375vw"
                height="3.28125vw"
                color="#F2F3F5"
                style="
                  padding: 0 1.40625vw;
                  margin: 0 0 1.09375vw 0;
                  box-shadow: 0 0 0 0 #f2f3f5 !important;
                  border-radius: 3.359375vw;
                "
              >
                <input
                  style="font-size: 0.9375vw"
                  type="text"
                  v-model="phonenumber"
                  placeholder="Phone Number"
                  name="entry.1166974658"
                />
              </v-card>

              <v-select
                multiple
                v-model="service"
                solo
                flat
                :items="items"
                label="Service"
                style="color: #2a9ae5"
                append-icon="+"
                name="entry.839337160"
              ></v-select>

              <v-card
                height="3.2031249999999996vw"
                outlined
                class="transparent"
              ></v-card>
              <v-card v-if="sessub" outlined class="transparent">
                <slot></slot>
              </v-card>
              <v-card v-if="!sessub" outlined class="transparent">
                <v-btn
                  v-if="!submitted"
                  @click="sub"
                  height="3.28125vw"
                  width="12.968750000000002vw"
                  depressed
                  color="#2A9AE5"
                  style="
                    text-transform: none;
                    border-radius: 3.359375vw;
                    margin: 0 14px 0 0;
                  "
                  class="white--text btntxt"
                >
                  <span style="margin: 0 0 0 0; font-size: 1.25vw">Submit</span>
                </v-btn>
                <slot></slot>
              </v-card>
              <span
                v-if="submitted"
                style="
                  margin: 0 0 0 0;

                  color: #2a9ae5;
                "
                v-html="textsub"
              ></span>

              <v-card v-if="sessub" outlined class="transparent">
                <span
                  style="
                    margin: 0 0 0 0;

                    color: #2a9ae5;
                  "
                  v-html="textsub"
                ></span>
              </v-card>
            </form>
          </v-card>
        </div>
        <v-card outlined class="transparent" height="100px"></v-card>
        <!-- MOBILE -->
        <div class="hidden-md-and-up" align="center">
          <v-card
            align="center"
            style="
              padding: 0 6.933333333333333vw;
              border-radius: 14.666666666666666vw;
              background-color: white !important;
            "
            width="82.66666666666667vw"
            height="101.33333333333334vw"
          >
            <iframe
              name="dummyframe"
              id="dummyframe"
              style="display: none"
            ></iframe>

            <v-card outlined class="transparent" height="4.266666666666667vw">
            </v-card>
            <v-card
              @click="clickbtn"
              outlined
              class="transparent"
              align="right"
            >
              <p
                style="
                  font-size: 5.066666666666666vw;
                  margin: 0 0 -4% 0;
                  color: #2a9ae5;
                "
              >
                X
              </p>
            </v-card>
            <h2
              style="margin: 0 0 4vw 0; color: #2a9ae5; font-size: 4.8vw"
              class="sboldm titlea"
            >
              Schedule <br />
              An Appointment
            </h2>
            <form
              method="POST"
              target="dummyframe"
              id="theForm1"
              name="theForm1"
              action="https://docs.google.com/forms/u/2/d/e/1FAIpQLSc1Aio08HlG4r9eVU908C-Ny3GtcR-yQ4lBWQLWv6yAjbLyvQ/formResponse"
            >
              <v-card
                class="d-flex justify-left align-center"
                width="69.06666666666666vw"
                height="7.733333333333333vw"
                color="#F2F3F5"
                style="
                  padding: 0 4.266666666666667vw;
                  margin: 0 0 2.666666666666667vw 0;
                  box-shadow: 0 0 0 0 #f2f3f5 !important;
                  border-radius: 3.359375vw;
                "
              >
                <input
                  style="font-size: 2.933333333333333vw"
                  type="text"
                  v-model="fname"
                  placeholder="First Name"
                  name="entry.2005620554"
                />
              </v-card>
              <v-card
                class="d-flex justify-left align-center"
                width="69.06666666666666vw"
                height="7.733333333333333vw"
                color="#F2F3F5"
                style="
                  padding: 0 4.266666666666667vw;
                  margin: 0 0 2.666666666666667vw 0;
                  box-shadow: 0 0 0 0 #f2f3f5 !important;
                  border-radius: 3.359375vw;
                "
              >
                <input
                  style="font-size: 2.933333333333333vw"
                  type="text"
                  v-model="lname"
                  placeholder="Last Name"
                  name="entry.1045781291"
                />
              </v-card>
              <v-card
                class="d-flex justify-left align-center"
                width="69.06666666666666vw"
                height="7.733333333333333vw"
                color="#F2F3F5"
                style="
                  padding: 0 4.266666666666667vw;
                  margin: 0 0 2.666666666666667vw 0;
                  box-shadow: 0 0 0 0 #f2f3f5 !important;
                  border-radius: 3.359375vw;
                "
              >
                <input
                  style="font-size: 2.933333333333333vw"
                  type="text"
                  v-model="email"
                  placeholder="E-Mail"
                  name="entry.1065046570"
                />
              </v-card>
              <v-card
                class="d-flex justify-left align-center"
                width="69.06666666666666vw"
                height="7.733333333333333vw"
                color="#F2F3F5"
                style="
                  padding: 0 4.266666666666667vw;
                  margin: 0 0 2.666666666666667vw 0;
                  box-shadow: 0 0 0 0 #f2f3f5 !important;
                  border-radius: 3.359375vw;
                "
              >
                <input
                  style="font-size: 2.933333333333333vw"
                  type="text"
                  v-model="phonenumber"
                  placeholder="Phone Number"
                  name="entry.1166974658"
                />
              </v-card>

              <v-select
                name="entry.839337160"
                multiple
                v-model="service"
                solo
                flat
                :items="items"
                label="Service"
                style="color: #2a9ae5; height: 7.733333333333333vw !important"
                append-icon="+"
              ></v-select>

              <v-card
                height="8.533333333333333vw"
                outlined
                class="transparent"
              ></v-card>
              <v-card v-if="!sessub" outlined class="transparent">
                <v-btn
                  v-if="!submitted"
                  @click="subm"
                  height="8.533333333333333vw"
                  width="33.6vw"
                  depressed
                  color="#2A9AE5"
                  style="
                    text-transform: none;
                    border-radius: 11.466666666666667vw;
                    margin: 0 0px 0 0;
                  "
                  class="white--text btntxt"
                >
                  <span style="margin: 0 0 0 0; font-size: 4.266666666666667vw"
                    >Submit</span
                  >
                </v-btn>
              </v-card>

              <span
                v-if="submitted"
                style="
                  margin: 0 0 0 0;
                  font-size: 4.266666666666667vw;
                  color: #2a9ae5;
                "
                v-html="textsub"
              ></span>

              <v-card v-if="sessub" outlined class="transparent">
                <span
                  style="
                    margin: 0 0 0 0;
                    font-size: 4.266666666666667vw;
                    color: #2a9ae5;
                  "
                  v-html="textsub"
                ></span>
              </v-card>
            </form>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["clickbtn"],
  data() {
    return {
      active: false,
      textsub: "Thanks For Submitting",
      phonenumber: "",
      fname: "",
      lname: "",
      email: "",
      service: "",
      submitted: false,
      sessub: sessionStorage["submitted"],
      items: [
        "Vision Correction",
        "Cataract",
        "Retina",
        "Glaucomo",
        "Post Operation",
        "Pediatric Ophithalmology",
        "Ophthalmic Plastic",
        "Other Services",
      ],
    };
  },
  methods: {
    sub() {
      if (
        this.fname == "" ||
        this.lname == "" ||
        this.email == "" ||
        this.phonenumber == ""
      ) {
        alert("Please Fill In all the boxes");
      } else {
        document.getElementById("theForm").submit();
        this.submitted = true;
        sessionStorage["submitted"] = true;
        this.textsub = "Thank You For Submitting";
      }
    },
    subm() {
      if (
        this.fname == "" ||
        this.lname == "" ||
        this.email == "" ||
        this.phonenumber == ""
      ) {
        alert("Please Fill In all the boxes");
      } else {
        document.getElementById("theForm1").submit();
        this.submitted = true;
        sessionStorage["submitted"] = true;
      }
    },
  },
};
</script>

<style>
.v-input__slot {
  background-color: #f2f3f5 !important;
  border-radius: 3.359375vw !important;
  padding: 0 1.40625vw !important;
}

.v-input__control {
  width: 38.359375vw !important;
  max-height: 3.28125vw !important;
  min-height: 3.28125vw !important;
}

.v-label {
  font-size: 0.9375vw;
  color: #2a9ae5 !important;
}

.v-icon {
  font-size: max(1.953125vw, 14px);
  color: #2a9ae5 !important;
  font-style: normal;
  font-family: monstrat-sb;
}
</style>