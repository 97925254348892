<template>
  <div>
    <div class="home hidden-sm-and-down">
      <Vcar :slides="maincar"></Vcar>
      <div
        id="comp2"
        style=" background-color:#EFF6F9; padding: 0% 11.875vw 8.359375vw; 11.875vw;"
      >
        <v-card
          tile
          outlined
          class="transparent"
          height="5.625vw"
          align="center"
        ></v-card>
        <v-card tile outlined class="transparent" align="center">
          <h1 style="color: #2a9ae5">
            <span style="color: #2a3977">Our</span> Services
          </h1>
          <v-card
            tile
            outlined
            class="transparent"
            height="4.53125vw"
            align="center"
          ></v-card>
          <v-row>
            <v-col v-for="(card, i) in cards" :key="i" xs="6" md="3">
              <router-link :to="card.route">
                <v-card
                  elevation="2"
                  style="
                    border-radius: 3.359375vw;
                    box-shadow: 0 0 1.171875vw 0.15625vw #dcdcdc !important;
                  "
                  min-width="146"
                  min-height="159"
                  width="17.890625vw"
                  height="22.265625vw"
                >
                  <div style="padding: 6.25vw 14% 0 14%">
                    <v-img
                      class="shrink mb-3"
                      :width="card.w"
                      :height="card.h"
                      :src="card.img"
                    ></v-img>
                    <h1 style="color: #2a9ae5" class="titlec mt-4">
                      {{ card.title }}
                    </h1>
                  </div>
                  <div style="padding: 0% 17%">
                    <p style="color: #204170" class="pcard mt-3">
                      {{ card.body }}
                    </p>
                  </div>
                </v-card>
              </router-link>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <div
        class="hidden-md-and-up"
        id="comp2-mob"
        style="
          height: 68.59375vw;
          background-color: #eff6f9;
          padding: 0% 7.2vw 0 7.2vw;
        "
      >
        <v-card
          tile
          outlined
          class="transparent"
          height="6.09375vw"
          align="center"
        ></v-card>
        <v-card tile outlined class="transparent" align="center">
          <h1 style="color: #2a9ae5">
            <span style="color: #2a3977">Our</span> Services
          </h1>
          <v-card
            tile
            outlined
            class="transparent"
            height="4.53125vw"
            align="center"
          ></v-card>
          <v-row no-gutters>
            <v-col v-for="(card, i) in cards" :key="i" xs="6" md="3">
              <router-link to="/test">
                <v-card
                  class="mb-2"
                  style="
                    border-radius: 3.359375vw;
                    box-shadow: 0 0 1.171875vw 0.15625vw #dcdcdc !important;
                  "
                  min-width="146"
                  min-height="159"
                  width="17.890625vw"
                  height="22.265625vw"
                >
                  <div style="padding: 2.96875vw 14% 0 14%">
                    <v-img
                      class="shrink mb-3"
                      :width="card.w"
                      :height="card.h"
                      :src="card.img"
                    ></v-img>

                    <h1 style="color: #2a9ae5" class="titlec mt-4">
                      {{ card.title }}
                    </h1>
                  </div>
                  <div style="padding: 0% 17%"></div>
                </v-card>
              </router-link>
            </v-col>
          </v-row>
        </v-card>
      </div>

      <div id="comp3" style="padding: 0% 4.61% 0 4.61%">
        <v-card
          tile
          outlined
          class="transparent"
          height="5.625vw"
          align="center"
        ></v-card>
        <v-card tile outlined class="transparent" align="center">
          <h1 style="color: #2a9ae5">
            <span style="color: #2a3977">LASIK</span> Latest Technology
          </h1>
          <v-card outlined class="transparent" height="1.953125vw"></v-card>
          <v-card
            style="padding: 0 0 0 5.234375vw"
            outlined
            class="transparent"
          >
            <v-row no-gutters>
              <v-col align-self="center">
                <div align="left">
                  <h2 style="color: #204170" class="mbold">
                    SCHWIND AMARIS® 1050 RS
                  </h2>
                </div>
                <div class="mt-6" align="left">
                  <p style="color: #204170; line-height: 1.2">
                    The SCHWIND AMARIS 1050 RS combines the highest speed with
                    extremely high precision. It is the superior model of the
                    SCHWIND AMARIS product family.
                  </p>
                  <v-btn
                    height="3.515625vw"
                    width="23.28125vw"
                    depressed
                    rounded
                    color="#2A9AE5"
                    style="text-transform: none"
                    class="white--text btntxt"
                    to="/technology"
                  >
                    More About Our Devices
                  </v-btn>
                </div>
              </v-col>
              <v-col>
                <v-img width="51.71875vw" height="39.53125vw" :src="llt"></v-img
              ></v-col>
            </v-row>
          </v-card>
        </v-card>
      </div>
      <v-card outlined tile class="transparent" height="0.625vw"></v-card>
      <div id="fb-root"></div>
      <div style="padding: 2vw 0; background-color: #e2ebfc" align="center">
        <div
          class="fb-page"
          data-href="https://www.facebook.com/ShamiEyeCenter/"
          data-tabs="timeline"
          data-width="500"
          data-height=""
          data-small-header="false"
          data-adapt-container-width="true"
          data-hide-cover="false"
          data-show-facepile="true"
        >
          <blockquote
            cite="https://www.facebook.com/ShamiEyeCenter/"
            class="fb-xfbml-parse-ignore"
          >
            <a href="https://www.facebook.com/ShamiEyeCenter/"
              >‎Shami Eye Center مركز الشامي للعيون‎</a
            >
          </blockquote>
        </div>
      </div>
      <!-- <div
        id="comp4"
        style="height: 29.375vw; background-color: #e2ebfc; overflow: hidden"
      >
        <v-card
          tile
          outlined
          class="transparent"
          height="4.84375vw"
          align="center"
        ></v-card>
        <v-card tile outlined class="transparent" align="center">
          <h1 style="color: #2a3977">Testimonials</h1>
        </v-card>
        <div style="margin: -2.890625vw 11.40625%">
          <v-carousel hide-delimiters height="20.78125vw">
            <template v-slot:prev="{ on, attrs }">
              <v-card outlined color="#E2EBFC">
                <img
                  :src="left"
                  v-bind="attrs"
                  v-on="on"
                  style="width: 0.9375vw; height: 1.5625vw"
                  class="mb-12"
                />
              </v-card>
            </template>
            <template v-slot:next="{ on, attrs }">
              <v-card outlined color="#E2EBFC">
                <img
                  :src="left"
                  v-bind="attrs"
                  v-on="on"
                  style="
                    width: 0.9375vw;
                    height: 1.5625vw;
                    transform: rotate(180deg);
                  "
                  class="mb-12"
                />
              </v-card>
            </template>
            <v-carousel-item v-for="(slide, i) in slides" :key="i">
              <div style="padding: 5.15625vw 11.40625% 0 11.40625%">
                <v-row align="center" justify="center" no-gutters>
                  <v-col align="center" cols="12">
                    <h1 style="color: #204170" class="titlec">
                      {{ slide.name }}
                    </h1>
                  </v-col>
                  <v-card
                    width="54.6875vw"
                    outlined
                    class="transparent"
                    align="center"
                  >
                    <p class="pcard" style="color: #204170">
                      {{ slide.text1 }} <br v-if="slide.text1" />
                      {{ slide.text }} <br v-if="slide.text2" />
                      {{ slide.text2 }} <br v-if="slide.text3" />
                      {{ slide.text3 }} <br v-if="slide.text4" />
                      {{ slide.text4 }} <br v-if="slide.text5" />
                      {{ slide.text5 }}
                    </p>
                  </v-card>
                </v-row>
              </div>
            </v-carousel-item>
          </v-carousel>
        </div>
      </div> -->
    </div>
    <!-- Mobile Start -->
    <div style="overflow: hidden" class="hidden-md-and-up">
      <Vcar :slides="maincar"> </Vcar>
      <div
        id="comp2"
        style=" background-color:#EFF6F9; padding: 0% 8vw 8.359375vw; 8vw;"
      >
        <v-card
          tile
          outlined
          class="transparent"
          height="5.625vw"
          align="center"
        ></v-card>
        <v-card tile outlined class="transparent" align="center">
          <h1 style="color: #2a9ae5; font-size: 5.333333333333334vw">
            <span style="color: #2a3977">Our</span> Services
          </h1>
          <v-card
            tile
            outlined
            class="transparent"
            height="4.53125vw"
            align="center"
          ></v-card>
          <v-row no-gutters>
            <v-col v-for="(card, i) in cards" :key="i" cols="6">
              <router-link :to="card.route">
                <v-card
                  elevation="2"
                  style="
                    border-radius: 7.199999999999999vw;
                    box-shadow: 0 0 20px 2px #dcdcdc !important;
                    margin: 0 0 2.4vw 0;
                  "
                  width="38.93333333333333vw"
                  height="42.4vw"
                  class="d-flex align-center justify-center"
                >
                  <div style="padding: 6.25vw 14% 0 14%">
                    <v-img
                      class="shrink mb-3"
                      :width="card.w"
                      :height="card.h"
                      :src="card.img"
                    ></v-img>
                    <h1 style="color: #2a9ae5; font-size: 14px" class="mt-4">
                      {{ card.title }}
                    </h1>
                  </div>
                </v-card>
              </router-link>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <div>
        <v-card style="padding" outlined class="transparent" align="center">
          <h1
            style="
              color: #2a9ae5;
              margin: 8.533333333333333vw 0;
              font-size: 5.333333333333334vw;
            "
          >
            <span style="color: #2a3977">LASIK</span> Latest Technology
          </h1>
          <v-card outlined class="transparent">
            <v-img width="97vw" height="70.13333333333334vw" :src="llt"></v-img>
          </v-card>
          <v-card style="padding: 0 10vw" outlined class="transparent">
            <div align="left">
              <h2 style="color: #204170; font-size: 4.8vw" class="mbold">
                SCHWIND AMARIS® 1050 RS
              </h2>
            </div>
            <div align="left">
              <p
                style="
                  color: #204170;
                  line-height: 1.2;
                  font-size: 4.266666666666667vw;
                  margin: 0;
                "
              >
                The SCHWIND AMARIS 1050 RS combines the highest speed with
                extremely high precision. <br />
                <br />
                It is the superior model of the SCHWIND AMARIS product family.
              </p>
            </div>
          </v-card>
          <v-card
            outlined
            class="transparent"
            style="margin: 10.133333333333333vw 0"
          >
            <h1
              style="
                color: #2a9ae5;
                margin: 8.533333333333333vw 0;
                font-size: 5.333333333333334vw;
              "
            >
              <span style="color: #2a3977">Stay</span> Connected
            </h1>
            <v-card outlined class="d-flex justify-center transparent">
              <v-card outlined class="transparent">
                <a
                  href="https://www.facebook.com/ShamiEyeCenter/"
                  target="_blank"
                >
                  <v-img
                    width="12.533333333333333vw"
                    height="12.533333333333333vw"
                    :src="p2"
                  ></v-img>
                </a>
              </v-card>
              <v-card outlined class="transparent" width="0.546875vw"></v-card>
              <v-card outlined class="transparent">
                <a
                  href="https://www.instagram.com/shamieye/?hl=en"
                  target="_blank"
                >
                  <v-img
                    width="12.8vw"
                    height="12.533333333333333vw"
                    :src="p3"
                  ></v-img>
                </a>
              </v-card>
              <v-card outlined class="transparent" width="0.546875vw"></v-card>
              <v-card outlined class="transparent">
                <a
                  href="https://jo.linkedin.com/in/shami-eye-center-21360346"
                  target="_blank"
                >
                  <v-img
                    width="13.066666666666665vw"
                    height="12.533333333333333vw"
                    :src="p4"
                  ></v-img>
                </a>
              </v-card>
              <v-card outlined class="transparent" width="0.546875vw"></v-card>
              <v-card outlined class="transparent">
                <a
                  href="https://twitter.com/shamieyecenter?lang=en"
                  target="_blank"
                >
                  <v-img
                    width="12.8vw"
                    height="12.533333333333333vw"
                    :src="p7"
                  ></v-img>
                </a>
              </v-card>
              <v-card outlined class="transparent" width="0.546875vw"></v-card>
              <v-card outlined class="transparent">
                <a
                  href="https://www.youtube.com/channel/UCE1QpIxda4Ua2xVJqTfd19w"
                  target="_blank"
                >
                  <v-img
                    width="12.533333333333333vw"
                    height="12.533333333333333vw"
                    :src="p8"
                  ></v-img>
                </a>
              </v-card>
            </v-card>
          </v-card>
          <h1
            style="
              color: #2a9ae5;
              margin: 8.533333333333333vw 0 6.533333333333333vw 0;
              font-size: 5.333333333333334vw;
            "
          >
            <span style="color: #2a3977">Schedule An </span> Appointment
          </h1>
          <v-btn
            width="54.400000000000006vw"
            height="11.200000000000001vw"
            depressed
            style="
              text-transform: none;
              font-size: 5.066666666666666vw;
              letter-spacing: -1px !important;
              border-radius: 5.866666666666666vw;
              color: white;
              margin: 0 0 8.533333333333333vw 0;
            "
            color="#2A9AE5"
          >
            <a style="color: white" href="tel:00962 06 586 6969 "> Call Now </a>
          </v-btn>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Vcar from "../components/Carousel";
import mp1 from "../assets/Homepage/Main-photo1.png";
import mp2 from "../assets/Homepage/Main-photo2.png";
import mp3 from "../assets/Homepage/Main-photo3.png";
import c1 from "../assets/Homepage/Vision-correction.png";
import c2 from "../assets/Homepage/Cataract.png";
import c3 from "../assets/Homepage/Retina.png";
import c4 from "../assets/Homepage/Cornea.png";
import c5 from "../assets/Homepage/Glaucoma.png";
import c6 from "../assets/Homepage/Post-Operation.png";
import c7 from "../assets/Homepage/Pediatrics.png";
import c8 from "../assets/Homepage/Opthalmic-plastic.png";
import llt from "../assets/Homepage/Schwind-amaris.png";
import left from "../assets/Homepage/left.png";
import mp1m from "../assets/Homepage/Group 444.png";
import mp2m from "../assets/Homepage/Group 445.png";
import mp3m from "../assets/Homepage/Group 446.png";
import p1 from "../assets/footer/Email-icon.png";
import p2 from "../assets/footer/Facebook-icon.png";
import p3 from "../assets/footer/Instagram-icon.png";
import p4 from "../assets/footer/Linkedin-icon.png";
import p5 from "../assets/footer/Location-icon.png";
import p6 from "../assets/footer/Phone-icon.png";
import p7 from "../assets/footer/Twitter-icon.png";
import p8 from "../assets/footer/Youtube-icon.png";

export default {
  name: "Home",
  components: {
    Vcar,
  },
  data() {
    return {
      p1,
      p2,
      p3,
      p4,
      p5,
      p6,
      p7,
      p8,
      maincar: [
        {
          img: mp1,
          mimg: mp1m,
          mtop: "42.625%",
          text: "The Latest Technology in Ophthalmology",
          mtext: "<br> <br>  The Latest Technology <br/> in Ophthalmology",
          textsize: "4.0625vw",
          mtextsize: "6.133333333333333vw",
          subtext: "",
          class: "2",
          color: "#2A4C77",
        },
        {
          img: mp2,
          mimg: mp2m,
          mtop: "36.4%",
          mtexts: "Lasik Vision Correction",
          texts: "Lasik Vision Correction",
          mtextsize: "6.133333333333333vw",
          subtext: "The Fastest Technology",
          subtext2: "in The World!",
          msubtext: "The Fastest Technology in <br/> The World!",
          class: "5",
          textsize: "3.671875vw",
          desc: "Precision 7D ● Speed 1050 Hz ● Fast healing ● Safe",
          mdesc: "Precision 7D ● Speed 1050 Hz ● <br/> Fast healing ● Safe",
          color: "white",
        },
        {
          img: mp3,
          mimg: mp3m,
          mtop: "41.2%",
          texts: "Cataract",
          mtexts: "Cataract",
          mtextsize: "6.133333333333333vw",
          textsize: "3.671875vw",
          subtext: "Phaco Surgery with Lens Implant",
          class: "4",
          subtext2: "The Latest Technology in the World! ",
          msubtext:
            "Phaco Surgery with Lens Implant <br/> The Latest Technology in <br/> The World! ",
          color: "white",
        },
      ],
      cards: [
        {
          img: c1,
          w: 80.19,
          h: 42.21,
          title: "Vision Correction",
          body: `Lasik, Trans PRK And ICL`,
          route: "/visioncorrection",
        },
        {
          img: c2,
          w: 71.59,
          h: 45,
          title: "Cataract",
          body: "Cataract Surgery and Other Vision Problems",
          route: "/cataract",
        },
        {
          img: c3,
          w: 51.52,
          h: 51.64,
          title: "Retina",
          body: "Vitreoretinal Disorders and Diabetic Eye Disease",
          route: "/retina",
        },
        {
          img: c4,
          w: 51.01,
          h: 51.46,
          title: "Cornea",
          body: "Keratoconus and Dry Eyes",
          route: "/cornea",
        },
        {
          img: c5,
          w: 51.46,
          h: 51.46,
          title: "Glaucoma",
          body: "High Eye Pressure and Eye Optic nerve Dysfunction",
          route: "/glaucoma",
        },
        {
          img: c6,
          w: 41.13,
          h: 50.45,
          title: "Post Operation",
          body: "Postoperative Patient Care",
          route: "/post",
        },
        {
          img: c7,
          w: 50.16,
          h: 50.96,
          title: "Pediatrics Ophthalmology",
          body: "Vision Problems and Strabismus",
          route: "/pediatric",
        },
        {
          img: c8,
          w: 65.5,
          h: 51.08,
          title: "Ophthalmic Plastic",
          body: "Eyelids, Orbits and Lacrimal Drainage Functional Disorders",
          route: "/ophthalmic",
        },
      ],
      slides: [
        {
          name: "Hadeel Mohammed",
          text1: "#Post_PRK",
          text: "Thank you from my heart #Shami_eye_center_Irbid_branch",
          text2:
            "Starting from Dr.Zaid Ismail was helpful, knowledgeable and professional, he makes the patient",
          text3:
            "feel comfortable during the procedure. Thanks a lot for the Branch Manager Mr.Alian he was very",
          text4:
            "helpful, all my questions was answered, he explained to me all the procedure details.",
          text5:
            "Thanks for all Staff of the branch for your effort. I had nothing perfect like this experince",
        },
        {
          name: "Noor Barakat",
          text1:
            "I had the laser surgery for fixing the eyesight, and I was soo happy and satisfied with",
          text: "them. Very clean. Very friendly and great service.",
          text2: "Highly recommend going!",
        },
        {
          name: "Alia Badran",
          text1: "Very organized and professional",
          text: "Doctors are so helpful",
        },
        {
          name: "Hana Bushnaq",
          text1:
            "Very professional and friendly staff. Very clean. Doctors are very knowledgeable and",
          text2:
            "Little touches of service make all the difference like the signature lemonade!",
          text:
            "helpful, going out of their way to explain and make sure patient is well taken care of.",
        },
        {
          name: "Rami Badwan",
          text1:
            "Great service, had the no touch LASIK done! After 24 hours felt better. Should have",
          text: "better vision soon hopefully. Thank you shami",
        },
        {
          name: "Tayseer Jaber",
          text1: "6/6 vision",
          text: "Thank you Shami Eye Center",
        },
        {
          name: "Salma Tahboub",
          text1: "One of the best, most sophisticated,",
          text: "most professional businesses in town!",
        },
      ],
      llt,
      left,
    };
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Shami Eye",
    meta: [
      {
        name: "description",
        content:
          "Shami Eye is jordans premier eye center with all the latest technology in ophthalmology",
      },
    ],
    // all titles will be injected into this template
  },
};
</script>
<style>
html,
body {
  overflow-x: hidden;
}

.theme--dark.v-btn--active:hover::before,
.theme--dark.v-btn--active::before {
  opacity: 1 !important;
  color: #2a9ae5;
  height: 1.7968749999999998vw !important;
  widows: 1.7968749999999998vw !important;
  overflow: hidden;
}

.v-btn--icon.v-size--small {
  color: #2a9ae5 !important;
  height: max(1.5625vw, 18px) !important;
  width: max(1.5625vw, 18px) !important;
  margin: 0.15625vw;
  bottom: 3.28125vw;
  overflow: hidden;
}

.theme--light.v-btn--active::before {
  opacity: 1 !important;
  overflow: hidden;
}
</style>