<template>
  <div>
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 12.578125000000002vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977">Medical Tips</h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <v-card align="center" class="transparent" outlined>
          <h2
            class="boldm"
            style="color: #2a9ae5; line-height: 0.5; font-size: 2.265625vw"
          >
            Health Tips <span style="font-size: 2.5vw"> ● </span
            ><span style="color: #73b6e4"> Doctors Videos </span>
          </h2>
        </v-card>
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <v-card align="center" class="transparent" outlined>
          <p style="color: #2a3977">
            General tips for eye safety and vision protection:
          </p>
        </v-card>
        <v-card outlined tile class="transparent" height="2.96875vw"></v-card>
        <v-card
          style="margin: 0 0 4.609375vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-row>
            <v-col v-for="(tip, i) in tips" :key="i" md="4">
              <v-card
                elevation="2"
                style="
                  border-radius: 3.359375vw;
                  box-shadow: 0 0 1.171875vw 0.15625vw #dcdcdc !important;
                "
                width="23.515625vw"
                height="33.4375vw"
              >
                <v-img
                  style="margin: 0 0 2.421875vw 0"
                  height="15vw"
                  :src="tip.img"
                ></v-img>

                <v-card
                  outlined
                  class="transparent"
                  style="padding: 0 2.109375vw"
                >
                  <h2 style="font-size: 1.484375vw; color: #2a9ae5">
                    Tip {{ i + 1 }}
                  </h2>
                  <p
                    v-html="tip.tip"
                    style="
                      font-size: 1.171875vw;
                      color: #204170;
                      line-height: 1 !important;
                    "
                  ></p>
                </v-card>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
    <!-- MOBILE -->
    <div class="hidden-md-and-up">
      <mimg-page :img="photo"></mimg-page>
      <div style="margin: 0 10.66666666vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977; font-size: 5.333333333333334vw">
            Medical Tips
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <v-card align="center" class="transparent" outlined>
          <h2
            class="boldm"
            style="color: #2a9ae5; line-height: 0.5; font-size: 4.8vw"
          >
            Health Tips <span style="font-size: 6vw"> ● </span
            ><span style="color: #73b6e4"> Doctors Videos </span>
          </h2>
        </v-card>
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <v-card outlined tile class="transparent" align="center">
          <p style="color: #2a3977; font-size: 4.266666666666667vw; margin: 0">
            General tips for eye safety and vision protection:
          </p>
        </v-card>
        <v-card outlined tile class="transparent" height="4.578125vw"></v-card>

        <v-card class="transparent" outlined>
          <div>
            <v-carousel
              style="
                border-radius: 11.466666666666667vw;
                box-shadow: 0 0 20px 2px #dcdcdc !important;
              "
              :show-arrows="false"
              hide-delimiters
              v-model="model"
              height="114.13333333333333vw"
            >
              <v-carousel-item v-for="(tip, i) in tips" :key="i">
                <v-card
                  style="
                    border-radius: 11.466666666666667vw;
                    box-shadow: 0 0 20px 2px #dcdcdc !important;
                  "
                  light
                  height="114.13333333333333vw"
                >
                  <v-img height="51.2vw" :src="tip.img"></v-img>
                  <v-card
                    style="padding: 0 7.199999999999999vw"
                    align="center"
                    outlined
                    class="transparent"
                  >
                    <br />
                    <br />
                    <h2 style="color: #2a9ae5; font-size: 5.066666666666666vw">
                      Tip {{ i + 1 }}
                    </h2>
                    <p
                      style="font-size: 4vw; color: #204170"
                      v-html="tip.tip"
                    ></p>
                  </v-card>
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </div>
          <v-card outlined tile class="transparent" height="10vw"></v-card>

          <v-row justify="center">
            <v-card
              class="transparent mr-1"
              outlined
              width="7.733333333333333vw"
              height="7.733333333333333vw"
            >
              <v-img :src="left" @click="model--"></v-img>
            </v-card>
            <v-card
              class="transparent ml-1"
              outlined
              width="7.733333333333333vw"
              height="7.733333333333333vw"
            >
              <v-img :src="right" @click="model++"> </v-img>
            </v-card>
          </v-row>
          <v-card outlined tile class="transparent" height="10vw"></v-card>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import photo from "../../assets/tips/Main-photo22.png";
import mphoto from "../../assets/Services/Post/Group 464.png";
import Tip1 from "../../assets/tips/Tip1.png";
import Tip2 from "../../assets/tips/Tip2.png";
import Tip3 from "../../assets/tips/Tip3.png";
import Tip4 from "../../assets/tips/Tip4.png";
import Tip5 from "../../assets/tips/Tip5.png";
import Tip6 from "../../assets/tips/Tip6.png";
import Tip7 from "../../assets/tips/Tip7.png";
import Tip8 from "../../assets/tips/Tip8.png";
import Tip9 from "../../assets/tips/Tip9.png";
import Tip10 from "../../assets/tips/Tip10.png";
import Tip11 from "../../assets/tips/Tip11.png";
import Tip12 from "../../assets/tips/Tip12.png";
import Tip13 from "../../assets/tips/Tip13.png";
import Tip14 from "../../assets/tips/Tip14.png";
import Tip15 from "../../assets/tips/Tip15.png";
import Tip16 from "../../assets/tips/Tip16.png";
import Tip17 from "../../assets/tips/Tip17.png";
import Tip18 from "../../assets/tips/Tip18.png";
import Tip19 from "../../assets/tips/Tip19.png";
import Tip20 from "../../assets/tips/Tip20.png";
import Tip21 from "../../assets/tips/Tip21.png";
import Tip22 from "../../assets/tips/Tip22.png";
import Tip23 from "../../assets/tips/Tip23.png";
import Tip24 from "../../assets/tips/Tip24.png";
import Tip25 from "../../assets/tips/Tip25.png";
import Tip26 from "../../assets/tips/Tip26.png";
import Tip27 from "../../assets/tips/Tip27.png";
import Tip28 from "../../assets/tips/Tip28.png";
import Tip29 from "../../assets/tips/Tip29.png";
import Tip30 from "../../assets/tips/Tip30.png";
import Tip31 from "../../assets/tips/Tip31.png";
import Tip32 from "../../assets/tips/Tip32.png";
import Tip33 from "../../assets/tips/Tip33.png";
import Tip34 from "../../assets/tips/Tip34.png";
import right from "../../assets/tips/Group 410.png";
import left from "../../assets/tips/Group 454.png";

export default {
  data() {
    return {
      model: 0,
      photo,
      mphoto,
      overlay: false,
      right,
      left,
      tips: [
        {
          img: Tip1,
          tip:
            "Make sure that you and your family get regular general eye exams",
        },
        {
          img: Tip2,
          tip:
            "Check your eye sight every now and then and make sure to wear eye glasses if you need them",
        },
        {
          img: Tip3,
          tip:
            "Make sure you clean and sterilize your hands well before getting them in contact with your eyes",
        },
        {
          img: Tip4,
          tip: "If you need to rub your eyes, use a paper tissue",
        },
        {
          img: Tip5,
          tip: "Preserving general health is key to preserving eye health",
        },
        {
          img: Tip6,
          tip:
            "Monitor your blood pressure every now and then because an increase in blood pressure has a negative effect on the eyes",
        },
        {
          img: Tip7,
          tip:
            "Monitor your blood sugar regularly because it is a well-known fact that complications from diabetes have an effect on the eye, especially the retina",
        },
        {
          img: Tip8,
          tip:
            "Fruits and vegetables are very important for eye health. Don’t forget to increase your intake with foods that contain Vitamins A and C",
        },
        {
          img: Tip9,
          tip:
            "Every 15 minutes, take a break from looking directly into the computer screen by relaxing your eye muscles. You can do this by closing your eyes or looking away from the computer screen and focusing on something further away",
        },
        {
          img: Tip10,
          tip:
            "A healthy and balanced diet are very important for the safety of the eye, especially for infants and elders",
        },
        {
          img: Tip11,
          tip:
            "To avoid eye dryness, stay away <br> from any air drifts such as fans, air-conditions, hair dryers or open car windows. ",
        },
        {
          img: Tip12,
          tip:
            "To avoid exhausting your eyes, <br> make sure you rest your eyes and get enough sleep",
        },
        {
          img: Tip13,
          tip:
            "Make sure there’s a minimum <br> distance of 3 meters when sitting in front of the television",
        },
        {
          img: Tip14,
          tip: "Try to decrease the brightness <br> of the computer screen",
        },
        {
          img: Tip15,
          tip:
            "Avoid looking directly into any source of strong light such as <br> bright spotlights",
        },
        {
          img: Tip16,
          tip: "Don’t look directly into sunlight",
        },
        {
          img: Tip17,
          tip:
            "Keep sharp objects such as needles, screws, scissors and knives away from your eyes",
        },
        {
          img: Tip18,
          tip:
            "It is advised that children avoid throwing rocks at each other because a lot of cases of internal bleeding and the loss of vision occur because of that",
        },
        {
          img: Tip19,
          tip:
            "To ensure the safety of your children’s eyes, its better if your children avoid playing with any sharp objects or toys such as swords, even if made out of plastic. Also avoid any toys that involve throwing or striking, such as arrows and spears. ",
        },
        {
          img: Tip20,
          tip:
            "Prevent your children from playing with any firecrackers, to avoid any flares or burning substance to reach the eyes ",
        },
        {
          img: Tip21,
          tip:
            "It is recommended that workers put on safety goggles to protect their eyes from the flares that come out of machines. ",
        },
        {
          img: Tip22,
          tip:
            "The chemical substances in makeup are harmful for the eyes, that’s why you should make sure the products you buy are safe and have proper licensing",
        },
        {
          img: Tip23,
          tip:
            "Increasing awareness on the importance of vitamin A might prevent countless cases of blindness",
        },
        {
          img: Tip24,
          tip:
            "Avoid first and second hand smoking since the smoke harms the eyes and causes infections <br> and dryness.",
        },
        {
          img: Tip25,
          tip:
            "Avoid sandy areas and sandstorms and try to protect the eye by wearing goggles that cover up the whole eye including the sides",
        },
        {
          img: Tip26,
          tip:
            "While swimming, it is advised that you wear goggles from the good time that cover the entire eye. Also protection against UV rays is very important so you must wear sunglasses while sitting under the sun, even if the weather is cloudy, keep in mind that UV radiations reach their peak between 10 am and 4 pm during summer and spring. ",
        },
        {
          img: Tip27,
          tip:
            "It is preferred that you wear goggles when playing sports that could cause eye injury.",
        },
        {
          img: Tip28,
          tip:
            "Don’t forget to wear sunglasses when going out into the sun so you can be protected by UV rays. High quality sunglasses absorb UV rays and protect your eyes from their harm. It is also advised to wear a hat to avoid the sun coming in direct contact with the eyes. ",
        },
        {
          img: Tip29,
          tip:
            "Before using anything spray able, make sure the nozzle is directed away from the eyes",
        },
        {
          img: Tip30,
          tip:
            "Be careful while using chemical substances that are used in houses such as bleaching fluids, ammonia, and cleaning detergents       ",
        },
        {
          img: Tip31,
          tip:
            "If your car gets overheated, don’t open the radiator cover until the car is completely cooled down. After it is cooled, you can open the cover but make sure you don’t bend over or get too close to avoid hot oil or water from getting in contact with the eye",
        },
        {
          img: Tip32,
          tip:
            "In case of any eye injury, make sure people around you don’t interfere and go immediately to a doctor",
        },
        {
          img: Tip33,
          tip:
            "If any foreign body enters the eye, don’t let yourself or anyone else try to remove it, instead go to a doctor immediately",
        },
        {
          img: Tip34,
          tip:
            "If any chemical substance gets in touch with your eyes, wash them with water then head straight to a doctor",
        },
      ],
    };
  },
  methods: {
    btnclick() {
      this.overlay = false;
    },
  },
};
</script>

<style>
</style>