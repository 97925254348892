<template>
  <div>
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 15.859375vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977">Cornea</h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <h2 style="color: #2a9ae5" class="boldm titlea">Keratoconus</h2>
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <h3 style="color: #2a9ae5; font-size: 1.5625vw" class="boldm">
          What is the Keratoconus?
        </h3>
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <p style="color: #2a3977">
          Keratoconus occurs when your cornea (the clear, dome-shaped front
          surface of your eye) thins and gradually bulges outward into a cone
          shape. A cone-shaped cornea causes blurred vision and may cause
          sensitivity to light and glare.
        </p>
        <v-card outlined tile class="transparent" height="1.09375vw"></v-card>
      </div>
      <v-card class="transparent" outlined align="center">
        <v-img
          width="75.9375vw"
          height="26.406249999999996vw"
          :src="photo1"
        ></v-img>

        <v-row no-gutters>
          <v-col cols="5">
            <v-card outlined class="transparent" align="center">
              <p
                style="
                  font-size: 1.171875vw;
                  color: #204170;
                  margin: 0% -50% 0 0;
                "
              >
                <span class="sboldm"> Normal Cornea </span>
              </p>
            </v-card>
          </v-col>
          <v-col>
            <v-card outlined class="transparent" align="center" cols="7">
              <p
                class="sboldm"
                style="
                  font-size: 1.171875vw;
                  color: #204170;
                  margin: 0% 0 0 -5%;
                "
              >
                <span class="sboldm"> Cone Shaped Cornea </span>
              </p>
            </v-card></v-col
          >
        </v-row>
      </v-card>
      <div style="margin: 0 15.2vw 0 15.859375vw">
        <v-card
          outlined
          tile
          class="transparent"
          height="6.406249999999999vw"
        ></v-card>

        <h3 style="color: #2a9ae5; font-size: 1.5625vw" class="boldm">
          Who is affected by Keratoconus?
        </h3>

        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>

        <p style="color: #2a3977">
          Keratoconus usually affects both eyes and generally begins to first
          affect people ages 10 to 25. The condition may progress slowly for 10
          years or more.
        </p>
        <v-card outlined tile class="transparent" height="2.265625vw"></v-card>

        <h3 style="color: #2a9ae5; font-size: 1.5625vw" class="boldm">
          Treatment methods of Keratoconus?
        </h3>
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>

        <p style="color: #2a3977">
          In the early stages of keratoconus, you can correct vision problems
          with glasses or soft contact lenses. Later you may have to be fitted
          with rigid gas permeable contact lenses or other types of lenses. If
          your condition progresses to an advanced stage, you may need corneal
          collagen cross linking using UV radiation, Intrastromal corneal rings
          or corneal transplant.
        </p>
        <v-card outlined tile class="transparent" height="2.265625vw"></v-card>

        <h2 style="color: #2a9ae5" class="boldm titlea">
          Dryness & Ocular Surface Disease
        </h2>

        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <p style="color: #2a3977">
          Ocular dryness may affect many patients especially in summer and hot
          climate, this can cause eye redness, discomfort, itching, and feeling
          of heaviness.
        </p>

        <v-card outlined tile class="transparent" height="1.875vw"></v-card>

        <v-card outlined class="transparent"
          ><h3 style="color: #2a3977; font-size: 1.5625vw" class="boldm">
            Common causes are:
          </h3></v-card
        >

        <vue-list :items="items2" color="#2A9AE5" :lh="18"></vue-list>

        <v-card outlined tile class="transparent" height="1.171875vw"></v-card>

        <p style="color: #2a3977">
          Patients might need to use lubricant eye drops frequently for long
          time.
        </p>

        <v-card outlined tile class="transparent" height="2.96875vw"></v-card>
        <v-card
          style="margin: 0 0 4.609375vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-btn
            width="35.78125vw"
            height="4.84375vw"
            depressed
            @click="overlay = !overlay"
            style="
              text-transform: none;
              font-size: 2.03125vw;
              letter-spacing: -1.5px !important;
              border-radius: 2.421875vw;
              color: white;
            "
            color="#2A9AE5"
          >
            Schedule An Appointment
          </v-btn>
        </v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform light>
          <v-btn
            @click="overlay = !overlay"
            height="3.28125vw"
            width="12.968750000000002vw"
            depressed
            color="#2A9AE5"
            style="
              text-transform: none;
              border-radius: 3.359375vw;
              margin: 0 0 0 14px;
            "
            class="white--text btntxt"
          >
            <span style="margin: 0 0 0 0; font-size: 1.25vw">Cancel</span>
          </v-btn>
        </Vform>
      </v-overlay>
    </div>
    <!-- MOBILE -->
    <div class="hidden-md-and-up">
      <mimg-page :img="photo"></mimg-page>
      <div style="margin: 0 10.66666666vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977; font-size: 5.333333333333334vw">
            Cornea
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <h2
          style="color: #2a9ae5; font-size: 4.8vw; margin: 0"
          class="boldm titlea"
        >
          Keratoconus
        </h2>
        <h3
          style="
            color: #2a9ae5;
            font-size: 4.533333333333333vw;
            margin: 3.4666666666666663vw 0;
          "
          class="boldm"
        >
          What is the Keratoconus?
        </h3>
        <p style="color: #2a3977; font-size: 4.266666666666667vw; margin: 0">
          Keratoconus occurs when your cornea (the clear, dome-shaped front
          surface of your eye) thins and gradually bulges outward into a cone
          shape. A cone-shaped cornea causes blurred vision and may cause
          sensitivity to light and glare.
        </p>
      </div>
      <v-card outlined tile class="transparent" height="3.515625vw"></v-card>
      <v-card outlined class="transparent" align="center">
        <v-img width="65.06666666666666vw" height="64vw" :src="g1m"></v-img>
        <p
          class="sboldm"
          style="margin: 1% 0 0 0; font-size: 4vw; color: #204170"
        >
          Normal Cornea
        </p>
      </v-card>
      <v-card outlined tile class="transparent" height="3.515625vw"></v-card>
      <v-card outlined class="transparent" align="center">
        <v-img width="77.33333333333333vw" height="64vw" :src="g2m"></v-img>
        <p
          class="sboldm"
          style="margin: 1% 0 0 0; font-size: 4vw; color: #204170"
        >
          Cone Shaped Cornea
        </p>
      </v-card>
      <div style="margin: 0 15.2vw 0 10.66666666vw">
        <v-card
          outlined
          tile
          class="transparent"
          height="6.406249999999999vw"
        ></v-card>

        <h3
          style="color: #2a9ae5; font-size: 4.533333333333333vw; margin: 0"
          class="boldm"
        >
          Who is affected by Keratoconus?
        </h3>

        <p
          style="
            color: #2a3977;
            font-size: 4.266666666666667vw;
            margin: 3.4666666666666663vw 0 6.933333333333333vw 0;
          "
        >
          Keratoconus usually affects both eyes and generally begins to first
          affect people ages 10 to 25. The condition may progress slowly for 10
          years or more.
        </p>

        <h3
          style="color: #2a9ae5; font-size: 4.533333333333333vw; margin: 0"
          class="boldm"
        >
          Treatment methods of Keratoconus?
        </h3>

        <p
          style="
            color: #2a3977;
            font-size: 4.266666666666667vw;
            margin: 3.4666666666666663vw 0 6.933333333333333vw 0;
          "
        >
          In the early stages of keratoconus, you can correct vision problems
          with glasses or soft contact lenses. Later you may have to be fitted
          with rigid gas permeable contact lenses or other types of lenses. If
          your condition progresses to an advanced stage, you may need corneal
          collagen cross linking using UV radiation, Intrastromal corneal rings
          or corneal transplant.
        </p>

        <h2
          style="color: #2a9ae5; font-size: 4.533333333333333vw; margin: 0"
          class="boldm titlea"
        >
          Dryness & Ocular Surface Disease
        </h2>

        <p
          style="
            color: #2a3977;
            font-size: 4.266666666666667vw;
            margin: 3.4666666666666663vw 0 6.933333333333333vw 0;
          "
        >
          Ocular dryness may affect many patients especially in summer and hot
          climate, this can cause eye redness, discomfort, itching, and feeling
          of heaviness.
        </p>

        <v-card outlined tile class="transparent" height="1.875vw"></v-card>

        <v-card outlined class="transparent"
          ><h3
            style="color: #2a3977; font-size: 4.533333333333333vw"
            class="boldm"
          >
            Common causes are:
          </h3></v-card
        >

        <vue-list :items="items2" color="#2A9AE5" :lh="11"></vue-list>

        <v-card outlined tile class="transparent" height="1.171875vw"></v-card>

        <p style="color: #2a3977; margin: 0; font-size: 4.266666666666667vw">
          Patients might need to use lubricant eye drops frequently for long
          time.
        </p>

        <v-card
          outlined
          tile
          class="transparent"
          height="10.666666666666668vw"
        ></v-card>
        <v-card
          style="margin: 0 0 14.133333333333335vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-btn
            width="70.13333333333334vw"
            height="10.133333333333333vw"
            depressed
            @click="overlay = !overlay"
            style="
              text-transform: none;
              font-size: 4.266666666666667vw;
              letter-spacing: -1.5px !important;
              border-radius: 5.066666666666666vw;
              color: white;
            "
            color="#2A9AE5"
          >
            Schedule An Appointment
          </v-btn>
        </v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform :clickbtn="btnclick" light> </Vform>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import photo from "../../assets/Services/Cornea/Main-photo13.png";
import photo1 from "../../assets/Services/Cornea/Corneapic1.png";
import g1m from "../../assets/Services/Cornea/Mask Group 58.png";
import g2m from "../../assets/Services/Cornea/Mask Group 59.png";
import Vform from "../../components/Form";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Shami Eye | Cornea",
    meta: [
      {
        name: "description",
        content:
          "Keratoconus occurs when your cornea (the clear, dome-shaped front surface of your eye) thins and gradually bulges outward into a cone shape. A cone-shaped cornea causes blurred vision and may cause sensitivity to light and glare.",
      },
    ],
    // all titles will be injected into this template
  },
  components: { Vform },
  data() {
    return {
      photo,
      g1m,
      g2m,
      overlay: false,
      photo1,
      items2: [
        { item: "Prolonged use of smart phones and computers" },
        { item: "Prolonged direct sunlight exposure" },
        { item: "Low water intake" },
        { item: "Chronic Blepharitis" },
        {
          item:
            "Rarely ocular dryness might be caused by some rheumatologic disorders.",
        },
      ],
    };
  },
  methods: {
    btnclick() {
      this.overlay = false;
    },
  },
};
</script>

<style>
</style>