<template>
  <div>
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 15.859375vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977">Contact Us</h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>

        <p style="color: #2a3977; margin: 0">
          Schedule a free consultation today! Our staff and surgeons are
          available and happy to answer your every question. We’d love to help
          you get all the information you need to in order to make the best
          choice.
        </p>

        <v-card outlined tile class="transparent" height="2.890625vw"></v-card>
        <v-card
          style="margin: 0 0 0 0"
          outlined
          class="transparent"
          align="center"
        >
          <h2 style="color: #2a9ae5; margin: 0" class="boldm titlea">
            Our Locations
          </h2>

          <br />
          <router-link to="/aqaba">
            <h1>Our New Location In Aqaba</h1>
          </router-link>
          <v-card
            outlined
            tile
            class="transparent"
            height="2.890625vw"
          ></v-card>
          <v-row>
            <v-col cols="4">
              <v-card
                style="
                  border-radius: 3.359375vw;
                  overflow: hidden;
                  box-shadow: 0 0 1.171875vw 0.15625vw #dcdcdc !important;
                "
                width="21.171875vw"
                height="31.640625vw"
              >
                <v-card
                  outlined
                  class="transparent"
                  height="4.609375vw"
                ></v-card>
                <h2
                  style="font-size: 1.328125vw; margin: 0; color: #2a9ae5"
                  class="boldm"
                >
                  Amman Main Branch
                </h2>
                <v-card
                  outlined
                  class="transparent"
                  height="2.265625vw"
                ></v-card>
                <v-card outlined class="transparent">
                  <v-img height="2.08203125vw" width="1.640625vw" :src="loc">
                  </v-img>
                </v-card>
                <v-card outlined class="transparent" height="0.9375vw"></v-card>
                <p style="margin: 0; font-size: 1.015625vw; color: #204170">
                  Building No. 53 <br />
                  Princess Sumaya Bint Al-Hasan St., <br />
                  7th Circle, Amman, Jordan
                </p>
                <v-card
                  outlined
                  class="transparent"
                  height="1.640625vw"
                ></v-card>
                <v-card outlined class="transparent">
                  <v-img
                    height="1.5882812499999999vw"
                    width="1.5882812499999999vw"
                    :src="phone"
                  >
                  </v-img>
                </v-card>
                <v-card outlined class="transparent" height="0.9375vw"></v-card>
                <p style="margin: 0; font-size: 1.015625vw; color: #204170">
                  T: +962 06 586 6969 <br />
                  M: +962 79 685 6010
                </p>
                <v-card
                  outlined
                  class="transparent"
                  height="2.265625vw"
                ></v-card>
                <v-card tile outlined class="transparent">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d54164.86286756661!2d35.845266994424634!3d31.952650444409088!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa957cfbb21f877d6!2z2KfZhNi02KfZhdmKINmE2YTYudmK2YjZhiBTaGFtaSBFeWU!5e0!3m2!1sen!2sjo!4v1610588834379!5m2!1sen!2sjo"
                    width="100%"
                    height="50%"
                    frameborder="0"
                    style="border: 0; height: 7vw !important"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe>
                </v-card>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card
                style="
                  border-radius: 3.359375vw;
                  overflow: hidden;
                  box-shadow: 0 0 1.171875vw 0.15625vw #dcdcdc !important;
                "
                width="21.171875vw"
                height="31.640625vw"
              >
                <v-card
                  outlined
                  class="transparent"
                  height="4.609375vw"
                ></v-card>
                <h2
                  style="font-size: 1.328125vw; margin: 0; color: #2a9ae5"
                  class="boldm"
                >
                  Zarqa Branch
                </h2>
                <v-card
                  outlined
                  class="transparent"
                  height="2.265625vw"
                ></v-card>
                <v-card outlined class="transparent">
                  <v-img height="2.08203125vw" width="1.640625vw" :src="loc">
                  </v-img>
                </v-card>
                <v-card outlined class="transparent" height="0.9375vw"></v-card>
                <p style="margin: 0; font-size: 1.015625vw; color: #204170">
                  Al AutoStrad Street <br />
                  Zarqa, Jordan <br /><br />
                </p>
                <v-card
                  outlined
                  class="transparent"
                  height="1.640625vw"
                ></v-card>
                <v-card outlined class="transparent">
                  <v-img
                    height="1.5882812499999999vw"
                    width="1.5882812499999999vw"
                    :src="phone"
                  >
                  </v-img>
                </v-card>
                <v-card outlined class="transparent" height="0.9375vw"></v-card>
                <p style="margin: 0; font-size: 1.015625vw; color: #204170">
                  T: +962 05 365 6599 <br />
                  M: +962 79 047 3677
                </p>
                <v-card
                  outlined
                  class="transparent"
                  height="2.265625vw"
                ></v-card>
                <v-card tile outlined class="transparent">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d216503.15109034232!2d35.837191072084366!3d32.018858790206046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151b6f7cdb17b371%3A0x1284b10f339ae51e!2zU2hhbWkgRXllIENlbnRyZSBBei1aYXJxYWEgLSDYp9mE2LTYp9mF2Yog2KfZhNi52YrZiNmG!5e0!3m2!1sen!2sjo!4v1610589114906!5m2!1sen!2sjo"
                    width="100%"
                    height="50%"
                    frameborder="0"
                    style="border: 0; height: 7vw !important"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe>
                </v-card>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card
                style="
                  border-radius: 3.359375vw;
                  overflow: hidden;
                  box-shadow: 0 0 1.171875vw 0.15625vw #dcdcdc !important;
                "
                width="21.171875vw"
                height="31.640625vw"
              >
                <v-card
                  outlined
                  class="transparent"
                  height="4.609375vw"
                ></v-card>
                <h2
                  style="font-size: 1.328125vw; margin: 0; color: #2a9ae5"
                  class="boldm"
                >
                  Irbid Branch
                </h2>
                <v-card
                  outlined
                  class="transparent"
                  height="2.265625vw"
                ></v-card>
                <v-card outlined class="transparent">
                  <v-img height="2.08203125vw" width="1.640625vw" :src="loc">
                  </v-img>
                </v-card>
                <v-card outlined class="transparent" height="0.9375vw"></v-card>
                <p style="margin: 0; font-size: 1.015625vw; color: #204170">
                  Al Hosson Road <br />Irbid, Jordan <br /><br />
                </p>
                <v-card
                  outlined
                  class="transparent"
                  height="1.640625vw"
                ></v-card>
                <v-card outlined class="transparent">
                  <v-img
                    height="1.5882812499999999vw"
                    width="1.5882812499999999vw"
                    :src="phone"
                  >
                  </v-img>
                </v-card>
                <v-card outlined class="transparent" height="0.9375vw"></v-card>
                <p style="margin: 0; font-size: 1.015625vw; color: #204170">
                  T: +962 02 710 5599 <br />
                  M: +962 79 199 4380
                </p>
                <v-card
                  outlined
                  class="transparent"
                  height="2.265625vw"
                ></v-card>
                <v-card tile outlined class="transparent">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3363.7800515433764!2d35.864427951229565!3d32.53202088095376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151c76f4058f0729%3A0xa13fb8f752ff57e2!2sShami%20Eye%20Center-Aydoun!5e0!3m2!1sen!2sjo!4v1610589158647!5m2!1sen!2sjo"
                    width="100%"
                    frameborder="0"
                    style="border: 0; height: 7vw !important"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe>
                </v-card>
              </v-card>
            </v-col>
          </v-row>
          <v-card
            outlined
            class="transparent"
            height="6.875000000000001vw"
          ></v-card>
        </v-card>
      </div>
      <div style="margin: 0 15vw 4.609375vw 15vw" align="center">
        <v-row>
          <v-col cols="6">
            <v-card
              outlined
              class="transparent"
              height="47.578125vw"
              width="31.640625vw"
              align="left"
            >
              <h2 style="color: #2a9ae5; margin: 0" class="boldm titlea">
                Get In Touch With Us
              </h2>
              <v-card outlined class="transparent" height="1.484375vw">
              </v-card>
              <p style="color: #204170; margin: 0">
                For general questions, please send us a message and we’ll get
                right back to you. You can also call us directly to speak <br />
                with a member of our service team or insurance expert.
              </p>
              <v-card outlined class="transparent" height="3.515625vw"></v-card>
              <h2 style="color: #2a9ae5; margin: 0" class="boldm titlea">
                Stay Connected
              </h2>
              <v-card outlined class="transparent" height="1.484375vw">
              </v-card>
              <v-card
                style="margin: 0 0 0 -1%"
                outlined
                class="d-flex justify-left transparent"
                width="21.015625vw"
              >
                <v-card outlined class="transparent">
                  <a
                    href="https://www.facebook.com/ShamiEyeCenter/"
                    target="_blank"
                  >
                    <v-img
                      width="3.671875vw"
                      height="3.671875vw"
                      :src="p2"
                    ></v-img>
                  </a>
                </v-card>
                <v-card
                  outlined
                  class="transparent"
                  width="0.546875vw"
                ></v-card>
                <v-card outlined class="transparent">
                  <a
                    href="https://www.instagram.com/shamieye/?hl=en"
                    target="_blank"
                  >
                    <v-img width="3.75vw" height="3.671875vw" :src="p3"></v-img>
                  </a>
                </v-card>
                <v-card
                  outlined
                  class="transparent"
                  width="0.546875vw"
                ></v-card>
                <v-card outlined class="transparent">
                  <a
                    href="https://jo.linkedin.com/in/shami-eye-center-21360346"
                    target="_blank"
                  >
                    <v-img
                      width="3.8281250000000004vw"
                      height="3.671875vw"
                      :src="p4"
                    ></v-img>
                  </a>
                </v-card>
                <v-card
                  outlined
                  class="transparent"
                  width="0.546875vw"
                ></v-card>
                <v-card outlined class="transparent">
                  <a
                    href="https://twitter.com/shamieyecenter?lang=en"
                    target="_blank"
                  >
                    <v-img width="3.75vw" height="3.671875vw" :src="p7"></v-img>
                  </a>
                </v-card>
                <v-card
                  outlined
                  class="transparent"
                  width="0.546875vw"
                ></v-card>
                <v-card outlined class="transparent">
                  <a
                    href="https://www.youtube.com/channel/UCE1QpIxda4Ua2xVJqTfd19w"
                    target="_blank"
                  >
                    <v-img
                      width="3.671875vw"
                      height="3.671875vw"
                      :src="p8"
                    ></v-img>
                  </a>
                </v-card>
              </v-card>
              <v-card outlined class="transparent" height="3.515625vw"></v-card>
              <h2 style="color: #2a9ae5; margin: 0" class="boldm titlea">
                Working Days & Hours
              </h2>
              <v-card outlined class="transparent" height="1.484375vw">
              </v-card>
              <v-card outlined class="d-flex transparent">
                <v-card outlined class="transparent"> </v-card>
                <p style="color: #2a3977; margin: 0">
                  <span class="boldm">Saturday to Wednesday </span> <br />
                  9:00 - 17:00 <br />
                  <br />
                  <span class="boldm"> Thursday </span><br />
                  9:00 - 14:00<br /><br />
                  <span class="boldm"> Friday </span><br />
                  Closed
                </p>
              </v-card>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card
              style="
                padding: 6.953125vw 2.890625vw;
                border-radius: 4.296875vw;
                box-shadow: 0 0 1.171875vw 0.15625vw #dcdcdc !important;
              "
              height="47.578125vw"
              width="31.640625vw"
            >
              <iframe
                name="dummyframe"
                id="dummyframe"
                style="display: none"
              ></iframe>
              <form
                target="dummyframe"
                id="theForm"
                name="theForm"
                action="https://docs.google.com/forms/u/2/d/e/1FAIpQLSc1Aio08HlG4r9eVU908C-Ny3GtcR-yQ4lBWQLWv6yAjbLyvQ/formResponse"
              >
                <v-card
                  class="d-flex justify-left align-center"
                  width="29.375vw"
                  height="3.28125vw"
                  color="#F2F3F5"
                  style="
                    padding: 0 1.40625vw;
                    margin: 0 0 1.09375vw 0;
                    box-shadow: 0 0 0 0 #f2f3f5 !important;
                    border-radius: 3.359375vw;
                  "
                >
                  <input
                    style="font-size: 0.9375vw"
                    type="text"
                    v-model="fname"
                    placeholder="First Name"
                    name="entry.2005620554"
                  />
                </v-card>
                <v-card
                  class="d-flex justify-left align-center"
                  width="29.375vw"
                  height="3.28125vw"
                  color="#F2F3F5"
                  style="
                    padding: 0 1.40625vw;
                    margin: 0 0 1.09375vw 0;
                    box-shadow: 0 0 0 0 #f2f3f5 !important;
                    border-radius: 3.359375vw;
                  "
                >
                  <input
                    style="font-size: 0.9375vw"
                    type="text"
                    v-model="lname"
                    placeholder="Last Name"
                    name="entry.1045781291"
                  />
                </v-card>
                <v-card
                  class="d-flex justify-left align-center"
                  width="29.375vw"
                  height="3.28125vw"
                  color="#F2F3F5"
                  style="
                    padding: 0 1.40625vw;
                    margin: 0 0 1.09375vw 0;
                    box-shadow: 0 0 0 0 #f2f3f5 !important;
                    border-radius: 3.359375vw;
                  "
                >
                  <input
                    style="font-size: 0.9375vw"
                    type="text"
                    v-model="email"
                    placeholder="E-Mail"
                    name="entry.1065046570"
                  />
                </v-card>
                <v-card
                  class="d-flex justify-left align-center"
                  width="29.375vw"
                  height="3.28125vw"
                  color="#F2F3F5"
                  style="
                    padding: 0 1.40625vw;
                    margin: 0 0 1.09375vw 0;
                    box-shadow: 0 0 0 0 #f2f3f5 !important;
                    border-radius: 3.359375vw;
                  "
                >
                  <input
                    style="font-size: 0.9375vw"
                    type="text"
                    v-model="phonenumber"
                    placeholder="Phone Number"
                    name="entry.1166974658"
                  />
                </v-card>
                <v-card
                  class="d-flex justify-left"
                  width="29.375vw"
                  height="9.84375vw"
                  color="#F2F3F5"
                  style="
                    padding: 1vw 1.40625vw 0 1.40625vw;
                    margin: 0 0 1.09375vw 0;
                    box-shadow: 0 0 0 0 #f2f3f5 !important;
                    border-radius: 2.109375vw;
                  "
                >
                  <textarea
                    style="font-size: 0.9375vw; resize: none; width: 100%"
                    type="text"
                    v-model="msg"
                    placeholder="Message"
                    name="entry.839337160"
                  ></textarea>
                </v-card>
                <v-card height="3.28125vw" outlined class="transparent">
                </v-card>
                <v-card v-if="!sessub" outlined class="transparent">
                  <v-btn
                    v-if="!submitted"
                    @click="sub"
                    height="3.28125vw"
                    width="12.968750000000002vw"
                    depressed
                    color="#2A9AE5"
                    style="text-transform: none; border-radius: 3.359375vw"
                    class="white--text btntxt"
                  >
                    <span style="margin: 0 0 0 0; font-size: 1.25vw"
                      >Submit</span
                    >
                  </v-btn>
                </v-card>
                <span
                  v-if="submitted"
                  style="
                    margin: 0 0 0 0;

                    color: #2a9ae5;
                  "
                  v-html="textsub"
                ></span>

                <v-card v-if="sessub" outlined class="transparent">
                  <span
                    style="
                      margin: 0 0 0 0;

                      color: #2a9ae5;
                    "
                    v-html="textsub"
                  ></span>
                </v-card>
              </form>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform light>
          <v-btn
            @click="overlay = !overlay"
            height="3.28125vw"
            width="12.968750000000002vw"
            depressed
            color="#2A9AE5"
            style="
              text-transform: none;
              border-radius: 3.359375vw;
              margin: 0 0 0 14px;
            "
            class="white--text btntxt"
          >
            <span style="margin: 0 0 0 0; font-size: 1.25vw">Cancel</span>
          </v-btn>
        </Vform>
      </v-overlay>
    </div>

    <!-- MOBILE -->
    <div class="hidden-md-and-up">
      <mimg-page :img="mphoto"></mimg-page>
      <div>
        <div style="margin: 0 10.66666666vw">
          <v-card outlined class="transparent" align="center">
            <h1 style="color: #2a3977; font-size: 5.333333333333334vw">
              Contact Us
            </h1></v-card
          >
          <v-card
            outlined
            tile
            class="transparent"
            height="2.578125vw"
          ></v-card>

          <p style="color: #2a3977; font-size: 4.266666666666667vw; margin: 0">
            Schedule a free consultation today! Our staff and surgeons are
            available and happy to answer your every question. We’d love to help
            you get all the information you need to in order to make the best
            choice.
          </p>
        </div>
        <div style="margin: 0 9.6vw">
          <h1
            style="
              color: #2a9ae5;
              margin: 10.666666666666668vw 0 6.133333333333333vw 0;
              font-size: 4.8vw;
            "
          >
            Our Location
          </h1>
          <div align="center">
            <router-link to="/aqaba">
              <h1 style="font-size: 5vw">Our New Location In Aqaba</h1>
            </router-link>
          </div>
          <br />
          <br />
          <v-card
            style="
              padding: 8.799999999999999vw 9.066666666666666vw;
              border-radius: 7.199999999999999vw;
              box-shadow: 0 0 20px 2px #dcdcdc !important;
            "
            width="80vw"
            height="80vw"
          >
            <h1
              style="
                font-size: 4.266666666666667vw;
                color: #2a9ae5;
                margin: 0 0 5.466666666666667vw 0;
              "
            >
              Amman Main Branch
            </h1>
            <v-card outlined class="d-flex transparent">
              <v-card outlined class="transparent">
                <v-img
                  width="5.6000000000000005vw"
                  height="7.1066666666666665vw"
                  :src="loc"
                >
                </v-img>
              </v-card>
              <p
                style="
                  font-size: 3.733333333333334vw;
                  color: #204170;
                  margin: 0 -1% 5.866666666666666vw 5.333333333333334vw;
                "
              >
                Building No. 53 <br />
                Princess Sumaya Bint Al-Hasan St., <br />
                7th Circle, Amman, Jordan
              </p>
            </v-card>
            <v-card outlined class="d-flex transparent">
              <v-card outlined class="transparent">
                <v-img
                  width="5.421333333333333vw"
                  height="5.421333333333333vw"
                  :src="phone"
                >
                </v-img>
              </v-card>
              <p
                style="
                  font-size: 3.733333333333334vw;
                  color: #204170;
                  margin: 0 -1% 5.866666666666666vw 5.333333333333334vw;
                "
              >
                T: +962 06 586 6969 <br />
                M: +962 79 685 6010
              </p>
            </v-card>
            <v-card outlined class="d-flex transparent">
              <v-card outlined class="transparent">
                <v-img
                  width="9.866666666666667vw"
                  height="9.866666666666667vw"
                  :src="gloc"
                >
                </v-img>
              </v-card>
              <a target="_blank" href="https://g.page/shamieyejo?share">
                <p
                  style="
                    font-size: 3.733333333333334vw;
                    color: #204170;
                    margin: 5% -1% 5.866666666666666vw 5.333333333333334vw;
                  "
                >
                  View in Google Maps
                </p>
              </a>
            </v-card>
          </v-card>
          <!-- ZARQA CARD -->
          <v-card
            style="
              margin: 6.933333333333333vw 0;
              padding: 8.799999999999999vw 9.066666666666666vw;
              border-radius: 7.199999999999999vw;
              box-shadow: 0 0 20px 2px #dcdcdc !important;
            "
            width="80vw"
            height="70.93333333333334vw"
          >
            <h1
              style="
                font-size: 4.266666666666667vw;
                color: #2a9ae5;
                margin: 0 0 5.466666666666667vw 0;
              "
            >
              Zarqa Branch
            </h1>
            <v-card outlined class="d-flex transparent">
              <v-card outlined class="transparent">
                <v-img
                  width="5.6000000000000005vw"
                  height="7.1066666666666665vw"
                  :src="loc"
                >
                </v-img>
              </v-card>
              <p
                style="
                  font-size: 3.733333333333334vw;
                  color: #204170;
                  margin: 0 -1% 5.866666666666666vw 5.333333333333334vw;
                "
              >
                Al AutoStrad Street <br />
                Zarqa, Jordan
              </p>
            </v-card>
            <v-card outlined class="d-flex transparent">
              <v-card outlined class="transparent">
                <v-img
                  width="5.421333333333333vw"
                  height="5.421333333333333vw"
                  :src="phone"
                >
                </v-img>
              </v-card>
              <p
                style="
                  font-size: 3.733333333333334vw;
                  color: #204170;
                  margin: 0 -1% 5.866666666666666vw 5.333333333333334vw;
                "
              >
                T: +962 05 365 6599 <br />
                M: +962 79 047 3677
              </p>
            </v-card>
            <v-card outlined class="d-flex transparent">
              <v-card outlined class="transparent">
                <v-img
                  width="9.866666666666667vw"
                  height="9.866666666666667vw"
                  :src="gloc"
                >
                </v-img>
              </v-card>
              <a target="_blank" href="https://goo.gl/maps/oPx9xXqXM5BPjdfQ8">
                <p
                  style="
                    font-size: 3.733333333333334vw;
                    color: #204170;
                    margin: 5% -1% 5.866666666666666vw 5.333333333333334vw;
                  "
                >
                  View in Google Maps
                </p>
              </a>
            </v-card>
          </v-card>
          <!-- IRBID BRANCH -->
          <v-card
            style="
              box-shadow: 0 0 20px 2px #dcdcdc !important;
              margin: 0 0 6.933333333333333vw 0;
              padding: 8.799999999999999vw 9.066666666666666vw;
              border-radius: 7.199999999999999vw;
            "
            width="80vw"
            height="70.93333333333334vw"
          >
            <h1
              style="
                font-size: 4.266666666666667vw;
                color: #2a9ae5;
                margin: 0 0 5.466666666666667vw 0;
              "
            >
              Irbid Branch
            </h1>
            <v-card outlined class="d-flex transparent">
              <v-card outlined class="transparent">
                <v-img
                  width="5.6000000000000005vw"
                  height="7.1066666666666665vw"
                  :src="loc"
                >
                </v-img>
              </v-card>
              <p
                style="
                  font-size: 3.733333333333334vw;
                  color: #204170;
                  margin: 0 -1% 5.866666666666666vw 5.333333333333334vw;
                "
              >
                Al Hosson Road <br />
                Irbid, Jordan
              </p>
            </v-card>
            <v-card outlined class="d-flex transparent">
              <v-card outlined class="transparent">
                <v-img
                  width="5.421333333333333vw"
                  height="5.421333333333333vw"
                  :src="phone"
                >
                </v-img>
              </v-card>
              <p
                style="
                  font-size: 3.733333333333334vw;
                  color: #204170;
                  margin: 0 -1% 5.866666666666666vw 5.333333333333334vw;
                "
              >
                T: +962 02 710 5599 <br />
                M: +962 79 199 4380
              </p>
            </v-card>
            <v-card outlined class="d-flex transparent">
              <v-card outlined class="transparent">
                <v-img
                  width="9.866666666666667vw"
                  height="9.866666666666667vw"
                  :src="gloc"
                >
                </v-img>
              </v-card>
              <a target="_blank" href="https://goo.gl/maps/xe65AD62VNER1UNdA">
                <p
                  style="
                    font-size: 3.733333333333334vw;
                    color: #204170;
                    margin: 5% -1% 5.866666666666666vw 5.333333333333334vw;
                  "
                >
                  View in Google Maps
                </p>
              </a>
            </v-card>
          </v-card>
          <h1 style="color: #2a9ae5; margin: 0 0 3.2vw 0; font-size: 4.8vw">
            Get In Touch With Us
          </h1>
          <p
            style="
              color: #2a3977;
              margin: 0 0 6.666666666666667vw 0;
              font-size: 4.266666666666667vw;
            "
          >
            For general questions, please send us a message and we’ll get right
            back to you. You can also call us directly to speak with a member of
            our service team or insurance expert.
          </p>
          <v-card
            style="margin: 0 0 10.133333333333333vw 0"
            outlined
            class="transparent"
            align="center"
          >
            <v-btn
              width="70.13333333333334vw"
              height="10.133333333333333vw"
              depressed
              @click="overlay = !overlay"
              style="
                text-transform: none;
                font-size: 4.266666666666667vw;
                letter-spacing: -0.5px !important;
                border-radius: 5.066666666666666vw;
                color: white;
              "
              color="#2A9AE5"
            >
              Send A Message
            </v-btn>
          </v-card>
          <v-card outlined class="transparent">
            <h1
              style="
                color: #2a9ae5;
                font-size: 4.8vw;
                margin: 0 0 5.333333333333334vw 0;
              "
            >
              Stay Connected
            </h1>

            <v-card outlined class="d-flex justify-left transparent">
              <v-card outlined class="transparent">
                <a
                  href="https://www.facebook.com/ShamiEyeCenter/"
                  target="_blank"
                >
                  <v-img
                    width="12.533333333333333vw"
                    height="12.533333333333333vw"
                    :src="p2"
                  ></v-img>
                </a>
              </v-card>
              <v-card outlined class="transparent" width="0.546875vw"></v-card>
              <v-card outlined class="transparent">
                <a
                  href="https://www.instagram.com/shamieye/?hl=en"
                  target="_blank"
                >
                  <v-img
                    width="12.8vw"
                    height="12.533333333333333vw"
                    :src="p3"
                  ></v-img>
                </a>
              </v-card>
              <v-card outlined class="transparent" width="0.546875vw"></v-card>
              <v-card outlined class="transparent">
                <a
                  href="https://jo.linkedin.com/in/shami-eye-center-21360346"
                  target="_blank"
                >
                  <v-img
                    width="13.066666666666665vw"
                    height="12.533333333333333vw"
                    :src="p4"
                  ></v-img>
                </a>
              </v-card>
              <v-card outlined class="transparent" width="0.546875vw"></v-card>
              <v-card outlined class="transparent">
                <a
                  href="https://twitter.com/shamieyecenter?lang=en"
                  target="_blank"
                >
                  <v-img
                    width="12.8vw"
                    height="12.533333333333333vw"
                    :src="p7"
                  ></v-img>
                </a>
              </v-card>
              <v-card outlined class="transparent" width="0.546875vw"></v-card>
              <v-card outlined class="transparent">
                <a
                  href="https://www.youtube.com/channel/UCE1QpIxda4Ua2xVJqTfd19w"
                  target="_blank"
                >
                  <v-img
                    width="12.533333333333333vw"
                    height="12.533333333333333vw"
                    :src="p8"
                  ></v-img>
                </a>
              </v-card>
            </v-card>

            <h2
              style="color: #2a9ae5; margin: 12.8vw 0 3.2vw 0; font-size: 4.8vw"
              class="boldm titlea"
            >
              Working Days & Hours
            </h2>
            <v-card outlined class="d-flex transparent">
              <v-card outlined class="transparent"> </v-card>
              <p
                style="
                  color: #2a3977;
                  margin: 0 0 19.466666666666665vw 0;
                  font-size: 4.266666666666667vw;
                "
              >
                <span class="boldm">Saturday to Wednesday </span> <br />
                9:00 - 17:00 <br />
                <br />
                <span class="boldm"> Thursday </span><br />
                9:00 - 14:00<br /><br />
                <span class="boldm"> Friday </span><br />
                Closed
              </p>
            </v-card>
            <!-- FORM -->
            <v-overlay :z-index="zIndex" :value="overlay">
              <iframe
                name="dummyframe"
                id="dummyframe"
                style="display: none"
              ></iframe>
              <v-card
                color="white"
                align="center"
                style="
                  padding: 0 6.933333333333333vw;
                  border-radius: 14.666666666666666vw;
                  box-shadow: 0 0 20px 2px gray !important;
                "
                height="110.93333333333332vw"
                width="82.66666666666667vw"
              >
                <v-card
                  @click="overlay = !overlay"
                  outlined
                  class="transparent"
                  align="right"
                >
                  <p
                    style="
                      margin: 4.533333333333333vw 0 0.26666666666666666vw 0;
                      font-size: 5.066666666666666vw;
                      color: #2a9ae5;
                    "
                  >
                    X
                  </p>
                </v-card>

                <h2
                  style="
                    color: #2a9ae5;
                    margin: 0 0 5.066666666666666vw 0;
                    font-size: 4.8vw;
                  "
                  class="sboldm"
                >
                  Send A Message
                </h2>

                <form
                  method="POST"
                  target="dummyframe"
                  id="theForm1"
                  name="theForm1"
                  action="https://docs.google.com/forms/u/2/d/e/1FAIpQLSc1Aio08HlG4r9eVU908C-Ny3GtcR-yQ4lBWQLWv6yAjbLyvQ/formResponse"
                >
                  <v-card
                    class="d-flex justify-left align-center"
                    width="69.06666666666666vw"
                    height="7.733333333333333vw"
                    color="#F2F3F5"
                    style="
                      padding: 0 4.533333333333333vw;
                      margin: 0 0 2.666666666666667vw 0;
                      box-shadow: 0 0 0 0 #f2f3f5 !important;
                      border-radius: 3.359375vw;
                    "
                  >
                    <input
                      style="font-size: 2.933333333333333vw"
                      type="text"
                      v-model="fname"
                      placeholder="First Name"
                      name="entry.2005620554"
                    />
                  </v-card>
                  <v-card
                    class="d-flex justify-left align-center"
                    width="69.06666666666666vw"
                    height="7.733333333333333vw"
                    color="#F2F3F5"
                    style="
                      padding: 0 4.533333333333333vw;
                      margin: 0 0 2.666666666666667vw 0;
                      box-shadow: 0 0 0 0 #f2f3f5 !important;
                      border-radius: 3.359375vw;
                    "
                  >
                    <input
                      style="font-size: 2.933333333333333vw"
                      type="text"
                      v-model="lname"
                      placeholder="Last Name"
                      name="entry.1045781291"
                    />
                  </v-card>
                  <v-card
                    class="d-flex justify-left align-center"
                    width="69.06666666666666vw"
                    height="7.733333333333333vw"
                    color="#F2F3F5"
                    style="
                      padding: 0 4.533333333333333vw;
                      margin: 0 0 2.666666666666667vw 0;
                      box-shadow: 0 0 0 0 #f2f3f5 !important;
                      border-radius: 3.359375vw;
                    "
                  >
                    <input
                      style="font-size: 2.933333333333333vw"
                      type="text"
                      v-model="email"
                      placeholder="E-Mail"
                      name="entry.1065046570"
                    />
                  </v-card>
                  <v-card
                    class="d-flex justify-left align-center"
                    width="69.06666666666666vw"
                    height="7.733333333333333vw"
                    color="#F2F3F5"
                    style="
                      padding: 0 4.533333333333333vw;
                      margin: 0 0 2.666666666666667vw 0;
                      box-shadow: 0 0 0 0 #f2f3f5 !important;
                      border-radius: 3.359375vw;
                    "
                  >
                    <input
                      style="font-size: 2.933333333333333vw"
                      type="text"
                      v-model="phonenumber"
                      placeholder="Phone Number"
                      name="entry.1166974658"
                    />
                  </v-card>
                  <v-card
                    class="d-flex justify-left"
                    width="69.06666666666666vw"
                    height="23.200000000000003vw"
                    color="#F2F3F5"
                    style="
                      padding: 1vw 4.533333333333333vw 0 4.533333333333333vw;
                      margin: 0 0 1.09375vw 0;
                      box-shadow: 0 0 0 0 #f2f3f5 !important;
                      border-radius: 7.199999999999999vw;
                    "
                  >
                    <textarea
                      style="
                        font-size: 2.933333333333333vw;
                        resize: none;
                        width: 100%;
                      "
                      type="text"
                      v-model="msg"
                      placeholder="Message"
                      name="entry.839337160"
                    ></textarea>
                  </v-card>
                  <v-card
                    height="6.133333333333333vw"
                    outlined
                    class="transparent"
                  >
                  </v-card>
                  <v-card v-if="!sessub" outlined class="transparent">
                    <v-btn
                      v-if="!submitted"
                      @click="subm"
                      height="8.533333333333333vw"
                      width="33.6vw"
                      depressed
                      color="#2A9AE5"
                      style="
                        text-transform: none;
                        border-radius: 11.466666666666667vw;
                        margin: 0 0px 0 0;
                      "
                      class="white--text btntxt"
                    >
                      <span
                        style="margin: 0 0 0 0; font-size: 4.266666666666667vw"
                        >Submit</span
                      >
                    </v-btn>
                  </v-card>
                  <span
                    v-if="submitted"
                    style="
                      margin: 0 0 0 0;
                      font-size: 4.266666666666667vw;
                      color: #2a9ae5;
                    "
                    v-html="textsub"
                  ></span>

                  <v-card v-if="sessub" outlined class="transparent">
                    <span
                      style="
                        margin: 0 0 0 0;
                        font-size: 4.266666666666667vw;
                        color: #2a9ae5;
                      "
                      v-html="textsub"
                    ></span>
                  </v-card>
                </form>
              </v-card>
            </v-overlay>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import photo from "../assets/contact/Main-photo21.png";
import mphoto from "../assets/contact/Group 470.png";
import gloc from "../assets/contact/Group 424.png";
import loc from "../assets/contact/Location-icon2.png";
import phone from "../assets/contact/Phone-icon2.png";
import p1 from "../assets/footer/Email-icon.png";
import p2 from "../assets/footer/Facebook-icon.png";
import p3 from "../assets/footer/Instagram-icon.png";
import p4 from "../assets/footer/Linkedin-icon.png";
import p5 from "../assets/footer/Location-icon.png";
import p6 from "../assets/footer/Phone-icon.png";
import p7 from "../assets/footer/Twitter-icon.png";
import p8 from "../assets/footer/Youtube-icon.png";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Shami Eye | Contact Us",
    meta: [
      {
        name: "description",
        content:
          "Contact Us, check the locations & phone numbers for an eye center near you to book your appointment Today!",
      },
    ],
    // all titles will be injected into this template
  },
  data() {
    return {
      photo,
      mphoto,
      loc,
      phone,
      gloc,
      p1,
      p2,
      p3,
      p4,
      p5,
      p6,
      p7,
      p8,
      fname: "",
      lname: "",
      email: "",
      phonenumber: "",
      msg: "",
      overlay: false,
      zIndex: 200,
      submitted: false,
      sessub: sessionStorage["submitted"],
      textsub: "Thanks For Submitting",
    };
  },
  methods: {
    sub() {
      if (
        this.fname == "" ||
        this.lname == "" ||
        this.email == "" ||
        this.phonenumber == ""
      ) {
        alert("Please Fill In all the boxes");
      } else {
        document.getElementById("theForm").submit();
        this.submitted = true;
        sessionStorage["submitted"] = true;
        this.textsub = "Thank You For Submitting";
      }
    },
    subm() {
      if (
        this.fname == "" ||
        this.lname == "" ||
        this.email == "" ||
        this.phonenumber == ""
      ) {
        alert("Please Fill In all the boxes");
      } else {
        document.getElementById("theForm1").submit();
        this.submitted = true;
        sessionStorage["submitted"] = true;
      }
    },
  },
};
</script>

<style>
</style>