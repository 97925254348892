<template>
  <div>
    <div align="right" class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 15.859375vw">
        <v-card outlined class="transparent" align="center">
          <v-card
            width="22.0328125vw"
            height="7.4875vw"
            style="margin: 0 0 3vw 0"
            class="transparent"
            outlined
            ><v-img :src="popen"></v-img
          ></v-card>
          <h1 class="ar" style="color: #2a3977">أكاديمية الشامي</h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>

        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <p class="ar" style="color: #2a3977">
          يعتبر الأردن، نظراً لكونه بلداً آمناً في الشرق الأوسط، مركزاً مرغوباً
          فيه ومناسباً للأوساط الأكاديمية والتعليمية بين الأطباء الأردنيين
          والأشقاء العرب الذين يعيشون في الأردن والبلدان المجاورة.
          <br /><br />
          أكاديمية الشامي هي معهد تعليمي يهدف إلى تثقيف أطباء العيون من الخريجين
          وتوعيتهم بأحدث التطورات في مجال طب العيون.
          <br /><br />
          وتهدف المؤتمرات الطبية والدورات الجراحية التي تعقد في أكاديمية الشامي
          إلى توفير أعلى مستوى من المعرفة الطبية النظرية وكسب المهارة الجراحية
          بحيث يستطيع المشاركون تقديم الخدمات الأفضل للمرضى في مراكزهم
          ومستشفياتهم أو في بلدانهم الأصلية.
          <br /><br />
          <span class="ar boldm">
            مقتطف: تعزيز تعليم تخصصات العيون لتوفير أفضل رعاية ممكنة لها</span
          >
        </p>
        <v-card outlined tile class="transparent" height="1.5625vw"></v-card>
        <v-card outlined class="transparent">
          <h2 class="boldm" style="color: #2a9ae5; line-height: 0.5">
            Hall of Fame
          </h2>
          <br />
        </v-card>

        <p class="ar" style="color: #2a3977">
          أول قاعة للمشاهير <bd>Hall Of Fame</bd> في مجال طب وجراحة العيون في
          عمان، الأردن. <br /><br />، أطلق الشامي للعيون أول فعالية بعنوان
          <bd>Hall Of Fame</bd>
          في عام 2015 التي تم فيها تكريمالعلماء البارزين والمتميزين في مجال طب
          العيون ممن كان لهم انجازات هامة في مجال طب العيون في الأردن.<br /><br />
          وفي ذات الفعالية، تمت استضافة البروفيسور الياباني تاكايوكي أكاهوشي،
          الطبيب الرائد عالمياً في جراحة الساد. ويبلغ متوسط الوقت الذي يقضيه
          البروفيسور أكاهوشي في هذه الجراحة أقل من 4 دقائق، وأسرع عملية جراحية
          قام بإنجازها تمت في دقيقة و 29 ثانية<br /><br />
          وقد شارك البروفيسور تاكايوكي أكاهوشي تجربته الغنية والقيمة في هذه
          الجلسة وكانت مشاركته بمثابة إضافة كبيرة إلى أنشطة أكاديمية الشامي.
        </p>

        <v-card outlined tile class="transparent" height="2.96875vw"></v-card>
      </div>
    </div>
    <!-- MOBILE -->
    <div align="right" class="hidden-md-and-up">
      <mimg-page :img="mphoto"></mimg-page>
      <div style="margin: 0 10.66666666vw">
        <v-card outlined class="transparent" align="center">
          <v-card
            width="57.86666666666667vw"
            height="19.663999999999998vw"
            style="margin: 0 0 8.853333333333333vw 0"
            class="transparent"
            outlined
            ><v-img :src="popen"></v-img
          ></v-card>
          <h1 class="ar" style="color: #2a3977; font-size: 5.333333333333334vw">
            أكاديمية الشامي
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>

        <p
          class="ar"
          style="
            color: #2a3977;
            font-size: 4.266666666666667vw;
            margin: 3.4666666666666663vw 0 9.066666666666666vw 0;
          "
        >
          يعتبر الأردن، نظراً لكونه بلداً آمناً في الشرق الأوسط، مركزاً مرغوباً
          فيه ومناسباً للأوساط الأكاديمية والتعليمية بين الأطباء الأردنيين
          والأشقاء العرب الذين يعيشون في الأردن والبلدان المجاورة.
          <br /><br />
          أكاديمية الشامي هي معهد تعليمي يهدف إلى تثقيف أطباء العيون من الخريجين
          وتوعيتهم بأحدث التطورات في مجال طب العيون.
          <br /><br />
          وتهدف المؤتمرات الطبية والدورات الجراحية التي تعقد في أكاديمية الشامي
          إلى توفير أعلى مستوى من المعرفة الطبية النظرية وكسب المهارة الجراحية
          بحيث يستطيع المشاركون تقديم الخدمات الأفضل للمرضى في مراكزهم
          ومستشفياتهم أو في بلدانهم الأصلية.
          <br /><br />
          <span class="ar boldm">
            مقتطف: تعزيز تعليم تخصصات العيون لتوفير أفضل رعاية ممكنة لها</span
          >
        </p>
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <h2
          style="color: #2a9ae5; margin: 0; font-size: 4.8vw"
          class="boldm titlea"
        >
          Hall of Fame
        </h2>
        <br />
        <p
          class="ar"
          style="
            color: #2a3977;
            font-size: 4.266666666666667vw;
            margin: 3.4666666666666663vw 0 9.066666666666666vw 0;
          "
        >
          أول قاعة للمشاهير <bd>Hall Of Fame</bd> في مجال طب وجراحة العيون في
          عمان، الأردن. <br /><br />، أطلق الشامي للعيون أول فعالية بعنوان
          <bd>Hall Of Fame</bd>
          في عام 2015 التي تم فيها تكريمالعلماء البارزين والمتميزين في مجال طب
          العيون ممن كان لهم انجازات هامة في مجال طب العيون في الأردن.<br /><br />
          وفي ذات الفعالية، تمت استضافة البروفيسور الياباني تاكايوكي أكاهوشي،
          الطبيب الرائد عالمياً في جراحة الساد. ويبلغ متوسط الوقت الذي يقضيه
          البروفيسور أكاهوشي في هذه الجراحة أقل من 4 دقائق، وأسرع عملية جراحية
          قام بإنجازها تمت في دقيقة و 29 ثانية<br /><br />
          وقد شارك البروفيسور تاكايوكي أكاهوشي تجربته الغنية والقيمة في هذه
          الجلسة وكانت مشاركته بمثابة إضافة كبيرة إلى أنشطة أكاديمية الشامي.
        </p>
        <v-card
          outlined
          tile
          class="transparent"
          height="10.666666666666668vw"
        ></v-card>
      </div>
    </div>
  </div>
</template>

<script>
import photo from "../../assets/news/academy/mask_group_50.png";
import popen from "../../assets/news/academy/Shamiacademy.png";
import mphoto from "../../assets/news/academy/Group 466.png";

export default {  
metaInfo: {
    title: "الشامي العيون",
  },
  data() {
    return {
      photo,
      mphoto,
      popen,
      overlay: false,
    };
  },
};
</script>

<style>
</style>