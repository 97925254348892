    <style>
input {
  font-size: 20px !important;
}
</style>

<template>
  <div style="margin-top: 15%">
    <img
      style="z-index: 0"
      src="../../Requirements/IMG/Body.png"
      class="Body-Background"
    />

    <iframe name="dummyframe" id="dummyframe" style="display: none"></iframe>

    <div class="LoginGH">
      <div class="LoginBanner">
        <form
          action="https://docs.google.com/forms/u/5/d/e/1FAIpQLSdQcJCctxZ6PWY_cawBtjTNgGc3uxsVBngDFtgybEIoF-L7pQ/formResponse"
          class="animate__animated animate__backInUp animate__delay-1s"
        >
          <div class="FormRow">
            <label> بتلبس نظارة ؟ : </label>
            <select name="entry.277189469" required>
              <option disabled selected></option>
              <option>Yes / نعم</option>
              <option>NO / لا</option>
            </select>
            <label> do you wear glasses : </label>
          </div>

          <div class="FormRow">
            <label> هل أنت مهتم بتصحيح النظر بالليزك: </label>
            <select name="entry.1453790737" required>
              <option disabled selected></option>
              <option>Yes / نعم</option>
              <option>NO / لا</option>
            </select>
            <label> Are you interested in Lasik: </label>
          </div>
          <div class="FormRow">
            <label> حدد موعد مناسب : </label>
            <input
              type="date"
              name="entry.465540520"
              min="2022-03-22"
              max="2030-12-31"
              style="
                display: inline-block;
                vertical-align: middle;
                width: 48%;
                height: 37px;
                background: #ffffff40;
                border: solid 1px #b0b0b0;
                border-radius: 8px;
                padding: 5px 20px;
                position: relative;
                bottom: 6px;
                color: aliceblue;
                text-align: center;
                transition: 300ms;
              "
            />
            <label> Book for your appointment: </label>
          </div>

          <div class="FormRow">
            <label> الإسم الكامل : </label>
            <input name="entry.1716215158" required type="text" />
            <label> Full name : </label>
          </div>
          <div class="FormRow">
            <label> رقم الهاتف : </label>
            <input required name="entry.1750862168" type="text" />
            <label> Phone number : </label>
          </div>
          <div class="FormRow">
            <label> العمر : </label>
            <input name="entry.838313877" required type="text" />
            <label> Age : </label>
          </div>
          <div class="FormRow">
            <label> (اختياري) البريد الإلكتروني : </label>
            <input name="entry.135626608" type="text" />
            <label> Email address : (Optional)</label>
          </div>
          <input
            type="hidden"
            name="entry.1726516164"
            value="Wayne's coffee house  مركز الحسين للسرطان"
          />
          <div class="FormRow">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>

    <h2 class="Credit">By Shami Eye</h2>
  </div>
</template>

<script>
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Shami Eye | Form",
    meta: [
      {
        name: "description",
        content: "Form",
      },
    ],
    // all titles will be injected into this template
  },
};
</script>


<style>
@import "../../Requirements/CSS/bootstrap.min.css";
@import "https://use.fontawesome.com/releases/v5.7.2/css/all.css";
@import "../../Requirements/CSS/Animation.css";
@import "../../Requirements/CSS/slick.css";
@import "../../Requirements/CSS/style.css";
@import "../../Requirements/CSS/mobile.css";

input,
select {
  font-size: 20px !important;
}
</style>
