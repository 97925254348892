<template>
  <div>
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 16.484375vw 10.390625vw 16.484375vw">
        <v-card outlined class="transparent" align="center">
          <h1 class="ar" style="color: #2a3977">قابل الفريق</h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.890625vw"></v-card>
        <v-row>
          <v-col
            style="margin: 0 0 -0.546875vw 0 !important"
            cols="4"
            v-for="(profile, i) in profiles"
            :key="i"
            ><v-card
              align="center"
              style="
                border-radius: 3.359375vw;
                padding: 0 0;
                box-shadow: 0 0 1.171875vw 0.15625vw #dcdcdc !important;
              "
              height="26.25vw"
              width="21.09375vw"
            >
              <v-card outlined class="transparent" height="2.96875vw"></v-card>
              <v-img
                contain
                style="height: 9.921875vw !important"
                :src="profile.img"
              ></v-img>
              <v-card outlined class="transparent" height="1.640625vw"></v-card>
              <h5 class="ar" style="color: #204170">{{ profile.title }}</h5>
              <div class="mt-3 mb-3" style="margin: 0 -10%">
                <h4 style="color: #2a9ae5" class="boldm ar">
                  <span>{{ profile.name }}</span> <br />
                  {{ profile.name2 }}
                </h4>
              </div>
              <h6
                class="ar"
                v-html="profile.desc"
                style="color: #204170"
              ></h6> </v-card
          ></v-col>
        </v-row>
      </div>
    </div>
    <!-- MOBILE -->
    <div align="right" class="hidden-md-and-up">
      <mimg-page :img="mphoto"></mimg-page>
      <div style="margin: 0 9.6vw 10.390625vw 9.6vw">
        <v-card outlined class="transparent" align="center">
          <h1
            class="art"
            style="color: #2a3977; font-size: 5.333333333333334vw"
          >
            قابل الفريق
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.890625vw"></v-card>
        <v-row no-gutters>
          <v-col
            style="margin: 0 0 2.4vw 0 !important"
            cols="12"
            v-for="(profile, i) in profiles"
            :key="i"
            ><v-card
              class="d-flex align-center justify-end"
              style="
                border-radius: 7.199999999999999vw;
                padding: 0 5.066666666666666vw;
                box-shadow: 0 0 20px 2px #dcdcdc !important;
              "
              height="44.266666666666666vw"
              width="80vw"
            >
              <v-card outlined class="transparent" height="1.640625vw"></v-card>
              <v-card
                outlined
                class="transparent"
                style="margin: 0 1.866666666666667vw 0 0"
              >
                <h5
                  class="ar"
                  style="color: #204170; font-size: 3.733333333333334vw"
                >
                  {{ profile.title }}
                </h5>

                <h4
                  style="color: #2a9ae5; font-size: 3.733333333333334vw"
                  class="boldm ar"
                >
                  <span>{{ profile.name }}</span> <br />
                  {{ profile.name2 }}
                </h4>

                <h6
                  class="ar"
                  v-html="profile.desc"
                  style="color: #204170; font-size: 3.733333333333334vw"
                ></h6>
              </v-card>
              <v-card outlined class="transparent" height="2.96875vw"></v-card>
              <v-card outlined class="transparent">
                <v-img
                  style="
                    height: 21.866666666666667vw !important;
                    width: 21.866666666666667vw;
                  "
                  :src="profile.img"
                ></v-img>
              </v-card> </v-card
          ></v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import photo from "../assets/mtt/Group 386.png";
import mphoto from "../assets/mtt/Group 452.png";

import pp1 from "../assets/mtt/Photo1.png";
import pp2 from "../assets/mtt/Photo2.png";
import pp4 from "../assets/mtt/Photo4.png";
import pp6 from "../assets/mtt/Photo6.png";

import pp8 from "../assets/mtt/Photo8.png";
import pp9 from "../assets/mtt/Photo9.png";
import pp12 from "../assets/mtt/Photo12.png";
import pp13 from "../assets/mtt/Photo13.png";

export default {
  metaInfo: {
    title: "الشامي العيون",
  },
  data() {
    return {
      photo,
      mphoto,
      profiles: [
        {
          img: pp1,
          title: "المدير الطبي",
          name: "د.محمد أبو الضبعات",
          desc: "أخصائي طب و جراحة العيون",
        },
        {
          img: pp2,
          title: "طبيب عيون",
          name: "د.عمر الزعبي",
          desc: "أخصائي طب و جراحة العيون",
        },

        {
          img: pp4,
          title: "طبيب عيون",
          name: "د. يزن التميمي ",
          desc: "أخصائي طب و جراحة العيون",
        },

        {
          img: pp6,
          title: "طبيب عيون",
          name: "د. محمد فرح",
          desc: "أخصائي طب و جراحة العيون",
        },

        {
          img: pp8,
          title: "طبيب عيون",
          name: "د. زيد اسماعيل",
          desc: "أخصائي طب و جراحة العيون",
        },
        {
          img: pp9,
          title: "طبيب عيون",
          name: "د. علي هوجا",
          desc: "أخصائي طب و جراحة العيون",
        },

        {
          img: pp12,
          title: "طبيبة عيون",
          name: "د. منى الفريحات",
          desc: "أخصائي طب و جراحة العيون",
        },
        {
          img: pp13,
          title: "طبيب عيون",
          name: "د. راشد نزال",
          desc: "أخصائي طب و جراحة العيون",
        },
      ],
    };
  },
};
</script>

<style>
</style>