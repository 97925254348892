<template>
  <div>
    <div class="hidden-sm-and-down">
      <v-carousel
        hide-controls
        hide-delimiter-background
        :show-arrows="false"
        light
        height="62.5vw"
        style="width: 100%"
        cycle
      >
        <v-carousel-item v-for="(slide, i) in slides" :key="i">
          <v-img height="62.5vw" :src="slide.img">
            <v-card
              outlined
              class="transparent"
              style="left: 37%; top: 42.625%"
              width="53.125vw"
            >
              <h1
                :style="
                  'font-size:' + slide.textsize + '; color:' + slide.color
                "
              >
                {{ slide.text }}
              </h1>
              <h1
                :style="
                  'font-size:' + slide.textsize + '; color:' + slide.color
                "
              >
                {{ slide.texts }}
              </h1>
              <h3 :style="'font-size: 3.046875vw; color:' + slide.color">
                {{ slide.subtext }}
              </h3>
              <h3
                :style="
                  'font-size: 3.046875vw; margin-right: -20%; color:' +
                  slide.color
                "
              >
                {{ slide.subtext2 }}
              </h3>
              <h4 :style="'font-size: 1.71875vw; color:' + slide.color">
                {{ slide.desc }}
              </h4>
              <v-btn
                width="36.171875vw"
                height="4.84375vw"
                depressed
                :class="'white--text mt-' + slide.class"
                style="
                  text-transform: none;
                  font-size: 1.71875vw;
                  letter-spacing: 1px !important;
                  border-radius: 2.421875vw;
                "
                color="#2A9AE5"
                @click="overlay = !overlay"
              >
                Schedule An Appointment
              </v-btn>
            </v-card>
          </v-img>
        </v-carousel-item>
      </v-carousel>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform light>
          <v-btn
            @click="overlay = !overlay"
            height="3.28125vw"
            width="12.968750000000002vw"
            depressed
            color="#2A9AE5"
            style="
              text-transform: none;
              border-radius: 3.359375vw;
              margin: 0 0 0 14px;
            "
            class="white--text btntxt"
          >
            <span style="margin: 0 0 0 0; font-size: 1.25vw">Cancel</span>
          </v-btn>
        </Vform>
      </v-overlay>
    </div>
    <!-- Mobile Start -->
    <div class="hidden-md-and-up">
      <v-carousel
        hide-controls
        hide-delimiter-background
        :show-arrows="false"
        light
        height="112.53333333333333vw"
        style="width: 100%"
        cycle
      >
        <v-carousel-item v-for="(slide, i) in slides" :key="i">
          <v-img height="112.53333333333333vw" :src="slide.mimg">
            <v-card
              align="center"
              outlined
              class="transparent"
              :style="'top: ' + slide.mtop"
            >
              <h1
                v-html="slide.mtext"
                :style="'font-size:' + slide.mtextsize + '; color: white'"
              ></h1>
              <h1
                v-html="slide.mtexts"
                :style="'font-size:' + slide.mtextsize + '; color: white'"
              ></h1>
              <h3
                v-html="slide.msubtext"
                :style="'font-size: 4.8vw; margin: 0; color: white'"
              ></h3>

              <h4
                v-html="slide.mdesc"
                :style="'font-size: 4.266666666666667vw; color: white; margin: 2% 0 0 0'"
              ></h4>
              <v-btn
                width="67.2vw"
                height="9.066666666666666vw"
                depressed
                :class="'white--text mt-' + slide.class"
                style="
                  text-transform: none;
                  font-size: 4.266666666666667vw;
                  letter-spacing: -1px !important;
                  border-radius: 4.533333333333333vw;
                "
                color="#2A9AE5"
                @click="overlay = !overlay"
              >
                Schedule An Appointment
              </v-btn>
            </v-card>
          </v-img>
        </v-carousel-item>
      </v-carousel>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform :clickbtn="btnclick" light> </Vform>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import Vform from "./Form";

export default {
  props: ["slides"],
  components: { Vform },
  data: () => ({
    model: 0,
    overlay: false,
    absolute: true,
  }),
  methods: {
    btnclick() {
      this.overlay = false;
    },
  },
};
</script>

<style>
.theme--dark.v-btn--active:hover::before,
.theme--dark.v-btn--active::before {
  opacity: 1 !important;
  color: #2a9ae5;
  height: 23px !important;
  widows: 23px !important;
}

.v-btn--icon.v-size--small {
  color: #2a9ae5 !important;
  height: 20px !important;
  width: 20px !important;
  margin: 2px;
  bottom: 42px;
}

.theme--light.v-btn--active::before {
  opacity: 1 !important;
}
</style>