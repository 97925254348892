<template>
  <div>
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 15.859375vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977">Aqaba</h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <div align="center">
          <p style="color: #2a3977">
            Book Your Apointment in Aqaba Today!
            <br />
            Our number is 0790016161
          </p>
        </div>
        <v-card outlined tile class="transparent" height="2.96875vw"></v-card>
        <v-card
          style="margin: 0 0 4.609375vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-btn
            width="35.78125vw"
            height="4.84375vw"
            depressed
            @click="overlay = !overlay"
            style="
              text-transform: none;
              font-size: 2.03125vw;
              letter-spacing: -1.5px !important;
              border-radius: 2.421875vw;
              color: white;
            "
            color="#2A9AE5"
          >
            Schedule An Appointment
          </v-btn>
        </v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform light>
          <v-btn
            @click="overlay = !overlay"
            height="3.28125vw"
            width="12.968750000000002vw"
            depressed
            color="#2A9AE5"
            style="
              text-transform: none;
              border-radius: 3.359375vw;
              margin: 0 0 0 14px;
            "
            class="white--text btntxt"
          >
            <span style="margin: 0 0 0 0; font-size: 1.25vw">Cancel</span>
          </v-btn>
        </Vform>
      </v-overlay>
    </div>
    <!-- MOBILE -->
    <div class="hidden-md-and-up">
      <mimg-page :img="mphoto"></mimg-page>
      <div style="margin: 0 10.66666666vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977; font-size: 5.333333333333334vw">
            Aqaba
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <div align="center">
          <p style="color: #2a3977; font-size: 4.266666666666667vw; margin: 0">
            Book Your Apointment in Aqaba Today!
            <br />
            Our number is 0790016161
          </p>
        </div>
        <v-card
          outlined
          tile
          class="transparent"
          height="10.666666666666668vw"
        ></v-card>
        <v-card
          style="margin: 0 0 4.609375vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-btn
            width="70.13333333333334vw"
            height="10.133333333333333vw"
            depressed
            @click="overlay = !overlay"
            style="
              text-transform: none;
              font-size: 4.266666666666667vw;
              letter-spacing: -1.5px !important;
              border-radius: 5.066666666666666vw;
              color: white;
            "
            color="#2A9AE5"
          >
            Schedule An Appointment
          </v-btn>
        </v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform :clickbtn="btnclick" light> </Vform>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import photo from "../../assets/Aqaba/Aqaba.jpg";
import mphoto from "../../assets/Aqaba/Aqaba.jpg";
import Vform from "../../components/Form";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Shami Eye | Aqaba",
    meta: [
      {
        name: "description",
        content: " Book Your Appointment in Aqaba",
      },
    ],
    // all titles will be injected into this template
  },
  components: { Vform },
  data() {
    return {
      photo,
      mphoto,
      overlay: false,
    };
  },
  methods: {
    btnclick() {
      this.overlay = false;
    },
  },
};
</script>

<style>
</style>