<template>
  <div>
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 15.859375vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977">Cataract</h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <p style="color: #2a3977">
          <span class="sboldm"
            >The Comprehensive Ophthalmology and Cataract Consultation Unit
          </span>
          provides care for all types of conditions of the eye and surrounding
          structures.
        </p>
        <v-card outlined tile class="transparent" height="0.390625vw"></v-card>
        <v-card outlined class="transparent"
          ><h3 style="color: #2a3977; font-size: 1.5625vw" class="boldm">
            Services include:
          </h3></v-card
        >
        <v-card outlined tile class="transparent" height="0.78125vwx"></v-card>

        <vue-list :items="items" color="#2A9AE5" :lh="18"></vue-list>
        <v-card
          outlined
          tile
          class="transparent"
          height="3.9843749999999996vw"
        ></v-card>
        <v-img width="69.21875vw" height="23.59375vw" :src="photo1"></v-img>
        <v-row no-gutters>
          <v-col cols="5">
            <v-card outlined class="transparent" align="center">
              <p
                style="
                  font-size: 1.171875vw;
                  color: #204170;
                  margin: 1% 0 0 30%;
                "
              >
                <span class="sboldm"> Normal Lens </span>
              </p>
            </v-card>
          </v-col>
          <v-col>
            <v-card outlined class="transparent" align="center" cols="7">
              <p
                class="sboldm"
                style="
                  font-size: 1.171875vw;
                  color: #204170;
                  margin: 1% 0 0 30%;
                "
              >
                <span class="sboldm"> Lens With Cataract </span>
              </p>
            </v-card></v-col
          >
        </v-row>
        <v-card outlined tile class="transparent" height="3.515625vw"></v-card>
        <p style="color: #204170">
          All of our physicians in this unit are committed to meeting the needs
          of each individual patient. They can also provide referrals to
          specialists in the following sub-specialties: Cornea and Refractive
          Surgery, Glaucoma, Retina, Ophthalmic Plastic Surgery, Pediatric
          Ophthalmology, Strabismus, Neuro-Ophthalmology, Vision Rehabilitation,
          Uveitis, and Contact Lens.
        </p>
        <v-card
          outlined
          tile
          class="transparent"
          height="1.7187500000000002vw"
        ></v-card>
        <v-card outlined class="transparent"
          ><h3 style="color: #2a3977; font-size: 1.5625vw" class="boldm">
            Symptoms of Cataract:
          </h3></v-card
        >
        <v-card outlined tile class="transparent" height="0.78125vw"></v-card>

        <vue-list :items="items2" color="#2A9AE5" :lh="18"></vue-list>
        <v-card outlined tile class="transparent" height="3.125vw"></v-card>
        <v-card
          outlined
          @click="overlay = !overlay"
          style="margin: 0 0 4.609375vw 0"
          class="transparent"
          align="center"
        >
          <v-btn
            width="35.78125vw"
            height="4.84375vw"
            depressed
            style="
              text-transform: none;
              font-size: 2.03125vw;
              letter-spacing: -1.5px !important;
              border-radius: 2.421875vw;
              color: white;
            "
            color="#2A9AE5"
          >
            Schedule An Appointment
          </v-btn>
        </v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform light>
          <v-btn
            @click="overlay = !overlay"
            height="3.28125vw"
            width="12.968750000000002vw"
            depressed
            color="#2A9AE5"
            style="
              text-transform: none;
              border-radius: 3.359375vw;
              margin: 0 0 0 14px;
            "
            class="white--text btntxt"
          >
            <span style="margin: 0 0 0 0; font-size: 1.25vw">Cancel</span>
          </v-btn>
        </Vform>
      </v-overlay>
    </div>
    <!-- MOBILE -->
    <div class="hidden-md-and-up">
      <mimg-page :img="photo"></mimg-page>
      <div style="margin: 0 10.666666666666668vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977; font-size: 5.333333333333334vw">
            Cataract
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <p style="color: #2a3977; font-size: 4.266666666666667vw; margin: 0">
          <span class="sboldm"
            >The Comprehensive Ophthalmology and Cataract Consultation Unit
          </span>
          provides care for all types of conditions of the eye and surrounding
          structures.
        </p>
        <v-card outlined class="transparent"
          ><h3
            style="
              color: #2a3977;
              font-size: 4.533333333333333vw;
              margin: 8vw 0 6.133333333333333vw 0;
            "
            class="boldm"
          >
            Services include:
          </h3></v-card
        >

        <vue-list :items="items" color="#2A9AE5" :lh="11"></vue-list>

        <v-card outlined class="transparent" align="center">
          <v-img width="84vw" height="50.66666666666667vw" :src="g1m"></v-img>
          <p
            class="sboldm"
            style="margin: 1% 0 0 0; font-size: 4vw; color: #204170"
          >
            Normal Eye
          </p>
        </v-card>
        <v-card outlined tile class="transparent" height="3.515625vw"></v-card>
        <v-card outlined class="transparent" align="center">
          <v-img width="72vw" height="50.4vw" :src="g2m"></v-img>
          <p
            class="sboldm"
            style="margin: 1% 0 0 0; font-size: 4vw; color: #204170"
          >
            Eye With Glaucoma
          </p>
        </v-card>

        <p
          style="
            color: #204170;
            font-size: 4.266666666666667vw;
            margin: 8.799999999999999vw 0 0 0;
          "
        >
          All of our physicians in this unit are committed to meeting the needs
          of each individual patient. They can also provide referrals to
          specialists in the following sub-specialties: Cornea and Refractive
          Surgery, Glaucoma, Retina, Ophthalmic Plastic Surgery, Pediatric
          Ophthalmology, Strabismus, Neuro-Ophthalmology, Vision Rehabilitation,
          Uveitis, and Contact Lens.
        </p>

        <v-card outlined class="transparent"
          ><h3
            style="
              color: #2a3977;
              font-size: 4.533333333333333vw;
              margin: 8vw 0 6.133333333333333vw 0;
            "
            class="boldm"
          >
            Symptoms of Cataract:
          </h3></v-card
        >

        <vue-list :items="items2" color="#2A9AE5" :lh="11"></vue-list>
        <v-card
          outlined
          tile
          class="transparent"
          height="10.666666666666668vw"
        ></v-card>
        <v-card
          style="margin: 0 0 14.133333333333335vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-btn
            width="70.13333333333334vw"
            height="10.133333333333333vw"
            depressed
            @click="overlay = !overlay"
            style="
              text-transform: none;
              font-size: 4.266666666666667vw;
              letter-spacing: -1.5px !important;
              border-radius: 5.066666666666666vw;
              color: white;
            "
            color="#2A9AE5"
          >
            Schedule An Appointment
          </v-btn>
        </v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform :clickbtn="btnclick" light> </Vform>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import photo from "../../assets/cataract/mask_group_20.png";
import photo1 from "../../assets/cataract/catarctpic1.png";
import Vform from "../../components/Form";
import g1m from "../../assets/cataract/Mask Group 69.png";
import g2m from "../../assets/cataract/Mask Group 68.png";
import mphoto from "../../assets/cataract/mask_group_20.png";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Shami Eye | Cataract",
    meta: [
      {
        name: "description",
        content:
          "All of our physicians in this unit are committed to meeting the needs of each individual patient. They can also provide referrals to specialists in the following sub-specialties: Cornea and Refractive Surgery, Glaucoma, Retina, Ophthalmic Plastic Surgery, Pediatric Ophthalmology, Strabismus, Neuro-Ophthalmology, Vision Rehabilitation, Uveitis, and Contact Lens.",
      },
    ],
    // all titles will be injected into this template
  },
  components: { Vform },
  data() {
    return {
      photo,
      mphoto,
      g1m,
      g2m,
      overlay: false,
      photo1,
      items: [
        { item: "Routine eye and vision exams" },
        { item: "Prescriptions for eyeglasses" },
        { item: "Referrals for contact lens fittings" },
        {
          item:
            "Management of a variety of common eye problems, including cataracts, conjunctivitis, dry <br/> eye, blepharitis, and other common disorders.",
        },
      ],
      items2: [
        { item: 'Having a <span class="boldm">blurry vision</span>' },
        {
          item:
            '<span class="boldm"> Seeing double</span> (When you see two images instead of one)',
        },
        { item: 'Being extra <span class="boldm">sensitive to light</span>' },
        {
          item:
            'having <span class="boldm">trouble seeing well at night</span>, or needing more light when you read',
        },
        { item: "Seeing bright colors as faded or yellow instead" },
      ],
    };
  },
  methods: {
    btnclick() {
      this.overlay = false;
    },
  },
};
</script>

<style>
</style>