<template>
  <div align="right">
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 15.859375vw">
        <v-card outlined class="transparent" align="center">
          <h1 class="ar" style="color: #2a3977">عيون الأطفال والحول</h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <h2 style="color: #2a9ae5; font-size: 2.5vw" class="boldm titlea ar">
          عيون الأطفال
        </h2>

        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <p class="ar" style="color: #2a3977">
          نحن نقدم الرعاية للأطفال أيضًا في مركز الشامي للعيون
        </p>

        <v-card outlined tile class="transparent" height="1.875vw"></v-card>

        <v-card outlined class="transparent"
          ><h3
            style="color: #2a3977; font-size: 1.7187500000000002vw"
            class="boldm ar"
          >
            <span>:</span> في وحدة طب وجراحة العيون والحول للأطفال، يقوم الأطباء
            لدينا بتوفير
          </h3></v-card
        >
        <v-card outlined tile class="transparent" height="0.78125vw"></v-card>

        <vue-list :items="items2" color="#2A9AE5" :lh="18"></vue-list>

        <v-card outlined tile class="transparent" height="3.671875vw"></v-card>

        <p style="color: #2a3977" class="ar">
          يجب أن يتلقى الأطفال الفحوص الروتينية المتعلقة بالرؤية والعيون في
          مواعيد الرعاية الأولية العادية مع أطباء الأطفال.
          <br /><br />
          إذا كان هناك ما يدعو للقلق أو لمزيد من التقييم، فإن طبيب العيون
          للأطفال هو أفضل المؤهلين لتوفير الرعاية اللازمة. حالما يتلقى الطفل
          العلاج، فمن الأفضل التأكد من تواجد طفلك في المواعيد الموصى بها
          للمتابعة، وذلك للسماح للطبيب بمراقبة الحالة عن كثب ودقة.
        </p>

        <v-card outlined tile class="transparent" height="2.96875vw"></v-card>
        <v-card
          style="margin: 0 0 4.609375vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-btn
            class="ar"
            width="35.78125vw"
            height="4.84375vw"
            depressed
            @click="overlay = !overlay"
            style="
              text-transform: none;
              font-size: 2.03125vw;
              letter-spacing: -1.5px !important;
              border-radius: 2.421875vw;
              color: white;
            "
            color="#2A9AE5"
          >
            احجز موعد استشارة
          </v-btn>
        </v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform light>
          <v-btn
            @click="overlay = !overlay"
            height="3.28125vw"
            width="12.968750000000002vw"
            depressed
            color="#2A9AE5"
            style="
              text-transform: none;
              border-radius: 3.359375vw;
              margin: 0 14px 0 0;
            "
            class="white--text btntxt"
          >
            <span class="ar" style="margin: 0 0 0 0; font-size: 1.25vw"
              >الغاء</span
            >
          </v-btn>
        </Vform>
      </v-overlay>
    </div>
    <!-- MOBILE -->
    <div class="hidden-md-and-up">
      <mimg-page :img="mphoto"></mimg-page>
      <div style="margin: 0 10.66666666vw">
        <v-card outlined class="transparent" align="center">
          <h1
            class="art"
            style="color: #2a3977; font-size: 5.333333333333334vw"
          >
            عيون الأطفال والحول
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <h2
          style="color: #2a9ae5; margin: 0; font-size: 4.8vw"
          class="boldm titlear"
        >
          عيون الأطفال
        </h2>

        <p
          class="arp"
          style="
            color: #2a3977;
            font-size: 4.266666666666667vw;
            margin: 3.4666666666666663vw 0 9.066666666666666vw 0;
          "
        >
          نحن نقدم الرعاية للأطفال أيضًا في مركز الشامي للعيون
        </p>

        <v-card outlined class="transparent"
          ><h3
            style="
              color: #2a3977;
              font-size: 4.533333333333333vw;
              margin: 0 0 3.733333333333334vw 0;
            "
            class="boldm subtitlemmar"
          >
            في وحدة طب وجراحة العيون والحول للأطفال، يقوم الأطباء لدينا بتوفير:
          </h3></v-card
        >

        <vue-list :items="items2" color="#2A9AE5" :lh="11"></vue-list>

        <p
          class="arp"
          style="
            color: #2a3977;
            font-size: 4.266666666666667vw;
            margin: 13.333333333333334vw 0 0 0;
          "
        >
          يجب أن يتلقى الأطفال الفحوص الروتينية المتعلقة بالرؤية والعيون في
          مواعيد الرعاية الأولية العادية مع أطباء الأطفال.
          <br /><br />
          إذا كان هناك ما يدعو للقلق أو لمزيد من التقييم، فإن طبيب العيون
          للأطفال هو أفضل المؤهلين لتوفير الرعاية اللازمة. حالما يتلقى الطفل
          العلاج، فمن الأفضل التأكد من تواجد طفلك في المواعيد الموصى بها
          للمتابعة، وذلك للسماح للطبيب بمراقبة الحالة عن كثب ودقة.
        </p>

        <v-card
          outlined
          tile
          class="transparent"
          height="10.666666666666668vw"
        ></v-card>
        <v-card
          style="margin: 0 0 14.133333333333335vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-btn
            class="arp"
            width="70.13333333333334vw"
            height="10.133333333333333vw"
            depressed
            @click="overlay = !overlay"
            style="
              text-transform: none;
              font-size: 4.266666666666667vw;
              border-radius: 5.066666666666666vw;
              color: white;
            "
            color="#2A9AE5"
          >
            احجز موعد استشارة
          </v-btn>
        </v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform :clickbtn="btnclick" light> </Vform>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import photo from "../../assets/Services/Ped/Main-photo14.png";
import mphoto from "../../assets/Services/Ped/Group 462.png";
import Vform from "../../componentsar/Form";

export default {  
metaInfo: {
    title: "الشامي العيون",
  },
  components: {
    Vform,
  },
  data() {
    return {
      photo,
      mphoto,
      overlay: false,
      items2: [
        { item: "التقييم" },
        { item: "التشخيص" },
        {
          item: "العلاج لمجموعة كاملة من مشاكل العين التي تؤثر على الأطفال",
        },
        { item: "علاج مشاكل اختلال العين (الحول) في الأطفال والبالغين" },
      ],
    };
  },
  methods: {
    btnclick() {
      this.overlay = false;
    },
  },
};
</script>

<style>
</style>