<template>
  <div>
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 15.859375vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977">Vision Correction</h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <div>
          <p class="boldm" style="color: #2a9ae5; font-size: 2.34375vw">
            <v-icon color="#2a9ae5" class="mr-3" style="font-size: 2.34375vw">
              mdi-square-rounded </v-icon
            ><span style="font-size: 1.953125vw">Laser Or Lasik</span>
          </p>
          <p class="boldm" style="color: #2a9ae5; font-size: 2.34375vw">
            <v-icon color="#2a9ae5" class="mr-3" style="font-size: 2.34375vw">
              mdi-square-rounded </v-icon
            ><span style="font-size: 1.953125vw">Premium IOLs & ICLs</span>
          </p>
        </div>
        <v-card
          outlined
          tile
          class="transparent"
          height="1.7187500000000002vw"
        ></v-card>
        <v-img
          style="border-radius: 2.34375vw"
          width="68.515625vw"
          height="21.71875vw"
          :src="photo1"
        ></v-img>
        <v-card
          outlined
          tile
          class="transparent"
          height="3.5937499999999996vw"
        ></v-card>
        <div>
          <p style="color: #2a3977">
            Refractive surgery (or vision correction surgery) is any surgical
            procedure used for fixing vision problems. There are various
            surgical procedures for correcting or adjusting your eye's focusing
            ability by reshaping the cornea; the clear, round dome at the front
            of your eye. The cornea plays an important part in the eye's visual
            ability. Most types of vision correction surgery reshape your
            cornea, the clear front part of your eye, that lets light travel
            through it and focus properly on the back of your eye, or retina.
          </p>
          <p style="color: #2a3977">
            Recent years have seen huge advances in this field. Refractive and
            laser eye surgery allow many patients to see better than any other
            time in their lives.
          </p>
          <p style="color: #2a3977">
            The Cornea and Refractive Surgery Service at Shami Eye Center is
            characterized by its unparalleled expertise and state-of-the-art
            equipment, which allow it to offer excellent medical and surgical
            treatment for most of the routine, complex and high-risk corneal and
            sight-threatening external diseases. Our services cover corneal
            ulcers, severe viral disease and dry eye syndrome as well as the
            most current vision correction procedures.
          </p>
        </div>
        <v-card outlined tile class="transparent" height="2.34375vw"></v-card>
        <v-img
          style="border-radius: 2.34375vw"
          width="68.515625vw"
          height="21.71875vw"
          :src="photo2"
        ></v-img>
        <v-card
          outlined
          tile
          class="transparent"
          height="3.5937499999999996vw"
        ></v-card>
        <div>
          <p style="color: #2a3977">
            If your vision is affected by nearsightedness (myopia),
            farsightedness (hyperopia), or astigmatism, our physicians will help
            you choose the right solution to achieve your vision goals. Our
            physicians perform thousands of vision correction procedures
            annually which are done on an outpatient basis using the most
            advanced techniques and technology available today.
          </p>
          <p style="color: #2a3977">
            Common procedures include excimer laser vision correction through
            Transepithelial photorefractive keratectomy (T-PRK) and laser
            in-situ keratomileusis (LASIK), in addition to Implantable Contact
            Lens insertion (ICL).
          </p>
          <p style="color: #2a3977">
            We will provide you with the facts and resources you need to make
            the decision that is best for your situation.
          </p>
        </div>
        <v-card outlined tile class="transparent" height="2.34375vw"></v-card>
        <v-card
          style="margin: 0 0 4.609375vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-btn
            @click="overlay = !overlay"
            width="35.78125vw"
            height="4.84375vw"
            depressed
            style="
              text-transform: none;
              font-size: 2.03125vw;
              letter-spacing: -1.5px !important;
              border-radius: 2.421875vw;
              color: white;
            "
            color="#2A9AE5"
          >
            Schedule An Appointment
          </v-btn>
        </v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform light>
          <v-btn
            @click="overlay = !overlay"
            height="3.28125vw"
            width="12.968750000000002vw"
            depressed
            color="#2A9AE5"
            style="
              text-transform: none;
              border-radius: 3.359375vw;
              margin: 0 0 0 14px;
            "
            class="white--text btntxt"
          >
            <span style="margin: 0 0 0 0; font-size: 1.25vw">Cancel</span>
          </v-btn>
        </Vform>
      </v-overlay>
    </div>
    <!-- MOBILE -->
    <div class="hidden-md-and-up">
      <mimg-page :img="mphoto"></mimg-page>
      <div style="margin: 0 10.666666666666668vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977; font-size: 5.333333333333334vw">
            Vision Correction
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <div>
          <p class="boldm" style="color: #2a9ae5; font-size: 4.8vw; margin: 0">
            <v-icon
              color="#2a9ae5"
              class="mr-3"
              style="font-size: 3.4666666666666663vw"
            >
              mdi-square-rounded </v-icon
            ><span>Laser Or Lasik</span>
          </p>
          <p class="boldm" style="color: #2a9ae5; font-size: 4.8vw; margin: 0">
            <v-icon
              color="#2a9ae5"
              class="mr-3"
              style="font-size: 3.4666666666666663vw"
            >
              mdi-square-rounded </v-icon
            ><span>Premium IOLs & ICLs</span>
          </p>
        </div>

        <v-img
          style="
            border-radius: 8vw;
            margin: 9.066666666666666vw 0 10.666666666666668vw 0;
          "
          width="78.66666666666666vw"
          height="51.46666666666667vw"
          :src="photo1"
        ></v-img>

        <div>
          <p style="font-size: 4.266666666666667vw; color: #2a3977; margin: 0">
            Refractive surgery (or vision correction surgery) is any surgical
            procedure used for fixing vision problems. There are various
            surgical procedures for correcting or adjusting your eye's focusing
            ability by reshaping the cornea; the clear, round dome at the front
            of your eye. The cornea plays an important part in the eye's visual
            ability. Most types of vision correction surgery reshape your
            cornea, the clear front part of your eye, that lets light travel
            through it and focus properly on the back of your eye, or retina.
            <br />
            <br />
            Recent years have seen huge advances in this field. Refractive and
            laser eye surgery allow many patients to see better than any other
            time in their lives.
            <br />
            <br />
            The Cornea and Refractive Surgery Service at Shami Eye Center is
            characterized by its unparalleled expertise and state-of-the-art
            equipment, which allow it to offer excellent medical and surgical
            treatment for most of the routine, complex and high-risk corneal and
            sight-threatening external diseases. Our services cover corneal
            ulcers, severe viral disease and dry eye syndrome as well as the
            most current vision correction procedures.
          </p>
        </div>
        <v-card outlined tile class="transparent" height="2.34375vw"></v-card>
        <v-img
          style="border-radius: 8vw; margin: 10.666666666666668vw 0"
          width="78.66666666666666vw"
          height="51.46666666666667vw"
          :src="photo2"
        ></v-img>

        <div>
          <p style="font-size: 4.266666666666667vw; color: #2a3977; margin: 0">
            If your vision is affected by nearsightedness (myopia),
            farsightedness (hyperopia), or astigmatism, our physicians will help
            you choose the right solution to achieve your vision goals. Our
            physicians perform thousands of vision correction procedures
            annually which are done on an outpatient basis using the most
            advanced techniques and technology available today.
            <br />
            <br />
            Common procedures include excimer laser vision correction through
            Transepithelial photorefractive keratectomy (T-PRK) and laser
            in-situ keratomileusis (LASIK), in addition to Implantable Contact
            Lens insertion (ICL).
            <br />
            <br />
            We will provide you with the facts and resources you need to make
            the decision that is best for your situation.
          </p>
        </div>
        <v-card
          outlined
          tile
          class="transparent"
          height="10.666666666666668vw"
        ></v-card>
        <v-card
          style="margin: 0 0 14.133333333333335vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-btn
            width="70.13333333333334vw"
            height="10.133333333333333vw"
            depressed
            @click="overlay = !overlay"
            style="
              text-transform: none;
              font-size: 4.266666666666667vw;
              letter-spacing: -1.5px !important;
              border-radius: 5.066666666666666vw;
              color: white;
            "
            color="#2A9AE5"
          >
            Schedule An Appointment
          </v-btn>
        </v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform :clickbtn="btnclick" light> </Vform>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import photo from "../../assets/Services/VisionCorrection/Main-photo10.png";
import mphoto from "../../assets/Services/VisionCorrection/Group 458.png";
import photo1 from "../../assets/Services/VisionCorrection/Lasikpic1.png";
import photo2 from "../../assets/Services/VisionCorrection/Lasikpic2.png";
import Vform from "../../components/Form";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Shami Eye | Vision Correction",
    meta: [
      {
        name: "description",
        content:
          "Correct your vision with Laser Or Lazik Surgery at a shami eye center near your in Amman Jordan!",
      },
    ],
    // all titles will be injected into this template
  },
  name: "VisionCorrection",
  components: { Vform },
  data() {
    return {
      photo,
      mphoto,
      photo1,
      photo2,
      overlay: false,
    };
  },
  methods: {
    btnclick() {
      this.overlay = false;
    },
  },
};
</script>

<style>
</style>