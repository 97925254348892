<template>
  <div align="right">
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 15.859375vw">
        <v-card outlined class="transparent" align="center">
          <h1 class="ar" style="color: #2a3977">القرنية</h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <h2 style="color: #2a9ae5; font-size: 2.5vw" class="boldm titlea ar">
          القرنية المخروطية
        </h2>
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <h3 style="color: #2a9ae5; font-size: 1.875vw" class="boldm ar">
          ما هي القرنية المخروطية؟
        </h3>
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <p class="ar" style="color: #2a3977">
          تحدث القرنية المخروطية عندما تصبح القرنية الخاصة بك (السطح الأمامي
          الواضح للعين على شكل قبة) أرق تدريجيًا وتنتفخ إلى الخارج بشكل مخروطي.
          والقرنية المخروطية تؤدي إلى عدم وضوح الرؤية وقد تسبب حساسية للضوء
          والوهج
        </p>
        <v-card outlined tile class="transparent" height="1.09375vw"></v-card>
      </div>
      <v-card class="transparent" outlined align="center">
        <v-img
          width="75.9375vw"
          height="26.406249999999996vw"
          :src="photo1"
        ></v-img>

        <v-row no-gutters>
          <v-col cols="5">
            <v-card outlined class="transparent" align="center">
              <p
                style="
                  font-size: 1.171875vw;
                  color: #204170;
                  margin: 0% -50% 0 0;
                "
              >
                <span class="sboldm ar"> القرنية الطبيعية </span>
              </p>
            </v-card>
          </v-col>
          <v-col>
            <v-card outlined class="transparent" align="center" cols="7">
              <p
                class="sboldm ar"
                style="
                  font-size: 1.171875vw;
                  color: #204170;
                  margin: 0% 0 0 -5%;
                "
              >
                <span class="sboldm ar"> القرنية المخروطية </span>
              </p>
            </v-card></v-col
          >
        </v-row>
      </v-card>
      <div style="margin: 0 15.2vw 0 15.859375vw">
        <v-card
          outlined
          tile
          class="transparent"
          height="6.406249999999999vw"
        ></v-card>

        <h3 style="color: #2a9ae5; font-size: 1.875vw" class="boldm ar">
          من الذي يتأثر بمشكلة القرنية المخروطية؟
        </h3>

        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>

        <p class="ar" style="color: #2a3977">
          القرنية المخروطية عادة ما تؤثر على كلتا العينين وعادة ما يبدأ التأثير
          لأول مرة عند الأشخاص الذين تتراوح أعمارهم بين 10 -25 عاماً. وقد تتطور
          الحالة ببطء لمدة 10 سنوات أو أكثر
        </p>
        <v-card outlined tile class="transparent" height="2.265625vw"></v-card>

        <h3 style="color: #2a9ae5; font-size: 1.875vw" class="boldm ar">
          طرق علاج القرنية المخروطية؟
        </h3>
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>

        <p class="ar" style="color: #2a3977">
          في المراحل المبكرة من القرنية المخروطية، يمكنك تصحيح مشاكل الرؤية
          باستخدام النظارات أو العدسات اللاصقة اللينة. في وقت لاحق قد تضطر إلى
          أن يتم تزويدك بالعدسات اللاصقة الجامدة التي ستمح للغاز بالمرور منها أو
          أنواع أخرى من العدسات. إذا تطورت حالتك إلى مرحلة متقدمة، قد تحتاج إلى
          ربط الكولاجين في القرنية باستخدام الأشعة فوق البنفسجية، أو حلقات
          القرنية أو زرع القرنية
        </p>
        <v-card outlined tile class="transparent" height="2.265625vw"></v-card>

        <h2 style="color: #2a9ae5" class="boldm titlea ar">
          الجفاف وأمراض سطح العين
        </h2>

        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <p class="ar" style="color: #2a3977">
          يعاني الكثير من الناس وخاصة في فصل الصيف من جفاف العين والذي يسبب
          احمرار العين وشعور بثقل في الجفون وكذلك حرقة وحكة
        </p>

        <v-card outlined tile class="transparent" height="1.875vw"></v-card>

        <v-card outlined class="transparent"
          ><h3 style="color: #2a3977; font-size: 1.5625vw" class="boldm ar">
            <span>:</span> من أسباب الجفاف
          </h3></v-card
        >

        <vue-list :items="items2" color="#2A9AE5" :lh="18"></vue-list>

        <v-card outlined tile class="transparent" height="1.171875vw"></v-card>

        <p class="ar" style="color: #2a3977">
          قد يحتاج المريض الذي يعاني من الجفاف لاستعمال قطرات الترطيب بشكل متكرر
        </p>

        <v-card outlined tile class="transparent" height="2.96875vw"></v-card>
        <v-card
          style="margin: 0 0 4.609375vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-btn
            class="ar"
            width="35.78125vw"
            height="4.84375vw"
            depressed
            @click="overlay = !overlay"
            style="
              text-transform: none;
              font-size: 2.03125vw;
              letter-spacing: -1.5px !important;
              border-radius: 2.421875vw;
              color: white;
            "
            color="#2A9AE5"
          >
            احجز موعد استشارة
          </v-btn>
        </v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform light>
          <v-btn
            @click="overlay = !overlay"
            height="3.28125vw"
            width="12.968750000000002vw"
            depressed
            color="#2A9AE5"
            style="
              text-transform: none;
              border-radius: 3.359375vw;
              margin: 0 14px 0 0;
            "
            class="white--text btntxt"
          >
            <span class="ar" style="margin: 0 0 0 0; font-size: 1.25vw"
              >الغاء</span
            >
          </v-btn>
        </Vform>
      </v-overlay>
    </div>
    <!-- MOBILE -->
    <div class="hidden-md-and-up">
      <mimg-page :img="photo"></mimg-page>
      <div style="margin: 0 10.66666666vw">
        <v-card outlined class="transparent" align="center">
          <h1
            class="art"
            style="color: #2a3977; font-size: 5.333333333333334vw"
          >
            القرنية
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <h2
          style="color: #2a9ae5; font-size: 4.8vw; margin: 0"
          class="boldm titlear"
        >
          القرنية المخروطية
        </h2>
        <h3
          style="
            color: #2a9ae5;
            font-size: 4.533333333333333vw;
            margin: 3.4666666666666663vw 0;
          "
          class="boldm subtitlemmar"
        >
          ما هي القرنية المخروطية؟
        </h3>
        <p
          class="arp"
          style="color: #2a3977; font-size: 4.266666666666667vw; margin: 0"
        >
          تحدث القرنية المخروطية عندما تصبح القرنية الخاصة بك (السطح الأمامي
          الواضح للعين على شكل قبة) أرق تدريجيًا وتنتفخ إلى الخارج بشكل مخروطي.
          والقرنية المخروطية تؤدي إلى عدم وضوح الرؤية وقد تسبب حساسية للضوء
          والوهج.
        </p>
      </div>
      <v-card outlined tile class="transparent" height="3.515625vw"></v-card>
      <v-card outlined class="transparent" align="center">
        <v-img width="65.06666666666666vw" height="64vw" :src="g1m"></v-img>
        <p
          class="sboldm arp"
          style="margin: 1% 0 0 0; font-size: 4vw; color: #204170"
        >
          القرنية الطبيعية
        </p>
      </v-card>
      <v-card outlined tile class="transparent" height="3.515625vw"></v-card>
      <v-card outlined class="transparent" align="center">
        <v-img width="77.33333333333333vw" height="64vw" :src="g2m"></v-img>
        <p
          class="sboldm arp"
          style="margin: 1% 0 0 0; font-size: 4vw; color: #204170"
        >
          القرنية المخروطية
        </p>
      </v-card>
      <div style="margin: 0 15.2vw 0 10.66666666vw">
        <v-card
          outlined
          tile
          class="transparent"
          height="6.406249999999999vw"
        ></v-card>

        <h3
          style="color: #2a9ae5; font-size: 4.533333333333333vw; margin: 0"
          class="boldm subtitlemmar"
        >
          من الذي يتأثر بمشكلة القرنية المخروطية؟
        </h3>

        <p
          class="ar"
          style="
            color: #2a3977;
            font-size: 4.266666666666667vw;
            margin: 3.4666666666666663vw 0 6.933333333333333vw 0;
          "
        >
          القرنية المخروطية عادة ما تؤثر على كلتا العينين وعادة ما يبدأ التأثير
          لأول مرة عند الأشخاص الذين تتراوح أعمارهم بين 10 -25 عاماً. وقد تتطور
          الحالة ببطء لمدة 10 سنوات أو أكثر.
        </p>

        <h3
          style="color: #2a9ae5; font-size: 4.533333333333333vw; margin: 0"
          class="boldm subtitlemmar"
        >
          الجفاف وأمراض سطح العين
        </h3>

        <p
          class="arp"
          style="
            color: #2a3977;
            font-size: 4.266666666666667vw;
            margin: 3.4666666666666663vw 0 6.933333333333333vw 0;
          "
        >
          يعاني الكثير من الناس وخاصة في فصل الصيف من جفاف العين والذي يسبب
          احمرار العين وشعور بثقل في الجفون وكذلك حرقة وحكة.
        </p>

        <v-card outlined class="transparent"
          ><h3
            style="color: #2a3977; font-size: 4.533333333333333vw"
            class="boldm subtitlemmar"
          >
            <span>:</span>
            من أسباب الجفاف
          </h3></v-card
        >

        <vue-list :items="items2" color="#2A9AE5" :lh="11"></vue-list>

        <v-card outlined tile class="transparent" height="5.171875vw"></v-card>

        <p
          class="arp"
          style="color: #2a3977; margin: 0; font-size: 4.266666666666667vw"
        >
          قد يحتاج المريض الذي يعاني من الجفاف لاستعمال قطرات الترطيب بشكل
          متكرر.
        </p>

        <v-card
          outlined
          tile
          class="transparent"
          height="10.666666666666668vw"
        ></v-card>
        <v-card
          style="margin: 0 0 14.133333333333335vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-btn
            class="arp"
            width="70.13333333333334vw"
            height="10.133333333333333vw"
            depressed
            @click="overlay = !overlay"
            style="
              text-transform: none;
              font-size: 4.266666666666667vw;
              border-radius: 5.066666666666666vw;
              color: white;
            "
            color="#2A9AE5"
          >
            احجز موعد استشارة
          </v-btn>
        </v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform :clickbtn="btnclick" light> </Vform>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import photo from "../../assets/Services/Cornea/Main-photo13.png";
import photo1 from "../../assets/Services/Cornea/Corneapic1.png";
import g1m from "../../assets/Services/Cornea/Mask Group 58.png";
import g2m from "../../assets/Services/Cornea/Mask Group 59.png";
import Vform from "../../componentsar/Form";

export default {  
metaInfo: {
    title: "الشامي العيون",
  },
  components: { Vform },
  data() {
    return {
      photo,
      g1m,
      g2m,
      overlay: false,
      photo1,
      items2: [
        { item: "استعمال الأجهزة الذكية والكمبيوتر" },
        { item: "التعرض المباشر للشمس لفترات طويلة" },
        { item: "قلة شرب السوائل" },
        { item: "التهاب الجفون المزمن" },
        {
          item:
            "في بعض الحالات النادرة ينتج الجفاف عن بعض الأمراض الروماتيزمية",
        },
      ],
    };
  },
  methods: {
    btnclick() {
      this.overlay = false;
    },
  },
};
</script>

<style>
</style>