import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import Meta from "vue-meta";
import './assets/main.css'

Vue.config.productionTip = false

Vue.component("main-bar", require('./components/NavBar.vue').default)
Vue.component("img-page", require('./components/imgpage.vue').default)
Vue.component("mimg-page", require('./components/mimgpage.vue').default)
Vue.component("vue-list", require('./components/VueList.vue').default)


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')


Vue.use(Meta, {
  keyName: "metaInfo",
  attribute: "data-vue-meta",
  ssrAttribute: "data-vue-meta-server-rendered",
  tagIDKeyName: "vmid",
  refreshOnceOnNavigation: true,
});