<template>
  <div>
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 15.859375vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977">Investing In Technology</h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>

        <p style="color: #2a3977">
          We are committed to using the state of the art technology in order to
          offer premium, quality care to our patients. And by combining modern
          technology with our expert staff, we are able to function as one of
          the largest specialized centers in both the Kingdom and the region.
          <br /><br />
          To enhance its services and maintain its leadership in the eye-care
          industry, Shami Eye Center has been breaking new ground when it comes
          to ophthalmic care since its establishment in 2008. The Center has
          pioneered many of the globally accepted treatments now widely being
          used locally.
          <br /><br />
          <span class="boldm">
            “We are continuing this trend with our investment in the latest
            technology, because we know that quality and safety must never be
            compromised when it comes to treating eye conditions”.
          </span>
        </p>

        <v-card outlined tile class="transparent" height="4.0625vw"></v-card>
      </div>
      <div style="margin: 0 10.9375vw">
        <v-carousel
          light
          style="
            border-radius: 2.65625vw;
            box-shadow: 0 0 1.171875vw 0.15625vw #dcdcdc !important;
          "
          :show-arrows="false"
          v-model="model"
          height="41.25vw"
        >
          <v-carousel-item v-for="(tech, i) in techitems" :key="i">
            <v-card
              style="padding: 6.406249999999999vw"
              light
              outlined
              class="transparent"
            >
              <v-row>
                <v-col cols="6">
                  <h2
                    style="
                      font-size: 2.265625vw;
                      color: #2a9ae5;
                      margin: 0 -3vw 1.484375vw 0;
                      z-index: 1000 !important;
                    "
                    class="boldm"
                  >
                    {{ tech.title }}
                    <span
                      style="font-size: 2.109375vw; color: black"
                      v-if="tech.lazer"
                    >
                      <br />
                      <span style="font-size: 2.3vw">●</span> {{ tech.lazer }}
                    </span>
                  </h2>
                  <p
                    v-html="tech.text"
                    style="margin: 0 0 1.7187500000000002vw 0"
                  ></p>
                  <p v-if="tech.additional" style="color: #2a9ae5">
                    <span>✔</span>
                    <span class="ml-4">7D eye-tracking</span>
                    <v-card
                      outlined
                      tile
                      class="transparent"
                      height="1vw"
                    ></v-card>

                    <span>✔</span>
                    <span class="ml-4">Touch-free eye treatment</span>
                    <v-card
                      outlined
                      tile
                      class="transparent"
                      height="1vw"
                    ></v-card>
                    <span>✔</span>
                    <span class="ml-4">No Pain at all</span>
                    <v-card
                      outlined
                      tile
                      class="transparent"
                      height="1vw"
                    ></v-card>
                    <span>✔</span>
                    <span class="ml-4">Safe & gentle</span>
                    <v-card
                      outlined
                      tile
                      class="transparent"
                      height="1vw"
                    ></v-card>
                    <span>✔</span>
                    <span class="ml-4">Fast recovery</span>
                  </p>
                </v-col>
                <v-col cols="6">
                  <v-card class="transparent" outlined>
                    <v-img
                      :aspect-ratio="16 / 15"
                      contain
                      :src="tech.img"
                      style="z-index: -2"
                    ></v-img>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </div>
      <v-card outlined tile class="transparent" height="4.0625vw"></v-card>
    </div>
    <!-- MOBILE -->
    <div class="hidden-md-and-up">
      <mimg-page :img="mphoto"></mimg-page>
      <div style="margin: 0 10.66666666vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977; font-size: 5.333333333333334vw">
            Investing In Technology
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>

        <p style="color: #2a3977; font-size: 4.266666666666667vw; margin: 0">
          We are committed to using the state of the art technology in order to
          offer premium, quality care to our patients. And by combining modern
          technology with our expert staff, we are able to function as one of
          the largest specialized centers in both the Kingdom and the region.
          <br /><br />
          To enhance its services and maintain its leadership in the eye-care
          industry, Shami Eye Center has been breaking new ground when it comes
          to ophthalmic care since its establishment in 2008. The Center has
          pioneered many of the globally accepted treatments now widely being
          used locally.
          <br /><br />
          <span class="boldm">
            “We are continuing this trend with our investment in the latest
            technology, because we know that quality and safety must never be
            compromised when it comes to treating eye conditions”.
          </span>
        </p>

        <v-card
          outlined
          tile
          class="transparent"
          height="10.666666666666668vw"
        ></v-card>
        <v-card class="transparent" outlined>
          <div>
            <v-carousel
              style="
                border-radius: 11.466666666666667vw;
                box-shadow: 0 0 20px 2px #dcdcdc !important;
              "
              :show-arrows="false"
              hide-delimiters
              v-model="model"
              height="173.33333333333334vw"
            >
              <v-carousel-item v-for="(tip, i) in techitems" :key="i">
                <v-card
                  style="
                    padding: 0 0 0 0;
                    border-radius: 11.466666666666667vw;
                    box-shadow: 0 0 20px 2px #dcdcdc !important;
                  "
                  light
                  height="173.33333333333334vw"
                >
                  <v-card outlined class="transparent" height="12.8vw"></v-card>
                  <v-img height="60.53333333333333vw" :src="tip.img"></v-img>
                  <v-card
                    style="padding: 0 7.199999999999999vw"
                    outlined
                    class="transparent"
                  >
                    <br />
                    <br />
                    <h2 style="color: #2a9ae5; font-size: 4vw">
                      {{ tip.title }}
                      <br />
                      <span v-if="tip.lazer" style="color: black">
                        <span style="font-size: 4vw">●</span> {{ tip.lazerm }}
                      </span>
                    </h2>
                    <p
                      style="font-size: 4vw; color: #204170"
                      v-html="tip.text"
                    ></p>
                    <p
                      v-if="tip.additional"
                      style="color: #2a9ae5; font-size: 4vw"
                    >
                      <span>✔</span>
                      <span class="ml-4">7D eye-tracking</span>
                      <v-card
                        outlined
                        tile
                        class="transparent"
                        height="1vw"
                      ></v-card>

                      <span>✔</span>
                      <span class="ml-4">Touch-free eye treatment</span>
                      <v-card
                        outlined
                        tile
                        class="transparent"
                        height="1vw"
                      ></v-card>
                      <span>✔</span>
                      <span class="ml-4">No Pain at all</span>
                      <v-card
                        outlined
                        tile
                        class="transparent"
                        height="1vw"
                      ></v-card>
                      <span>✔</span>
                      <span class="ml-4">Safe & gentle</span>
                      <v-card
                        outlined
                        tile
                        class="transparent"
                        height="1vw"
                      ></v-card>
                      <span>✔</span>
                      <span class="ml-4">Fast recovery</span>
                    </p>
                  </v-card>
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </div>
          <v-card outlined tile class="transparent" height="10vw"></v-card>

          <v-row justify="center">
            <v-card
              class="transparent mr-1"
              outlined
              width="7.733333333333333vw"
              height="7.733333333333333vw"
            >
              <v-img :src="left" @click="model--"></v-img>
            </v-card>
            <v-card
              class="transparent ml-1"
              outlined
              width="7.733333333333333vw"
              height="7.733333333333333vw"
            >
              <v-img :src="right" @click="model++"> </v-img>
            </v-card>
          </v-row>
          <v-card outlined tile class="transparent" height="10vw"></v-card>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import photo from "../assets/Tech/Main-photo6.png";
import mphoto from "../assets/Tech/Main-photo6.png";
import Technology1 from "../assets/Tech/Technology1.png";
import Technology2 from "../assets/Tech/Technology2.png";
import Technology3 from "../assets/Tech/Technology3.png";
import Technology4 from "../assets/Tech/Technology4.png";
import Technology5 from "../assets/Tech/Technology5.png";
import Technology6 from "../assets/Tech/Technology6.png";
import Technology7 from "../assets/Tech/Technology7.png";
import Technology8 from "../assets/Tech/Technology8.png";
import Technology9 from "../assets/Tech/Technology9.png";
import Technology10 from "../assets/Tech/Technology10.png";
import Technology11 from "../assets/Tech/Technology11.png";
import Technology12 from "../assets/Tech/Technology12.png";
import Technology13 from "../assets/Tech/Technology13.png";
import right from "../assets/tips/Group 410.png";
import left from "../assets/tips/Group 454.png";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Shami Eye | Technology",
    meta: [
      {
        name: "description",
        content:
          "We are committed to using the state of the art technology in order to offer premium, quality care to our patients.",
      },
    ],
    // all titles will be injected into this template
  },
  data() {
    return {
      photo,
      right,
      left,
      mphoto,
      model: 0,
      overlay: false,
      techitems: [
        {
          text:
            "The SCHWIND AMARIS 1050 RS combines the highest speed with extremely high precision. It is the superior model of the SCHWIND AMARIS product family.",
          additional: "additional",
          img: Technology1,
          title: "SCHWIND AMARIS® 1050 RS",
        },
        {
          text:
            "The CENTURION® Vision System is the only intelligent Phaco platform that dynamically optimizes every moment of the cataract removal procedure with anterior chamber stability and excellent emulsification efficiency.",
          img: Technology2,
          title: "Centurion Vision System",
        },
        {
          text:
            "The Alcon CONSTELLATION® Vision System establishes the standard of surgical control in Vitreoretinal Surgeries including High Speed Vitrectomy & various Efficiency Components to deliver an exceptional level of performance.",
          img: Technology3,
          title: "Constellation Vision System",
        },
        {
          text:
            "The world-class unique microscope for cataract and retinal surgery from Carl <br> Zeiss –Meditec. <br> <br> With OPMI LUMERA 700, even the finest anatomical details are clearly visible, and the red reflex is high in contrast and stable.",
          img: Technology4,
          title: "OPMI LUMERA 700 Surgical Microscope",
        },
        {
          text:
            "The Pentacam® is a rotating Scheimpflug camera system for anterior segment analysis. It measures topography and elevation of the anterior and posterior corneal surface and the corneal thickness prior to refractive surgery and for diagnosing Corneal Ectatic Disorders like Keratoconus.",
          img: Technology5,
          title: "PENTACAM",
        },
        {
          text:
            "The Topcon 3D OCT-2000 FA plus is the ultimate all-in-one OCT for OCT scans, Color, FA , FAF and red-free images. It’s 3d images are used for diagnosing retinal diseases & optic nerve head disorders.",
          img: Technology6,
          title: "TOPCON 3D OCT 2000 FA PLUS",
        },
        {
          text:
            "The LIGHTMED-CXL corneal cross-linking system is the newest, fastest (10 folds) and most feature-packed device in its market segment.",
          img: Technology7,
          title: "LIGHTMED Cross Linking System",
        },
        {
          text:
            "The ZEISS IOLMaster® 500 is the gold standard in optical biometry with more than 100 million successful IOL power calculations to date. With the ZEISS IOLMaster 500 you get a piece of cutting-edge technology that points the way to the future of optical biometry.",
          img: Technology8,
          title: "IOLMaster 500 from ZEISS",
        },
        {
          text:
            "Humphrey® Field Analyzer (HFA™) is the accepted standard of care in assessing the visual field in cases of glaucoma and optic nerve head diseases.",
          img: Technology9,
          title: "Humphrey Field Analyzer (HFA) by Zeiss Carl Meditec",
        },
        {
          text:
            "The Ocular Wavefront Analyzer is the latest generation of multi-functional aberrometer. It analyses the optical characteristics of the whole eye in a single measuring process.",
          img: Technology10,
          title: "Ocular Wavefront Analyzer by Schwind",
        },
        {
          lazer: "Tango SLT-YAG Laser",
          lazerm: "Tango SLT-YAG Laser",
          text:
            "Combining a full-featured SLT (selective laser trabeculoplasty) laser and a precise, powerful YAG laser for performing iridotomy & capsulotomy treatments.",
          img: Technology11,
          title: "Ellex Medical Lasers",
        },
        {
          lazer: "Argon Lasers",
          lazerm: "Argon Lasers",
          text:
            "Ellex solid-state photocoagulators provide safe, predictable and consistent energy delivery through a true, continuous-wave laser beam that delivers consistently better performance in the treatment of retinal disease.",
          img: Technology12,
          title: "Ellex Medical Lasers",
        },
        {
          text:
            "The E-Z Scan AB5500+ offers a portable, digital, combination A-scan and B-scan, with easy-to-use touch screen operation, extreme accuracy, repeatable Intraocular lens measurements prior to cataract surgery and high resolution in diagnosing vitreous hemorrhage and Retinal Detachment.",
          img: Technology13,
          title: "E-Z Scan AB5500+ Combination A-Scan / B-Scan",
        },
      ],
    };
  },
  methods: {
    btnclick() {
      this.overlay = false;
    },
  },
};
</script>

<style >
.v-carousel__controls {
  background: none !important;
}
</style>