<template>
  <div>
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 12.734375vw 4.609375vw 12.734375vw">
        <v-card height="3.125vw" class="transparent" outlined align="center">
          <h1 style="color: #2a3977">
            Questions We Hear From Patients
          </h1></v-card
        >
        <v-card class="transparent" outlined height="4.21875vw"></v-card>
        <v-card outlined class="transparent">
          <div>
            <v-card
              v-for="(item, i) in items"
              :key="i"
              outlined
              class="transparent"
            >
              <v-card
                min-height="5.078125vw"
                v-if="!item.active"
                @click.native="item.active = !item.active"
                class="d-flex align-center pl-10"
                style="
                  border-radius: 3.359375vw;
                  box-shadow: 0 0 1.171875vw 0.15625vw #dcdcdc !important;
                "
              >
                <v-card
                  :style="'padding: 1% 0 0 0; height:' + item.exh"
                  outlined
                  class="d-flex align-center transparent"
                  height="2.34375vw"
                >
                  <v-card outlined class="transparent" width="66.40625vw">
                    <h2 style="font-size: 1.5625vw; color: #2a9ae5">
                      {{ item.item }}
                    </h2>
                  </v-card>
                  <v-card
                    height="2.734375vw"
                    outlined
                    class="d-flex justify-end align-center transparent"
                  >
                    <h2 style="font-size: 2.734375vw; color: #2a9ae5">+</h2>
                  </v-card>
                </v-card>
              </v-card>

              <v-card
                min-height="5.078125vw"
                color="#2A9AE5"
                v-if="item.active"
                @click.native="item.active = !item.active"
                class="d-flex align-center pl-10"
                style="
                  border-radius: 3.359375vw;
                  box-shadow: 0 0 1.171875vw 0.15625vw #dcdcdc !important;
                "
              >
                <v-card
                  :style="'padding: 1% 0 0 0; height:' + item.exh"
                  outlined
                  class="d-flex align-center transparent"
                  height="2.34375vw"
                >
                  <v-card outlined class="transparent" width="66.40625vw">
                    <h2 style="font-size: 1.5625vw; color: white">
                      {{ item.item }}
                    </h2>
                  </v-card>
                  <v-card
                    outlined
                    class="d-flex justify-end align-center transparent"
                  >
                    <h2 style="font-size: 2.734375vw; color: white">-</h2>
                  </v-card>
                </v-card>
              </v-card>
              <v-card
                v-if="item.active"
                height="2.03125vw"
                outlined
                class="transparent"
              ></v-card>

              <v-card
                style="padding: 0 2.96875vw"
                outlined
                class="transparent"
                v-if="item.active"
              >
                <span
                  style="
                    font-size: 1.5625vw;
                    line-height: 1.3 !important;
                    word-spacing: -2%;
                    color: #2a3977;
                  "
                  v-html="item.desc"
                >
                </span>
              </v-card>
              <v-card class="transparent" outlined height="2.03125vw"></v-card>
            </v-card>
          </div>
        </v-card>
      </div>
    </div>
    <!-- MOBILE -->
    <div class="hidden-md-and-up">
      <mimg-page :img="mphoto"></mimg-page>
      <div style="margin: 0 7.199999999999999vw 4.609375vw 7.199999999999999vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977; font-size: 5.333333333333334vw">
            Question We Hear <br />
            From Patients
          </h1></v-card
        >
        <v-card class="transparent" outlined height="4.21875vw"></v-card>
        <v-card outlined class="transparent">
          <div>
            <v-card
              v-for="(item, i) in items"
              :key="i"
              outlined
              class="transparent"
            >
              <v-card
                v-if="!item.active"
                @click.native="item.active = !item.active"
                class="d-flex align-center"
                style="
                  border-radius: 100em;
                  box-shadow: 0 0 20px 2px #dcdcdc !important;
                "
              >
                <v-card
                  :style="
                    'padding: 3.733333333333334vw 7.199999999999999vw 3.733333333333334vw 7.199999999999999vw; height:' +
                    item.exh
                  "
                  outlined
                  class="d-flex align-center transparent"
                  height="2.34375vw"
                >
                  <v-card outlined class="transparent" width="66.40625vw">
                    <h2
                      style="
                        font-size: 4.266666666666667vw;
                        color: #2a9ae5;
                        margin: 0;
                      "
                    >
                      {{ item.item }}
                    </h2>
                  </v-card>
                  <v-card
                    height="2.734375vw"
                    outlined
                    class="d-flex justify-end align-center transparent"
                  >
                    <h2
                      class="pl-3"
                      style="
                        font-size: 5.333333333333334vw;
                        color: #2a9ae5;
                        margin: 0;
                      "
                    >
                      +
                    </h2>
                  </v-card>
                </v-card>
              </v-card>

              <v-card
                color="#2A9AE5"
                v-if="item.active"
                @click.native="item.active = !item.active"
                class="d-flex align-center"
                style="
                  border-radius: 100em;
                  box-shadow: 0 0 20px 2px #dcdcdc !important;
                "
              >
                <v-card
                  :style="
                    'padding: 3.733333333333334vw 7.199999999999999vw 3.733333333333334vw 7.199999999999999vw; height:' +
                    item.exh
                  "
                  outlined
                  class="d-flex align-center transparent"
                  height="2.34375vw"
                >
                  <v-card outlined class="transparent" width="66.40625vw">
                    <h2
                      style="
                        font-size: 4.266666666666667vw;
                        color: white;
                        margin: 0;
                      "
                    >
                      {{ item.item }}
                    </h2>
                  </v-card>
                  <v-card
                    height="2.734375vw"
                    outlined
                    class="d-flex justify-end align-center transparent"
                  >
                    <h2
                      class="pl-3"
                      style="
                        font-size: 5.333333333333334vw;
                        color: white;
                        margin: 0;
                      "
                    >
                      -
                    </h2>
                  </v-card>
                </v-card>
              </v-card>
              <v-card
                v-if="item.active"
                height="2.03125vw"
                outlined
                class="transparent"
              ></v-card>

              <v-card
                style="padding: 0 6.8vw 0 7.2vw"
                outlined
                class="transparent"
                v-if="item.active"
              >
                <span
                  style="
                    font-size: 4.266666666666667vw;
                    line-height: 1.3 !important;
                    word-spacing: -2%;
                    font-family: monstrat-r;
                    color: #2a3977;
                  "
                  v-html="item.desc"
                >
                </span>
              </v-card>
              <v-card class="transparent" outlined height="2.03125vw"></v-card>
            </v-card>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import photo from "../assets/faq/Main-photo-3.png";
import mphoto from "../assets/faq/Group 472.png";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Shami Eye | FAQ",
    meta: [
      {
        name: "description",
        content:
          "Check Our Frequently Asked Questions for any info you need regarding your next appointment and concerns",
      },
    ],
    // all titles will be injected into this template
  },
  data() {
    return {
      active: false,
      photo,
      mphoto,
      items: [
        {
          item: "What is LASIK surgery?",
          active: false,
          desc:
            "Lasik is a surgical procedure that corrects a wide range of myopia (nearsightedness), hyperopia (farsightedness), & astigmatism.",
        },
        {
          item: "What does LASIK mean?",
          active: false,
          desc:
            "LASIK is short for “Laser In Situ Keratomileusis”, which means the use of a laser to reshape the cornea without invading the nearby cell layers. “In Situ” is a Latin term meaning “situated in the natural place”. Medically, In Situ means “confining to the site of origin”. The word “Kerato” is a Greek work meaning “Cornea” and Mileusis means “to shape”. ",
        },
        {
          item: "How long has LASIK been performed?",
          active: false,
          desc:
            "LASIK vision correction has been performed worldwide around 20 years ago. It was first performed in the Unites Stated in 1991, while PRK was first performed there in 1989. In Canada, both LASIK and PRK were first performed in 1990. Ophthalmologists have been reshaping the cornea for over 50 years, creating a protective layer of tissue over the cornea for over 35 years, and using the Excimer laser since the 1980s.  ",
        },
        {
          item: "Who benefits from LASIK?",
          active: false,
          desc:
            "Lasik is useful for people w myopia (nearsightedness), hyperopia (farsightedness), and astigmatism.",
        },
        {
          item: "Who cannot have LASIK vision correction?",
          active: false,
          desc:
            "There are numerous factors that doctors must evaluate before deciding who is an eligible candidate for the operation. Some doctors believe certain pre-existing conditions conflicting to the procedure and will not perform surgery if you have them. Other symptoms may cause difficulties during the procedure and increase the chance of complications. Patients should consult a doctor to make sure that the surgery will not only be a success, but that no harm will come to the patient.",
        },
        {
          item: "Why is LASIK considered an advancement in refractive surgery?",
          active: false,
          desc:
            "Lasik surgery combines the technique of the Excimer laser used in Lazer surgeries and the technique of the corneal flap which is when the outer membrane of the cornea gets cut into an incomplete circle. The precision of the procedure is higher than the precision of the Lazer surgery, complications are fewer, recovery is faster, the number of eye drops required after the surgery are less, plus it is more comfortable for the patient. ",
        },
        {
          item: "I am happy with contact lenses, should I pursue having LASIK?",
          active: false,
          desc:
            "Most surgeons agree that if you are comfortable wearing contacts lenses and are not bothered by depending on them, you should carefully assess the risks and benefits of LASIK eye surgery. Eventually, it will depend on your wants and everyday life.",
        },
        {
          item: "How much does LASIK cost?",
          active: false,
          desc:
            "Price may vary according to each patient’s condition. The final price is set during your free pre-operative consultation. The final price includes Standard or Advanced Wavefront Optimized LASIK or PRK surgery, as well as all post-operative visits.  Plus, you pay no sales or service taxes on laser vision correction surgeries at Shami Eye Center. ",
        },
        {
          item: "What kinds of results can I expect from LASIK?",
          active: false,
          desc:
            "LASIK surgery improves the uncorrected vision – one’s visual capability while not wearing corrective lenses – in most patients who have the procedure. At Shami Eye Center, over 96% of patients with low to moderate myopia attain 20/20 vision or better after the original laser procedure, which is the visual acuity similar to that with contact lenses. The number boosts to over 99% when an enhancement procedure is included, if needed. ",
        },
        {
          item: "Are the results achieved from LASIK permanent?",
          active: false,
          desc:
            "The effects of the LASIK procedure are permanent. However, it is important to know that a person’s eye can still change internally. That’s why many vision correction surgeons suggest having the procedure done after the major eye changes have come about in one’s life. For example, LASIK eye surgery is not advised on children because their eyes change drastically and their vision would need to be enhanced in a few years. If the procedure is done after the age of 18, the chance of a stable correction is more likely. Even the eyes of patients above the age of 18 can still change. For that reason, we recommend a good conversation with your eye surgeon so that they can review the changes you have undergone and, if appropriate; they can help you make a decision to whether or not you should wait until the changes have slowed down. Patients should know that while results are usually stable, they can be modified by enhancements procedures – procedures performed after the original one – if needed. ",
        },
        {
          item: "Do I need a referral from my eye doctor?",
          active: false,
          desc:
            "No, you can have a free consultation before the operation and have the Laser eye correction surgery without a referral from your private doctor. Our team of ophthalmologist and optometrists can select the best treatment for you. During the pre-op free consultation, our doctors will be able to assess your vision correction needs. However, it is always beneficial to consult your own doctor or family doctor.  We also have a list of Shami Eye Center affiliate eye doctors in your area.",
        },
        {
          item: "Can LASIK be redone if my vision changes later in life?",
          active: false,
          desc:
            "Retreatment the surgery might be a good enough solution to vision changes later in life. The important thing is to know the alternative options. That’s why it’s best if you consult an eye doctor to determine the cause of the change and to determine which option is best for you. If the only solution is a retreatment, then a patient must wait at least 3 months after the initial laser eye surgery.",
        },
        {
          item: "How long is the procedure?",
          active: false,
          desc:
            "The operation takes ten minutes for forming the flap and the performing Laser surgery. The actual usage of the laser takes less than 20 seconds per eye. ",
        },
        {
          item: "Can I drive immediately after doing the surgery?",
          active: false,
          desc:
            "The patient might experience discomfort or blurred vision for a few hours after the operation. In addition, a sedative is given to most of the patients before the operation. Therefore, you have to wait a minimum of 24 hours before driving a car. ",
        },
        {
          item: "What type of anesthesia is used for the LASIK surgery?",
          active: false,
          desc:
            "Anesthetic eye drops will be placed in your eyes; you will be wide awake and comfortable during the procedure. Patients may be given a small amount of oral sedative to help them relax. ",
        },
        {
          item: "Does the Lasik surgery hurt?",
          active: false,
          desc:
            "Anesthetic eye drops will be given to the patient to numb the eye; therefore the procedure itself is virtually painless. When the surgeon applies the vacuum ring, the patient feels a sensation of slight pressure just before his or her vision fades away for a few seconds. Neither the precision flap-making instrument, nor the laser cause any pain or discomfort. For a few hours after the procedure, many patients describe a “foreign body” sensation, such as after opening while swimming in chlorinated water. Some people feel as though there is an unclean contact lens in their eye. After the few first hours, this feeling goes away. ",
        },
        {
          item:
            "Can I have both eyes done with LASIK eye surgery at the same time?",
          active: false,
          desc:
            "Having both eyes done at the same time, medically known as bilateral simultaneous LASIK eye surgery, can be done. 95% of people choose to have both eyes done at the same time. If the surgery of your first eye is not perfect, your doctor will likely hold off on going on with the second eye. ",
        },
        {
          item: "How long will I be away from my job?",
          active: false,
          desc:
            "It depends on your career. Some jobs that require intense clarity of vision (such as surgery or dentistry), need a day or two. Most patients may return to their jobs the next day assuming that their vision is suitable for their jobs. Some patients might feel tired the next day. We will inform you about your activities schedule after the operation. ",
        },
        {
          item: "Can I play sports after the Lasik surgery?",
          active: false,
          desc:
            "You can continue with your normal activities immediately after laser eye surgery. However, you will need to avoid activities that could cause perspiration to run into your eyes for at least two weeks. Safety glasses should be used while playing contacts sports whether or not you have had surgery. If you do not usually wear safety glasses, your eye surgeon may recommend wearing them for at least one month after the surgery. It is important to consult your doctor about the limitations on specific activities following the surgery. ",
        },
        {
          item:
            "How long will it take before I can see well, and how long will it take before I have my best vision?",
          active: false,

          desc:
            "Fast visual recovery characterizes this operation. Most patients achieve good vision the day of surgery and find that their eyes feel fairly normal within a day. In about 4 hours the vision will be clearer. While your eyes are healing, it is normal for your vision to go back and forth between clear and blurry phases for several weeks. However, vision can continue to improve, and best vision can still take two to three months to occur.",
        },
        {
          item: "Will I need glasses after the Lasik operation?",
          active: false,
          desc:
            "The goal of refractive surgery is to reduce your dependence on glasses and/or contact lenses, so most patients will not need to wear them after the surgery. However, patients may need to wear reading glasses if they are over the age of 40. This is caused by the normal aging of the eye, known as presbyopia. This condition occurs with our without LASIK vision correction. It is possible that some patients need a minimal prescription for certain activities, such as night driving. ",
        },
        {
          item: "Will my eyes look different after LASIK vision correction?",
          active: false,
          desc: "No, they will stay the same. ",
        },
        {
          item: "How safe is the Lasik surgery?",
          active: false,
          desc:
            "Most surgeries show that the possibilities of complications occurring are minimal. The total number of complications occurring is less than 1%. If any complications were to happen, they will be treated in less than three months and don’t lead to any long term damage on vision. To be more precise, the percentages of complications that have been recorded in major studies vary from 0.001% to 0.05%. The Lasik surgery is performed on a particularly sensitive area in the eyes, that’s why the possibility of complications forming exists. To learn more about safety issues, read our brochure on Lasik. ",
        },
        {
          item:
            "How will my vision be like during night time or dim lighting after the surgery?",
          active: false,
          desc:
            "After LASIK, most cases of disturbed night vision will be eliminated. Since the treated area of the cornea is larger than the dilated pupil size. Some patients might see halo like shapes and starbursts around areas with dim light conditions temporarily after the surgery. Normally, these have no effect on driving during the night or any night time activities. These symptoms are temporary for the vast majority, and they usually only last from a few days to a week. With the uprising laser technology, it is extremely rare for these symptoms to be permanent.",
        },
        {
          item: "Will my eyes be dry after the operation?",
          active: false,
          desc:
            "Although taking place very rarely, all patients should know that dry eyes are a possible complication after the surgery. Laser vision correction makes everyone’s eyes a little dryer later. Your surgeon will test you for the potential of developing this complication after surgery if you suffer from a history of dry eyes or are bothered by contact lenses. All patients should use refreshing eye drops after the surgery when needed.  ",
        },
        {
          item:
            "I wear bifocals now, why will I still need my reading glasses after LASIK?",
          active: false,
          desc:
            'Most people in their forties or older need reading glasses, even with having done vision correction surgery. This is the result of the eye losing its lens flexibility with age, the condition is known as presbyopia. This case currently cannot be treated by the Lasik surgery. Some patients prefer monovision as a treatment technique.  This technique creates a "reading eye" and a "distance vision eye”.  Although monovision is satisfying for most of the patients, many are not comfortable with this procedure. <br/><br/> Patients should discuss their options with their doctors. Most doctors recommend trying monovision contact lenses for a couple of weeks before choosing to go for monovision Lasik. Make sure to ask your doctor if this trial option is available. Learn more about monovision.  ',
        },
        {
          item:
            "If my distance vision was corrected, will I lose the ability to well up-close?",
          active: false,
          desc:
            "If you had Lasik vision correction surgery, you can expect a generally better vision power. However, if you have the surgery when you’re over forty, images closer to you will not appear as clear. After the age of forty, expect the need of reading glasses for up-close work.",
        },
        {
          item: "What is PRK laser eye surgery?",
          active: false,
          desc:
            "PRK, or photorefractive keratectomy, laser eye surgery is like LASIK eye surgery in a way that both procedures use a computer-controlled Excimer laser to reshape the cornea of the affected eye. However, during the LASIK procedure, the surgeon preserves the epithelium (the outermost protective layer of the eye) by creating a flap. During PRK vision correction, the epithelium is removed by gently scraping the surface (no flap is created). Anesthetic drops in the eye ensure that the patient experiences as little discomfort as possible. PRK laser eye surgery also has a longer healing process and more discomfort that LASIK eye surgery. ",
        },
        {
          item:
            "Not everyone can have the LASIK surgery, but can anyone have the PRK surgery?",
          active: false,
          desc:
            "Patients who have autoimmune disorder (Lupus, rheumatoid arthritis), Keloids (aggressive scar formation after a skin incision), or those with degenerative eye disorders are not eligible for PRK surgery. You will know whether or not you are a candidate at your pre-operative evaluation. ",
        },
        {
          item: "How do various lasers differ from one another?",
          active: false,
          desc:
            "All three lasers include wave front technology, which allows the surgeon to treat higher order aberrations beyond sphere and cylinder, and potentially improve vision better than the capabilities of non-wave front lasers. <br/><br/> The three lasers each integrate a slightly different method of acquiring the wave front data. They each also use a slightly different laser beam shape, a different speed and a different tracking system. They are also more user-friendly.<br/><br/> Shami Eye Center is dedicated to using only confirmed technology to offer superior care to our patients. Our doctors will recommend treatment options best suited to your needs, including standard, custom and All-Laser LASIK.",
        },
      ],
    };
  },
};
</script>

<style>
</style>