<template>
  <div align="right">
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 15.859375vw">
        <v-card outlined class="transparent" align="center">
          <h1 class="ar" style="color: #2a3977">الزرق (الجلوكوما)</h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <v-card outlined class="d-flex transparent">
          <v-card
            outlined
            class="transparent"
            align="center"
            style="margin: 0 1.5625vw 0 0"
          >
            <v-img
              style="margin: 0 0 1.640625vw 0"
              contain
              height="21.875vw"
              width="33.515625vw"
              :src="g1"
            ></v-img>
            <span class="ptitle ar">نطاق الرؤية الطبيعي</span>
          </v-card>
          <v-card outlined class="transparent" align="center">
            <v-img
              style="margin: 0 0 1.640625vw 0"
              contain
              height="21.875vw"
              width="33.515625vw"
              :src="g2"
            ></v-img>
            <p class="ptitle ar">نطاق الرؤية المنخفض نتيجة للزرق</p>
          </v-card>
        </v-card>
        <v-card outlined tile class="transparent" height="0.390625vw"></v-card>
        <v-card outlined class="transparent"
          ><h2 class="boldm subtitlem ar" style="color: #2a9ae5">
            ما هو الزرق (الجلوكوما)؟
          </h2></v-card
        >
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <p class="ar" style="color: #2a3977">
          .
          <span
            >هو مجموعة من الاضطرابات التي يكون فيها عامل الخطر الرئيسي هو الضغط
            المرتفع للسائل داخل العين
          </span>
          <br />
          . وجميع اضطرابات الزرق تؤدي إلى فقدان الرؤية، وذلك نتيجة للأضرار التي
          لحقت بالعصب البصري
        </p>
        <v-card outlined tile class="transparent" height="1.5625vw"></v-card>
        <v-card outlined class="transparent"
          ><h2 class="boldm subtitlem ar" style="color: #2a9ae5">
            كيفية علاج الزرق؟
          </h2></v-card
        >
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <p class="ar" style="color: #2a3977; margin: 0 -0.5% 0 0">
          .
          <span class="sboldm ar">
            إذا اكتشف الطبيب الزرق في وقت مبكر وكان المريض يتبع التوجيهات
            بعناية، عندئذ يمكن معالجة الآثار الضارة للزرق</span
          ><br />
          إذا كان لديك الزرق، طبيبك يمكن أن يبقي الضغط داخل العين في مستوى طبيعي
          مع العلاج الحريص. من<br />
          المهم أن يقوم طبيب العيون بفحص ضغطك بانتظام بحيث يمكنه أن يصف العلاج
          المناسب (قطرات خفض <br />ضغط العين، رأب التربيق بالليزر أو إدراج
          صمام). وبهذه الطريقة، يمكنك منع المزيد من فقدان الرؤية.
        </p>
        <v-card outlined tile class="transparent" height="3.515625vw"></v-card>
        <v-img
          style="margin: 0 0 3.515625vw 0"
          contain
          height="20.78125vw"
          width="75.859375vw"
          :src="g3"
        ></v-img>
        <v-row no-gutters>
          <v-col cols="5">
            <v-card outlined class="transparent" align="center">
              <p
                style="
                  font-size: 1.171875vw;
                  color: #204170;
                  margin: -15% 0 0 65%;
                "
              >
                <span class="sboldm ar">عين بها الزرق </span>
              </p>
            </v-card>
          </v-col>
          <v-col>
            <v-card outlined class="transparent" align="center" cols="7">
              <p
                class="sboldm"
                style="
                  font-size: 1.171875vw;
                  color: #204170;
                  margin: -10% 0 0 45%;
                "
              >
                <span class="sboldm ar"> عين طبيعية </span>
              </p>
            </v-card></v-col
          >
        </v-row>
        <v-card outlined tile class="transparent" height="3.515625vw"></v-card>
        <v-card outlined tile class="transparent">
          <h2 style="color: #2a9ae5; font-size: 2.5vw" class="titlea boldm ar">
            أعصاب العين
          </h2>
        </v-card>
        <v-card outlined tile class="transparent" height="1.484375vw"></v-card>
        <vue-list
          :items="items1"
          color="#2A3977 !important"
          :lh="18"
        ></vue-list>
        <v-card outlined class="transparent" style="margin: 0 1.671875vw 0 0">
          <vue-list
            :items="subitems"
            color="#2A9AE5 !important"
            :bullet="true"
            :lh="18"
          ></vue-list>
        </v-card>
        <vue-list
          :items="items2"
          color="#2A3977 !important"
          :lh="18"
        ></vue-list>
        <v-card outlined tile class="transparent" height="3.125vw"></v-card>
        <v-card
          style="margin: 0 0 4.609375vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-btn
            @click="overlay = !overlay"
            width="35.78125vw"
            height="4.84375vw"
            depressed
            style="
              text-transform: none;
              font-size: 2.03125vw;
              letter-spacing: -1.5px !important;
              border-radius: 2.421875vw;
              color: white;
            "
            color="#2A9AE5"
            class="ar"
          >
            احجز موعد استشارة
          </v-btn>
        </v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform light>
          <v-btn
            @click="overlay = !overlay"
            height="3.28125vw"
            width="12.968750000000002vw"
            depressed
            color="#2A9AE5"
            style="
              text-transform: none;
              border-radius: 3.359375vw;
              margin: 0 14px 0 0;
            "
            class="white--text btntxt"
          >
            <span class="ar" style="margin: 0 0 0 0; font-size: 1.25vw"
              >الغاء</span
            >
          </v-btn>
        </Vform>
      </v-overlay>
    </div>
    <!-- Mobile -->
    <div class="hidden-md-and-up">
      <mimg-page :img="mphoto"></mimg-page>
      <div style="margin: 0 10.666666666666668vw">
        <v-card outlined class="transparent" align="center">
          <h1
            class="art"
            style="color: #2a3977; font-size: 5.333333333333334vw"
          >
            الزرق (الجلوكوما)
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <v-card outlined class="transparent">
          <v-card
            outlined
            class="transparent"
            align="center"
            style="margin: 0 0 0 0"
          >
            <v-img
              style="margin: 0 0 0 0"
              contain
              height="51.46666666666667vw"
              width="78.66666666666666vw"
              :src="g1"
            ></v-img>
            <v-card
              class="transparent"
              outlined
              style="margin: 3.733333333333334vw 0 6.933333333333333vw 0"
            >
              <span style="font-size: 4vw" class="arp"
                >نطاق الرؤية المنخفض نتيجة للزرق</span
              >
            </v-card>
          </v-card>
          <v-card outlined class="transparent" align="center">
            <v-img
              style="margin: 0 0 0 0"
              contain
              height="51.46666666666667vw"
              width="78.66666666666666vw"
              :src="g2"
            ></v-img>
            <v-card
              class="transparent"
              outlined
              style="margin: 3.733333333333334vw 0 6.933333333333333vw 0"
            >
              <p style="font-size: 4vw" class="arp">نطاق الرؤية الطبيعي</p>
            </v-card>
          </v-card>
        </v-card>
        <v-card outlined class="transparent"
          ><h2
            class="boldm subtitlemmar"
            style="color: #2a9ae5; font-size: 4.8vw; margin: 0"
          >
            ما هو الزرق (الجلوكوما)؟
          </h2></v-card
        >
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <p class="arp" style="color: #2a3977; font-size: 4.266666666666667vw">
          هو مجموعة من الاضطرابات التي يكون فيها عامل الخطر الرئيسي هو الضغط
          المرتفع للسائل داخل العين. وجميع اضطرابات الزرق تؤدي إلى فقدان الرؤية،
          وذلك نتيجة للأضرار التي لحقت بالعصب البصري.
        </p>
        <v-card outlined tile class="transparent" height="1.5625vw"></v-card>
        <v-card outlined class="transparent"
          ><h2
            class="boldm subtitlemmar"
            style="color: #2a9ae5; font-size: 4.8vw; margin: 0"
          >
            كيفية علاج الزرق؟
          </h2></v-card
        >
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <p
          class="arp"
          style="
            color: #2a3977;
            font-size: 4.266666666666667vw;
            margin: 0 0 0 0;
          "
        >
          <span style="font-weight: 1000" class="sboldm arp">
            إذا اكتشف الطبيب الزرق في وقت مبكر وكان المريض يتبع التوجيهات
            بعناية، عندئذ يمكن معالجة الآثار الضارة للزرق</span
          ><br />
          <br />
          إذا كان لديك الزرق، طبيبك يمكن أن يبقي الضغط داخل العين في مستوى طبيعي
          مع العلاج الحريص. من المهم أن يقوم طبيب العيون بفحص ضغطك بانتظام بحيث
          يمكنه أن يصف العلاج المناسب (قطرات خفض ضغط العين، رأب التربيق بالليزر
          أو إدراج صمام). وبهذه الطريقة، يمكنك منع المزيد من فقدان الرؤية
        </p>
        <v-card outlined tile class="transparent" height="3.515625vw"></v-card>
        <v-card outlined class="transparent" align="center">
          <v-img
            width="45.33333333333333vw"
            height="34.13333333333333vw"
            :src="g1m"
          ></v-img>
          <p
            class="sboldm arp"
            style="margin: 1% 0 0 0; font-size: 4vw; color: #204170"
          >
            عين طبيعية
          </p>
        </v-card>
        <v-card outlined tile class="transparent" height="3.515625vw"></v-card>
        <v-card outlined class="transparent" align="center">
          <v-img
            width="83.46666666666667vw"
            height="34.13333333333333vw"
            :src="g2m"
          ></v-img>
          <p
            class="sboldm arp"
            style="margin: 1% 0 0 0; font-size: 4vw; color: #204170"
          >
            عين بها الزرق
          </p>
        </v-card>

        <v-card outlined tile class="transparent" height="3.515625vw"></v-card>
        <v-card outlined tile class="transparent">
          <h2
            style="color: #2a9ae5; font-size: 4.8vw; margin: 0"
            class="titlear boldm"
          >
            أعصاب العين
          </h2>
        </v-card>
        <v-card outlined tile class="transparent" height="1.484375vw"></v-card>
        <vue-list
          :items="items1"
          color="#2A3977 !important"
          :lh="18"
        ></vue-list>
        <v-card outlined class="transparent" style="margin: 0 0 0 3.671875vw">
          <vue-list
            :items="subitems"
            color="#2A9AE5 !important"
            :bullet="true"
            :lh="18"
          ></vue-list>
        </v-card>
        <vue-list
          :items="items2"
          color="#2A3977 !important"
          :lh="18"
        ></vue-list>
        <v-card
          outlined
          tile
          class="transparent"
          height="10.666666666666668vw"
        ></v-card>
        <v-card
          style="margin: 0 0 14.133333333333335vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-btn
            class="arp"
            width="70.13333333333334vw"
            height="10.133333333333333vw"
            depressed
            @click="overlay = !overlay"
            style="
              text-transform: none;
              font-size: 4.266666666666667vw;
              border-radius: 5.066666666666666vw;
              color: white;
            "
            color="#2A9AE5"
          >
            احجز موعد استشارة
          </v-btn>
        </v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform :clickbtn="btnclick" light> </Vform>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import photo from "../../assets/Services/Glaucoma/Main-photo9.png";
import mphoto from "../../assets/Services/Glaucoma/Group 457.png";
import g1m from "../../assets/Services/Glaucoma/Mask Group 50.png";
import g2m from "../../assets/Services/Glaucoma/Mask Group 51.png";
import g1 from "../../assets/Services/Glaucoma/glaucomapic1.png";
import g2 from "../../assets/Services/Glaucoma/glaucomapic2.png";
import g3 from "../../assets/Services/Glaucoma/Glaucomaar.png";
import Vform from "../../componentsar/Form";

export default {
  metaInfo: {
    title: "الشامي العيون",
  },
  components: { Vform },
  data() {
    return {
      photo,
      mphoto,
      g1m,
      g2m,
      overlay: false,
      g1,
      g2,
      g3,
      items1: [
        { item: "أمراض و اعتلالات وجلطات العصب البصري" },
        { item: ":" + "الأعصاب التاجية" },
      ],
      subitems: [
        { item: "خاصة العصب الثالث" },
        { item: "العصب الرابع" },
        { item: "العصب السادس" },
      ],
      items2: [
        { item: "صداع الشقيقة المرتبطة بالعين" },
        { item: "رأرأة  العين" },
        { item: "تقييم الساحة البصرية" },
        { item: "ازدواجية الرؤية" },
        { item: "اعتلالات عضلات العين" },
        { item: "التهاب العصب في التصلب اللويحي" },
        { item: "ارتفاع ضغط الدماغ وعمليات تنفيس الضغط على عصب العين" },
        {
          item: "الأورام الدماغية الضاغطة على العصب البصري",
        },
      ],
    };
  },
  methods: {
    btnclick() {
      this.overlay = false;
    },
  },
};
</script>

<style>
</style>