<template>
  <div align="right">
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 15.859375vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977" class="ar">تصحيح النظر</h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <div>
          <p class="boldm" style="color: #2a9ae5; font-size: 2.34375vw">
            <span class="ar">الليزر أو الليزك</span>
            <v-icon color="#2a9ae5" class="ml-3" style="font-size: 2.34375vw">
              mdi-square-rounded
            </v-icon>
          </p>
          <p class="boldm" style="color: #2a9ae5; font-size: 2.34375vw">
            <span class="ar">العدسات المتقدمة </span>
            <v-icon color="#2a9ae5" class="ml-3" style="font-size: 2.34375vw">
              mdi-square-rounded
            </v-icon>
          </p>
        </div>
        <v-card
          outlined
          tile
          class="transparent"
          height="1.7187500000000002vw"
        ></v-card>
        <v-img
          style="border-radius: 2.34375vw"
          width="68.515625vw"
          height="21.71875vw"
          :src="photo1"
        ></v-img>
        <v-card
          outlined
          tile
          class="transparent"
          height="3.5937499999999996vw"
        ></v-card>
        <div>
          <p class="ar" style="color: #2a3977">
            الجراحة الانكسارية (أو جراحة تصحيح الرؤية) هي أي عملية جراحية تستخدم
            لتصحيح مشاكل الرؤية. وهناك العديد من العمليات الجراحية لتصحيح أو
            تعديل قدرة العين على التركيز من خلال إعادة تشكيل القرنية: وهي القبة
            المستديرة الواضحة في مقدمة عينيك. وتلعب القرنية دورًا هامًا في
            القدرة البصرية للعين. ومعظم أنواع جراحة تصحيح الرؤية تعيد تشكيل
            القرنية، الجزء الأمامي الواضح من عينيك، والذي يتيح للضوء الانتقال من
            خلالها والتركيز بشكل صحيح على الجزء الخلفي من العين، أو شبكية
            <span>العين</span>
            <br /><br />

            وقد شهدت السنوات الأخيرة تقدمًا كبيرًا في هذا المجال. جراحة تصحيح
            الرؤية للعين والليزر تسمح للعديد من المرضى بالرؤية الأفضل من أي وقت
            آخر في حياتهم
            <br /><br />
            وتتميز خدمة جراحة القرنية وجراحة تصحيح الرؤية في مركز الشامي للعيون
            بخبراتها التي لا مثيل لها وباستخدام المعدات الحديثة التي تسمح لها
            بتقديم العلاج الطبي والجراحي الممتاز لمعظم أمراض القرنية الروتينية
            والمعقدة والعالية المخاطر - والأمراض التي تهدد الرؤية. وتغطي خدماتنا
            قرحة القرنية، والأمراض الفيروسية الشديدة ومتلازمة جفاف العين، فضلاً
            عن أحدث إجراءات تصحيح الرؤية
          </p>
        </div>
        <v-card outlined tile class="transparent" height="2.34375vw"></v-card>
        <v-img
          style="border-radius: 2.34375vw"
          width="68.515625vw"
          height="21.71875vw"
          :src="photo2"
        ></v-img>
        <v-card
          outlined
          tile
          class="transparent"
          height="3.5937499999999996vw"
        ></v-card>
        <div>
          <p class="ar" style="color: #2a3977">
            (هايبروبيا)، أو الاستجماتيزم، فالأطباء لدينا سيقدمون لك المساعدة في
            اختيار الحل الصحيح لتحقيق أهداف الرؤية الخاصة بك. يقوم الأطباء لدينا
            بإجراء الآلاف من إجراءات تصحيح الرؤية سنويًا التي تتم على أساس
            العيادات الخارجية باستخدام التقنيات الأكثر تقدما والتكنولوجيا
            المتاحة اليوم
            <br /><br />
            وتشمل الإجراءات الشائعة تصحيح الرؤية باستخدام إكسيمر الليزر من خلال
            استئصال القرنية عبر الخلايا الظهارية واستخدام الليزر في القرنية
            الموضعية (الليزك)، بالإضافة إلى إدخال العدسات اللاصقة القابلة للزرع
            . سوف نقدم لكم كافة الحقائق والموارد التي تحتاجونها لاتخاذ القرار
            الأفضل لحالتكم
          </p>
        </div>
        <v-card outlined tile class="transparent" height="2.34375vw"></v-card>
        <v-card
          style="margin: 0 0 4.609375vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-btn
            @click="overlay = !overlay"
            width="35.78125vw"
            height="4.84375vw"
            depressed
            class="ar"
            style="
              text-transform: none;
              font-size: 2.03125vw;
              letter-spacing: -1.5px !important;
              border-radius: 2.421875vw;
              color: white;
            "
            color="#2A9AE5"
          >
            احجز موعد استشارة
          </v-btn>
        </v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform light>
          <v-btn
            @click="overlay = !overlay"
            height="3.28125vw"
            width="12.968750000000002vw"
            depressed
            color="#2A9AE5"
            style="
              text-transform: none;
              border-radius: 3.359375vw;
              margin: 0 14px 0 0;
            "
            class="white--text btntxt"
          >
            <span class="ar" style="margin: 0 0 0 0; font-size: 1.25vw"
              >الغاء</span
            >
          </v-btn>
        </Vform>
      </v-overlay>
    </div>
    <!-- MOBILE -->
    <div class="hidden-md-and-up">
      <mimg-page :img="mphoto"></mimg-page>
      <div style="margin: 0 10.666666666666668vw">
        <v-card outlined class="transparent" align="center">
          <h1
            class="art"
            style="color: #2a3977; font-size: 5.333333333333334vw"
          >
            تصحيح النظر
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <div>
          <p
            class="boldm titlear"
            style="color: #2a9ae5; font-size: 4.8vw; margin: 0"
          >
            <span>الليزر أو الليزك</span>
            <v-icon
              color="#2a9ae5"
              class="ml-3"
              style="font-size: 3.4666666666666663vw"
            >
              mdi-square-rounded
            </v-icon>
          </p>
          <p
            class="boldm titlear"
            style="color: #2a9ae5; font-size: 4.8vw; margin: 0"
          >
            <span>العدسات المتقدمة </span>
            <v-icon
              color="#2a9ae5"
              class="ml-3"
              style="font-size: 3.4666666666666663vw"
            >
              mdi-square-rounded
            </v-icon>
          </p>
        </div>

        <v-img
          style="
            border-radius: 8vw;
            margin: 9.066666666666666vw 0 10.666666666666668vw 0;
          "
          width="78.66666666666666vw"
          height="51.46666666666667vw"
          :src="photo1"
        ></v-img>

        <div>
          <p
            class="arp"
            style="font-size: 4.266666666666667vw; color: #2a3977; margin: 0"
          >
            الجراحة الانكسارية (أو جراحة تصحيح الرؤية) هي أي عملية جراحية تستخدم
            لتصحيح مشاكل الرؤية. وهناك العديد من العمليات الجراحية لتصحيح أو
            تعديل قدرة العين على التركيز من خلال إعادة تشكيل القرنية: وهي القبة
            المستديرة الواضحة في مقدمة عينيك. وتلعب القرنية دورًا هامًا في
            القدرة البصرية للعين. ومعظم أنواع جراحة تصحيح الرؤية تعيد تشكيل
            القرنية، الجزء الأمامي الواضح من عينيك، والذي يتيح للضوء الانتقال من
            خلالها والتركيز بشكل صحيح على الجزء الخلفي من العين، أو شبكية العين.
            <br /><br />
            وقد شهدت السنوات الأخيرة تقدمًا كبيرًا في هذا المجال. جراحة تصحيح
            الرؤية للعين والليزر تسمح للعديد من المرضى بالرؤية الأفضل من أي وقت
            آخر في حياتهم.
            <br /><br />
            وتتميز خدمة جراحة القرنية وجراحة تصحيح الرؤية في مركز الشامي للعيون
            بخبراتها التي لا مثيل لها وباستخدام المعدات الحديثة التي تسمح لها
            بتقديم العلاج الطبي والجراحي الممتاز لمعظم أمراض القرنية الروتينية
            والمعقدة والعالية المخاطر - والأمراض التي تهدد الرؤية. وتغطي خدماتنا
            قرحة القرنية، والأمراض الفيروسية الشديدة ومتلازمة جفاف العين، فضلاً
            عن أحدث إجراءات تصحيح الرؤية.
          </p>
        </div>
        <v-card outlined tile class="transparent" height="2.34375vw"></v-card>
        <v-img
          style="border-radius: 8vw; margin: 10.666666666666668vw 0"
          width="78.66666666666666vw"
          height="51.46666666666667vw"
          :src="photo2"
        ></v-img>

        <div>
          <p
            class="arp"
            style="font-size: 4.266666666666667vw; color: #2a3977; margin: 0"
          >
            إذا تأثرت رؤيتك نتيجة لقصر النظر (المبيوبيا)، أو بعد النظر
            (هايبروبيا)، أو الاستجماتيزم، فالأطباء لدينا سيقدمون لك المساعدة في
            اختيار الحل الصحيح لتحقيق أهداف الرؤية الخاصة بك. يقوم الأطباء لدينا
            بإجراء الآلاف من إجراءات تصحيح الرؤية سنويًا التي تتم على أساس
            العيادات الخارجية باستخدام التقنيات الأكثر تقدما والتكنولوجيا
            المتاحة اليوم.
            <br /><br />
            وتشمل الإجراءات الشائعة تصحيح الرؤية باستخدام إكسيمر الليزر من خلال
            استئصال القرنية عبر الخلايا الظهارية واستخدام الليزر في القرنية
            الموضعية (الليزك)، بالإضافة إلى إدخال العدسات اللاصقة القابلة للزرع
            . سوف نقدم لكم كافة الحقائق والموارد التي تحتاجونها لاتخاذ القرار
            الأفضل لحالتكم.
          </p>
        </div>
        <v-card
          outlined
          tile
          class="transparent"
          height="10.666666666666668vw"
        ></v-card>
        <v-card
          style="margin: 0 0 14.133333333333335vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-btn
            class="arp"
            width="70.13333333333334vw"
            height="10.133333333333333vw"
            depressed
            @click="overlay = !overlay"
            style="
              text-transform: none;
              font-size: 4.266666666666667vw;

              border-radius: 5.066666666666666vw;
              color: white;
            "
            color="#2A9AE5"
          >
            احجز موعد استشارة
          </v-btn>
        </v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform :clickbtn="btnclick" light> </Vform>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import photo from "../../assets/Services/VisionCorrection/Main-photo10.png";
import mphoto from "../../assets/Services/VisionCorrection/Group 458.png";
import photo1 from "../../assets/Services/VisionCorrection/Lasikpic1.png";
import photo2 from "../../assets/Services/VisionCorrection/Lasikpic2.png";
import Vform from "../../componentsar/Form";

export default {  
metaInfo: {
    title: "الشامي العيون",
  },
  name: "VisionCorrection",
  components: { Vform },
  data() {
    return {
      photo,
      mphoto,
      photo1,
      photo2,
      overlay: false,
    };
  },
  methods: {
    btnclick() {
      this.overlay = false;
    },
  },
};
</script>

<style>
</style>