<template>
  <div>
    <v-img height="35.078125vw" width="100vw" :src="img"></v-img>
    <v-card outlined tile class="transparent" height="4.609375vw"></v-card>
  </div>
</template>

<script>
export default {
  name: "imgpage",
  props: ["img"],
};
</script>

<style>
</style>