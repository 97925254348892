<template>
  <div>
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 15.859375vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977">CSR</h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <v-card outlined class="transparent">
          <h2 class="boldm" style="color: #2a9ae5">
            Our Social Responsibility
          </h2></v-card
        >
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <p style="color: #2a3977">
          In the context of our high sense of responsibility towards the local
          community, and in collaboration with governmental, nongovernmental
          organizations, academic institutions (universities &amp; schools) and
          various charitable organizations, Shami eye center team and its
          partners regularly offer their best quality services in the field of
          Ophthalmology &amp; Eye care. This is done in line with our efforts to
          support people living in underserved areas in the periphery of Jordan,
          and it is achieved through medical campaigns during which patients are
          whole-heartedly welcome to be diagnosed and receive appropriate
          treatment if needed.
        </p>
        <v-card outlined tile class="transparent" height="1.5625vw"></v-card>
        <v-card outlined class="transparent">
          <h2 class="boldm" style="color: #2a9ae5">
            Underserved Communities
          </h2></v-card
        >
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <p style="color: #2a3977">
          Most of us who are fortunate enough to be well-sighted may never have
          given a thought about this: But when we wake up every morning, the
          first thing we do is to open two of our greatest gifts – our eyes.
          <br /><br />
          For some, however, the gift of sight is something that permanently
          evades them. “Shami Eye Center” is characterized with its continuous
          commitment to the care of the less fortunate and those in need through
          the provision of highly specialized services to a broader community.
          <br /><br />
          Shami community outreach efforts include Medical campaigns in which
          free eye exams and medical advice is given to patients living in
          underserved areas in Jordan and the Middle East.
          <br /><br />
          Shami Eye Center holds many public educational activities to highlight
          the means for protecting eyes and available treatments for various
          conditions that impair vision.
        </p>

        <v-card outlined tile class="transparent" height="2.96875vw"></v-card>
      </div>
    </div>
    <!-- MOBILE -->
    <div class="hidden-md-and-up">
      <mimg-page :img="mphoto"></mimg-page>
      <div style="margin: 0 10.66666666vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977; font-size: 5.333333333333334vw">
            CSR
          </h1></v-card
        >

        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <h2
          style="color: #2a9ae5; margin: 0; font-size: 4.8vw"
          class="boldm titlea"
        >
          Our Social Responsibility
        </h2>

        <p
          style="
            color: #2a3977;
            font-size: 4.266666666666667vw;
            margin: 3.4666666666666663vw 0 9.066666666666666vw 0;
          "
        >
          In the context of our high sense of responsibility towards the local
          community, and in collaboration with governmental, nongovernmental
          organizations, academic institutions (universities &amp; schools) and
          various charitable organizations, Shami eye center team and its
          partners regularly offer their best quality services in the field of
          Ophthalmology &amp; Eye care. This is done in line with our efforts to
          support people living in underserved areas in the periphery of Jordan,
          and it is achieved through medical campaigns during which patients are
          whole-heartedly welcome to be diagnosed and receive appropriate
          treatment if needed.
        </p>
        <h2
          style="color: #2a9ae5; margin: 0; font-size: 4.8vw"
          class="boldm titlea"
        >
          Our Social Responsibility
        </h2>

        <p
          style="
            color: #2a3977;
            font-size: 4.266666666666667vw;
            margin: 3.4666666666666663vw 0 9.066666666666666vw 0;
          "
        >
          Most of us who are fortunate enough to be well-sighted may never have
          given a thought about this: But when we wake up every morning, the
          first thing we do is to open two of our greatest gifts – our eyes.
          <br /><br />
          For some, however, the gift of sight is something that permanently
          evades them. “Shami Eye Center” is characterized with its continuous
          commitment to the care of the less fortunate and those in need through
          the provision of highly specialized services to a broader community.
          <br /><br />
          Shami community outreach efforts include Medical campaigns in which
          free eye exams and medical advice is given to patients living in
          underserved areas in Jordan and the Middle East.
          <br /><br />
          Shami Eye Center holds many public educational activities to highlight
          the means for protecting eyes and available treatments for various
          conditions that impair vision.
        </p>

        <v-card
          outlined
          tile
          class="transparent"
          height="10.666666666666668vw"
        ></v-card>
      </div>
    </div>
  </div>
</template>

<script>
import photo from "../../assets/news/CSR/mask_group_20.png";
import mphoto from "../../assets/news/CSR/Group 465.png";

export default {
  data() {
    return {
      photo,
      mphoto,
      overlay: false,
    };
  },
};
</script>

<style>
</style>