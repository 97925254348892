<template>
  <div>
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 18.90625vw 10.390625vw 18.90625vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977">Insurance Companies</h1></v-card
        >
        <v-card outlined tile class="transparent" height="4.21875vw"></v-card>
        <v-row no-gutters justify="center">
          <v-col
            align-self="center"
            class="cols5"
            v-for="(company, i) in companies"
            :key="i"
          >
            <v-card
              class="d-flex justify-center align-center flip-card mb-3"
              height="11.796875vw"
              width="11.5625vw"
              style="
                border-radius: 2.65625vw;
                box-shadow: 0 0 1.171875vw 0.15625vw #dcdcdc !important;
              "
              @mouseenter="company.active = true"
              @mouseleave="company.active = false"
              align="center"
            >
              <div class="flip-card-inner">
                <div v-if="!company.active" class="flip-card-front">
                  <v-img contain :src="company.logo"></v-img>
                </div>

                <div
                  style="padding: 0 0.8203125vw"
                  v-if="company.active"
                  class="flip-card-back"
                >
                  <h6
                    style="color: #2a9ae5"
                    class="boldm"
                    v-html="company.name"
                  ></h6>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- MOBILE -->
    <div class="hidden-md-and-up">
      <mimg-page :img="mphoto"></mimg-page>
      <div style="margin: 0 10.2vw 10.390625vw 10.2vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977; font-size: 5.333333333333334vw">
            Insurance Companies
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="4.21875vw"></v-card>
        <v-row>
          <v-col
            align-self="center"
            cols="6"
            v-for="(company, i) in companies"
            :key="i"
          >
            <v-card
              class="d-flex justify-center align-center flip-card"
              min-height="40.266666666666666vw"
              min-width="39.46666666666667vw"
              style="
                border-radius: 9.066666666666666vw;
                box-shadow: 0 0 20px 2px #dcdcdc !important;
                margin: 0 0 -2.666666666666667vw 0 !important;
              "
              @click.native="company.active = !company.active"
              align="center"
            >
              <div class="flip-card-inner">
                <div v-if="!company.active" class="flip-card-front">
                  <v-img contain :src="company.logo"></v-img>
                </div>

                <div
                  style="padding: 0 0.8203125vw"
                  v-if="company.active"
                  class="flip-card-back"
                >
                  <h6
                    style="color: #2a9ae5; font-size: 4.266666666666667vw"
                    class="boldm"
                    v-html="company.name"
                  ></h6>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import photo from "../assets/Insurance/Main-photo7.png";
import mphoto from "../assets/Insurance/Group 455.png";
import Logo1 from "../assets/Insurance/Logo1.png";
import Logo2 from "../assets/Insurance/Logo2.png";
import Logo3 from "../assets/Insurance/Logo3.png";
import Logo4 from "../assets/Insurance/Logo4.png";
import Logo5 from "../assets/Insurance/Logo5.png";
import Logo6 from "../assets/Insurance/Logo6.png";
import Logo7 from "../assets/Insurance/Logo7.png";
import Logo8 from "../assets/Insurance/Logo8.png";
import Logo9 from "../assets/Insurance/Logo9.png";
import Logo10 from "../assets/Insurance/Logo10.png";
import Logo11 from "../assets/Insurance/Logo11.png";
import Logo12 from "../assets/Insurance/Logo12.png";
import Logo13 from "../assets/Insurance/Logo13.png";
import Logo14 from "../assets/Insurance/Logo14.png";
import Logo15 from "../assets/Insurance/Logo15.png";
import Logo16 from "../assets/Insurance/Logo16.png";
import Logo17 from "../assets/Insurance/Logo17.png";
import Logo18 from "../assets/Insurance/Logo18.png";
import Logo19 from "../assets/Insurance/Logo19.png";
import Logo20 from "../assets/Insurance/Logo20.png";
import Logo21 from "../assets/Insurance/Logo21.png";
import Logo22 from "../assets/Insurance/Logo22.png";
import Logo23 from "../assets/Insurance/Logo23.png";
import Logo24 from "../assets/Insurance/Logo24.png";
import Logo25 from "../assets/Insurance/Logo25.png";
import Logo26 from "../assets/Insurance/Logo26.png";
import Logo27 from "../assets/Insurance/Logo27.png";
import Logo28 from "../assets/Insurance/Logo28.png";
import Logo29 from "../assets/Insurance/Logo29.png";
import Logo30 from "../assets/Insurance/Logo30.png";
import Logo31 from "../assets/Insurance/Logo31.png";
import Logo32 from "../assets/Insurance/Logo32.png";
import Logo33 from "../assets/Insurance/Logo33.png";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Shami Eye | Insurance Companies",
    meta: [
      {
        name: "description",
        content: "List of Insurance Companies that Shami Eye Supports",
      },
    ],
    // all titles will be injected into this template
  },
  data() {
    return {
      photo,
      mphoto,
      companies: [
        { active: false, name: "GIG Jordan", logo: Logo1 },
        { active: false, name: "Nat Health", logo: Logo2 },
        { active: false, name: "Solidarity First Insurance", logo: Logo3 },
        { active: false, name: "Yarmouk University", logo: Logo4 },
        {
          active: false,
          name: "Jordan Petroleum Refinery Co. <br/>  Ltd.",
          logo: Logo5,
        },
        { active: false, name: "Central Bank <br/> of Jordan", logo: Logo6 },
        {
          active: false,
          name: "Arab Jordan Investment <br/> Bank <br/> (AJIB)",
          logo: Logo7,
        },
        { active: false, name: "Med. Service", logo: Logo8 },
        { active: false, name: "Mednet", logo: Logo9 },
        {
          active: false,
          name: "Jordan <br/> Medical Association",
          logo: Logo10,
        },
        { active: false, name: "Arab ASsurers Insurance Co.", logo: Logo11 },
        { active: false, name: "Cableco <br/> Jordan", logo: Logo12 },
        {
          active: false,
          name: "Central Electricity Generating  Co. (CEGCO)",
          logo: Logo13,
        },
        {
          active: false,
          name: "Jordan <br/> Electric Power Company",
          logo: Logo14,
        },
        {
          active: false,
          name: "National Electric Power <br/> Co. <br/> (NEPCO)",
          logo: Logo15,
        },
        {
          active: false,
          name: "Islamic International Arab Bank",
          logo: Logo16,
        },
        { active: false, name: "The Islamic Insurance Co.", logo: Logo17 },
        {
          active: false,
          name: "Jordan Phosphate <br/> Mines Co. <br/> (JPMC)",
          logo: Logo18,
        },
        { active: false, name: "GlobeMed <br/> Jordan", logo: Logo19 },
        { active: false, name: "Medi Visa", logo: Logo20 },
        {
          active: false,
          name: "Royalty Insurance Management <br/> Co.",
          logo: Logo21,
        },
        {
          active: false,
          name: "Al-Nisr <br/> Al-Arabi <br/> Insurance",
          logo: Logo22,
        },
        {
          active: false,
          name: "Al-Balqa <br/> Applied University",
          logo: Logo23,
        },
        { active: false, name: "Arab Potash", logo: Logo24 },
        { active: false, name: "Jordan <br/> Customs", logo: Logo25 },
        { active: false, name: "Jordan Engineers Association", logo: Logo26 },
        { active: false, name: "Jordanian Bar Association", logo: Logo27 },
        { active: false, name: "Newton Insurance", logo: Logo28 },
        {
          active: false,
          name: "Electricity Distribution Co. (EDCO)",
          logo: Logo29,
        },
        {
          active: false,
          name: "Omni Care Health Insurance Administration Company",
          logo: Logo30,
        },
        {
          active: false,
          name: "Indo-Jordan Chemicals Company Limited",
          logo: Logo31,
        },
        {
          active: false,
          name: "Samra Electric Power Co. (SEPCO)",
          logo: Logo32,
        },
        { active: false, name: "Euro Arab Insurance", logo: Logo33 },
      ],
    };
  },
};
</script>

<style>
.cols5 {
  width: 20%;
  max-width: 20%;
  flex-basis: 20%;
}
</style>