<template>
  <div>
    <div v-if="!isAr">
      <v-footer
        style="padding: 5.78125vw 9.375vw"
        width="100vw"
        color="#EFF6F9"
      >
        <v-row>
          <v-col cols="4">
            <v-card outlined class="transparent" align="center">
              <v-card outlined class="transparent">
                <h1
                  style="font-size: 1.953125vw; margin: 0 0 0 0; color: #2a3977"
                >
                  Schedule <br />An Appointment
                </h1>
              </v-card>
              <v-btn
                height="4.21875vw"
                width="21.015625vw"
                depressed
                to="/contact"
                color="#2A9AE5"
                style="
                  text-transform: none;
                  margin: 2.109375vw 0;
                  border-radius: 2.421875vw;
                  color: #2a9ae5 !important;
                "
                class="white--text btntxt"
              >
                <span
                  style="margin: 0 0 0 0; font-size: 2.03125vw; color: white"
                  >Call Now</span
                >
              </v-btn>
              <v-card outlined class="d-flex justify-center transparent">
                <v-card outlined class="transparent">
                  <a
                    href="https://www.facebook.com/ShamiEyeCenter/"
                    target="_blank"
                  >
                    <v-img
                      width="3.671875vw"
                      height="3.671875vw"
                      :src="p2"
                    ></v-img>
                  </a>
                </v-card>
                <v-card
                  outlined
                  class="transparent"
                  width="0.546875vw"
                ></v-card>
                <v-card outlined class="transparent">
                  <a
                    href="https://www.instagram.com/shamieye/?hl=en"
                    target="_blank"
                  >
                    <v-img width="3.75vw" height="3.671875vw" :src="p3"></v-img>
                  </a>
                </v-card>
                <v-card
                  outlined
                  class="transparent"
                  width="0.546875vw"
                ></v-card>
                <v-card outlined class="transparent">
                  <a
                    href="https://jo.linkedin.com/in/shami-eye-center-21360346"
                    target="_blank"
                  >
                    <v-img
                      width="3.8281250000000004vw"
                      height="3.671875vw"
                      :src="p4"
                    ></v-img>
                  </a>
                </v-card>
                <v-card
                  outlined
                  class="transparent"
                  width="0.546875vw"
                ></v-card>
                <v-card outlined class="transparent">
                  <a
                    href="https://twitter.com/shamieyecenter?lang=en"
                    target="_blank"
                  >
                    <v-img width="3.75vw" height="3.671875vw" :src="p7"></v-img>
                  </a>
                </v-card>
                <v-card
                  outlined
                  class="transparent"
                  width="0.546875vw"
                ></v-card>
                <v-card outlined class="transparent">
                  <a
                    href="https://www.youtube.com/channel/UCE1QpIxda4Ua2xVJqTfd19w"
                    target="_blank"
                  >
                    <v-img
                      width="3.671875vw"
                      height="3.671875vw"
                      :src="p8"
                    ></v-img>
                  </a>
                </v-card>
              </v-card>
            </v-card>
          </v-col>
          <v-col cols="5">
            <v-card
              style="margin: 0 0 0 3.515625vw"
              outlined
              class="transparent"
            >
              <v-card outlined class="transparent">
                <h2
                  style="font-size: 1.640625vw; margin: 0; color: #2a3977"
                  class="boldm"
                >
                  Contact Us
                </h2>
              </v-card>
              <v-card outlined class="transparent" height="2.109375vw"></v-card>
              <v-card outlined class="d-flex transparent">
                <v-card outlined class="transparent">
                  <v-img
                    height="2.67109375vw"
                    width="2.1046875vw"
                    :src="p5"
                  ></v-img>
                </v-card>
                <v-card outlined class="transparent" width="1.5625vw"></v-card>
                <p style="color: #2a3977; font-size: 1.09375vw; margin: 0">
                  <span class="boldm">Amman Main Branch</span><br />
                  Building No.53 <br />
                  Princess Sumaya Bint Al-Hasan Street, <br />
                  7th Circle, Amman, Jordan <br />
                </p>
              </v-card>
              <v-card outlined class="d-flex transparent">
                <v-card outlined class="transparent">
                  <br />
                  <v-img
                    height="2.12578125vw"
                    width="2.12578125vw"
                    :src="p6"
                  ></v-img>
                </v-card>
                <v-card outlined class="transparent" width="1.5625vw"></v-card>
                <p style="color: #2a3977; font-size: 1.09375vw; margin: 0">
                  <br />
                  T: 00962 06 586 6969 <br />
                  M: 00962 79 685 6010<br />
                  <br />
                </p>
              </v-card>
              <v-card outlined class="d-flex transparent">
                <v-card outlined class="transparent">
                  <v-img
                    height="1.71171875vw"
                    width="2.578125vw"
                    :src="p1"
                  ></v-img>
                </v-card>
                <v-card outlined class="transparent" width="1.5625vw"></v-card>
                <p
                  style="
                    color: #2a3977;
                    font-size: 1.09375vw;
                    margin: 0.1171875vw 0 0 -0.390625vw;
                  "
                >
                  Customer@shamieye.com
                </p>
              </v-card>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card style="margin: 0 0 0 1.5625vw" outlined class="transparent">
              <v-card outlined class="transparent">
                <h2
                  style="font-size: 1.640625vw; margin: 0; color: #2a3977"
                  class="boldm"
                >
                  Openning Hours
                </h2>
              </v-card>
              <v-card outlined class="transparent" height="2.109375vw"></v-card>
              <v-card outlined class="d-flex transparent">
                <v-card outlined class="transparent"> </v-card>
                <p style="color: #2a3977; font-size: 1.09375vw; margin: 0">
                  Saturday to Wednesday <br />
                  9:00 - 17:00 <br />
                  <br />
                  Thursday<br />
                  9:00 - 14:00<br /><br />
                  Friday<br />
                  Closed
                </p>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
      </v-footer>
    </div>
    <!-- ARABIC -->
    <div v-if="isAr">
      <v-footer
        style="padding: 5.78125vw 9.375vw"
        width="100vw"
        color="#EFF6F9"
      >
        <v-row>
          <v-col cols="3">
            <v-card style="margin: 0 0 0 0" outlined class="transparent">
              <v-card align="right" outlined class="transparent">
                <h2
                  style="font-size: 1.640625vw; margin: 0; color: #2a3977"
                  class="boldm ar"
                >
                  ساعات الدوام
                </h2>
              </v-card>
              <v-card outlined class="transparent" height="2.109375vw"></v-card>
              <v-card outlined align="right" class="transparent">
                <v-card outlined class="transparent"> </v-card>
                <p style="color: #2a3977; font-size: 1.09375vw; margin: 0">
                  <span class="ar">السبت إلى الأربعاء</span> <br />
                  17:00 - 9:00 <br />
                  <br />
                  <span class="ar">الخميس</span><br />
                  14:00 - 9:00<br /><br />
                  <span class="ar"
                    >الجمعة<br />
                    مغلق
                  </span>
                </p>
              </v-card>
            </v-card>
          </v-col>

          <v-col cols="5">
            <v-card
              style="margin: 0 3.515625vw 0 0"
              outlined
              class="transparent"
            >
              <v-card align="right" outlined class="transparent">
                <h2
                  style="font-size: 1.640625vw; margin: 0; color: #2a3977"
                  class="boldm ar"
                >
                  تواصل معنا
                </h2>
              </v-card>
              <v-card outlined class="transparent" height="2.109375vw"></v-card>
              <v-card outlined class="d-flex transparent justify-end">
                <v-card outlined class="transparent" align="right">
                  <p style="color: #2a3977; font-size: 1.09375vw; margin: 0">
                    <span class="boldm ar">عنوان الفرع الرئيسي</span><br />
                    <span class="ar">
                      عمّان، الدوار السابع <br />
                      شارع الأميرة سمية بنت <br />
                    </span>
                    <span>53</span>
                    <span class="ar"> الحسن مبنى رقم</span>
                  </p>
                </v-card>
                <v-card outlined class="transparent" width="1.5625vw"></v-card>

                <v-card outlined class="transparent">
                  <v-img
                    height="2.67109375vw"
                    width="2.1046875vw"
                    :src="p5"
                  ></v-img>
                </v-card>
              </v-card>
              <v-card outlined class="d-flex transparent justify-end">
                <p
                  style="
                    color: #2a3977;
                    font-size: 1.09375vw;
                    margin: 0;
                    text-align: right;
                  "
                >
                  <br />
                  <span class="ar"> الهاتف</span>
                  <span class="mr-4"> 6969 586 06 00962</span> <br />
                  <span class="ar">المحمول</span>
                  <span> 6010 685 79 00962 </span><br />
                  <br />
                </p>
                <v-card outlined class="transparent" width="1.5625vw"></v-card>

                <v-card outlined class="transparent">
                  <br />
                  <v-img
                    height="2.12578125vw"
                    width="2.12578125vw"
                    :src="p6"
                  ></v-img>
                </v-card>
              </v-card>
              <v-card outlined class="d-flex transparent justify-end">
                <p
                  style="
                    color: #2a3977;
                    font-size: 1.09375vw;
                    margin: 0.1171875vw 0 0 -0.390625vw;
                  "
                >
                  Customer@shamieye.com
                </p>
                <v-card outlined class="transparent" width="1.5625vw"></v-card>

                <v-card outlined class="transparent">
                  <v-img
                    height="1.71171875vw"
                    width="2.578125vw"
                    :src="p1"
                  ></v-img>
                </v-card>
              </v-card>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card outlined class="transparent" align="center">
              <v-card outlined class="transparent">
                <h1
                  class="ar"
                  style="font-size: 1.953125vw; margin: 0 0 0 0; color: #2a3977"
                >
                  <span> !</span>
                  احجزموعد استشارة
                </h1>
              </v-card>
              <v-btn
                height="4.21875vw"
                width="21.015625vw"
                depressed
                to="/contact"
                color="#2A9AE5"
                style="
                  text-transform: none;
                  margin: 2.109375vw 0;
                  border-radius: 2.421875vw;
                "
                class="white--text btntxt"
              >
                <span class="ar" style="margin: 0 0 0 0; font-size: 2.03125vw"
                  >اتصل بنا</span
                >
              </v-btn>
              <v-card outlined class="d-flex justify-center transparent">
                <v-card outlined class="transparent">
                  <a
                    href="https://www.facebook.com/ShamiEyeCenter/"
                    target="_blank"
                  >
                    <v-img
                      width="3.671875vw"
                      height="3.671875vw"
                      :src="p2"
                    ></v-img>
                  </a>
                </v-card>
                <v-card
                  outlined
                  class="transparent"
                  width="0.546875vw"
                ></v-card>
                <v-card outlined class="transparent">
                  <a
                    href="https://www.instagram.com/shamieye/?hl=en"
                    target="_blank"
                  >
                    <v-img width="3.75vw" height="3.671875vw" :src="p3"></v-img>
                  </a>
                </v-card>
                <v-card
                  outlined
                  class="transparent"
                  width="0.546875vw"
                ></v-card>
                <v-card outlined class="transparent">
                  <a
                    href="https://jo.linkedin.com/in/shami-eye-center-21360346"
                    target="_blank"
                  >
                    <v-img
                      width="3.8281250000000004vw"
                      height="3.671875vw"
                      :src="p4"
                    ></v-img>
                  </a>
                </v-card>
                <v-card
                  outlined
                  class="transparent"
                  width="0.546875vw"
                ></v-card>
                <v-card outlined class="transparent">
                  <a
                    href="https://twitter.com/shamieyecenter?lang=en"
                    target="_blank"
                  >
                    <v-img width="3.75vw" height="3.671875vw" :src="p7"></v-img>
                  </a>
                </v-card>
                <v-card
                  outlined
                  class="transparent"
                  width="0.546875vw"
                ></v-card>
                <v-card outlined class="transparent">
                  <a
                    href="https://www.youtube.com/channel/UCE1QpIxda4Ua2xVJqTfd19w"
                    target="_blank"
                  >
                    <v-img
                      width="3.671875vw"
                      height="3.671875vw"
                      :src="p8"
                    ></v-img>
                  </a>
                </v-card>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
      </v-footer>
    </div>
  </div>
</template>

<script>
import p1 from "../assets/footer/Email-icon.png";
import p2 from "../assets/footer/Facebook-icon.png";
import p3 from "../assets/footer/Instagram-icon.png";
import p4 from "../assets/footer/Linkedin-icon.png";
import p5 from "../assets/footer/Location-icon.png";
import p6 from "../assets/footer/Phone-icon.png";
import p7 from "../assets/footer/Twitter-icon.png";
import p8 from "../assets/footer/Youtube-icon.png";

export default {
  data() {
    return {
      isAr: sessionStorage.getItem("lang"),
      active: false,
      p1,
      p2,
      p3,
      p4,
      p5,
      p6,
      p7,
      p8,
    };
  },
};
</script>