<template>
  <div>
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 15.859375vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977">Glaucoma</h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <v-card outlined class="d-flex transparent">
          <v-card
            outlined
            class="transparent"
            align="center"
            style="margin: 0 1.5625vw 0 0"
          >
            <v-img
              style="margin: 0 0 1.640625vw 0"
              contain
              height="21.875vw"
              width="33.515625vw"
              :src="g1"
            ></v-img>
            <span class="ptitle">Normal Field Of Vision</span>
          </v-card>
          <v-card outlined class="transparent" align="center">
            <v-img
              style="margin: 0 0 1.640625vw 0"
              contain
              height="21.875vw"
              width="33.515625vw"
              :src="g2"
            ></v-img>
            <p class="ptitle">
              Normal Field Of Vision <br />
              Decreased from Glaucoma
            </p>
          </v-card>
        </v-card>
        <v-card outlined tile class="transparent" height="0.390625vw"></v-card>
        <v-card outlined class="transparent"
          ><h2 class="boldm subtitlem" style="color: #2a9ae5">
            What is Glaucoma?
          </h2></v-card
        >
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <p style="color: #2a3977">
          It is a group of disorders in which the main risk factor is high fluid
          pressure within the eye.<br />
          All glaucoma disorders are characterized by vision loss, caused by
          damage to the optic nerve.
        </p>
        <v-card outlined tile class="transparent" height="1.5625vw"></v-card>
        <v-card outlined class="transparent"
          ><h2 class="boldm subtitlem" style="color: #2a9ae5">
            How to treat Glaucoma?
          </h2></v-card
        >
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <p style="color: #2a3977; margin: 0 -0.5% 0 0">
          <span class="sboldm">
            If a doctor discovers glaucoma early and the patient follows
            directions carefully, the damaging effects of glaucoma can be
            addressed.</span
          >
          If you have glaucoma, your doctor can keep the pressure within your
          eye to a normal level with careful treatment. It is important to have
          your eye doctor check your pressure regularly so that he or she can
          prescribe proper treatment (Intraocular pressure lowering eye drops,
          Laser Trabeculoplasty or valve insertion). This way, you can prevent
          further loss of vision.
        </p>
        <v-card outlined tile class="transparent" height="3.515625vw"></v-card>
        <v-img
          style="margin: 0 0 3.515625vw 0"
          contain
          height="20.78125vw"
          width="75.859375vw"
          :src="g3"
        ></v-img>
        <v-row no-gutters>
          <v-col cols="5">
            <v-card outlined class="transparent" align="center">
              <p
                style="font-size: 1.171875vw; color: #204170; margin: -7% 0 0 0"
              >
                <span class="sboldm"> Normal Eye </span>
              </p>
            </v-card>
          </v-col>
          <v-col>
            <v-card outlined class="transparent" align="center" cols="7">
              <p
                class="sboldm"
                style="
                  font-size: 1.171875vw;
                  color: #204170;
                  margin: -5% 0 0 5%;
                "
              >
                <span class="sboldm"> Eye With Glaucoma </span>
              </p>
            </v-card></v-col
          >
        </v-row>
        <v-card outlined tile class="transparent" height="3.515625vw"></v-card>
        <v-card outlined tile class="transparent">
          <h2 style="color: #2a9ae5" class="titlea boldm">
            Nero Ophthalmology
          </h2>
        </v-card>
        <v-card outlined tile class="transparent" height="1.484375vw"></v-card>
        <vue-list
          :items="items1"
          color="#2A3977 !important"
          :lh="18"
        ></vue-list>
        <v-card outlined class="transparent" style="margin: 0 0 0 3.671875vw">
          <vue-list
            :items="subitems"
            color="#2A9AE5 !important"
            :bullet="true"
            :lh="18"
          ></vue-list>
        </v-card>
        <vue-list
          :items="items2"
          color="#2A3977 !important"
          :lh="18"
        ></vue-list>
        <v-card outlined tile class="transparent" height="3.125vw"></v-card>
        <v-card
          style="margin: 0 0 4.609375vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-btn
            @click="overlay = !overlay"
            width="35.78125vw"
            height="4.84375vw"
            depressed
            style="
              text-transform: none;
              font-size: 2.03125vw;
              letter-spacing: -1.5px !important;
              border-radius: 2.421875vw;
              color: white;
            "
            color="#2A9AE5"
          >
            Schedule An Appointment
          </v-btn>
        </v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform light>
          <v-btn
            @click="overlay = !overlay"
            height="3.28125vw"
            width="12.968750000000002vw"
            depressed
            color="#2A9AE5"
            style="
              text-transform: none;
              border-radius: 3.359375vw;
              margin: 0 0 0 14px;
            "
            class="white--text btntxt"
          >
            <span style="margin: 0 0 0 0; font-size: 1.25vw">Cancel</span>
          </v-btn>
        </Vform>
      </v-overlay>
    </div>
    <!-- Mobile -->
    <div class="hidden-md-and-up">
      <mimg-page :img="mphoto"></mimg-page>
      <div style="margin: 0 10.666666666666668vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977; font-size: 5.333333333333334vw">
            Galucoma
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <v-card outlined class="transparent">
          <v-card
            outlined
            class="transparent"
            align="center"
            style="margin: 0 0 0 0"
          >
            <v-img
              style="margin: 0 0 0 0"
              contain
              height="51.46666666666667vw"
              width="78.66666666666666vw"
              :src="g1"
            ></v-img>
            <v-card
              class="transparent"
              outlined
              style="margin: 3.733333333333334vw 0 6.933333333333333vw 0"
            >
              <span style="font-size: 4vw" class="ptitle"
                >Normal Field Of Vision</span
              >
            </v-card>
          </v-card>
          <v-card outlined class="transparent" align="center">
            <v-img
              style="margin: 0 0 0 0"
              contain
              height="51.46666666666667vw"
              width="78.66666666666666vw"
              :src="g2"
            ></v-img>
            <v-card
              class="transparent"
              outlined
              style="margin: 3.733333333333334vw 0 6.933333333333333vw 0"
            >
              <p style="font-size: 4vw" class="ptitle">
                Normal Field Of Vision <br />
                Decreased from Glaucoma
              </p>
            </v-card>
          </v-card>
        </v-card>
        <v-card outlined class="transparent"
          ><h2
            class="boldm subtitlem"
            style="color: #2a9ae5; font-size: 4.8vw; margin: 0"
          >
            What is Glaucoma?
          </h2></v-card
        >
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <p style="color: #2a3977; font-size: 4.266666666666667vw">
          It is a group of disorders in which the main risk factor is high fluid
          pressure within the eye.<br />
          All glaucoma disorders are characterized by vision loss, caused by
          damage to the optic nerve.
        </p>
        <v-card outlined tile class="transparent" height="1.5625vw"></v-card>
        <v-card outlined class="transparent"
          ><h2
            class="boldm subtitlem"
            style="color: #2a9ae5; font-size: 4.8vw; margin: 0"
          >
            How to treat Glaucoma?
          </h2></v-card
        >
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <p
          style="
            color: #2a3977;
            font-size: 4.266666666666667vw;
            margin: 0 0 0 0;
          "
        >
          <span class="sboldm">
            If a doctor discovers glaucoma early and the patient follows
            directions carefully, the damaging effects of glaucoma can be
            addressed.</span
          >
          If you have glaucoma, your doctor can keep the pressure within your
          eye to a normal level with careful treatment. It is important to have
          your eye doctor check your pressure regularly so that he or she can
          prescribe proper treatment (Intraocular pressure lowering eye drops,
          Laser Trabeculoplasty or valve insertion). This way, you can prevent
          further loss of vision.
        </p>
        <v-card outlined tile class="transparent" height="3.515625vw"></v-card>
        <v-card outlined class="transparent" align="center">
          <v-img
            width="45.33333333333333vw"
            height="34.13333333333333vw"
            :src="g1m"
          ></v-img>
          <p
            class="sboldm"
            style="margin: 1% 0 0 0; font-size: 4vw; color: #204170"
          >
            Normal Eye
          </p>
        </v-card>
        <v-card outlined tile class="transparent" height="3.515625vw"></v-card>
        <v-card outlined class="transparent" align="center">
          <v-img
            width="83.46666666666667vw"
            height="34.13333333333333vw"
            :src="g2m"
          ></v-img>
          <p
            class="sboldm"
            style="margin: 1% 0 0 0; font-size: 4vw; color: #204170"
          >
            Eye With Glaucoma
          </p>
        </v-card>

        <v-card outlined tile class="transparent" height="3.515625vw"></v-card>
        <v-card outlined tile class="transparent">
          <h2
            style="color: #2a9ae5; font-size: 4.8vw; margin: 0"
            class="titlea boldm"
          >
            Nero Ophthalmology
          </h2>
        </v-card>
        <v-card outlined tile class="transparent" height="1.484375vw"></v-card>
        <vue-list
          :items="items1"
          color="#2A3977 !important"
          :lh="18"
        ></vue-list>
        <v-card outlined class="transparent" style="margin: 0 0 0 3.671875vw">
          <vue-list
            :items="subitems"
            color="#2A9AE5 !important"
            :bullet="true"
            :lh="18"
          ></vue-list>
        </v-card>
        <vue-list
          :items="items2"
          color="#2A3977 !important"
          :lh="18"
        ></vue-list>
        <v-card
          outlined
          tile
          class="transparent"
          height="10.666666666666668vw"
        ></v-card>
        <v-card
          style="margin: 0 0 14.133333333333335vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-btn
            width="70.13333333333334vw"
            height="10.133333333333333vw"
            depressed
            @click="overlay = !overlay"
            style="
              text-transform: none;
              font-size: 4.266666666666667vw;
              letter-spacing: -1.5px !important;
              border-radius: 5.066666666666666vw;
              color: white;
            "
            color="#2A9AE5"
          >
            Schedule An Appointment
          </v-btn>
        </v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform :clickbtn="btnclick" light> </Vform>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import photo from "../../assets/Services/Glaucoma/Main-photo9.png";
import mphoto from "../../assets/Services/Glaucoma/Group 457.png";
import g1m from "../../assets/Services/Glaucoma/Mask Group 50.png";
import g2m from "../../assets/Services/Glaucoma/Mask Group 51.png";
import g1 from "../../assets/Services/Glaucoma/glaucomapic1.png";
import g2 from "../../assets/Services/Glaucoma/glaucomapic2.png";
import g3 from "../../assets/Services/Glaucoma/glaucomapic3.png";
import Vform from "../../components/Form";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Shami Eye | Glaucoma",
    meta: [
      {
        name: "description",
        content:
          "It is a group of disorders in which the main risk factor is high fluid pressure within the eye.",
      },
    ],
    // all titles will be injected into this template
  },
  components: { Vform },
  data() {
    return {
      photo,
      mphoto,
      g1m,
      g2m,
      overlay: false,
      g1,
      g2,
      g3,
      items1: [
        { item: "Optic nerve Dysfunction and ischemic optic neuropathy" },
        { item: "Cranial Nerves problems:" },
      ],
      subitems: [
        { item: "(in particular) the 3rd cranial nerve" },
        { item: "The 4th cranial nerve" },
        { item: "The 5th cranial nerve" },
      ],
      items2: [
        { item: "Migraines headache related to eyes" },
        { item: "Nystagmus" },
        { item: "Visual field assessment" },
        { item: "Diplopia" },
        { item: "Ophthalmoplegia" },
        { item: "Optic Neuritis related to Multiple sclerosis" },
        { item: "Intracranial hypertension optic nerve sheath fenestration." },
        {
          item:
            "Compressive optic neuropathy due to <span class='boldm'> intracranial </span> and <span class='boldm'> intraorbital </span> tumors",
        },
      ],
    };
  },
  methods: {
    btnclick() {
      this.overlay = false;
    },
  },
};
</script>

<style>
</style>