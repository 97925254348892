

<template>
  <div>
    <img src="../Requirements/IMG/Body.png" class="Body-Background" />
    <img
      src="../Requirements/IMG/Logo.png"
      class="Logo animate__animated animate__backInLeft animate__delay-2s"
    />

    <div class="LoginGH">
      <div class="LoginBanner">
        <img
          src="../Requirements/IMG/LoginBanner.png"
          class="animate__animated animate__bounceInDown"
        />
        <form class="animate__animated animate__backInUp animate__delay-1s">
          <div class="FormRow">
            <label> الإسم الكامل : </label>
            <input type="text" />
            <label> Full name : </label>
          </div>
          <div class="FormRow">
            <label> رقم الهاتف : </label>
            <input type="text" />
            <label> Phone number : </label>
          </div>
          <div class="FormRow">
            <label> البريد الإلكتروني : </label>
            <input type="text" />
            <label> Email address : </label>
          </div>
          <div class="FormRow">
            <label> الجنس : </label>
            <select>
              <option disabled selected></option>
              <option>Option 1</option>
              <option>Option 2</option>
              <option>Option 3</option>
            </select>
            <label> Gender : </label>
          </div>
          <div class="FormRow">
            <label> العمر : </label>
            <input type="text" />
            <label> age : </label>
          </div>
          <div class="FormRow">
            <label> بتلبس نظارة ؟ : </label>
            <select>
              <option disabled selected></option>
              <option>Yes / نعم</option>
              <option>NO / لا</option>
            </select>
            <label> do you wear glasses : </label>
          </div>
          <div class="FormRow">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>

    <h2 class="Credit">By Shami Eye</h2>
  </div>
</template>

<script>
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Shami Eye | Zoom Meeting",
    meta: [
      {
        name: "description",
        content: "Zoom Meeting",
      },
    ],
    // all titles will be injected into this template
  },
};
</script>


<style>
@import "../Requirements/CSS/bootstrap.min.css";
@import "https://use.fontawesome.com/releases/v5.7.2/css/all.css";
@import "../Requirements/CSS/Animation.css";
@import "../Requirements/CSS/slick.css";
@import "../Requirements/CSS/style.css";
@import "../Requirements/CSS/mobile.css";
</style>
