

<template>
  <div id="main-img">
    <a
      class="b6"
      href="https://forms.gle/R8pMHojwMLNNj8Dw9"
      target="_blank"
      style=""
    >
      anchor
    </a>

    <a
      class="b7"
      href="https://www.youtube.com/channel/UCE1QpIxda4Ua2xVJqTfd19w"
      style=""
    >
      a
    </a>

    <a class="b8" href="https://www.instagram.com/shamieye/" style=""> a </a>

    <a class="b9" href="www.shamieye.com" style=""> a </a>

    <a class="b10" href="https://www.facebook.com/ShamiEyeCenter/" style="">
      a
    </a>
  </div>
</template>

<script>
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Shami Eye | Awareness",
    meta: [
      {
        name: "description",
        content: "Awareness",
      },
    ],
    // all titles will be injected into this template
  },
};
</script>


<style>
#main-img {
  background-image: url("../assets/lpm.jpg");
  background-size: 100vw;
  height: 545vw;
}

.b6 {
  position: absolute;
  top: 505vw;
  left: 24.5vw;
  cursor: pointer;
  opacity: 0;
  height: 19vw;
  width: 55vw;
}

.b7 {
  position: absolute;
  top: 530vw;
  left: 29vw;
  cursor: pointer;
  opacity: 0;
  height: 9vw;
  width: 7vw;
}

.b8 {
  position: absolute;
  top: 530vw;
  left: 42vw;
  cursor: pointer;
  opacity: 0;
  height: 9vw;
  width: 7vw;
}

.b9 {
  position: absolute;
  top: 530vw;
  left: 56vw;
  cursor: pointer;
  opacity: 0;
  height: 9vw;
  width: 7vw;
}

.b10 {
  position: absolute;
  top: 530vw;
  left: 69vw;
  cursor: pointer;
  opacity: 0;
  height: 9vw;
  width: 7vw;
}
</style>
