import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import MTT from '../views/MeetTheTeam.vue'
import IC from '../views/InsuranceCompanies.vue'
import Careers from '../views/Careers.vue'
import FAQ from '../views/FAQ.vue'
import Aqaba from '../views/aqaba/Aqaba.vue'
import Glaucoma from '../views/Services/Glaucoma.vue'
import VisionCorrection from '../views/Services/VisionCorrection.vue'
import Cataract from '../views/Services/Cataract.vue'
import Retina from '../views/Services/Retina.vue'
import Cornea from '../views/Services/Cornea.vue'
import Pediatric from '../views/Services/Pediatric.vue'
import Ophthalmic from '../views/Services/Ophthalmic.vue'
import Post from '../views/Services/Post.vue'
import Gallery from '../views/gallery/Gallery.vue'
import Contact from '../views/Contact.vue'
import arHome from '../viewsar/Home.vue'
import arMTT from '../viewsar/MeetTheTeam.vue'
import arIC from '../viewsar/InsuranceCompanies.vue'
import arCareers from '../viewsar/Careers.vue'
import arFAQ from '../viewsar/FAQ.vue'
import arGlaucoma from '../viewsar/Services/Glaucoma.vue'
import arVisionCorrection from '../viewsar/Services/VisionCorrection.vue'
import arCataract from '../viewsar/Services/Cataract.vue'
import arRetina from '../viewsar/Services/Retina.vue'
import arCornea from '../viewsar/Services/Cornea.vue'
import arPediatric from '../viewsar/Services/Pediatric.vue'
import arOphthalmic from '../viewsar/Services/Ophthalmic.vue'
import arPost from '../viewsar/Services/Post.vue'
import arContact from '../viewsar/Contact.vue'
import CSR from '../views/news/CSR.vue'
import Zoom from '../views/news/Zoom.vue'
import arCSR from '../viewsar/news/CSR.vue'
import Academy from '../views/news/Academy.vue'
import arAcademy from '../viewsar/news/Academy.vue'
import Athletics from '../views/news/Athletics.vue'
import arAthletics from '../viewsar/news/Athletics.vue'
import Medical from '../views/medicaltips/Medical.vue'
import arMedical from '../viewsar/medicaltips/Medical.vue'
import arGallery from '../viewsar/gallery/Gallery.vue'
import arAqaba from '../viewsar/Aqaba/Aqaba.vue'
import Tech from '../views/Tech.vue'
// import Test from '../views/Test.vue'
import arTest from '../viewsar/Test.vue'
import FormShabab from '../views/FormShabab.vue'
import Awareness from '../views/Awareness.vue'
import NewForm from '../views/NewForm.vue'
import NewFromLocA from '../views/newForms/NewFormLocA.vue'
import NewFromLocB from '../views/newForms/NewFormLocB.vue'
import NewFromLocC from '../views/newForms/NewFormLocC.vue'
import NewFromLocD from '../views/newForms/NewFormLocD.vue'
import NewFromLocE from '../views/newForms/NewFormLocE.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/test',
    name: 'Test',
    component: FormShabab
  },
  {
    path: '/newform',
    name: 'newform',
    component: NewForm
  },
    {
    path: '/form-brew',
    name: 'newform',
    component: NewFromLocA
  },
      {
    path: '/form-Wayne-loc1',
    name: 'newform',
    component: NewFromLocB
  },
      {
    path: '/form-Wayne-loc2',
    name: 'newform',
    component: NewFromLocC
  },
      {
    path: '/form-Wayne-loc3',
    name: 'newform',
    component: NewFromLocD
  },
      {
    path: '/form-yuz',
    name: 'newform',
    component: NewFromLocE
  },
    {
    path: '/awareness',
    name: 'Awareness',
    component: Awareness
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/meettheteam',
    name: 'Meet-The-Team',
    component: MTT
  },
  {
    path: '/technology',
    name: 'Technology',
    component: Tech
  },
  {
    path: '/csr',
    name: 'CSR',
    component: CSR
  },
  {
    path: '/academy',
    name: 'Academy',
    component: Academy
  },
    {
    path: '/aqaba',
    name: 'Aqaba',
    component: Aqaba
  },
  {
    path: '/athletics',
    name: 'Athletics',
    component: Athletics
  },
  {
    path: '/medical',
    name: 'Medical',
    component: Medical
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/insurance',
    name: 'Insurance-Companies',
    component: IC
  },
    {
    path: '/zoom',
    name: 'Zoom Meeting',
    component: Zoom
  },
  {
    path: '/careers',
    name: 'Careers',
    component: Careers
  },
    {
    path: '/glaucoma',
    name: 'Glaucoma',
    component: Glaucoma
  },
  {
    path: '/visioncorrection',
    name: 'Glaucoma',
    component: VisionCorrection
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: Gallery
  },
  {
    path: '/cataract',
    name: 'Cataract',
    component: Cataract
  },
    {
    path: '/retina',
    name: 'Retina',
    component: Retina
  },
  {
    path: '/cornea',
    name: 'Cornea',
    component: Cornea
  },
  {
    path: '/pediatric',
    name: 'Pediatric',
    component: Pediatric
  },
  {
    path: '/ophthalmic',
    name: 'Ophthalmic',
    component: Ophthalmic
  },
  {
    path: '/post',
    name: 'Post',
    component: Post
  },
    {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
    // ARABIC
 {
    path: '/ar/',
    name: 'Home',
    component: arHome
  },
  {
    path: '/ar/csr',
    name: 'CSR',
    component: arCSR
  },
  {
    path: '/ar/athletics',
    name: 'Athletics',
    component: arAthletics
  },
    {
    path: '/ar/academy',
    name: 'Academy',
    component: arAcademy
  },
      {
    path: '/ar/test',
    name: 'Test',
    component: arTest
  },
  {
    path: '/ar/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../viewsar/About.vue')
  },
  {
    path: '/ar/meettheteam',
    name: 'Meet-The-Team',
    component: arMTT
  },
  {
    path: '/ar/faq',
    name: 'FAQ',
    component: arFAQ
  },
  {
    path: '/ar/insurance',
    name: 'Insurance-Companies',
    component: arIC
  },
  {
    path: '/ar/careers',
    name: 'Careers',
    component: arCareers
  },
    {
    path: '/ar/aqaba',
    name: 'Aqaba',
    component: arAqaba
  },
    {
    path: '/ar/glaucoma',
    name: 'Glaucoma',
    component: arGlaucoma
  },
  {
    path: '/ar/visioncorrection',
    name: 'Glaucoma',
    component: arVisionCorrection
  },
  {
    path: '/ar/cataract',
    name: 'Cataract',
    component: arCataract
  },
    {
    path: '/ar/retina',
    name: 'Retina',
    component: arRetina
  },
  {
    path: '/ar/cornea',
    name: 'Cornea',
    component: arCornea
  },
  {
    path: '/ar/pediatric',
    name: 'Pediatric',
    component: arPediatric
  },
  {
    path: '/ar/ophthalmic',
    name: 'Ophthalmic',
    component: arOphthalmic
  },
  {
    path: '/ar/post',
    name: 'Post',
    component: arPost
  },
    {
    path: '/ar/contact',
    name: 'Contact',
    component: arContact
  },
  {
    path: '/ar/medical',
    name: 'Medical',
    component: arMedical
  },
    {
    path: '/ar/gallery',
    name: 'Gallery',
    component: arGallery
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
    scrollBehavior() {
    return { x: 0, y: 0 };
  },
})

export default router
