<template>
  <div align="right">
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 12.734375vw 4.609375vw 12.734375vw">
        <v-card height="3.125vw" class="transparent" outlined align="center">
          <h1 class="ar" style="color: #2a3977">الأسئلة المتداولة</h1></v-card
        >
        <v-card class="transparent" outlined height="4.21875vw"></v-card>
        <v-card outlined class="transparent">
          <div>
            <v-card
              v-for="(item, i) in items"
              :key="i"
              outlined
              class="transparent"
            >
              <v-card
                min-height="5.078125vw"
                v-if="!item.active"
                @click.native="item.active = !item.active"
                class="d-flex align-center pl-10"
                style="
                  border-radius: 3.359375vw;
                  box-shadow: 0 0 1.171875vw 0.15625vw #dcdcdc !important;
                "
              >
                <v-card
                  :style="'padding: 1% 0 0 0; height:' + item.exh"
                  outlined
                  class="d-flex align-center transparent"
                  height="2.34375vw"
                >
                  <v-card
                    height="2.734375vw"
                    outlined
                    class="d-flex justify-start align-center transparent"
                  >
                    <h2 style="font-size: 2.734375vw; color: #2a9ae5">+</h2>
                  </v-card>
                  <v-card outlined class="transparent" width="66.40625vw">
                    <h2
                      class="ar"
                      style="font-size: 1.7187500000000002vw; color: #2a9ae5"
                    >
                      {{ item.item }}
                    </h2>
                  </v-card>
                </v-card>
              </v-card>

              <v-card
                min-height="5.078125vw"
                color="#2A9AE5"
                v-if="item.active"
                @click.native="item.active = !item.active"
                class="d-flex align-center pl-10"
                style="
                  border-radius: 3.359375vw;
                  box-shadow: 0 0 1.171875vw 0.15625vw #dcdcdc !important;
                "
              >
                <v-card
                  :style="'padding: 1% 0 0 0; height:' + item.exh"
                  outlined
                  class="d-flex align-center transparent"
                  height="2.34375vw"
                >
                  <v-card
                    height="2.734375vw"
                    outlined
                    class="d-flex justify-start align-center transparent"
                  >
                    <h2 style="font-size: 2.734375vw; color: white">-</h2>
                  </v-card>
                  <v-card outlined class="transparent" width="66.40625vw">
                    <h2
                      class="ar"
                      style="font-size: 1.7187500000000002vw; color: white"
                    >
                      {{ item.item }}
                    </h2>
                  </v-card>
                </v-card>
              </v-card>
              <v-card
                v-if="item.active"
                height="2.03125vw"
                outlined
                class="transparent"
              ></v-card>

              <v-card
                style="padding: 0 2.96875vw"
                outlined
                class="transparent"
                v-if="item.active"
              >
                <span
                  class="ar"
                  style="
                    font-size: 1.7187500000000002vw;
                    line-height: 1.3 !important;
                    word-spacing: -2%;
                    color: #2a3977;
                  "
                  v-html="item.desc"
                >
                </span>
              </v-card>
              <v-card class="transparent" outlined height="2.03125vw"></v-card>
            </v-card>
          </div>
        </v-card>
      </div>
    </div>
    <!-- MOBILE -->
    <div class="hidden-md-and-up">
      <mimg-page :img="mphoto"></mimg-page>
      <div style="margin: 0 7.199999999999999vw 4.609375vw 7.199999999999999vw">
        <v-card outlined class="transparent" align="center">
          <h1
            class="art"
            style="color: #2a3977; font-size: 5.333333333333334vw"
          >
            الأسئلة المتداولة
          </h1></v-card
        >
        <v-card class="transparent" outlined height="4.21875vw"></v-card>
        <v-card outlined class="transparent">
          <div>
            <v-card
              v-for="(item, i) in items"
              :key="i"
              outlined
              class="transparent"
            >
              <v-card
                v-if="!item.active"
                @click.native="item.active = !item.active"
                class="d-flex align-center"
                style="
                  border-radius: 100em;
                  box-shadow: 0 0 20px 2px #dcdcdc !important;
                "
              >
                <v-card
                  :style="
                    'padding: 3.733333333333334vw 7.199999999999999vw 3.733333333333334vw 7.199999999999999vw; height:' +
                    item.exh
                  "
                  outlined
                  class="d-flex align-center transparent"
                  height="2.34375vw"
                >
                  <v-card outlined class="transparent" width="66.40625vw">
                    <h2
                      class="ar"
                      style="
                        font-size: 4.266666666666667vw;
                        color: #2a9ae5;
                        margin: 0;
                      "
                    >
                      {{ item.item }}
                    </h2>
                  </v-card>
                  <v-card
                    height="2.734375vw"
                    outlined
                    class="d-flex justify-end align-center transparent"
                  >
                    <h2
                      class="pl-3"
                      style="
                        font-size: 5.333333333333334vw;
                        color: #2a9ae5;
                        margin: 0;
                      "
                    >
                      +
                    </h2>
                  </v-card>
                </v-card>
              </v-card>

              <v-card
                color="#2A9AE5"
                v-if="item.active"
                @click.native="item.active = !item.active"
                class="d-flex align-center"
                style="
                  border-radius: 100em;
                  box-shadow: 0 0 20px 2px #dcdcdc !important;
                "
              >
                <v-card
                  :style="
                    'padding: 3.733333333333334vw 7.199999999999999vw 3.733333333333334vw 7.199999999999999vw; height:' +
                    item.exh
                  "
                  outlined
                  class="d-flex align-center transparent"
                  height="2.34375vw"
                >
                  <v-card outlined class="transparent" width="66.40625vw">
                    <h2
                      class="ar"
                      style="
                        font-size: 4.266666666666667vw;
                        color: white;
                        margin: 0;
                      "
                    >
                      {{ item.item }}
                    </h2>
                  </v-card>
                  <v-card
                    height="2.734375vw"
                    outlined
                    class="d-flex justify-end align-center transparent"
                  >
                    <h2
                      class="pl-3"
                      style="
                        font-size: 5.333333333333334vw;
                        color: white;
                        margin: 0;
                      "
                    >
                      -
                    </h2>
                  </v-card>
                </v-card>
              </v-card>
              <v-card
                v-if="item.active"
                height="2.03125vw"
                outlined
                class="transparent"
              ></v-card>

              <v-card
                style="padding: 0 6.8vw 0 7.2vw"
                outlined
                class="transparent"
                v-if="item.active"
              >
                <span
                  class="ar"
                  style="
                    font-size: 4.266666666666667vw;
                    line-height: 1.3 !important;
                    word-spacing: -2%;
                    font-family: monstrat-r;
                    color: #2a3977;
                  "
                  v-html="item.desc"
                >
                </span>
              </v-card>
              <v-card class="transparent" outlined height="2.03125vw"></v-card>
            </v-card>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import photo from "../assets/faq/Main-photo-3.png";
import mphoto from "../assets/faq/Group 472.png";

export default {
  metaInfo: {
    title: "الشامي العيون",
  },
  data() {
    return {
      active: false,
      photo,
      mphoto,
      items: [
        {
          item: "ما هي عملية الليزك؟",
          active: false,
          desc:
            "عملية الليزك هي إجراء جراحي يعمل على تصحيح نطاق واسع من حالات قصر النظر و طول النظر و الانحراف",
        },
        {
          item: "ما معنى كلمة ليزك؟",
          active: false,
          desc:
            '“Laser In Situ Keratomileusis” هي اختصار لجملة  LASIK كلمة ليزك باللغة الانجليزية     و التي تعني تعديل شكل القرنية باستعمال الليزر. يشير الاسم إلى استعمال الليزر لتعديل شكل القرنية دون التعدي على طبقات الخلايا المجاورة. مصطلح    <bdi>In Situ</bdi>   هو لاتيني و يعني "في المكان الطبيعي". طبياً،<bdi> Kerato </bdi> تعني  "الالتزام بموقع المنشأ دون التعدي على الأنسجة المجاورة". كلمة <bdi> mileusis </bdi>  في اللغة اليونانية هي  "القرنية" و هي التشكيل "',
        },
        {
          item: "متى بدأ إجراء عملية الليزك؟",
          active: false,
          desc:
            "بدأ إجراء عملية الليزك لتصحيح البصر حول العالم منذ ما يقرب من 20 عاماً. تم إجراؤها في الولايات المتحدة لأول مرة عام 1991، بينما تم إجراء عملية الليزر لأول مرة هناك عام 1989. في كندا تم إجراء عمليتي الليزر و الليزك لأول مرة عام 1990. المكونات الرئيسية للعملية لها تاريخ طويل. بدأ أطباء العيون في تشكيل القرنية منذ ما يزيد عن 50 عاماً، نجحوا في تشكيل طبقة حامية من الأنسجة فوق القرنية منذ ما يزيد عن 35 عاماً، و أخذوا يستعملون ليزر إكسيمر منذ عقد ثمانينات القرن العشرين.",
        },
        {
          item: "من يستفيد من عملية الليزك؟",
          active: false,
          desc:
            "عملية الليزك مفيدة لأعداد كبيرة من الناس المصابين بقصر النظر، طول النظر أو الانحراف.",
        },
        {
          item: "من الذي لا يستطيع إجراء عملية الليزك لتصحيح البصر؟",
          active: false,
          desc:
            "هناك عدة عوامل يجب أن يأخذها الأطباء بعين الاعتبار قبل تقرير من هو مؤهل لإجراء العملية. ينظر بعض الأطباء إلى بعض الأعراض الموجودة مسبقاً عند المريض على أنها موانع أكيدة لإجراء العملية، و بالتالي فإنهم لا يجرونها. بعض الأعراض الأخرى قد تعقد عملية الليزر و تزيد من احتمال حدوث مضاعفات. يجب على المصاب بتلك الأعراض استشارة طبيبه بخصوص مدى أهليته لإجراء العملية و درجة توقع نجاح العملية. ",
        },
        {
          item: "لماذا تعتبر عملية الليزك تقدماً في مجال عمليات تصحيح البصر؟",
          active: false,
          desc:
            "تجمع عملية الليزك بين دقة ليزر إكسيمر المستعمل في عمليات الليزر مع تقنية لسان القرنية التي يتم فيها قطع الغشاء الخارجي للقرنية على شكل دائرة غير مكتملة. دقة العملية أعلى من دقة عملية الليزر، مضاعفاتها أقل،التعافي منها أسرع، عدد قطرات العين المطلوبة بعدها أقل بالإضافة إلى كونها أكثر راحة.",
        },
        {
          item:
            "أنا سعيد بعدساتي اللاصقة. لماذا يجب أن أسعى إلى إجراء عملية الليزك؟",
          active: false,
          desc:
            "يتفق معظم الجراحين على أنك إذا كنت مرتاحاً بعدساتك اللاصقة و لا يضايقك اعتمادك عليها، فإنك يجب أن تقيّم جيداً مخاطر و فوائد عملية الليزك قبل أن تقرر إجراءها. في النهاية، القرار يعود إلى احتياجاتك و أسلوب حياتك.",
        },
        {
          item: "كم تكلف عملية الليزك؟",
          active: false,
          desc:
            "تختلف الأسعار باختلاف ظروف كل مريض. سيتم تحديد السعر النهائي أثناء الاستشارة المجانية قبل العملية. السعر النهائي يشمل عملية ليزر أو ليزك اعتيادية أو خاصة متطورة. يشمل كذلك كل المراجعات بعد العملية. بالإضافة إلى ذلك، لن تدفع ضرائب خدمة أو مبيعات على عمليات تصحيح البصر باستعمال الليزر في مركز الشامي للعيون.",
        },
        {
          item: "ما طبيعة النتائج التي يمكن توقعها من إجراء عملية الليزك؟",
          active: false,
          desc:
            "عملية الليزك تحسن الرؤية – أي قدرة الشخص على الإبصار دون وضع عدسات تصحيحية- عند معظم من يجرون العملية. في مركز الشامي للعيون، يحصل أكثر من <bdi>96%</bdi> من المرضى المصابين بقصر نظر منخفض إلى معتدل على قوة إبصار <bdi>6/6</bdi> أو أفضل بعد عملية الليزر الأولى، و التي تعادل حدة البصر التي تحققها العدسات اللاصقة. يرتفع هذا الرقم إلى أكثر من <bdi>99%</bdi>عندما يتم إجراء عملية تحسينية عند الحاجة",
        },
        {
          item: "هل نتائج عملية الليزك دائمة؟",
          active: false,
          desc:
            "نتائج عملية الليزك دائمة. مع ذلك، فإنه من الضروري إدراك أن عين الإنسان قد تتغير داخلياً. لهذا فإن الكثير من جراحي عمليات تصحيح البصر ينصحون بإجراء العملية بعد حدوث التغييرات الرئيسية في العين في حياة الإنسان. على سبيل المثال، لا ينصح بإجراء عملية الليزك للأطفال لأن عيونهم ستتغير بشكل كبير و من ثم سيحتاجون إلى إعادة ضبط بصرهم بعد عدة سنين. احتمال ديمومة عملية تصحيح البصر تزيد إن أُجريت العملية بعد سن ال 18. مع ذلك، يبقى احتمال تغير العين بعد سن ال 18 موجوداً. لهذا فإننا ننصح أن تتشاور مع جراح عيونك كي يفحص التغيرات الحادثة في عينيك، ثم يساعدك على أخذ قرار بخصوص الانتظار حتى تقل وتيرة تغيرات العينين.<br><br>يجب على المرضى أن يعرفوا كذلك أنه رغم كون النتائج مستقرة في العادة، يمكن تعديلها بعمليات تحسينية –عمليات يتم إجراؤها بعد العملية الأولى- إن اقتضت الحاجة",
        },
        {
          item: "هل يجب أن يقوم طبيب العيون الخاص بتحويلي؟",
          active: false,
          desc:
            "لا، بإمكانك أن تجري الاستشارة المجانية قبل العملية و أن تجري كذلك عملية تصحيح البصر باستعمال الليزر دون وجود تحويل من طبيب عيونك. يستطيع فريقنا المكون من أطباء عيون و مختصي العناية بالعيون وفاحصي البصر  أن يحدد العلاج الذي تحتاجه. أثناء الاستشارة المجانية قبل العملية، سيكون بمقدور أطباء العيون في فريقنا أن يحددوا طبيعة تصحيح البصر الذي تحتاجه. رغم ذلك، فإنه من المفيد دائماً أن تستشير طبيب عيونك أو طبيب العائلة. لدينا كذلك قائمة بأطباء العيون المرتبطين بمركز الشامي للعيون في منطقتك.",
        },
        {
          item:
            "إن قمت بإجراء عملية الليزك ثم تغير بصري في مرحلة لاحقة من حياتي، هل يمكنني إعادة إجراء العملية؟",
          active: false,
          desc:
            "لا، بإمكانك أن تجري الاستشارة المجانية قبل العملية و أن تجري كذلك عملية تصحيح البصر باستعمال الليزر دون وجود تحويل من طبيب عيونك. يستطيع فريقنا المكون من أطباء عيون و مختصي العناية بالعيون وفاحصي البصر  أن يحدد العلاج الذي تحتاجه. أثناء الاستشارة المجانية قبل العملية، سيكون بمقدور أطباء العيون في فريقنا أن يحددوا طبيعة تصحيح البصر الذي تحتاجه. رغم ذلك، فإنه من المفيد دائماً أن تستشير طبيب عيونك أو طبيب العائلة. لدينا كذلك قائمة بأطباء العيون المرتبطين بمركز الشامي للعيون في منطقتك",
        },
        {
          item: "كم يستغرق إجراء عملية الليزك؟",
          active: false,
          desc:
            "يستغرق إجراء العملية 10 دقائق للعينين، بما في ذلك  تكوين اللسان و إجراء عملية الليزر. في المعدل، يكون الاستعمال الفعلي لليزر لمدة أقل من 20 ثانية لكل عين. ",
        },
        {
          item: "هل يمكنني قيادة السيارة مباشرة بعد إجراء عملية الليزك؟",
          active: false,
          desc:
            "قد يشعر المرضى بعدم الراحة و/أو بضبابية في البصر لعدة ساعات بعد إجراء العملية. بالإضافة لذلك، يتم إعطاء مهديء لمعظم المرضى قبل العملية. لذلك، لا يمكنك أن تقود السيارة إلى بيتك بعد إجراء عملية الليزك و عليك أن تنوي عدم القيادة لمدة 24 ساعة",
        },
        {
          item: "ما نوع المخدر المستعمل في عملية الليزك؟",
          active: false,
          desc:
            "يتم استعمال مخدر موضعي (قطرات عيون) يقوم بتخدير العين. قد يتم إعطاء المرضى جرعة مهديء صغيرة عن طريق الفم لمساعدتهم على الاسترخاء",
        },
        {
          item: "هل تؤلم عملية الليزك؟",
          active: false,
          desc:
            'يُعطى المرضى مخدراً موضعياً (قطرات عيون) لتخدير العين، و بذلك لا يشعرون بأي ألم أثناء العملية. عندما يضع الجراح حلقة التفريغ، سيشعر المريض بضغط خفيف مباشرة قبل أن يختفي بصره لعدة ثوانٍ. لا ينتج أي شعور بالألم من أداة تكوين اللسان الدقيقة أو جهاز الليزر. الكثير من المرضى وصفوا شعوراً بسيطاً بوجود "جسم غريب" لعدة ساعات بعد العملية، كشعور فتح العينين أثناء السباحة في مياه مكلورة. يشعر بعض الناس كأنهم يضعون عدسة لاصقة متسخة. عادةً، و بمرور الساعات الأولى بعد العملية، يختفى شعور عدم الراحة',
        },
        {
          item: "هل يمكن إجراء عملية الليزك في كلتا العينين في وقت واحد؟",
          active: false,
          desc:
            "يمكنك إجراء عملية الليزك في كلتا العينين في وقت واحد. ظهر في معظم الحالات أن إجراء عملية ثنائية للعينين في وقت واحد هو أمر آمن و فعال. في واقع الأمر، يختار أكثر من 95% من الناس إجراء العملية في العينين معاً في وقت واحد. إن لم تكن نتائج العملية في عينك الأولى ممتازة، من الأرجح أن يؤجل الطبيب البدء بالعين الثانية",
        },
        {
          item: "ما المدة التي سأضطر للابتعاد أثنائها عن عملي؟",
          active: false,
          desc:
            "تعتمد المدة على مهنتك. قد تصعب ممارسة بعض الوظائف التي تتطلب وضوحاً شديداً في الرؤية (مثل طب الأسنان و الجراحة) لمدة يوم أو اثنين. يمكن لمعظم المرضى أن يعودوا إلى أعمالهم في اليوم التالي على افتراض أن وضوح بصرهم مناسب لعملهم. رغم ذلك، قد يشعر بعض الناس بالتعب ليوم أو نحو ذلك بعد العملية. تعلم المزيد عن جدول النشاطات الذي ننصح به بعد العملية",
        },
        {
          item: "هل يمكن أن أمارس الرياضة بعد إجراء عملية الليزك؟.",
          active: false,
          desc:
            "يمكنك أن تستأنف معظم نشاطاتك الاعتيادية مباشرة بعد عملية الليزك، لكنك ستضطر للتوقف عن كل النشاطات التي يمكن أن تسبب دخول العرق في عينيك لمدة أسبوعين. يجب عليك أن ترتدي نظارات أمان أثناء لعب الرياضات الالتحامية بغض النظر عن كونك أجريت العملية أم لا. إن لم تكن ترتدي نظارات الأمان بشكل اعتيادي، قد ينصحك جراح العيون بارتدائها على الأقل لمدة شهر بعد عملية الليزك. ستضطر كذلك لتجنب بعض النشاطات مثل الرياضات الالتحامية و السباحة لمدة أسبوع على الأقل. من المهم أن تستشير طبيبك بخصوص القيود على نشاطات معينة بعد إجراء عملية الليزك. تعلم المزيد عن جدول النشاطات الذي ننصح به بعد العملية",
        },
        {
          item:
            "كم سأحتاج من الوقت قبل أن أستطيع الرؤية بشكل واضح و كم سأحتاج لأصل إلى أفضل رؤية ممكنة؟",
          active: false,

          desc:
            "تعتمد المدة على مهنتك. قد تصعب ممارسة بعض الوظائف التي تتطلب وضوحاً شديداً في الرؤية (مثل طب الأسنان و الجراحة) لمدة يوم أو اثنين. يمكن لمعظم المرضى أن يعودوا إلى أعمالهم في اليوم التالي على افتراض أن وضوح بصرهم مناسب لعملهم. رغم ذلك، قد يشعر بعض الناس بالتعب ليوم أو نحو ذلك  بعد العملية. تعلم المزيد عن جدول النشاطات الذي ننصح به بعد العملية",
        },
        {
          item: "هل سأضطر إلى ارتداء نظارات بعد أن تشفى عيناي؟",
          active: false,
          desc:
            "معظم المرضى لا يضطروت إلى ارتداء نظارات لممارسة نشاطاتهم اليومية. مع ذلك قد يضطر المرضى الذين تجاوزوا سن ال 40 إلى ارتداء نظارات قراءة. سبب هذا هو عملية التقدم الطبيعي في عمر العينين و المعروف باسم قصو البصر الشيخوخي. من المهم إدراك أنّ هذه الحالة تحدث بغض النظر عن إجراء عملية الليزك من عدمه. من الممكن كذلك أن يحتاج بعض المرضى إلى حد أدنى من الدرجات في الوصفة الطبية لممارسة بعض النشاطات مثل القيادة ليلاً",
        },
        {
          item: "هل سيتغير شكل عيناي بعد عملية الليزك؟",
          active: false,
          desc: "لا، سيبقى شكل عينيك كما هو",
        },
        {
          item: "ما درجة أمان عملية الليزك؟",
          active: false,
          desc:
            "تظهر معظم العمليات أن احتمال حدوث مضاعفات من عملية الليزك متدنية للغاية. النسبة الاجمالية لحدوث مضاعفات هي أقل من واحد بالمئة. حتى لو حدثت مضاعفات، فإن معظم المضاعفات يتم علاجها في غضون ثلاثة أشهر و لا تؤدي إلى تشويش طويل الأمد على البصر. لمزيد من الدقة، فإن نسبة المضاعفات التي تم تسجيلها في الدراسات الكبيرة تتراوح بين 0.001% و 0.05%. عملية الليزك هي عملية جراحية تُجرى على جزء حساس من العين و لذا يوجد احتمال حدوث مضاعفات. تعلم المزيد عن الأمان. لمزيد من المعلومات، إقرأ كتيبنا عن معلومات الليزك",
        },
        {
          item:
            "بعد إجراء عملية الليزك، كيف سيكون بصري في الليل أو في الضوء الخافت؟",
          active: false,
          desc:
            "مع إجراء عملية الليزك، يتم التخلص من الأغلبية العظمى من حالات اضطراب الرؤية الليلية، حيث أن المساحة المعالجة من القرنية أكبر من حجم البؤبؤ المتسع. قد يرى بعض المرضى وهجاً خفيفاً أو هالات أو أشكالاً نجمية حول مصادر الضوء في المناطق ذات الإضاءة الخافتة و ذلك بشكل مؤقت بعد العملية. عادة لا تشوش تلك الأعراض على القيادة ليلاً أو النشاطات الليلية. تلك الأعراض مؤقتة بالنسبة للأغلبية العظمى من الناس، و عادة ما تستغرق عدة أيام و قد تصل إلى أسبوع. مع ظهور تكنولوجيا ليزر جديدة، أصبح من النادر جداً ظهور تلك الأعراض",
        },
        {
          item: "هل ستصبح عيناي جافتين بعد العملية؟",
          active: false,
          desc:
            "رغم ندرة حدوث تلك الحالة، يجب على كل المرضى أن يدركوا أن جفاف العينين هو أحد المضاعفات المحتملة بعد إجراء عملية الليزك. عملية تصحيح البصر باستخدام الليزر تجعل عيون كل المرضى أكثر جفافاً، لكن لفترة مؤقتة. سيقوم جراحك بفحصك لمعرفة احتمال حدوث هذه الحالة عندك بعد إجراء العملية إن كنت تعاني من حالات جفاف عينين سابقة أو إن كنت تشعر بعدم راحة عند وضع العدسات اللاصقة. يجب على جميع المرضى بعد العملية أن يستعملوا قطرات عينين عند الحاجة",
        },
        {
          item:
            "أنا أرتدي الآن نظارة بعدستين مختلفتي القوة. لماذا سأبقى بحاجة إلى نظارات القراءة بعد إجراء عملية الليزك؟",
          active: false,
          desc:
            "يحتاج معظم الناس ممن هم في الأربعينات من عمرهم أو من هم أكبر إلى نظارات قراءة بغض النظر عن خضوعهم لعمليات تصحيح البصر للمسافات. ينتج هذا بسبب خسارة العين لمرونة عدستها مع تقدم العمر. تسمى هذه الحالة قصو البصر الشيخوخي، و لا يمكن علاجها حالياً عن طريق إجراء عملية الليزك. تعلم المزيد عن الطرق الجديدة التي تصحح قصو البصر الشيخوخي. بعض المرضى في شريحة العمر المذكورة يختارون أحادية الرؤية لتصحيح هذه الحالة. يسمح هذا الخيار للمرضى باستعمال احدى العينين للمسافات و الأخرى للأشياء القريبة. رغم أن هذه العملية قد تكون مرضية لبعض المرضى، فإن الكثير منهم لا يحبذونها. يجب على المرضى مناقشة خياراتهم مع طبيبهم. معظم الأطباء يقترحون على المرضى تجربة أحادية الرؤية باستعمال عدسات لاصقة لعدة أسابيع قبل اختيار أحادية الرؤية عن طريق الليزك. احرص على سؤال طبيبك إن كان هذا الخيار التجريبي متوفراً لك. تعلم المزيد عن أحادية الرؤية ",
        },
        {
          item:
            "إن تمت معالجة بصري للمسافات، هل سأفقد القدرة على رؤية الأشياء القريبة؟",
          active: false,
          desc:
            "إن أجريت عملية الليزك لتصحيح بصرك، يمكنك أن تتوقع قوة إبصار واضحة بشكل عام. مع ذلك، فإن الصور القريبة منك لن تبدو بنفس الوضوح إن أجريت عملية الليزك بعد تجاوزك سن ال 40. بعد سن ال 40 توقع أن تحتاج نظارات قراءة للأشياء التي تحتاج رؤية عن قرب",
        },
        {
          item: "ما هي عملية الليزر؟",
          active: false,
          desc:
            "عملية الليزر مشابهة لعملية الليزك من حيث أن العمليتين تستعملان ليزر اكسيمر موجهاً عن طريق الحاسوب لتشكيل قرنية العين المتضررة. الفرق أن الجراح يقوم في عملية الليزك بحفظ الظهارة (الطبقة الخارجية الحامية للعين) عن طريق تكوين لسان. في عملية الليزر تتم إزالة الظهارة عن طريق كشط السطح بلطف (لا يتم تكوين لسان). يتم وضع قطرات مخدرة في العين لتقليل شعور المريض بعدم الراحة إلى أدنى حد. تتميز عملية الليزر كذلك بأن مدة التعافي منها أطول و الشعور بعدم الراحة أكبر من عملية الليزك",
        },
        {
          item:
            "لا يستطيع الجميع إجراء عملية الليزك، لكن هل يستطيع أي شخص إجراء عملية الليزر؟",
          active: false,
          desc:
            "لا يمكن إجراء عملية الليزر للناس المصابين باضطرابات المناعة الذاتية (الذئبة، التهاب المفاصل الروماتويدي)، حالات سابقة من التشكل العدواني للندب بعد حدوث جرح في الجلد (الجدرة) أو أولئك المصابين ببعض أمراض العين التنكسية. سيتم تقييم حالتك قبل عملية الليزر لتحديد مدى أهليتك لإجرائها",
        },
        {
          item: "ما هي الاختلافات بين أنواع الليزر المختلفة؟",
          active: false,
          desc:
            "أنواع الليزر الثلاث تستعمل تقنية واجهة الموجة، و التي تسمح للجراح بمعالجة الانحرافات الشديدة التي تتجاوز الأشكال الكروية و الاسطوانية، و قد تحسن الإبصار بشكل يفوق قدرة الليزر الذي لا يستعمل تقنية واجهة الموجة. كل نوع من أنواع الليزر الثلاث يستعمل أسلوباً مختلفاً اختلافاً طفيفاً للحصول على معلومات واجهة الموجة. كذلك فإن كلاً منهم يستعمل شعاعاً يختلف اختلافاً طفيفاً في الشكل، السرعة و نظام الملاحقة. كذلك فإن سهولة التعامل معهم تختلف. يلتزم مركز الشامي للعيون باستعمال التقنيات المثبتة فقط لتوفير رعاية متميزة لمرضانا. قواعد الليزر المتعددة لدينا تضمن أفضل نتائج ممكنة في أكثر الظروف أماناً. سيقدم أطباؤنا نصحاً بخصوص خيارات العلاج الأكثر ملاءمة لاحتياجاتك، بما في ذلك عمليات الليزك الاعتيادية أو الخاصة أو التي تستعمل الليزر بشكل كامل",
        },
      ],
    };
  },
};
</script>

<style>
</style>