<template>
  <div>
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 15.859375vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977">
            Zoom Meeting/<bd
              ><span style="font-size: 3vw" class="ar">اجتماع زووم</span></bd
            >
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <div align="center">
          <p style="color: #2a3977">
            Zoom
            <span class="ar">
              رابط الدخول لاجتماع الهيئة العامة لشركة المركز الشامي للعيون
              المنعقد من خلال تطبيق</span
            >
          </p>
          <br />

          <p style="color: #2a3977">
            <a
              href="https://zoom.us/j/97600712704?pwd=ZHNrbVRzbk1YdU94L0paNHdMV3paZz09"
            >
              https://zoom.us/j/97600712704?pwd=ZHNrbVRzbk1YdU94L0paNHdMV3paZz09
            </a>
          </p>
        </div>

        <v-card outlined tile class="transparent" height="2.96875vw"></v-card>
      </div>
      <v-overlay :z-index="200" :value="overlay" v-if="overlay">
        <Vform light>
          <v-btn
            @click="overlay = !overlay"
            height="3.28125vw"
            width="12.968750000000002vw"
            depressed
            color="#2A9AE5"
            style="
              text-transform: none;
              border-radius: 3.359375vw;
              margin: 0 0 0 14px;
            "
            class="white--text btntxt"
          >
            <span style="margin: 0 0 0 0; font-size: 1.25vw">Cancel</span>
          </v-btn>
        </Vform>
      </v-overlay>
    </div>
    <!-- MOBILE -->
    <div class="hidden-md-and-up">
      <mimg-page :img="mphoto"></mimg-page>
      <div style="margin: 0 10.66666666vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977; font-size: 5.333333333333334vw">
            Zoom Meeting/<bd
              ><span style="font-size: 6vw" class="ar">اجتماع زووم</span></bd
            >
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <div align="center">
          <p style="color: #2a3977; font-size: 4.266666666666667vw; margin: 0">
            <span class="ar">
              <bd>
                رابط الدخول لاجتماع الهيئة العامة لشركة المركز الشامي للعيون
                المنعقد من خلال تطبيق زووم</bd
              ></span
            >
            <br />
            <br />

            <a
              href="https://zoom.us/j/97600712704?pwd=ZHNrbVRzbk1YdU94L0paNHdMV3paZz09"
            >
              https://zoom.us/j/97600712704?pwd=ZHNrbVRzbk1YdU94L0paNHdMV3paZz09
            </a>
          </p>
        </div>
        <v-card
          outlined
          tile
          class="transparent"
          height="10.666666666666668vw"
        ></v-card>
      </div>
    </div>
  </div>
</template>

<script>
import photo from "../../assets/Services/Post/Main-photo16.png";
import mphoto from "../../assets/Services/Post/Group 464.png";
import Vform from "../../components/Form";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Shami Eye | Zoom Meeting",
    meta: [
      {
        name: "description",
        content: "Zoom Meeting",
      },
    ],
    // all titles will be injected into this template
  },
  components: { Vform },
  data() {
    return {
      photo,
      mphoto,
      overlay: false,
    };
  },
  methods: {
    btnclick() {
      this.overlay = false;
    },
  },
};
</script>

<style>
</style>