<template>
  <div>
    <div align="right" class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 15.859375vw">
        <v-card outlined class="transparent" align="center">
          <h1 class="ar" style="color: #2a3977">
            المسؤولية الاجتماعية
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <v-card outlined class="transparent">
          <h2 class="boldm ar" style="color: #2a9ae5">
            مسؤوليتنا الاجتماعية
          </h2></v-card
        >
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <p class="ar" style="color: #2a3977">
          في إطار إحساسنا الكبير بالمسؤولية تجاه المجتمع المحلي، وبالتعاون مع
          المنظمات الحكومية وغير الحكومية والمؤسسات الأكاديمية (الجامعات
          والمدارس) ومختلف المنظمات الخيرية، يقدم فريق الشامي للعيون وشركاؤه
          خدماتهم المتميزة بالجودة العالية في مجال طب و جراحة العيون. ويتم ذلك
          بما يتماشى مع جهودنا الرامية إلى دعم<br />
          الأشخاص الذين يعيشون في المناطق الأقل حظاً المحرومة من الخدمات في
          المواقع النائية من الأردن. ويتم تحقيق ذلك من خلال الحملات الطبية التي
          يتم فيها الترحيب بالمرضى وتشخيصهم والعمل على حصولهم على العلاج
          المناسب.
        </p>
        <v-card outlined tile class="transparent" height="1.5625vw"></v-card>
        <v-card outlined class="transparent">
          <h2 class="boldm ar" style="color: #2a9ae5">
            المناطق الأقل حظًا
          </h2></v-card
        >
        <v-card outlined tile class="transparent" height="0.9375vw"></v-card>
        <p class="ar" style="color: #2a3977">
          لقد أنعم الله علينا بنعمة البصر، فعندما نستيقظ كل صباح، فإن أول شيء
          نقوم به هو فتح اثنتين من أعظم الهبات؛ أعيننا، لنستمتع بنعمة البصر و
          نور الصباح. و لأهمية ذلك يقوم الشامي للعيون بالتزامه الدائم تجاه رعاية
          مرضاه من خلال توفير خدمات عالية واستشارة أكفأ الأطباء والخبراء
          ومتابعته الدورية لهم لتلبية احتياجاتهم.
          <br /><br />
          وتشمل جهود التواصل المجتمعي في الشامي للعيون الحملات الطبية التي توفر
          فحوصات العيون والمشورة الطبية التطوعية للمرضى في المناطق الطرفية
          المحرومة من الخدمات الصحية في الأردن. كما يقوم الشامي للعيون بإجراء
          العديد من الأنشطة التعليمية والتوعوية لتسليط الضوء على وسائل وقاية
          وحماية العين والعلاجات المتاحة لأمراض العيون.
        </p>

        <v-card outlined tile class="transparent" height="2.96875vw"></v-card>
      </div>
    </div>
    <!-- MOBILE -->
    <div align="right" class="hidden-md-and-up">
      <mimg-page :img="mphoto"></mimg-page>
      <div style="margin: 0 10.66666666vw">
        <v-card outlined class="transparent" align="center">
          <h1 class="ar" style="color: #2a3977; font-size: 5.333333333333334vw">
            المسؤولية الاجتماعية
          </h1></v-card
        >

        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <h2
          style="color: #2a9ae5; margin: 0; font-size: 4.8vw"
          class="boldm titlea ar"
        >
          قصتنا و تراثنا
        </h2>

        <p
          class="ar"
          style="
            color: #2a3977;
            font-size: 4.266666666666667vw;
            margin: 3.4666666666666663vw 0 9.066666666666666vw 0;
          "
        >
          في إطار إحساسنا الكبير بالمسؤولية تجاه المجتمع المحلي، وبالتعاون مع
          المنظمات الحكومية وغير الحكومية والمؤسسات الأكاديمية (الجامعات
          والمدارس) ومختلف المنظمات الخيرية، يقدم فريق الشامي للعيون وشركاؤه
          خدماتهم المتميزة بالجودة العالية في مجال طب و جراحة العيون. ويتم ذلك
          بما يتماشى مع جهودنا الرامية إلى دعم الأشخاص الذين يعيشون في المناطق
          الأقل حظاً المحرومة من الخدمات في المواقع النائية من الأردن. ويتم
          تحقيق ذلك من خلال الحملات الطبية التي يتم فيها الترحيب بالمرضى
          وتشخيصهم والعمل على حصولهم على العلاج المناسب.
        </p>
        <h2
          style="color: #2a9ae5; margin: 0; font-size: 4.8vw"
          class="boldm titlea ar"
        >
          المناطق الأقل حظًا
        </h2>

        <p
          class="ar"
          style="
            color: #2a3977;
            font-size: 4.266666666666667vw;
            margin: 3.4666666666666663vw 0 9.066666666666666vw 0;
          "
        >
          لقد أنعم الله علينا بنعمة البصر، فعندما نستيقظ كل صباح، فإن أول شيء
          نقوم به هو فتح اثنتين من أعظم الهبات؛ أعيننا، لنستمتع بنعمة البصر و
          نور الصباح. و لأهمية ذلك يقوم الشامي للعيون بالتزامه الدائم تجاه رعاية
          مرضاه من خلال توفير خدمات عالية واستشارة أكفأ الأطباء والخبراء
          ومتابعته الدورية لهم لتلبية احتياجاتهم.
          <br /><br />
          وتشمل جهود التواصل المجتمعي في الشامي للعيون الحملات الطبية التي توفر
          فحوصات العيون والمشورة الطبية التطوعية للمرضى في المناطق الطرفية
          المحرومة من الخدمات الصحية في الأردن. كما يقوم الشامي للعيون بإجراء
          العديد من الأنشطة التعليمية والتوعوية لتسليط الضوء على وسائل وقاية
          وحماية العين والعلاجات المتاحة لأمراض العيون.
        </p>

        <v-card
          outlined
          tile
          class="transparent"
          height="10.666666666666668vw"
        ></v-card>
      </div>
    </div>
  </div>
</template>

<script>
import photo from "../../assets/news/CSR/mask_group_20.png";
import mphoto from "../../assets/news/CSR/Group 465.png";

export default {  
metaInfo: {
    title: "الشامي العيون",
  },
  data() {
    return {
      photo,
      mphoto,
      overlay: false,
    };
  },
};
</script>

<style>
</style>