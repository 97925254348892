<template>
  <div>
    <v-img height="112.79999999999998vw" width="100vw" :src="img"></v-img>
    <v-card
      tile
      outlined
      class="transparent"
      height="5.625vw"
      align="center"
    ></v-card>
  </div>
</template>

<script>
export default {
  name: "mimgpage",
  props: ["img"],
};
</script>

<style>
</style>