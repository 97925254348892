<template>
  <div>
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 12.578125000000002vw">
        <v-card outlined class="transparent" align="center">
          <h1 class="ar" style="color: #2a3977">نصائح طبية</h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <v-card align="center" class="transparent" outlined>
          <h2
            class="boldm ar"
            style="color: #2a9ae5; line-height: 0.5; font-size: 2.265625vw"
          >
            <span style=""> نصائح صحية</span
            ><span style="font-size: 2.5vw"> ● </span>
            <span style="color: #73b6e4">فيديو الأطباء</span>
          </h2>
        </v-card>
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <v-card align="center" class="transparent" outlined>
          <p class="ar" style="color: #2a3977">
            : نصائح عامة لسلامة العين وحماية البصر
          </p>
        </v-card>
        <v-card outlined tile class="transparent" height="2.96875vw"></v-card>
        <v-card
          style="margin: 0 0 4.609375vw 0"
          outlined
          class="transparent"
          align="center"
        >
          <v-row>
            <v-col v-for="(tip, i) in tips" :key="i" md="4">
              <v-card
                elevation="2"
                style="
                  border-radius: 3.359375vw;
                  box-shadow: 0 0 1.171875vw 0.15625vw #dcdcdc !important;
                "
                width="23.515625vw"
                height="33.4375vw"
              >
                <v-img
                  style="margin: 0 0 2.421875vw 0"
                  height="15vw"
                  :src="tip.img"
                ></v-img>

                <v-card
                  outlined
                  class="transparent"
                  style="padding: 0 2.109375vw"
                >
                  <h2 class="ar" style="font-size: 1.484375vw; color: #2a9ae5">
                    {{ tip.numb }} نصيحة
                  </h2>
                  <p
                    class="ar"
                    v-html="tip.tip"
                    style="
                      font-size: 1.171875vw;
                      color: #204170;
                      line-height: 1 !important;
                    "
                  ></p>
                </v-card>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
    <!-- MOBILE -->
    <div class="hidden-md-and-up">
      <mimg-page :img="photo"></mimg-page>
      <div style="margin: 0 10.66666666vw">
        <v-card outlined class="transparent" align="center">
          <h1 class="ar" style="color: #2a3977; font-size: 5.333333333333334vw">
            نصائح طبية
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <v-card align="center" class="transparent" outlined>
          <h2
            class="boldm ar"
            style="color: #2a9ae5; line-height: 0.5; font-size: 4.8vw"
          >
            نصائح صحية <span style="font-size: 6vw"> ● </span
            ><span style="color: #73b6e4"> فيديو الأطباء </span>
          </h2>
        </v-card>
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>
        <v-card outlined tile class="transparent" align="center">
          <p
            class="ar"
            style="color: #2a3977; font-size: 4.266666666666667vw; margin: 0"
          >
            <span>:</span>
            نصائح عامة لسلامة العين وحماية البصر
          </p>
        </v-card>
        <v-card outlined tile class="transparent" height="4.578125vw"></v-card>

        <v-card class="transparent" outlined>
          <div>
            <v-carousel
              style="
                border-radius: 11.466666666666667vw;
                box-shadow: 0 0 20px 2px #dcdcdc !important;
              "
              :show-arrows="false"
              hide-delimiters
              v-model="model"
              height="114.13333333333333vw"
            >
              <v-carousel-item v-for="(tip, i) in tipsm" :key="i">
                <v-card
                  style="
                    border-radius: 11.466666666666667vw;
                    box-shadow: 0 0 20px 2px #dcdcdc !important;
                  "
                  light
                  height="114.13333333333333vw"
                >
                  <v-img height="51.2vw" :src="tip.img"></v-img>
                  <v-card
                    style="padding: 0 7.199999999999999vw"
                    align="center"
                    outlined
                    class="transparent"
                  >
                    <br />
                    <br />
                    <h2
                      class="ar"
                      style="color: #2a9ae5; font-size: 5.066666666666666vw"
                    >
                      {{ tip.numb }} نصيحة
                    </h2>
                    <p
                      class="ar"
                      style="font-size: 4vw; color: #204170"
                      v-html="tip.tip"
                    ></p>
                  </v-card>
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </div>
          <v-card outlined tile class="transparent" height="10vw"></v-card>

          <v-row justify="center">
            <v-card
              class="transparent mr-1"
              outlined
              width="7.733333333333333vw"
              height="7.733333333333333vw"
            >
              <v-img :src="left" @click="model--"></v-img>
            </v-card>
            <v-card
              class="transparent ml-1"
              outlined
              width="7.733333333333333vw"
              height="7.733333333333333vw"
            >
              <v-img :src="right" @click="model++"> </v-img>
            </v-card>
          </v-row>
          <v-card outlined tile class="transparent" height="10vw"></v-card>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import photo from "../../assets/tips/Main-photo22.png";
import mphoto from "../../assets/Services/Post/Group 464.png";
import Tip1 from "../../assets/tips/Tip8.png";
import Tip2 from "../../assets/tips/Tip5.png";
import Tip3 from "../../assets/tips/Tip28.png";
import Tip4 from "../../assets/tips/Tip1.png";
import Tip5 from "../../assets/tips/Tip6.png";
import Tip6 from "../../assets/tips/Tip7.png";
import Tip7 from "../../assets/tips/Tip9.png";
import Tip8 from "../../assets/tips/Tip15.png";
import Tip9 from "../../assets/tips/Tip2.png";
import Tip10 from "../../assets/tips/Tip7.png";
import Tip11 from "../../assets/tips/Tip11.png";
import Tip12 from "../../assets/tips/Tip32.png";
import Tip13 from "../../assets/tips/Tip4.png";
import Tip14 from "../../assets/tips/Tip14.png";
import Tip15 from "../../assets/tips/Tip21.png";
import Tip16 from "../../assets/tips/Tip16.png";
import right from "../../assets/tips/Group 410.png";
import left from "../../assets/tips/Group 454.png";

export default {
  metaInfo: {
    title: "الشامي العيون",
  },
  data() {
    return {
      model: 0,
      photo,
      mphoto,
      overlay: false,
      right,
      left,
      tipsm: [
        {
          img: Tip3,
          tip:
            "الفواكه والخضروات تحتوي على مضادات أكسدة لذلك هي مهمة جداً لصحة العين وخاصة التي تحتوي على فيتامين أ وج  ",
          numb: "1",
        },
        {
          img: Tip2,
          tip:
            "ممارسة الرياضة بانتظام تقلل نسبة الاصابة بارتفاع ضغط العين وتُحسّن صحة الجسم والدورة الدموية بشكل عام",
          numb: "2",
        },
        {
          img: Tip1,
          tip:
            "ارتداء النظارات الشمسية عند الخروج أثناء النهار ضروري لحماية العين من الأشعة فوق البنفسجية الضارة التي قد تسبب إصابة العين بالماء الأبيض ",
          numb: "3",
        },

        {
          img: Tip6,
          tip: "الفحص الدوري للعيون ضروري للتأكد من سلامة العين وحدة الابصار",
          numb: "4",
        },
        {
          img: Tip5,
          tip:
            "ان ارتفاع ضغط الدم له تأثير سلبي على  صحة العين لذلك احرص على مراقبته بين الحين والآخر",
          numb: "5",
        },
        {
          img: Tip4,
          tip:
            "ننصح مريض السكري بفحص العيون الدوري لان ارتفاع مستوى السكر في الدم قد يكون احدى أسباب أمراض العيون وخاصة أمراض الشبكية ",
          numb: "6",
        },

        {
          img: Tip9,
          tip:
            "لتجنب ارهاق عينيك لا تنسى قاعدة 20-20-20 <br><br>كل 20  دقيقة من النظر الى الشاشة انظرالى شيء يبعُد عنك  قدم ولمدة 20 ثانية",
          numb: "7",
        },
        {
          img: Tip8,
          tip:
            "تجنب النظر المباشر الى أي مصدر للضوء القوي  كالشمس أو أي انارة ساطع",
          numb: "8",
        },
        {
          img: Tip7,
          tip:
            "ينصح الأطباء بإجراء فحص شامل لعيون الطفل في السنة الرابعة أو الخامسة من عمره لاكتشاف أي ضعف نظرغير ملاحظ  من الأهل والوقاية  أو علاج الكسل البصري مبكراً",
          numb: "9",
        },
        {
          img: Tip12,
          tip:
            "عند تشخيصك بمرض السكري من الضروري أن يتم فحص قاع العين والتأكد من سلامة الشبكية بشكل دوري ",
          numb: "10",
        },
        {
          img: Tip11,
          tip:
            "اذا كنت تعاني من رؤية أجسام عائمة كالذباب، ننصحك بزيارة طبيب العيون للتأكد من سلامة الشبكية ",
          numb: "11",
        },
        {
          img: Tip10,
          tip:
            "اذا كان عمرك  فوق الأربعين أو اذا كان أحد أفراد عائلتك مصاب بمرض ارتفاع ضغط العين (الجلوكوما                               ) ننصحك  بقياس ضغط العين بشكل دوري سنوياً لخطورة هذا المرض اذ يسمى بسارق النظر الصامت",
          numb: "12",
        },

        {
          img: Tip15,
          tip:
            "تجنب فرك العين، وفي حال وجود حكّة استشر طبيب العيون بأسرع وقت ممكن",
          numb: "13",
        },
        {
          img: Tip14,
          tip:
            "اذا تطلبت طبيعة عملك كثرة النظر إلى شاشات الأجهزة الرقمية ننصحك باستخدام قطرات الترطيب ويفضل القطرات الخالية من المواد الحافظة",
          numb: "14",
        },
        {
          img: Tip13,
          tip:
            "التوعية في مواقع العمل بارتداء نظارة الحماية الضرورية لأصحاب المهن الذين يتطلب منهم حماية عيونهم خلال أداء عملهم حفاظاً على سلامتهم ",
          numb: "15",
        },

        {
          img: Tip16,
          tip:
            "يجب على مستخدمي العدسات اللاصقة مراعة معايير سلامة العين وذلك بغسل اليدين قبل وضع وازالة العدسات و حفظهم بالعلبة والمحلول الخاص بهم بالاضافة الى تجنب وضعهم أثناء النوم ",
          numb: "16",
        },
      ],
      tips: [
        {
          img: Tip1,
          tip:
            "ارتداء النظارات الشمسية عند الخروج أثناء النهار ضروري لحماية العين من الأشعة فوق البنفسجية الضارة التي قد تسبب إصابة العين بالماء الأبيض ",
          numb: "3",
        },
        {
          img: Tip2,
          tip:
            "ممارسة الرياضة بانتظام تقلل نسبة الاصابة بارتفاع ضغط العين وتُحسّن صحة الجسم والدورة الدموية بشكل عام",
          numb: "2",
        },
        {
          img: Tip3,
          tip:
            "الفواكه والخضروات تحتوي على مضادات أكسدة لذلك هي مهمة جداً لصحة العين وخاصة التي تحتوي على فيتامين أ وج  ",
          numb: "1",
        },
        {
          img: Tip4,
          tip:
            "ننصح مريض السكري بفحص العيون الدوري لان ارتفاع مستوى السكر في الدم قد يكون احدى أسباب أمراض العيون وخاصة أمراض الشبكية ",
          numb: "6",
        },
        {
          img: Tip5,
          tip:
            "ان ارتفاع ضغط الدم له تأثير سلبي على  صحة العين لذلك احرص على مراقبته بين الحين والآخر",
          numb: "5",
        },
        {
          img: Tip6,
          tip: "الفحص الدوري للعيون ضروري للتأكد من سلامة العين وحدة الابصار",
          numb: "4",
        },
        {
          img: Tip7,
          tip:
            "ينصح الأطباء بإجراء فحص شامل لعيون الطفل في السنة الرابعة أو الخامسة من عمره لاكتشاف أي ضعف نظرغير ملاحظ  من الأهل والوقاية  أو علاج الكسل البصري مبكراً",
          numb: "9",
        },
        {
          img: Tip8,
          tip:
            "تجنب النظر المباشر الى أي مصدر للضوء القوي  كالشمس أو أي انارة ساطع",
          numb: "8",
        },
        {
          img: Tip9,
          tip:
            "لتجنب ارهاق عينيك لا تنسى قاعدة 20-20-20 <br><br>كل 20  دقيقة من النظر الى الشاشة انظرالى شيء يبعُد عنك  قدم ولمدة 20 ثانية",
          numb: "7",
        },
        {
          img: Tip10,
          tip:
            "اذا كان عمرك  فوق الأربعين أو اذا كان أحد أفراد عائلتك مصاب بمرض ارتفاع ضغط العين (الجلوكوما                               ) ننصحك  بقياس ضغط العين بشكل دوري سنوياً لخطورة هذا المرض اذ يسمى بسارق النظر الصامت",
          numb: "12",
        },
        {
          img: Tip11,
          tip:
            "اذا كنت تعاني من رؤية أجسام عائمة كالذباب، ننصحك بزيارة طبيب العيون للتأكد من سلامة الشبكية ",
          numb: "11",
        },
        {
          img: Tip12,
          tip:
            "عند تشخيصك بمرض السكري من الضروري أن يتم فحص قاع العين والتأكد من سلامة الشبكية بشكل دوري ",
          numb: "10",
        },
        {
          img: Tip13,
          tip:
            "التوعية في مواقع العمل بارتداء نظارة الحماية الضرورية لأصحاب المهن الذين يتطلب منهم حماية عيونهم خلال أداء عملهم حفاظاً على سلامتهم ",
          numb: "15",
        },
        {
          img: Tip14,
          tip:
            "اذا تطلبت طبيعة عملك كثرة النظر إلى شاشات الأجهزة الرقمية ننصحك باستخدام قطرات الترطيب ويفضل القطرات الخالية من المواد الحافظة",
          numb: "14",
        },

        {
          img: Tip15,
          tip:
            "تجنب فرك العين، وفي حال وجود حكّة استشر طبيب العيون بأسرع وقت ممكن",
          numb: "13",
        },
        {
          img: Tip16,
          tip:
            "يجب على مستخدمي العدسات اللاصقة مراعة معايير سلامة العين وذلك بغسل اليدين قبل وضع وازالة العدسات و حفظهم بالعلبة والمحلول الخاص بهم بالاضافة الى تجنب وضعهم أثناء النوم ",
          numb: "16",
        },
      ],
    };
  },
  methods: {
    btnclick() {
      this.overlay = false;
    },
  },
};
</script>

<style>
</style>